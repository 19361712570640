<div class="mt-4 row">
  <div class="col-md-12">
    <!-- <a [routerLink]="['/']" class="btn btn-dark"> <i class="fa fa-sign-out-alt"></i> Exit</a> -->
    <button
      routerLink="/application/list"
      class="btn btn-dark float-left md-btn"
    >
      <i class="fa fa-arrow-circle-left">
        {{ "applications.EXIT_COMMUNICATIONS" | translate }}</i
      >
    </button>
    <app-create-ticket
      [successFn]="getData"
      [template]="addTicket"
    ></app-create-ticket>
    <br />
  </div>
</div>
<br />
<div class="mt-4 row">
  <div class="col-md-6">
    <div class="d-flex justify-content-between header-bg">
      <h4>{{ "applications.HELP_TICKETS_CREATED_HEADER" | translate }}</h4>
    </div>
    <div class="card" *ngFor="let item of commThread">
      <div class="card-body">
        <h5 class="card-title">
          {{ "applications.SUBJECT" | translate }}: {{ item.subject }}
        </h5>
        <h6 class="card-title">APP ID: {{ item.related_applications }}</h6>
        <h6 class="card-title">
          {{ "applications.TYPE" | translate }}:
          {{ item.ticket_type | transform | translate }}
        </h6>
        <h6 class="card-subtitle mb-2 text-muted">
          {{ "applications.MESSAGE" | translate }}: {{ item.details }} <br />
          {{ item.datecreated * 1000 | date }}
        </h6>
        <p class="card-text chips">
          <a
            *ngIf="item.file_doc_1"
            target="_blank"
            [href]="getFileRouteLink(item.file_doc_1_url)"
            >{{ item.file_doc_1 }}</a
          >
          <a
            *ngIf="item.file_doc_2"
            target="_blank"
            [href]="getFileRouteLink(item.file_doc_2_url)"
            >{{ item.file_doc_2 }}</a
          >
          <a
            *ngIf="item.file_doc_3"
            target="_blank"
            [href]="getFileRouteLink(item.file_doc_3_url)"
            >{{ item.file_doc_3 }}</a
          >
          <a
            *ngIf="item.file_doc_4"
            target="_blank"
            [href]="getFileRouteLink(item.file_doc_4_url)"
            >{{ item.file_doc_4 }}</a
          >
          <a
            *ngIf="item.file_doc_5"
            target="_blank"
            [href]="getFileRouteLink(item.file_doc_5_url)"
            >{{ item.file_doc_5 }}</a
          >
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="d-flex justify-content-between header-bg">
      <h4>
        {{ "applications.MESSAGES_FROM_CASE_MANAGER_HEADER" | translate }}
      </h4>
    </div>
    <div class="card" *ngFor="let item of outboundMessages">
      <div class="card-body">
        <h5 class="card-title">
          {{ "applications.SUBJECT" | translate }}: {{ item.subject }}
        </h5>
        <h6 class="card-title">APP ID: {{ item.related_applications }}</h6>
        <h6 class="card-subtitle mb-2 text-muted">
          {{ "applications.MESSAGE" | translate }}: {{ item.details }} <br />
          {{ item.datecreated * 1000 | date }}
        </h6>
      </div>
    </div>
  </div>
</div>

<ng-template #addTicket>
  <button class="float2-btn" style="top: unset; z-index: 999">
    <i class="fa fa-envelope"></i>
  </button>
</ng-template>
