<div class="main">
  <div><br /></div>
  <div class="card">
    <div class="card-header background-secondary  mt-2 mb-3">
      <!-- NES - disallow landlord to create application -->
      <div *ngIf="applicantType === 'Landlord'"><h4 class="mb-0 ">{{ 'applications.CREATE_APPLICATION' | translate }} - Landlord</h4></div>
      <!-- NES - disallow applicant to create application -->
      <div *ngIf="applicantType === 'Applicant1'"><h4 class="mb-0 ">{{ 'applications.CREATE_APPLICATION' | translate }} - Applicant</h4></div>

      <div *ngIf="applicantType === 'Applicant'"><h4 class="mb-0 ">{{ 'applications.CREATE_APPLICATION' | translate }} - {{ 'applications.FEMA_ID_CONFIRMATION' | translate }}</h4></div>

    </div>
    <form [formGroup]="appForm" (ngSubmit)="onAppFormSubmit()">
      <div *ngIf="applicantType === 'Applicant'">
        <div class="form-body form-margin">
          <div class="card-body">

            <div class="row background-secondary mt-4 mb-3">
              <div class="col-12">
                <h4 class="mb-0 pt-2 pb-2">{{ 'applications.FEMA_ID' | translate }}</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    {{ 'applications.FEMA_ID' | translate }} <app-required [control]="appForm.controls['fema_id']"></app-required>
                  </label>

                  <div class="input-group">
                    <div class="input-group-prepend" style="width: 25%;">
                      <input
                        class="form-control"
                        type="text"
                        value="FEMA ID"
                        disabled>
                    </div>
                    <div style="width: 75%;">
                      <div class="d-flex">
                        <input type="text" class="form-control" formControlName="fema_id">
                        <!-- <input #tin_enc [mask]="tinMask" type="text" class="form-control pw" formControlName="fema_id">
                        <button type="button" class="btn" (click)="viewProtected(fema_id)"><i class="fa fa-eye"></i></button> -->
                      </div>
                      <small *ngIf="appForm.controls.fema_id.touched && appForm.controls.fema_id.errors?.required"
                        class="text-danger">
                        {{ 'errors.REQUIRED' | translate }}
                      </small>
                      <!-- <small *ngIf="appForm.controls.fema_id.touched && appForm.controls.fema_id.errors?.pattern"
                        class="text-danger">
                        <div>{{ 'errors.INVALID_TIN' | translate }} FEMA ID</div>
                      </small> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div><label class="control-label">{{ 'applications.CONFIRM' | translate }} FEMA ID <app-required [control]="appForm.controls['cfema_id']"></app-required></label></div>
                  <div class="d-flex">
                    <input type="text" class="form-control" formControlName="cfema_id">
                    <!-- <input #ctin [mask]="tinMask" type="text" class="form-control pw" formControlName="cfema_id">
                    <button type="button" class="btn" (click)="viewProtected(cfema_id)"><i class="fa fa-eye"></i></button> -->
                  </div>
                  <small *ngIf="appForm.controls.cfema_id.touched && appForm.controls.cfema_id.errors?.required"
                    class="text-danger">
                    {{ 'errors.REQUIRED' | translate }}
                  </small>
                  <small *ngIf="appForm.controls.cfema_id.touched && appForm.controls.cfema_id.errors?.noMatch"
                    class="text-danger">
                    <div>FEMA ID {{ 'errors.NO_MATCH' | translate }}</div>
                  </small>
                </div>
              </div>
            </div>

            <div class="mt-3 btn-responsive">
              <button type="submit" class="btn btn-save">
                <i class="fa fa-check"> {{ 'applications.SAVE' | translate }}</i>
              </button>
              <button type="button" routerLink="/application/list" class="btn btn-dark">
                <i class="fa fa-times"> {{ 'applications.CANCEL' | translate }}</i>
              </button>
              <small [innerHTML]="'applications.SAVE_BTN_INFO_TEXT' | translate"></small>
            </div>
            
          </div>
        </div>
      </div>

      <div *ngIf="applicantType === 'Applicant1'">
        <div class="form-body form-margin">
          <div class="card-body">

            <div class="row background-secondary mt-4 mb-3">
              <div class="col-12">
                <h4 class="mb-0 pt-2 pb-2">{{ 'applications.CONTACT_DETAILS' | translate }}</h4>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-2">
                <label class="control-label">{{ 'applications.USER_FIRST_NAME' | translate }}
                  <app-required [mark]="true"></app-required><app-required [control]="appForm.controls['contact_first_name']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_first_name" noSpecialChars>
                <small
                  *ngIf="appForm.controls.contact_first_name.touched && appForm.controls.contact_first_name.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-2">
                <label class="control-label">{{ 'applications.USER_MI' | translate }}<app-required [mark]="true"></app-required><app-required [control]="appForm.controls['contact_mi']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_mi" noSpecialChars>
                <small *ngIf="appForm.controls.contact_mi.touched && appForm.controls.contact_mi.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-2">
                <label class="control-label">{{ 'applications.USER_LAST_NAME' | translate }}<app-required [mark]="true"></app-required><app-required [control]="appForm.controls['contact_last_name']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_last_name" noSpecialChars>
                <small
                  *ngIf="appForm.controls.contact_last_name.touched && appForm.controls.contact_last_name.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.CONTACT_PHONE' | translate }}<app-required [mark]="true"></app-required><app-required [control]="appForm.controls['contact_phone']"></app-required></label>
                <input mask="(000) 000-0000" type="text" class="form-control" formControlName="contact_phone">
                <small *ngIf="appForm.controls.contact_phone.touched && appForm.controls.contact_phone.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
                <small *ngIf="appForm.controls.contact_phone.touched && appForm.controls.contact_phone.errors?.pattern"
                  class="text-danger">
                  {{ 'errors.INVALID_PHONE' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.CONTACT_EMAIL' | translate }}<app-required [mark]="true"></app-required><app-required [control]="appForm.controls['contact_email']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_email">
                <small *ngIf="appForm.controls.contact_email.touched && appForm.controls.contact_email.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
                <small *ngIf="appForm.controls.contact_email.touched && appForm.controls.contact_email.errors?.pattern"
                  class="text-danger">
                  {{ 'errors.INVALID_EMAIL' | translate }}
                </small>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    {{ 'applications.SSN_OR_ITIN' | translate }}
                  </label>

                  <div class="input-group">
                    <div class="input-group-prepend" style="width: 25%;">
                      <input
                        class="form-control"
                        type="text"
                        value="SSN/ITIN"
                        disabled>
                    </div>
                    <div style="width: 75%;">
                      <div class="d-flex">
                        <input #tin [mask]="tinMask" type="password" class="form-control" formControlName="tin">
                        <button type="button" class="btn" (click)="viewProtected(tin)"><i class="fa fa-eye"></i></button>
                      </div>
                      <small *ngIf="appForm.controls.tin.touched && appForm.controls.tin.errors?.required"
                        class="text-danger">
                        {{ 'errors.REQUIRED' | translate }}
                      </small>
                      <small *ngIf="appForm.controls.tin.touched && appForm.controls.tin.errors?.pattern"
                        class="text-danger">
                        <div *ngIf="appForm.value['tin_type'] === 'SSN'">{{ 'errors.INVALID_TIN' | translate }} SSN/ITIN</div>
                        <div *ngIf="appForm.value['tin_type'] !== 'SSN'">{{ 'errors.INVALID_TIN' | translate }} EIN</div>
                      </small>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    {{ 'applications.CONFIRM' | translate }} {{ 'applications.SSN_OR_ITIN' | translate }}
                  </label>
                  <div class="d-flex">
                    <input #ctin [mask]="tinMask" type="password" class="form-control" formControlName="ctin">
                    <button type="button" class="btn" (click)="viewProtected(ctin)"><i class="fa fa-eye"></i></button>
                  </div>
                  <small *ngIf="appForm.controls.ctin.touched && appForm.controls.ctin.errors?.required"
                    class="text-danger">
                    {{ 'errors.REQUIRED' | translate }}
                  </small>
                  <small *ngIf="appForm.controls.ctin.touched && appForm.controls.ctin.errors?.noMatch"
                    class="text-danger">
                    <div *ngIf="appForm.value['tin_type'] === 'SSN'">SSN/ITIN {{ 'errors.NO_MATCH' | translate }}</div>
                    <div *ngIf="appForm.value['tin_type'] !== 'SSN'">EIN {{ 'errors.NO_MATCH' | translate }}</div>
                  </small>
                </div>
              </div>
            </div> -->

            <div class="row background-secondary mt-2 mb-3">
              <div class="col-12">
                <h4 class="mb-0 pt-2 pb-2">{{ 'applications.ADDRESS_DETAILS' | translate }}</h4>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.STREET' | translate }}<app-required [control]="appForm.controls['physical_street']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_street"
                  ngx-google-places-autocomplete [options]="options"
                  (onAddressChange)="addressChangePhysical($event, 'physical_street')"
                  placeholder="{{'applications.PLACEHOLDER_ADDRESS' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_street.touched && appForm.controls.physical_street.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.STREET_2' | translate }}<app-required [control]="appForm.controls['physical_street_2']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_street_2" placeholder="{{'applications.PLACEHOLDER_UNIT' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_street_2.touched && appForm.controls.physical_street_2.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.CITY' | translate }}<app-required [control]="appForm.controls['physical_city']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_city" placeholder="{{'applications.PLACEHOLDER_CITY' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_city.touched && appForm.controls.physical_city.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.STATE' | translate }}<app-required [control]="appForm.controls['physical_state']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_state" placeholder="{{'applications.PLACEHOLDER_STATE' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_state.touched && appForm.controls.physical_state.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.ZIP_CODE' | translate }}<app-required [control]="appForm.controls['physical_zip_code']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_zip_code" placeholder="{{'applications.PLACEHOLDER_ZIP' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_zip_code.touched && appForm.controls.physical_zip_code.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>


              <div class="col-md-6">
                <label class="control-label">{{ 'applications.PARISH' | translate }}<app-required [control]="appForm.controls['parish']"></app-required></label>
                <select
                  class="form-control"
                  formControlName="parish">
                  <option value="">- {{"applications.SELECT" | translate}} -</option>
                  <option *ngFor="let p of parishes" value="{{p.Value}}">{{p.Name | transform | translate}}
                  </option>
                </select>
                <!-- <small
                  *ngIf="appForm.controls.parish.touched && appForm.controls.parish.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small> -->
              </div>
            </div>

            <!-- <div class="row pt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{ 'applications.PROPERTY_NAME' | translate }}</label>
                  <input type="text" class="form-control" formControlName="business_name"
                    placeholder="{{ 'applications.PROPERTY_NAME_PLACEHOLDER' | translate}}">
                  <small *ngIf="appForm.controls.business_name.touched && appForm.controls.business_name.errors?.required"
                    class="text-danger">
                    {{ 'errors.REQUIRED' | translate }}
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{ 'applications.LANDLORD_EMAIL' | translate }}</label>
                  <input type="text" class="form-control" formControlName="landlord_email"
                    placeholder="{{ 'applications.LANDLORD_EMAIL_PLACEHOLDER' | translate}}">
                  <small *ngIf="appForm.controls.landlord_email.touched && appForm.controls.landlord_email.errors?.required"
                    class="text-danger">
                    {{ 'errors.REQUIRED' | translate }}
                  </small>
                  <small *ngIf="appForm.controls.landlord_email.touched && appForm.controls.landlord_email.errors?.pattern"
                    class="text-danger">
                    {{ 'errors.INVALID_EMAIL' | translate }}
                  </small>
                </div>
              </div>
            </div> -->

            <div class="mt-3 btn-responsive">
              <button type="submit" class="btn btn-save">
                <i class="fa fa-check"> {{ 'applications.SAVE' | translate }}</i>
              </button>
              <button type="button" routerLink="/application/list" class="btn btn-dark">
                <i class="fa fa-times"> {{ 'applications.CANCEL' | translate }}</i>
              </button>
              <small [innerHTML]="'applications.SAVE_BTN_INFO_TEXT' | translate"></small>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="applicantType === 'Landlord'">
        <div class="form-body form-margin">
          <div class="card-body">
            <div class="row pt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{ 'applications.BUSINESS_NAME' | translate }}<app-required [control]="appForm.controls['business_name']"></app-required></label>
                  <input type="text" class="form-control" formControlName="business_name" noSpecialChars
                    placeholder="{{ 'applications.BUSINESS_NAME_PLACEHOLDER' | translate}}">
                  <small *ngIf="appForm.controls.business_name.touched && appForm.controls.business_name.errors?.required"
                    class="text-danger">
                    {{ 'errors.REQUIRED' | translate }}
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <label>{{ 'applications.BUSINESS_ENTITY_TYPE' | translate }}<app-required [control]="appForm.controls['business_entity_type']"></app-required></label>
                <select
                  class="form-control"
                  (change)="onChangeBusinessEntityType($event.target.value)"
                  formControlName="business_entity_type">
                  <option value="">- {{"applications.SELECT" | translate}} -</option>
                  <option *ngFor="let entity of businessEntities" value="{{entity.Value}}">{{entity.Name | transform | translate}}
                  </option>
                </select>
                <small
                  *ngIf="appForm.controls.business_entity_type.touched && appForm.controls.business_entity_type.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    {{ 'applications.TIN' | translate }} <app-required [control]="appForm.controls['tin_enc']"></app-required>
                  </label>

                  <div class="input-group">
                    <div class="input-group-prepend" style="width: 25%;">
                      <input
                        *ngIf="appForm.value['business_entity_type'] === 'Sole Proprietor'"
                        class="form-control"
                        type="text"
                        value="SSN/ITIN"
                        disabled>
                      <select *ngIf="appForm.value['business_entity_type'] !== 'Sole Proprietor'" class="form-control" formControlName="tin_type"
                        (change)="onChangeTintype($event.target.value)">
                        <option value="EIN">EIN</option>
                        <option value="SSN">SSN/ITIN</option>
                      </select>
                    </div>
                    <div style="width: 75%;">
                      <div class="d-flex">
                        <input #tin_enc [mask]="tinMask" type="text" class="form-control pw" formControlName="tin_enc">
                        <button type="button" class="btn" (click)="viewProtected(tin_enc)"><i class="fa fa-eye"></i></button>
                      </div>
                      <small *ngIf="appForm.controls.tin_enc.touched && appForm.controls.tin_enc.errors?.required"
                        class="text-danger">
                        {{ 'errors.REQUIRED' | translate }}
                      </small>
                      <small *ngIf="appForm.controls.tin_enc.touched && appForm.controls.tin_enc.errors?.pattern"
                        class="text-danger">
                        <div *ngIf="appForm.value['tin_type'] === 'SSN'">{{ 'errors.INVALID_TIN' | translate }} SSN/ITIN</div>
                        <div *ngIf="appForm.value['tin_type'] !== 'SSN'">{{ 'errors.INVALID_TIN' | translate }} EIN</div>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div *ngIf="appForm.value['tin_type'] === 'SSN'"><label class="control-label">{{ 'applications.CONFIRM' | translate }} SSN/ITIN <app-required [control]="appForm.controls['ctin']"></app-required></label></div>
                  <div *ngIf="appForm.value['tin_type'] !== 'SSN'"><label class="control-label">{{ 'applications.CONFIRM' | translate }} EIN <app-required [control]="appForm.controls['ctin']"></app-required></label></div>
                  <div class="d-flex">
                    <input #ctin [mask]="tinMask" type="text" class="form-control pw" formControlName="ctin">
                    <button type="button" class="btn" (click)="viewProtected(ctin)"><i class="fa fa-eye"></i></button>
                  </div>
                  <small *ngIf="appForm.controls.ctin.touched && appForm.controls.ctin.errors?.required"
                    class="text-danger">
                    {{ 'errors.REQUIRED' | translate }}
                  </small>
                  <small *ngIf="appForm.controls.ctin.touched && appForm.controls.ctin.errors?.noMatch"
                    class="text-danger">
                    <div *ngIf="appForm.value['tin_type'] === 'SSN'">SSN/ITIN {{ 'errors.NO_MATCH' | translate }}</div>
                    <div *ngIf="appForm.value['tin_type'] !== 'SSN'">EIN {{ 'errors.NO_MATCH' | translate }}</div>
                  </small>
                </div>
              </div>
            </div>

            <!-- <div class="row" *ngIf="appForm.value['business_entity_type'] === 'Sole Proprietor'">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    {{ 'applications.TIN' | translate }}
                  </label>

                  <div class="input-group">
                    <div class="input-group-prepend" style="width: 25%;">
                      <input
                        class="form-control"
                        type="text"
                        value="{{appForm.value['tin_type2']}}"
                        disabled>
                    </div>
                    <div style="width: 75%;">
                      <div class="d-flex">
                        <input #tin_2 [mask]="tin_2Mask" type="password" class="form-control" formControlName="tin_2">
                        <button type="button" class="btn" (click)="viewProtected(tin_2)"><i class="fa fa-eye"></i></button>
                      </div>
                      <small *ngIf="appForm.controls.tin_2.touched && appForm.controls.tin_2.errors?.required"
                        class="text-danger">
                        {{ 'errors.REQUIRED' | translate }}
                      </small>
                      <small *ngIf="appForm.controls.tin_2.touched && appForm.controls.tin_2.errors?.pattern"
                        class="text-danger">
                        <div *ngIf="appForm.value['tin_type2'] === 'SSN'">{{ 'errors.INVALID_TIN' | translate }} SSN/ITIN</div>
                        <div *ngIf="appForm.value['tin_type2'] !== 'SSN'">{{ 'errors.INVALID_TIN' | translate }} EIN</div>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    {{ 'applications.CONFIRM' | translate }} {{appForm.value['tin_type2']}}
                  </label>
                  <div class="d-flex">
                    <input #ctin_2 [mask]="tin_2Mask" type="password" class="form-control" formControlName="ctin_2">
                    <button type="button" class="btn" (click)="viewProtected(ctin_2)"><i class="fa fa-eye"></i></button>
                  </div>
                  <small *ngIf="appForm.controls.ctin_2.touched && appForm.controls.ctin_2.errors?.required"
                    class="text-danger">
                    {{ 'errors.REQUIRED' | translate }}
                  </small>
                  <small *ngIf="appForm.controls.ctin_2.touched && appForm.controls.ctin_2.errors?.noMatch"
                    class="text-danger">
                    <div *ngIf="appForm.value['tin_type2'] === 'SSN'">SSN/ITIN {{ 'errors.NO_MATCH' | translate }}</div>
                    <div *ngIf="appForm.value['tin_type2'] !== 'SSN'">EIN {{ 'errors.NO_MATCH' | translate }}</div>
                  </small>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    {{ 'applications.DUNS' | translate }}
                    <app-info-pop [visible]="true" [template]="DUNSNumberInfo"></app-info-pop>
                  </label>

                  <div class="input-group">
                    <div >
                      <div class="d-flex">
                        <input #duns_nbr [mask]="dunsMask" type="text" class="form-control pw" formControlName="duns_nbr">
                        <button type="button" class="btn" (click)="viewProtected(duns_nbr)"><i class="fa fa-eye"></i></button>
                      </div>
                      <small *ngIf="appForm.controls.duns_nbr.touched && appForm.controls.duns_nbr.errors?.pattern"
                        class="text-danger">
                        {{ 'errors.INVALID_DUNS' | translate }}
                      </small>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="row background-secondary mt-2 mb-3">
              <div class="col-12">
                <h4 class="mb-0 pt-2 pb-2">{{ 'applications.BUSINESS_ADDRESS' | translate }}</h4>
              </div>
            </div>

            <!-- <div class="row mb-3">
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.PROPERTY_NAME' | translate }}</label>
                <input type="text" class="form-control" formControlName="property_name"
                  placeholder="{{ 'applications.PROPERTY_NAME_PLACEHOLDER' | translate}}">
                <small *ngIf="appForm.controls.property_name.touched && appForm.controls.property_name.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.PROPERTY_DESCRIPTION' | translate }}</label>
                <input type="text" class="form-control" formControlName="property_description"
                  placeholder="{{ 'applications.PROPERTY_DESCRIPTION_PLACEHOLDER' | translate}}">
                <small *ngIf="appForm.controls.property_description.touched && appForm.controls.property_description.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.PROPERTY_TYPE' | translate }}</label>
                <select
                  class="form-control"
                  formControlName="property_type">
                  <option value="">- Select -</option>
                  <option *ngFor="let pt of propertyTypes" value="{{pt.value}}">{{pt.name}}
                  </option>
                </select>
                <small
                  *ngIf="appForm.controls.property_type.touched && appForm.controls.property_type.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div> -->

            <div class="row mb-3">
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.PHYSICAL_STREET' | translate }}<app-required [control]="appForm.controls['physical_street']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_street"
                  ngx-google-places-autocomplete [options]="options"
                  (onAddressChange)="addressChangePhysical($event, 'physical_street')"
                  placeholder="{{'applications.PLACEHOLDER_ADDRESS' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_street.touched && appForm.controls.physical_street.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.PHYSICAL_STREET_2' | translate }}<app-required [control]="appForm.controls['physical_street_2']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_street_2" placeholder="{{'applications.PLACEHOLDER_UNIT' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_street_2.touched && appForm.controls.physical_street_2.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.PHYSICAL_CITY' | translate }}<app-required [control]="appForm.controls['physical_city']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_city" placeholder="{{'applications.PLACEHOLDER_CITY' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_city.touched && appForm.controls.physical_city.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.PHYSICAL_STATE' | translate }}<app-required [control]="appForm.controls['physical_state']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_state" placeholder="{{'applications.PLACEHOLDER_STATE' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_state.touched && appForm.controls.physical_state.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.PHYSICAL_ZIP_CODE' | translate }}<app-required [control]="appForm.controls['physical_zip_code']"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_zip_code" placeholder="{{'applications.PLACEHOLDER_ZIP' | translate}}" noSpecialChars>
                <small
                  *ngIf="appForm.controls.physical_zip_code.touched && appForm.controls.physical_zip_code.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>


            <div class="row background-secondary mt-4 mb-3">
              <div class="col-12">
                <h4 class="mb-0  pt-2 pb-2">{{ 'applications.CONTACT_DETAILS' | translate }}</h4>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-2">
                <label class="control-label">{{ 'applications.USER_FIRST_NAME' | translate }}<app-required [control]="appForm.controls['contact_first_name']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_first_name" noSpecialChars>
                <small
                  *ngIf="appForm.controls.contact_first_name.touched && appForm.controls.contact_first_name.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-2">
                <label class="control-label">{{ 'applications.USER_MI' | translate }}<app-required [control]="appForm.controls['contact_mi']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_mi" noSpecialChars>
                <small *ngIf="appForm.controls.contact_mi.touched && appForm.controls.contact_mi.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-2">
                <label class="control-label">{{ 'applications.USER_LAST_NAME' | translate }}<app-required [control]="appForm.controls['contact_last_name']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_last_name" noSpecialChars>
                <small
                  *ngIf="appForm.controls.contact_last_name.touched && appForm.controls.contact_last_name.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.CONTACT_TITLES' | translate }}<app-required [control]="appForm.controls['contact_titles']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_titles">
                <small *ngIf="appForm.controls.contact_titles.touched && appForm.controls.contact_titles.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.CONTACT_PHONE' | translate }}<app-required [control]="appForm.controls['contact_phone']"></app-required></label>
                <input mask="(000) 000-0000" type="text" class="form-control" formControlName="contact_phone">
                <small *ngIf="appForm.controls.contact_phone.touched && appForm.controls.contact_phone.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
                <small *ngIf="appForm.controls.contact_phone.touched && appForm.controls.contact_phone.errors?.pattern"
                  class="text-danger">
                  {{ 'errors.INVALID_PHONE' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.CONTACT_EMAIL' | translate }}<app-required [control]="appForm.controls['contact_email']"></app-required></label>
                <input type="text" class="form-control" formControlName="contact_email">
                <small *ngIf="appForm.controls.contact_email.touched && appForm.controls.contact_email.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
                <small *ngIf="appForm.controls.contact_email.touched && appForm.controls.contact_email.errors?.pattern"
                  class="text-danger">
                  {{ 'errors.INVALID_EMAIL' | translate }}
                </small>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.BUSINESS_PHONE' | translate }}<app-required [control]="appForm.controls['business_phone']"></app-required></label>
                <input mask="(000) 000-0000" type="text" class="form-control" formControlName="business_phone">
                <small
                  *ngIf="appForm.controls.business_phone.touched && appForm.controls.business_phone.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
                <small
                  *ngIf="appForm.controls.business_phone.touched && appForm.controls.business_phone.errors?.pattern"
                  class="text-danger">
                  {{ 'errors.INVALID_PHONE' | translate }}
                </small>
              </div>
            </div>


            <div class="mt-3 btn-responsive">
              <button type="submit" class="btn btn-save">
                <i class="fa fa-check"> {{ 'applications.SAVE' | translate }}</i>
              </button>
              <button type="button" routerLink="/application/list" class="btn btn-dark">
                <i class="fa fa-times"> {{ 'applications.CANCEL' | translate }}</i>
              </button>
              <small [innerHTML]="'applications.SAVE_BTN_INFO_TEXT' | translate"></small>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>

<!--------------------------------- BUBBLES START --------------------------------->

<ng-template #DUNSNumberInfo>
	<h1>{{ 'applications.DUNS' | translate }}</h1>
	<p>{{ 'BUBBLE.DUNS_NUMBER' | translate }}</p>
</ng-template>

<!--------------------------------- BUBBLES END --------------------------------->
