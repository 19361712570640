import { AppData } from "./app-data"

export class ProjectSpecificData {
    appId: number
    appData: AppData
    applicationsData: ApplicationsData
    documentsData: DocumentsData
    expensesData: ExpensesData
    paymentFilesData: PaymentFilesData
    householdData: HouseholdData
    //pastDueAmountsData: PastDueAmountsData
    applicantInvitationsData: ApplicantInvitationsData
    zipCodesData: ZipCodesData
    commThreadsData: CommThreadsData
    propertiesData: PropertiesData
    outboundMessagesData: OutboundMessagesData
}

export class ApplicationsData {
    TableId: number;
    ApplicationListReportId: number;
    LandlordApplicationListReportId: number;
    RecordIdFieldId: number;
    BusinessEntityTypeMCID: number;
    RaceMCID: number
    EthnicityMCID: number
    VeteranMCID: number
    GenderMCID: number
    //BusinessTypeMCID: number;
    //BankAccountTypeMCID: number;
    ParishMCID: number
    PropertyTypeMCID: number;
    LandlordEmail: number;
    LandlordStatus: number;
    LandlordID: number;
    ApplicantType: number;
    CreatedBy: number;
    TinId: number;
    TinTypeId: number;
    IsValidLLAck: number;
    IsValidLLBusiness: number;
    IsValidLLContact: number;
    IsValidLLPayment: number;
    IsValidLLProperties: number;
    IsValidRAck: number;
    IsValidRAddress: number;
    IsValidRContact: number;
    IsValidRDocs: number;
    IsValidRExpenses: number;
    IsValidRHousehold: number;
    IsValidRLandlord: number;
    HouseholdErrorMessage: number
}

export class DocumentsData {
    TableId: number
    RecordIdFieldId: number
    RelatedExpensesFieldId: number
    RelatedApplicationsFieldId: number
    documentTypeFieldId: number
}

export class ExpensesData {
    TableId: number
    RecordIdFieldId: number
    ExpenseTypeMCID: number
    InArrearsMCID: number
    RelatedApplicationsFieldId: number
    ElectricityCompaniesFieldId: number
}

export class PaymentFilesData {
    TableId: number
    RecordIdFieldId: number
    RelatedApplicationsFieldId: number
}

export class HouseholdData {
    TableId: number
    RecordIdFieldId: number
    DOBId: number
    HouseholdNumberMCID: number
    EmploymentStatusMCID: number
    RaceMCID: number
    EthnicityMCID: number
    VeteranMCID: number
    GenderMCID: number
    IncomeGrossAmountTypeMCID: number
    RelatedApplicationsFieldId:number
    RelationToApplicant: number
    ErrorMessageFieldId: number
}

// export class PastDueAmountsData {
//     TableId: number
//     RecordIdFieldId: number
//     MonthMCID: number
//     RelatedApplicationsFieldId:number
//     RelatedExpensesFieldId:number
// }

export class ApplicantInvitationsData {
    TableId: number
    RecordIdFieldId: number
}

export class ZipCodesData {
    TableId: number
    RecordIdFieldId: number
}

export class CommThreadsData {
    TableId: number
    RecordIdFieldId: number
    TicketTypeMCID: number
    ElectricityCompaniesFieldId: number
}

export class PropertiesData {
    TableId: number
    RecordIdFieldId: number
    PropertyTypeMCID: number;
    RelatedApplicationsFieldId: number
}

export class OutboundMessagesData {
    TableId: number
    RecordIdFieldId: number
}