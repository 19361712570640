export class PopupModel {
  busy: boolean = false;
  title: string;
  modalClass?: any;
  settings: any = {
    size: "lg",
    centered: true,
    backdrop: false,
    animation: true,
    keyboard: false,
    ariaLabelledBy: "modal-basic-title",
    windowClass: "",
  };
}
