<!-- <div *ngIf="applicantType === 'Landlord'">Landlord</div>
<div *ngIf="applicantType === 'Applicant'">Applicant</div> -->

<div class="wrapper">
  <div class="card">
    <div class="card-body">
      <div class="text-center">
        <h6>
          <p>{{ "applications.HEADER_1" | translate }} <br /></p>
          <p>
            {{ "applications.HEADER_2" | translate }}
            <!-- <br>For assistance on the weekend, our Contact Center will be open noon - 5:00 p.m. (central).</p> -->
          </p>

          <!-- <p>{{ "applications.OUR_CONTACT_CENTER_NO_PERSONAL" | translate }}</p> -->
        </h6>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      applicationData.length === 0 ||
      (!canApprove() && applicantType === 'Landlord')
    "
  >
    <div class="error-body text-center">
      <div *ngIf="receivedData">
        <h4 class="m-t-30 m-b-30 error-subtitle text-danger">
          {{ "errors.INVALID_FEMA_ID_TEXT" | translate }}<br />{{ "errors.INVALID_FEMA_ID_TEXT2" | translate }}
        </h4>
        <h4 class="m-t-30 m-b-30 error-subtitle text-danger">
          {{ "errors.INVALID_FEMA_ID_TEXT3" | translate }}
        </h4>
      </div>
      
      <h3 class="text-uppercase error-subtitle">
        {{ "applications.DONT_HAVE_APPLICATION" | translate }}
      </h3>
      <div class="card" *ngIf="applicantType === 'Applicant'">
        <div class="card-body">
          <div class="text-left">
            <div class="row">
              <div class="col-md-8">
                <label
                  ><b>{{ "applications.HOME_1" | translate }}</b></label
                >
                <p>{{ "applications.HOME_2" | translate }}</p>
                <label
                  ><b>{{ "applications.HOME_3" | translate }}</b></label
                >
                <ul>
                  <li>{{ "applications.HOME_4" | translate }}</li>
                  <li>{{ "applications.HOME_5" | translate }}</li>
                  <li>{{ "applications.HOME_6" | translate }}</li>
                  <li>{{ "applications.HOME_7" | translate }}</li>
                  <!-- <li>{{ "applications.HOME_8" | translate }}</li> -->
                </ul>
              </div>
              <div class="col-md-4 help-section">
                <label
                  ><b>{{ "applications.NEED_HELP" | translate }}</b></label
                >
                <p>{{ "applications.NEED_HELP_TEXT" | translate }}</p>
                <!-- <p>{{ "applications.NEED_HELP_LINE_1" | translate }}</p>
                <p>{{ "applications.NEED_HELP_LINE_2" | translate }}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- NES - disallow create application -->
      <!-- <div *ngIf="applicantType === 'Landlord'"> -->


        
      

      <!-- <ng-container *ngIf="!receivedData"> -->
        <h4 class="m-t-30 m-b-30">
          {{ "applications.COMPLETE_SURVEY2" | translate }}
        </h4>
        <a
          routerLink="/application/add"
          class="btn btn-info btn-rounded m-b-40 btn-success1"
        >
          {{ "applications.CREATE_APPLICATION" | translate }}</a
        >
      <!-- </ng-container> -->
      <!-- </div> -->
    </div>
  </div>

  <div *ngIf="applicationData.length > 0">
    <!---------------------- LANDLORD ------------------->
    <div *ngIf="applicantType === 'Landlord'">
      <div *ngFor="let app of applicationData">
        <div class="card" *ngIf="app.applicant_type === 'Landlord'">
          <div class="card-body">
            <div class="row col-md-12">
              <div class="col-md-12">
                <h4>
                  <p class="text-dfa">
                    <strong>{{ app.business_name }}</strong>
                  </p>
                </h4>
                <h5 class="card-title text-dfa">
                  <!-- <span class="label label-success1"> {{app.tin_type}}</span>
                      &nbsp;
                      <span *ngIf="app.tin_type === 'EIN'">
                        ##-###{{app.tin.toString().substring(5,9)}}
                      </span>
                      <span *ngIf="app.tin_type === 'SSN'">
                        ###-##-{{app.tin.toString().substring(5,9)}}
                      </span> -->
                  <span class="label label-success1">APP ID</span> {{ app.id }}
                </h5>
              </div>
              <div class="col-md-3">
                <h5 class="card-title text-dfa">
                  {{ "applications.APPLICATION_STATUS" | translate }} :
                  <span class="text-success1">{{
                    app.status | transform | translate
                  }}</span>
                </h5>
                <h5
                  *ngIf="app.status != 'Ineligible' && app.status != 'Pre-Eligibility'"
                  class="card-title text-dfa"
                >
                  {{ "applications.COMPLETED" | translate }} :
                  <span class="text-success1">
                    {{ app.percent_complete }}%</span
                  >
                </h5>
              </div>
              <div class="col-md-4">
                <div>
                  <button
                    *ngIf="app.status === 'Open' || app.status === 'Submitted'"
                    type="button"
                    class="add-details-btn"
                    routerLink="/application/edit_landlord/{{ app.id }}"
                  >
                    <i class="fa fa-plus-circle" aria-hidden="true">
                      {{ "applications.UPDATE_APPLICATION" | translate }}</i
                    >
                  </button>
                </div>
                <div>
                      <button
                        *ngIf="isViewMode(app.status)" 
                        type="button"
                        class="add-details-btn"
                        routerLink="/application/edit_landlord/{{app.id}}">
                        <i class="fa fa-eye" aria-hidden="true"> {{ 'applications.VIEW_APPLICATION' | translate }}</i>
                      </button>
                    </div>
                &nbsp;
                <div>
                  <button
                    *ngIf="app.status === 'Open' && app.percent_complete == 100"
                    type="button"
                    (click)="submitApplication(app.id)"
                    class="add-details-btn"
                  >
                    <i class="fa fa-check-circle" aria-hidden="true">
                      {{ "applications.SUBMIT_APPLICATION" | translate }}</i
                    >
                  </button>
                </div>
                <button
                  *ngIf="
                    app.status === 'Pre-Eligibility' ||
                    app.status === 'Eligibility Questions Answered'
                  "
                  type="button"
                  class="add-details-btn"
                  routerLink="/application/determine-eligibility/{{ app.id }}"
                >
                  <i class="fas fa-exclamation-circle">
                    {{ "applications.DETERMINE_ELIGIBILITY" | translate }}</i
                  >
                </button>
                &nbsp;
              </div>
              <div class="col-md-2" style="text-align: end">
                <!-- <div>
                      <button type="button" class="add-details-btn create-ticket" (click)="createTicket(createTicketModal, app.id)">
                        <i class="fa fa-info-circle" aria-hidden="true"> {{ 'applications.CREATE_TICKET' | translate }} </i>
                      </button>
                    </div>
                    &nbsp;
                    <br> -->
                <div>
                  <button
                    type="button"
                    class="add-details-btn"
                    (click)="openInviteApplicant(inviteApplicant, app.id)"
                  >
                    <i class="fa fa-plus-circle" aria-hidden="true">
                      {{ "applications.INVITE_RENTER" | translate }}</i
                    >
                  </button>
                </div>
              </div>
            </div>
            <div class="row col-md-12">
              <hr />
            </div>
            <div class="card" style="background-color: whitesmoke">
              <div class="col-md-12">
                <h5>
                  <p class="background-secondary">
                    {{ "applications.REGISTERED_RENTERS" | translate }}
                  </p>
                </h5>
              </div>
              <div class="row col-md-12">
                <div class="col-sm-1"></div>
                <div class="col-md-11">
                  <div *ngIf="rentalData.length > 0">
                    <div class="row align-items-center">
                      <div class="col-md-2">
                        <h6 style="text-decoration-line: underline">
                          {{
                            "applications.APP_STATUS" | translate | uppercase
                          }}
                        </h6>
                      </div>
                      <div class="col-md-2">
                        <h6 style="text-decoration-line: underline">
                          {{
                            "applications.RECERT_STATUS" | translate | uppercase
                          }}
                        </h6>
                      </div>
                      <div class="col-md-2">
                        <h6 style="text-decoration-line: underline">
                          {{ "applications.NAME" | translate | uppercase }}
                        </h6>
                      </div>
                      <div class="col-md-2">
                        <h6 style="text-decoration-line: underline">
                          {{ "applications.ADDRESS" | translate | uppercase }}
                        </h6>
                      </div>
                      <div class="col-md-2">
                        <h6 style="text-decoration-line: underline">
                          {{ "applications.EMAIL" | translate | uppercase }}
                        </h6>
                      </div>
                      <div class="col-md-2">
                        <h6 style="text-decoration-line: underline">
                          {{
                            "applications.CONTACT_PHONE" | translate | uppercase
                          }}
                        </h6>
                      </div>
                    </div>
                    <div *ngFor="let rent of rentalData">
                      <div *ngIf="rent.landlord_id === app.id">
                        <div class="row align-items-center">
                          <div class="col-md-2">
                            <h6>{{ rent.status | transform | translate }}</h6>
                          </div>
                          <div class="col-md-2">
                            <h6 *ngIf="rent.recert_status != ''">{{ rent.recert_status }}</h6>
                            <h6 *ngIf="rent.recert_status === ''">N/A</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>
                              {{ rent.contact_last_name }},
                              {{ rent.contact_first_name }}
                              {{ rent.contact_mi }}
                            </h6>
                          </div>
                          <div class="col-md-2">
                            <h6>
                              {{ rent.physical_street }}
                              {{ rent.physical_street_2 }}
                              {{ rent.physical_city }},
                              {{ rent.physical_state }}
                              {{ rent.physical_zip_code }}
                            </h6>
                          </div>
                          <div class="col-md-2">
                            <h6>
                              <a href="mailto:{{ rent.contact_email }}">{{
                                rent.contact_email
                              }}</a>
                            </h6>
                          </div>
                          <div class="col-md-2">
                            <h6>
                              {{ rent.contact_phone | mask: "(000) 000-0000" }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-md-12">
        <hr />
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row col-md-12">
            <div class="col-md-4">
              <h3 class="card-title text-dfa">
                <span class="label label-success1">
                  {{ "applications.PENDING_RENTERS" | translate }}</span
                >
                <app-info-pop
                  [visible]="true"
                  [template]="pendingApplicantsDetailsInfo"
                ></app-info-pop>
              </h3>
            </div>
            <!-- <div class="col-md-8 text-right">
              <button type="button" (click)="openInviteApplicant(inviteApplicant)"
                class="btn btn-success1 btn-sm"> {{ 'applications.INVITE_RENTER' | translate }}</button>
            </div> -->
          </div>
          <div class="mb-2">
            {{ "BUBBLE.PENDING_RENTERS" | translate }}
          </div>
          <div class="card" style="background-color: whitesmoke">
            <div class="row col-md-12">
              <div class="col-sm-1"></div>
              <div class="col-md-11">
                <div *ngIf="rentalPendingData.length > 0">
                  <div class="row align-items-center">
                    <div *ngIf="canApprove()" class="col-md-1">
                      <h6 style="text-decoration-line: underline">
                        {{ "applications.ACTION" | translate | uppercase }}
                      </h6>
                    </div>
                    <div class="col-md-1">
                      <h6 style="text-decoration-line: underline">
                        {{ "applications.APP_STATUS" | translate | uppercase }}
                      </h6>
                    </div>
                    <div class="col-md-2">
                      <h6 style="text-decoration-line: underline">
                        {{ "applications.NAME" | translate | uppercase }}
                      </h6>
                    </div>
                    <div class="col-md-3">
                      <h6 style="text-decoration-line: underline">
                        {{ "applications.ADDRESS" | translate | uppercase }}
                      </h6>
                    </div>
                    <div class="col-md-2">
                      <h6 style="text-decoration-line: underline">
                        {{ "applications.EMAIL" | translate | uppercase }}
                      </h6>
                    </div>
                    <div class="col-md">
                      <h6 style="text-decoration-line: underline">
                        {{
                          "applications.CONTACT_PHONE" | translate | uppercase
                        }}
                      </h6>
                    </div>
                    <div class="col-md">
                      <h6 style="text-decoration-line: underline">
                        {{ "applications.CNF_#" | translate | uppercase }}
                      </h6>
                    </div>
                  </div>
                  <div *ngFor="let rent of rentalPendingData">
                    <div class="row align-items-center">
                      <div *ngIf="canApprove()" class="col-md-1">
                        <button
                          (click)="openApproveApplicant(approveApplicant, rent.id)"
                          type="button"
                          class="btn background-third-whitetext btn-sm label label-success1 bg-danger"
                        >
                          <i class="fa fa-check-circle"> </i>
                        </button>
                      </div>
                      <div class="col-md-1">
                        <h6>{{ rent.status | transform | translate }}</h6>
                      </div>
                      <div class="col-md-2">
                        <h6>
                          {{ rent.contact_last_name }},
                          {{ rent.contact_first_name }} {{ rent.contact_mi }}
                        </h6>
                      </div>
                      <div class="col-md-3">
                        <h6>
                          {{ rent.physical_street }}
                          {{ rent.physical_street_2 }} {{ rent.physical_city }},
                          {{ rent.physical_state }} {{ rent.physical_zip_code }}
                        </h6>
                      </div>
                      <div class="col-md-2">
                        <h6>
                          <a href="mailto:{{ rent.contact_email }}">{{
                            rent.contact_email
                          }}</a>
                        </h6>
                      </div>
                      <div class="col-md">
                        <h6>
                          {{ rent.contact_phone | mask: "(000) 000-0000" }}
                        </h6>
                      </div>
                      <div class="col-md">
                        <h6>{{ rent.confirmation_number }}</h6>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <hr class="hr_seperator" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!---------------------- RENTER ------------------->
    <div *ngIf="applicantType === 'Applicant'">
      <div *ngFor="let app of applicationData">
        <div class="card">
          <div class="card-body">
            <div class="row col-md-12">
              <div class="col-md-4">
                <!-- <h4>
                  <p class="text-dfa">
                    <strong
                      >{{ app.contact_first_name }} {{ app.contact_mi }}
                      {{ app.contact_last_name }}</strong
                    >
                  </p>
                </h4> -->
                <!-- <h5>
                  <p class="card-title text-dfa">
                    <strong
                      >{{ app.physical_street }} {{ app.physical_street_2
                      }}<br />{{ app.physical_city }}, {{ app.physical_state }}
                      {{ app.physical_zip_code }}</strong
                    >
                  </p>
                </h5> -->
                <!-- <h5>
                  <p class="card-title text-dfa">
                    <strong>{{ app.property_name }}</strong>
                  </p>
                </h5> -->
                <h5 class="card-title text-dfa">
                  <!-- <span class="label label-success1"> {{app.tin_type}}</span>
                    &nbsp;
                    <span *ngIf="app.tin_type === 'SSN'">
                      ###-##-{{app.tin.toString().substring(5,9)}}
                    </span> -->
                  <span class="label label-success1">APP ID</span> {{ app.id }}
                </h5>

                <div *ngIf="app.status != 'Referred'">
                  <h5 class="card-title text-dfa">
                    {{ "applications.APPLICATION_STATUS" | translate }} :
                    <span class="text-success1">{{
                      app.status | transform | translate
                    }}</span>
                  </h5>
                  <h5
                    *ngIf="
                      app.recert_status != '' &&
                      app.status === 'Application Paid'
                    "
                    class="card-title text-dfa"
                  >
                    {{ "applications.RECERTIFICATION_STATUS" | translate }} :
                    <span class="text-success1">
                      {{ app.recert_status}}</span>
                  </h5>
                  <!-- <h5
                    *ngIf="
                      app.status != 'Referred' &&
                      app.status != 'Ineligible' &&
                      app.status != 'Pre-Eligibility' &&
                      app.status != 'Eligibility Questions Answered'
                    "
                    class="card-title text-dfa"
                  >
                    {{ "applications.COMPLETED" | translate }} :
                    <span class="text-success1">
                      {{ app.percent_complete }}%</span
                    >
                  </h5> -->
                  <!-- NES Remove applicant from ability to create and edit applications -->
                  <!-- <div>
                    <button
                      *ngIf="app.status === 'Open' && communityUser"
                      type="button"
                      class="add-details-btn"
                      routerLink="/application/edit_applicant/{{ app.id }}"
                    >
                      <i class="fa fa-plus-circle" aria-hidden="true">
                        {{ "applications.UPDATE_APPLICATION" | translate }}</i
                      >
                    </button>
                  </div> -->
  
  
                  <div
                    *ngIf="
                      app.status != 'Referred' &&
                      app.status != 'Ineligible' &&
                      app.status != 'Pre-Eligibility' &&
                      app.status != 'Eligibility Questions Answered' &&
                      app.status != 'Commitment Letter Issued' &&
                      app.status != 'Recertification Pending'">
                    <div *ngIf="isViewMode(app.status)">
                      <button
                        type="button"
                        class="add-details-btn label-success1"
                        routerLink="/application/edit_applicant/{{ app.id }}"
                      >
                        <i class="fa fa-eye" aria-hidden="true">
                          {{ "applications.VIEW_APPLICATION" | translate }}</i
                        >
                      </button>
                    </div>
                  </div>
                  
                  <!-- <div>
                    <button
                      *ngIf="isViewMode(app.status) && app.status !== 'Commitment Letter Issued'" 
                      type="button"
                      class="add-details-btn"
                      routerLink="/application/edit_applicant/{{ app.id }}">
                      <i class="fa fa-eye" aria-hidden="true"> {{ 'applications.VIEW_APPLICATION' | translate }}</i>
                    </button>
                  </div> -->
                  <div>
                    <button
                      *ngIf="isViewMode(app.status) && app.status === 'Recertification Pending'" 
                      type="button"
                      class="add-details-btn"
                      routerLink="/application/edit_applicant/{{ app.id }}">
                      <i class="fa fa-eye" aria-hidden="true"> {{ 'applications.RECERTIFICATION' | translate }}</i>
                    </button>
                  </div>
                  <div>
                    <button
                      *ngIf="isViewMode(app.status) && app.status === 'Commitment Letter Issued'" 
                      type="button"
                      class="add-details-btn"
                      routerLink="/application/edit_applicant/{{ app.id }}">
                      <i class="fa fa-eye" aria-hidden="true"> {{ 'applications.COMPLETE_UNIT_REQUEST_FORM' | translate }}</i>
                    </button>
                  </div>
                  <div *ngIf="!isViewMode(app.status)">
                    <button
                      type="button"
                      class="add-details-btn label-success1"
                      routerLink="/application/edit_applicant/{{ app.id }}"
                    >
                      <i class="fa fa-plus-circle" aria-hidden="true">
                        {{ "applications.UPDATE_APPLICATION" | translate }}</i
                      >
                    </button>
                  </div>
                  &nbsp;
                  <!-- <div>
                    <button
                      *ngIf="app.status === 'Open' && app.percent_complete == 100 && communityUser"
                      type="button"
                      (click)="submitApplication(app.id)"
                      class="add-details-btn"
                    >
                      <i class="fa fa-check-circle" aria-hidden="true">
                        {{ "applications.SUBMIT_APPLICATION" | translate }}</i
                      >
                    </button>
                  </div> -->
                  <button
                    *ngIf="
                      app.status === 'Pre-Eligibility' ||
                      app.status === 'Eligibility Questions Answered'
                    "
                    type="button"
                    class="add-details-btn"
                    routerLink="/application/determine-eligibility/{{ app.id }}"
                  >
                    <i class="fas fa-exclamation-circle">
                      {{ "applications.DETERMINE_ELIGIBILITY" | translate }}</i
                    >
                  </button>
                  &nbsp;
                </div>
              </div>
              <!-- <div class="col-md-7" *ngIf="app.status === 'Referred'">
                <h5>{{ "applications.REFERRED_1" | translate }}</h5>
                <h5>{{ "applications.REFERRED_2" | translate }}</h5>
                <h5>{{ "applications.REFERRED_3" | translate }}</h5>
              </div> -->

              <div class="col-md-7" *ngIf="app.status === 'Commitment Letter Issued'">
                <h5>{{ "DROPDOWNS.COMMITMENT_LETTER_1" | translate }}</h5>
                <!-- <h5>{{ "DROPDOWNS.COMMITMENT_LETTER_2" | translate }}</h5> -->
                <h5>{{ "DROPDOWNS.COMMITMENT_LETTER_3" | translate }}</h5>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Recertification Pending'">
                <h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_1" | translate }}</h5>
                <h5><u>{{ "DROPDOWNS.RECERTIFICATION_PENDING_2" | translate }}</u></h5>
                <h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_3" | translate }}</h5>
                <ol><h5>1. {{ "DROPDOWNS.RECERTIFICATION_PENDING_4" | translate }}</h5>
                  <ul>
                    <li><h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_5" | translate }}</h5></li>
                      <ul>
                        <li><h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_6" | translate }}</h5></li>
                        <li><h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_7" | translate }}</h5></li>
                        <li><h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_8" | translate }}</h5></li>
                      </ul>
                  </ul>
                  <h5>2. {{ "DROPDOWNS.RECERTIFICATION_PENDING_9" | translate }}</h5>
                </ol>
                <h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_10" | translate }}</h5>
                <ul>
                  <li><h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_11" | translate }}</h5></li>
                  <li><h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_12" | translate }}</h5></li>
                  <li><h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_13" | translate }}</h5></li>
                  <li><h5>{{ "DROPDOWNS.RECERTIFICATION_PENDING_14" | translate }}</h5></li>
                </ul>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Recertification Submitted'">
                <h5>{{ "DROPDOWNS.RECERTIFICATION_SUBMITTED_1" | translate }}</h5>
                <h5>{{ "DROPDOWNS.RECERTIFICATION_SUBMITTED_2" | translate }}</h5>
                <h5>{{ "DROPDOWNS.RECERTIFICATION_SUBMITTED_3" | translate }}</h5>
                <h5>{{ "DROPDOWNS.RECERTIFICATION_SUBMITTED_4" | translate }}</h5>
                <h5>{{ "DROPDOWNS.RECERTIFICATION_SUBMITTED_5" | translate }}</h5>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Recertification Approved'">
                <h5>{{ "DROPDOWNS.RECERTIFICATION_APPROVED_1" | translate }}</h5>
                <h5>{{ "DROPDOWNS.RECERTIFICATION_APPROVED_2" | translate }}</h5>
                <div class="row col-md-12">
                  <div class="col-md-12"><br />
                  </div>
                </div>
                <div class="row col-md-12">
                  <div class="col-md-12">
                    <div *ngIf="paymentFilesData.length > 0">
                      <div class="row align-items-center">
                        <div class="col-md-3">
                          <h6 style="text-decoration-line: underline">
                            {{
                              "DROPDOWNS.PAYMENT" | translate | uppercase
                            }}
                          </h6>
                        </div>
                        <div class="col-md-3">
                          <h6 style="text-decoration-line: underline">
                            {{
                              "DROPDOWNS.MONTHS_PAID" | translate | uppercase
                            }}
                          </h6>
                        </div>
                        <div class="col-md-3">
                          <h6 style="text-decoration-line: underline">
                            {{ "DROPDOWNS.STATUS" | translate | uppercase }}
                          </h6>
                        </div>
                        <div class="col-md-3">
                          <h6 style="text-decoration-line: underline">
                            {{ "DROPDOWNS.DATE_PAYMENT_ISSUED" | translate | uppercase }}
                          </h6>
                        </div>
                      </div>
                      <div *ngFor="let payment of paymentFilesData">
                        <div class="row align-items-center">
                          <div class="col-md-3">
                            <h6>{{ payment.expense_type  }}</h6>
                          </div>
                          <div class="col-md-3">
                            <h6>{{ payment.months_paid_for }}</h6>
                          </div>
                          <div class="col-md-3">
                            <h6>{{ payment.portal_payment_file_status }}</h6>
                          </div>
                          <div class="col-md-3">
                            <h6>{{ payment.date_payment_issued }}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-7" *ngIf="app.status === 'Ineligible'">
                <h5>{{ "applications.INELIGIBLE_1" | translate }}</h5>
                <h5>{{ "applications.INELIGIBLE_2" | translate }}</h5>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Approved for Initial Award'">
                <h5>{{ "applications.APPROVED_INITIAL_AWARD_1" | translate }}
                  <b>{{ "applications.APPROVED_INITIAL_AWARD_2" | translate }}</b>
                  {{ "applications.APPROVED_INITIAL_AWARD_3" | translate }}
                </h5>
                <h5>{{ "applications.APPROVED_INITIAL_AWARD_4" | translate }}</h5>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Initial Award Paid'">
                <h5>{{ "applications.INITIAL_AWARD_PAID_1" | translate }}<b><u>{{ "applications.INITIAL_AWARD_PAID_2" | translate }}</u></b>{{ "applications.INITIAL_AWARD_PAID_3" | translate }}<i>{{ "applications.INITIAL_AWARD_PAID_4" | translate }}</i>
                </h5>
                <h5>{{ "applications.INITIAL_AWARD_PAID_5" | translate }}<b>{{ "applications.INITIAL_AWARD_PAID_6" | translate }}</b>{{ "applications.INITIAL_AWARD_PAID_7" | translate }}</h5>
                <h5>{{ "applications.INITIAL_AWARD_PAID_8" | translate }}</h5>
                <ol>
                  <li>{{ "applications.INITIAL_AWARD_PAID_9" | translate }}
                    <ul><li>{{ "applications.INITIAL_AWARD_PAID_10" | translate }}</li></ul>
                  </li>
                  <li>{{ "applications.INITIAL_AWARD_PAID_11" | translate }}</li>
                  <li>{{ "applications.INITIAL_AWARD_PAID_12" | translate }}</li>
                  <li>{{ "applications.INITIAL_AWARD_PAID_13" | translate }}
                    <ul>
                      <li>{{ "applications.INITIAL_AWARD_PAID_14" | translate }}</li>
                      <li>{{ "applications.INITIAL_AWARD_PAID_15" | translate }}</li>
                    </ul>
                  </li>
                </ol>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Withdrawn'">
                <h5>{{ "applications.WITHDRAWN_1" | translate }}</h5>
                <h5>{{ "applications.WITHDRAWN_2" | translate }}</h5>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Reviewed Ineligible'">
                <h5>{{ "applications.REVIEWED_INELIGIBLE_1" | translate }}
                  <b>{{ "applications.REVIEWED_INELIGIBLE_2" | translate }}</b>
                  {{ "applications.REVIEWED_INELIGIBLE_3" | translate }}
                </h5>
                <h5>{{ "applications.REVIEWED_INELIGIBLE_4" | translate }}
                  
                <a href="mailto:'noreply@rapidrehouse.com'">{{ "applications.REVIEWED_INELIGIBLE_5" | translate }}</a>
                {{ "applications.REVIEWED_INELIGIBLE_6" | translate }}
                <u>{{ "applications.REVIEWED_INELIGIBLE_7" | translate }}</u>{{ "applications.REVIEWED_INELIGIBLE_8" | translate }}</h5>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Submitted'">
                <h5>{{ "applications.SUBMITTED_1" | translate }}</h5>
                <h5>{{ "applications.SUBMITTED_2" | translate }}</h5>
                <ul>
                  <li><h5>{{ "applications.SUBMITTED_3" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMITTED_4" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMITTED_5" | translate }}</h5></li>
                </ul>
                <h5>{{ "applications.SUBMITTED_6" | translate }}</h5>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Open'">
                <h5>{{ "applications.REGISTERED_1" | translate }}</h5>
                <h5>{{ "applications.REGISTERED_2" | translate }}</h5>
                <h5>{{ "applications.REGISTERED_3" | translate }}</h5>
                <ul>
                  <li><h5>{{ "applications.REGISTERED_4" | translate }}</h5></li>
                  <li><h5>{{ "applications.REGISTERED_5" | translate }}</h5></li>
                  <li><h5>{{ "applications.REGISTERED_6" | translate }}</h5></li>
                  <li><h5>{{ "applications.REGISTERED_7" | translate }}</h5></li>
                  <li><h5>{{ "applications.REGISTERED_8" | translate }}</h5></li>
                </ul>
                <h5>{{ "applications.REGISTERED_9" | translate }}</h5>
                <h5>{{ "applications.REGISTERED_10" | translate }}</h5>
              </div>
              <div class="col-md-7" *ngIf="app.status === 'Assistance Closed'">
                <h5><i>{{ "DROPDOWNS.ASSISTANCE_CLOSED_1" | translate }}</i> {{ "DROPDOWNS.ASSISTANCE_CLOSED_2" | translate }}<br />
                  <br />{{ "DROPDOWNS.ASSISTANCE_CLOSED_3" | translate }} {{ app.id }}</h5><br />
                <h5>{{ "DROPDOWNS.ASSISTANCE_CLOSED_4" | translate }} {{ app.contact_first_name }} {{ app.contact_last_name }},</h5><br />
                <h5>{{ "DROPDOWNS.ASSISTANCE_CLOSED_5" | translate }}</h5><br />

                <h5>
                  <ul>
                    <div *ngFor="let reason of assistanceClosedReasonsData">
                      <div *ngIf="reason != ''">
                        <li>{{ reason }}</li>
                      </div>
                    </div>
                  </ul>
                </h5>
                <br />
                
                <h5>{{ "DROPDOWNS.ASSISTANCE_CLOSED_6" | translate }}</h5><br />
                <h5>{{ "DROPDOWNS.ASSISTANCE_CLOSED_7" | translate }} <a href="mailto:noreply@rapidrehouse.com">{{ "DROPDOWNS.ASSISTANCE_CLOSED_8" | translate }}</a>{{ "DROPDOWNS.ASSISTANCE_CLOSED_9" | translate }}</h5><br />
                <h5>{{ "DROPDOWNS.ASSISTANCE_CLOSED_10" | translate }}</h5><br />
                <h5>{{ "DROPDOWNS.ASSISTANCE_CLOSED_11" | translate }}</h5><br />


              </div>
              <!-- <div class="col-md-3" *ngIf="app.status != 'Referred'">
                <div
                  *ngIf="
                    app.status != 'Ineligible' &&
                    app.status != 'Pre-Eligibility' &&
                    app.status != 'Eligibility Questions Answered'
                  "
                >
                  <h5 class="card-title text-dfa">
                    {{ "applications.LANDLORD_NAME" | translate }} :
                    <span class="text-success1"
                      >{{ app.landlord_first_name }}
                      {{ app.landlord_last_name }}</span
                    >
                  </h5>
                  <h5 class="card-title text-dfa">
                    {{ "applications.LANDLORD_EMAIL" | translate }} :
                    <span class="text-success1"
                      ><a href="mailto:{{ app.landlord_email }}">{{
                        app.landlord_email
                      }}</a></span
                    >
                  </h5>
                  <div *ngIf="app.confirmation_number != ''">
                    <h5 class="card-title text-dfa">
                      {{ "applications.CONFIRMATION_NUMBER" | translate }} :
                      <span class="text-success1">{{
                        app.confirmation_number
                      }}</span>
                    </h5>
                  </div>
                  <div *ngIf="app.confirmation_number === ''">
                    <h5 class="card-title text-dfa">
                      {{ "applications.CONFIRMATION_NUMBER" | translate }} :
                      <span class="text-success1">{{
                        "applications.NOT_REQUESTED" | translate
                      }}</span>
                    </h5>
                  </div>
                  <div *ngIf="app.landlord_status === ''">
                    <h5 class="card-title text-dfa">
                      {{ "applications.LANDLORD_STATUS" | translate }} :
                      <span class="text-success1">{{
                        "applications.NOT_REQUESTED" | translate
                      }}</span>
                    </h5>
                  </div>
                  <div *ngIf="app.landlord_status != ''">
                    <h5 class="card-title text-dfa">
                      {{ "applications.LANDLORD_STATUS" | translate }} :
                      <span class="text-success1">{{
                        app.landlord_status | transform | translate
                      }}</span>
                    </h5>
                  </div>
                </div>
              </div> -->

              
              <div
                class="col-md-2"
                *ngIf="app.status != 'Referred' && +app.expenses_utility_count"
              >
                <h4>Utility Status</h4>
                <a routerLink="/application/edit_applicant/{{ app.id }}"
                  >See Expenses Section of Application</a
                >
                <!-- <app-create-ticket [appId]="app.id" [template]="createTicketBtn"></app-create-ticket> -->
                <!-- <button type="button" class="add-details-btn create-ticket" (click)="createTicket(createTicketModal, app.id)">
                    <i class="fa fa-info-circle" aria-hidden="true">  {{ 'applications.CREATE_TICKET' | translate }} </i>
                  </button>                   -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- NES - disallow landlord to create application -->
<button
  class="float-btn"
  *ngIf="applicantType === 'Landlord'"
  routerLink="/application/add"
>
  <i class="fa fa-plus"></i>
</button> 

<!-- <button class="float2-btn"
  routerLink="/application/communication/help-desk">
  <i class="fa fa-envelope"></i>
</button> -->

<!-- ================================================================================================ -->
<!-------------------------------------------- Popups Start here---------------------------------------->
<!-- ================================================================================================ -->

<!------------------------ Approve Applicant end ------------------------------------>

<ng-template #approveApplicant let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.APPROVE_RENTER" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="approveApplicantFormGroup"
      (ngSubmit)="onApproveApplicantSubmit()"
    >
      <!-- CONTACT -->
      <div class="row col-md-12">
        <div class="card-title col-md-12">
          <h5>{{ "applications.RENTER_INFO" | translate }}</h5>
        </div>
      </div>
      <div class="card bg-light">
        <div class="card-body">
          <div class="row col-md-12">
            <div class="col-md-1"></div>
            <div class="col-md-11">
              <label class="control-label"
                >{{ "applications.NAME" | translate }}:
                {{ approveApplicantFormControl.contact_first_name.value }}
                {{ approveApplicantFormControl.contact_mi.value }}
                {{ approveApplicantFormControl.contact_last_name.value }}</label
              ><br />
              <label class="control-label"
                >{{ "applications.ADDRESS" | translate }}:
                {{ approveApplicantFormControl.physical_street.value }}
                {{ approveApplicantFormControl.physical_street_2.value }}
                {{ approveApplicantFormControl.physical_city.value }},
                {{ approveApplicantFormControl.physical_state.value }}
                {{ approveApplicantFormControl.physical_zip_code.value }}</label
              ><br />
              <label class="control-label"
                >{{ "applications.EMAIL" | translate }}:
                {{ approveApplicantFormControl.contact_email.value }}</label
              ><br />
              <label class="control-label"
                >{{ "applications.PHONE_NUMBER" | translate }}:
                {{
                  approveApplicantFormControl.contact_phone.value
                    | mask: "(000) 000-0000"
                }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-md-12">
        <div class="col-md-12">
          <h5>{{ "applications.VERIFICATION" | translate }}</h5>
        </div>
      </div>

      <div class="card bg-light">
        <div class="card-body">
          <div class="row col-md-12">
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <label class="control-label"
                >{{ "applications.VALIDATE_MONTHLY_RENT" | translate }}
                <app-required [mark]="true"></app-required></label
              ><br />
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  formControlName="ll_monthly_rent"
                />
              </div>
              <small
                *ngIf="
                  approveApplicantFormControl.ll_monthly_rent.touched &&
                  approveApplicantFormControl.ll_monthly_rent.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-5">
              <label class="control-label"
                >{{ "applications.VALIDATE_PAST_DUE" | translate }}
                <app-required [mark]="true"></app-required></label
              ><br />
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  formControlName="ll_amount_past_due"
                />
              </div>
              <small
                *ngIf="
                  approveApplicantFormControl.ll_amount_past_due.touched &&
                  approveApplicantFormControl.ll_amount_past_due.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
          </div>
          <div class="row col-md-12">
            <div class="col-md-1"></div>
            <div class="col-md-11">
              <br /><label class="control-label"
                >{{
                  "applications.VALIDATE_OTHER_FEDERAL_FUNDING" | translate
                }}
                <app-required
                  [control]="
                    getControl(
                      approveApplicantFormGroup,
                      'll_other_fed_fund_received'
                    )
                  "
                ></app-required
              ></label>
            </div>
          </div>
          <div class="row col-md-12">
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  formControlName="ll_other_fed_fund_received"
                />
              </div>
              <small
                *ngIf="
                  approveApplicantFormControl.ll_other_fed_fund_received.touched &&
                  approveApplicantFormControl.ll_other_fed_fund_received.errors
                    ?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="card bg-light">
        <div class="card-body">
          <div class="row col-md-12">
            <div class="col-md-1"></div>
            <div class="col-md-11">
              <label class="control-label">{{
                "applications.COPY_OF_LEASE" | translate
              }}</label
              ><br />
              <input
                type="file"
                (change)="
                  onSelectFileUpload(
                    $event,
                    'file_name_lease_ll',
                    'file_lease_ll'
                  )
                "
              />
              <!-- <small *ngIf="!file_lease_ll_url" class="text-warning d-block">
                {{ "applications.NOT_UPLOADED_YET" | translate }}
              </small>
              <div>
                <a
                  *ngIf="file_lease_ll_url"
                  href="{{ getFileRouteLink(file_lease_ll_url) }}"
                  class="text-success2"
                  target="_blank"
                >
                  {{ file_lease_ll }}
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="card bg-light">
        <div class="card-body">
          <div class="row mt-3 mb-2">
            <div class="col-md-1"></div>
            <div class="col">
              <small
                ><b>{{
                  "applications.LL_PENDING_RENTER_PROP_MSG" | translate
                }}</b></small
              >
            </div>
          </div>
          <div class="row col-md-12">
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <label
                >{{ "applications.SELECT_COMPANY" | translate
                }}<app-required
                  [control]="getControl(approveApplicantFormGroup, 'landlord_id')"
                ></app-required
              ></label>
              <select
                class="form-control form-control-sm"
                #c
                formControlName="landlord_id"
                (change)="companyChange(c.value)"
              >
                <option value="" hidden>
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option
                  *ngFor="let optn of applicationData"
                  value="{{ optn.id }}"
                >
                  {{ optn.business_name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label
                >{{ "applications.SELECT_PROPERTY" | translate
                }}<app-required
                  [control]="getControl(approveApplicantFormGroup, 'property_id')"
                ></app-required
              ></label>
              <select
                class="form-control form-control-sm"
                formControlName="property_id"
              >
                <option value="" hidden>
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option
                  *ngFor="let optn of propertiesData"
                  value="{{ optn.id }}"
                >
                  {{ optn.property_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="card bg-light">
        <div class="card-body">
          <div class="row col-md-12">
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <label class="control-label"
                >{{ "applications.CONFIRMATION_NUMBER" | translate
                }}<app-required
                  [control]="
                    getControl(approveApplicantFormGroup, 'll_confirmation_number')
                  "
                ></app-required></label
              ><br />
              <input
                type="text"
                class="form-control"
                formControlName="ll_confirmation_number"
              />
              <small
                *ngIf="
                  approveApplicantFormControl.ll_confirmation_number.touched &&
                  approveApplicantFormControl.ll_confirmation_number.errors
                    ?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-6">
              <p></p>
              <mat-checkbox formControlName="verification"
                ><label class="control-label"
                  >{{ "applications.APPROVAL_VERIFICATION" | translate }}
                  <app-required [mark]="true"></app-required></label
              ></mat-checkbox>
              <small
                *ngIf="
                  approveApplicantFormControl.verification.touched &&
                  approveApplicantFormControl.verification.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            *ngIf="!modelConfig.busy"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.APPROVE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.APPROVE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!------------------------ Approve Applicant end ------------------------------------>

<!------------------------ Invite Applicant end ------------------------------------>

<ng-template #inviteApplicant let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.INVITE_RENTER" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="inviteApplicantFormGroup"
      (ngSubmit)="onInviteApplicantSubmit()"
    >
      <div class="row col-md-12">
        <div class="col-md-4">
          <label class="control-label"
            >{{ "applications.RENTER_EMAIL_ADDRESS" | translate }}
            <app-required
              [control]="getControl(inviteApplicantFormGroup, 'applicant_email')"
            ></app-required
          ></label>
        </div>
        <div class="col-md-7">
          <input
            type="text"
            class="form-control"
            formControlName="applicant_email"
          />
          <small
            *ngIf="
              inviteApplicantFormControl.applicant_email.touched &&
              inviteApplicantFormControl.applicant_email.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="
              inviteApplicantFormControl.applicant_email.touched &&
              inviteApplicantFormControl.applicant_email.errors?.pattern
            "
            class="text-danger"
          >
            {{ "errors.INVALID_EMAIL" | translate }}
          </small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            *ngIf="!modelConfig.busy"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.INVITE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.INVITE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!------------------------ Invite Applicant end ------------------------------------>

<ng-template #createTicketModal let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.CREATE_TICKET" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="ticketForm" (ngSubmit)="saveTicket()">
      <div class="form-group">
        <label
          >{{ "applications.SUBJECT" | translate
          }}<app-required
            [control]="getControl(ticketForm, 'subject')"
          ></app-required
        ></label>
        <input type="text" class="form-control" formControlName="subject" />
      </div>
      <div class="form-group">
        <label
          >{{ "applications.DETAILS" | translate
          }}<app-required
            [control]="getControl(ticketForm, 'details')"
          ></app-required
        ></label>
        <textarea
          class="form-control"
          rows="5"
          formControlName="details"
        ></textarea>
      </div>

      <div class="btn-responsive">
        <button
          *ngIf="!modelConfig.busy"
          type="submit"
          class="btn background-third-whitetext"
        >
          {{ "applications.SUBMIT" | translate }}
        </button>
        <button
          *ngIf="modelConfig.busy"
          type="button"
          class="btn background-third-whitetext"
        >
          <strong
            ><i class="fa fa-cog fa-spin"></i>
            {{ "applications.PLEASE_WAIT" | translate }}</strong
          >
        </button>
        <button type="button" (click)="modal.close()" class="btn btn-dark">
          {{ "applications.CANCEL" | translate }}
        </button>
        <small
          [innerHTML]="'applications.SUBMIT_BTN_INFO_TEXT' | translate"
        ></small>
      </div>
    </form>
  </div>
</ng-template>

<!--------------------------------- BUBBLES START --------------------------------->

<ng-template #pendingApplicantsDetailsInfo>
  <h1>{{ "applications.PENDING_RENTERS" | translate }}</h1>
  <p>{{ "BUBBLE.PENDING_RENTERS" | translate }}</p>
</ng-template>

<!--------------------------------- BUBBLES END --------------------------------->

<!-- <ng-template #createTicketBtn>
  <button type="button" class="add-details-btn create-ticket">
    <i class="fa fa-info-circle" aria-hidden="true">
      {{ "applications.CREATE_TICKET" | translate }}
    </i>
  </button>
</ng-template> -->

<!-- <ng-template #addTicket>
  <button class="float2-btn">
    <i class="fa fa-envelope"></i>
  </button>
</ng-template> -->

<!-- <app-create-ticket [template]="addTicket"></app-create-ticket> -->

<ng-template #statusPopup>
  <div class="card">
    <div class="card-body" style="background-color:rgb(151, 208, 246);">
      <p>
        {{ "applications.STATUS_POP_H1" | translate }}
      </p>
      <p>
        {{ "applications.STATUS_POP_H2" | translate }}
      </p>
      <p>{{ "applications.STATUS_POP_H3" | translate }}</p>
      <ol>
        <li>{{ "applications.STATUS_POP_L1" | translate }}</li>
        <li>{{ "applications.STATUS_POP_L2" | translate }}</li>
        <li>{{ "applications.STATUS_POP_L3" | translate }}</li>
        <li>{{ "applications.STATUS_POP_L4" | translate }}</li>
        <li>{{ "applications.STATUS_POP_L5" | translate }}</li>
        <li>{{ "applications.STATUS_POP_L6" | translate }}</li>
      </ol>
      <p>{{ "applications.STATUS_POP_END" | translate }}</p>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-dialog-close class="btn btn-dark">Close</button>
    </div>
  </div>
</ng-template>

<ng-template #statusPopup2>
  <div class="card">
    <div class="card-body" style="background-color:rgb(229, 240, 171);">
      <p>
        {{ "applications.STATUS_POP2_H1" | translate }}
      </p>
      <p>
        {{ "applications.STATUS_POP2_H2" | translate }}
      </p>
      <ol>
        <li>{{ "applications.STATUS_POP2_L1" | translate }}</li>
        <li>{{ "applications.STATUS_POP2_L2" | translate }}</li>
        <li>{{ "applications.STATUS_POP2_L3" | translate }}</li>
        <!-- <li>{{ "applications.STATUS_POP2_L4" | translate }}</li> -->
        <li>{{ "applications.STATUS_POP2_L5" | translate }}</li>
        <li>{{ "applications.STATUS_POP2_L6" | translate }}</li>
      </ol>
      <p>{{ "applications.STATUS_POP2_END" | translate }}</p>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-dialog-close class="btn btn-dark">Close</button>
    </div>
  </div>
</ng-template>

<!-- ================================================================================================ -->
<!--------------------------------------------- Popups Ends here---------------------------------------->
<!-- ================================================================================================ -->
