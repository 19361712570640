import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";

@Component({
  selector: "app-help-desk",
  templateUrl: "./help-desk.component.html",
  styleUrls: ["./help-desk.component.css"],
})
export class HelpDeskComponent implements OnInit {
  commThread: Array<any> = [];
  outboundMessages: Array<any> = [];
  projectSpecificData: any =
    this.projectSpecificService.getProjectSpecificData();

  constructor(
    private projectSpecificService: ProjectSpecificService,
    private ignatiusService: IgnatiusService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.projectSpecificData =
    this.projectSpecificService.getProjectSpecificData();
    this.getData();
  }

  getData = async () => {
    this.spinner.show();
    this.commThread = await this.ignatiusService
      .getQueryReportObservable(this.projectSpecificData.appData, {
        ApplicationTableId: this.projectSpecificData.commThreadsData?.TableId,
      })
      .toPromise();
    this.outboundMessages = await this.ignatiusService
      .getQueryReportObservable(this.projectSpecificData.appData, {
        ApplicationTableId:
          this.projectSpecificData.outboundMessagesData.TableId,
      })
      .toPromise();
    if (this.commThread.length) this.commThread.reverse();
    if (this.outboundMessages.length) this.outboundMessages.reverse();
    this.spinner.hide();
  };

  /* create file view url
   */
  getFileRouteLink(url: string): string {
    if (!url) return "";
    const urlSplit = url.split("tables");
    if (urlSplit.length < 1) return "";
    return "application" + urlSplit[1];
  }
}
