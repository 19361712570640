import { Component, AfterViewInit, EventEmitter, Output, OnInit, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IgnatiusService } from 'src/app/services/ignatius.service';
import { ProjectSpecificService } from 'src/app/services/project-specific.service';
import { DashboardService } from 'src/app/modules/dashboards/dashboard.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})

export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  public isCollapsed = false;
  modalMessage = "";
  currentPassword: String;
  newPassword: String;
  confirmPassword: String;
  username = this.authService.user.getValue().userName;
  newMsg: boolean = true;
  rights: any;
  roleNameArr: any;
  showApplicants: boolean = false;
  applicant: string;
  apps: Object[] = [];
  selApplicant: string;
  userSessionData: any = {};
  isVisible:boolean = false;

  ngOnInit() {
    this.onResize('');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(window.innerWidth < 770){
      this.isVisible = true;
      this.isCollapsed = true;
    } else {
      this.isVisible = false;
      this.isCollapsed = false;

    }
  }

  onChange(event) {
    this.selApplicant = event;
    this.applicant = event.applicant_name;
    localStorage.setItem("relApplicant", event.id);
    //todo need to reload components with new applicant
    window.location.reload();
  }

  enableSavePassword() {
    return this.confirmPassword == this.newPassword &&
      this.confirmPassword &&
      this.newPassword &&
      this.currentPassword;
  }

  keyDownSubmitPassword(event) {
    if (event.keyCode == 13 && this.enableSavePassword()) {
      this.submitPassword();
    }
  }

  submitPassword() {
    this.authService.changePassword(this.currentPassword, this.confirmPassword)
      .subscribe(() => {
        this.toastr.success(this.translate.instant("login.PASSWORD_UPDATED"), this.translate.instant("applications.SUCCESS"));
        this.modalService.dismissAll('reason to dismiss');
      }, error => {
        this.modalMessage = error.error;
      });
  }

  openModal(content) {
    this.modalMessage = "";
    this.modalService.open(
      content,
      {
        centered: true,
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title'
      });
  }

  logout() {
    this.authService.logout();
  }

  changeLanguage(value: string) {
    this.dashboardService.changeLanguage(value);
  }

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private reportService: IgnatiusService,
    private linkService: ProjectSpecificService,
    private dashboardService: DashboardService,
    private translate: TranslateService
  ) { }
}
