import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-required',
  templateUrl: './required-field.component.html',
  styleUrls: ['./required-field.component.css'],
})
export class RequiredFieldComponent implements OnInit {
  @Input('control') control: FormControl;
  @Input('mark') mark: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  /**
   * @return true if field is required
   */
  isRequired = (): boolean => {
    if (this.mark) return true;
    if (this.control && this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  };
}
