// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl: "https://devapi.ignatius.io",
//   deploymentUrl: "erap-1-dev.herokuapp.com",
//   applicationId: "mjo5i8xq2",
//   applicantRole: "y82dfykg4",
//   landlordRole: "6azslnwa4",
//   captchaSiteKey: "6LeX0EUaAAAAACGS6j7J9JZ4Q1qLCZqq5leeyMYS",
//   client_id: "ERAPLA",
// };

export const environment = {
  production: false,
  apiUrl: "https://api.ignatius.io",
  deploymentUrl: "https://louisiana.rapidrehouse.com",
  domain: "louisiana.rapidrehouse.com",
  applicationId: "i8jt1xla3",
  applicantRole: "vrukwhca4",
  landlordRole: "dbbisfiq6",
  captchaSiteKey: "6LeX0EUaAAAAACGS6j7J9JZ4Q1qLCZqq5leeyMYS",
  client_id: "ERAPLA",
  captchaSiteKeyV3: "6Lc2Z7UdAAAAAL8HwkjdbWS1OsNKBv2aUvlN1clV",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
