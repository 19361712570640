import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { IgnatiusService } from './services/ignatius.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private ignatiusService: IgnatiusService) {
    
  }

  ngOnInit(): void {
    this.authService.autoLogin();
    this.ignatiusService.loaderText.subscribe(value => {
      this.loaderText = value || 'Loading...';
    })

  }

  loaderText: string;
  title = 'app';
}
