import { Injectable } from '@angular/core';
import { AppData, AppItem } from '../models/app-data';
import {
	ProjectSpecificData as ProjectSpecificData,
	ApplicationsData,
	DocumentsData,
	ExpensesData,
	PaymentFilesData,
	HouseholdData,
	//PastDueAmountsData,
	ApplicantInvitationsData,
	ZipCodesData,
	CommThreadsData,
	PropertiesData,
	OutboundMessagesData
} from '../models/project-specific-data';

@Injectable()
export class ProjectSpecificService {
	constructor() { }

	getProjectSpecificData(): ProjectSpecificData {
		let projectSpecificData = JSON.parse(localStorage.getItem('dfaData'));
		projectSpecificData.appData = this.parseAppData(projectSpecificData.appData);
		return projectSpecificData;
	}

	getPermissions(tableName: string): boolean {
		const permissionData = JSON.parse(localStorage.getItem('permissionData'))
		if (!permissionData || !permissionData.length) return false;
		const tablePermission = permissionData.find(elem => elem.Name === tableName);
		if (!tablePermission || !tablePermission.Create) return false;
		return Boolean(tablePermission.Create);
	}

	getPermissionType() {
		const permissioTypeData = JSON.parse(localStorage.getItem('permissionData'))
		if (!permissioTypeData) return {};
		return permissioTypeData;
	}

	getRoleData() {
		const roleData = JSON.parse(localStorage.getItem('roleData'))
		if (!roleData) return {};
		return roleData;
	}

	getUserData() {
		const roleData = JSON.parse(localStorage.getItem('userData'))
		if (!roleData) return {};
		return roleData;
	}

	private parseAppData(appData: AppData) {
		return new AppData(
			appData.Name,
			appData.DbName,
			appData.Tables,
			// appData.Users
		);

	}

	createProjectSpecificData(appData: AppData) {
		let projectSpecificData = new ProjectSpecificData();
		appData = this.parseAppData(appData);

		for (const table of appData.Tables) {

			switch (table.Name) {

				case 'Applications':
					projectSpecificData.applicationsData = new ApplicationsData();
					projectSpecificData.applicationsData.TableId = table.Id;
					projectSpecificData.applicationsData.ApplicationListReportId = appData.getAppItemId(table.Reports, 'List All');
					projectSpecificData.applicationsData.LandlordApplicationListReportId = appData.getAppItemId(table.Reports, 'Landlord Applications');
					projectSpecificData.applicationsData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.applicationsData.BusinessEntityTypeMCID = appData.getAppItemId(table.Fields, 'Business Entity Type');
					projectSpecificData.applicationsData.RaceMCID = appData.getAppItemId(table.Fields, 'LL Race');
					projectSpecificData.applicationsData.GenderMCID = appData.getAppItemId(table.Fields, 'LL Gender');
					//projectSpecificData.applicationsData.BusinessTypeMCID = appData.getAppItemId(table.Fields, 'Business Type');
					//projectSpecificData.applicationsData.BankAccountTypeMCID = appData.getAppItemId(table.Fields, 'Bank Account Type');
					projectSpecificData.applicationsData.ParishMCID = appData.getAppItemId(table.Fields, 'Parish');
					projectSpecificData.applicationsData.PropertyTypeMCID = appData.getAppItemId(table.Fields, 'Property Type');
					projectSpecificData.applicationsData.LandlordEmail = appData.getAppItemId(table.Fields, 'Landlord Email');
					projectSpecificData.applicationsData.LandlordStatus = appData.getAppItemId(table.Fields, 'Landlord Status');
					projectSpecificData.applicationsData.LandlordID = appData.getAppItemId(table.Fields, 'Landlord Id');
					projectSpecificData.applicationsData.CreatedBy = appData.getAppItemId(table.Fields, 'Created By');
					projectSpecificData.applicationsData.ApplicantType = appData.getAppItemId(table.Fields, 'Applicant Type');
					projectSpecificData.applicationsData.TinId = appData.getAppItemId(table.Fields, 'TIN Enc');
					projectSpecificData.applicationsData.TinTypeId = appData.getAppItemId(table.Fields, 'TIN Type');
					projectSpecificData.applicationsData.IsValidLLAck = appData.getAppItemId(table.Fields, 'Is Valid LL Acks');
					projectSpecificData.applicationsData.IsValidLLBusiness = appData.getAppItemId(table.Fields, 'Is Valid LL Business');
					projectSpecificData.applicationsData.IsValidLLContact = appData.getAppItemId(table.Fields, 'Is Valid LL Contact');
					projectSpecificData.applicationsData.IsValidLLPayment = appData.getAppItemId(table.Fields, 'Is Valid LL Payment');
					projectSpecificData.applicationsData.IsValidLLProperties = appData.getAppItemId(table.Fields, 'Is Valid LL Properties');
					projectSpecificData.applicationsData.IsValidRAck = appData.getAppItemId(table.Fields, 'Is Valid R Acks');
					projectSpecificData.applicationsData.IsValidRAddress = appData.getAppItemId(table.Fields, 'Is Valid R Address');
					projectSpecificData.applicationsData.IsValidRContact = appData.getAppItemId(table.Fields, 'Is Valid R Contact');
					projectSpecificData.applicationsData.IsValidRDocs = appData.getAppItemId(table.Fields, 'Is Valid R Docs');
					projectSpecificData.applicationsData.IsValidRExpenses = appData.getAppItemId(table.Fields, 'Is Valid R Expenses');
					projectSpecificData.applicationsData.IsValidRHousehold = appData.getAppItemId(table.Fields, 'Is Valid R Household');
					projectSpecificData.applicationsData.IsValidRLandlord = appData.getAppItemId(table.Fields, 'Is Valid R Landlord');
					projectSpecificData.applicationsData.HouseholdErrorMessage = appData.getAppItemId(table.Fields, 'Household Error Message');
					break;

				case 'Documents':
					projectSpecificData.documentsData = new DocumentsData();
					projectSpecificData.documentsData.TableId = table.Id;
					projectSpecificData.documentsData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.documentsData.RelatedApplicationsFieldId = appData.getAppItemId(table.Fields, 'Related Applications');
					projectSpecificData.documentsData.RelatedExpensesFieldId = appData.getAppItemId(table.Fields, 'Related Expenses');
					projectSpecificData.documentsData.documentTypeFieldId = appData.getAppItemId(table.Fields, 'Document Type');
					break;

				case 'Expenses':
					projectSpecificData.expensesData = new ExpensesData();
					projectSpecificData.expensesData.TableId = table.Id;
					projectSpecificData.expensesData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.expensesData.RelatedApplicationsFieldId = appData.getAppItemId(table.Fields, 'Related Applications');
					projectSpecificData.expensesData.ExpenseTypeMCID = appData.getAppItemId(table.Fields, 'Expense Type');
					projectSpecificData.expensesData.InArrearsMCID = appData.getAppItemId(table.Fields, 'In Arrears');
					projectSpecificData.expensesData.ElectricityCompaniesFieldId = appData.getAppItemId(table.Fields, 'Electricity Companies');
					break;
				
				case 'Payment Files':
					projectSpecificData.paymentFilesData = new PaymentFilesData();
					projectSpecificData.paymentFilesData.TableId = table.Id;
					projectSpecificData.paymentFilesData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.paymentFilesData.RelatedApplicationsFieldId = appData.getAppItemId(table.Fields, 'Related Applications');
					break;

				case 'Household':
					projectSpecificData.householdData = new HouseholdData();
					projectSpecificData.householdData.TableId = table.Id;
					projectSpecificData.householdData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.householdData.RelatedApplicationsFieldId = appData.getAppItemId(table.Fields, 'Related Applications');
					projectSpecificData.householdData.DOBId = appData.getAppItemId(table.Fields, 'Date of Birth');
					projectSpecificData.householdData.HouseholdNumberMCID = appData.getAppItemId(table.Fields, 'Household Number');
					projectSpecificData.householdData.EmploymentStatusMCID = appData.getAppItemId(table.Fields, 'Employment Status');
					projectSpecificData.householdData.RaceMCID = appData.getAppItemId(table.Fields, 'Race');
					projectSpecificData.householdData.EthnicityMCID = appData.getAppItemId(table.Fields, 'Ethnicity');
					projectSpecificData.householdData.VeteranMCID = appData.getAppItemId(table.Fields, 'Veteran');
					projectSpecificData.householdData.GenderMCID = appData.getAppItemId(table.Fields, 'Gender');
					projectSpecificData.householdData.IncomeGrossAmountTypeMCID = appData.getAppItemId(table.Fields, 'Income Gross Amount Type');
					projectSpecificData.householdData.RelationToApplicant = appData.getAppItemId(table.Fields, 'Relation to Applicant');
					projectSpecificData.householdData.ErrorMessageFieldId = appData.getAppItemId(table.Fields, 'Error Message');
					break;

				// case 'Past Due Amounts':
				// 	projectSpecificData.pastDueAmountsData = new PastDueAmountsData();
				// 	projectSpecificData.pastDueAmountsData.TableId = table.Id;
				// 	projectSpecificData.pastDueAmountsData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
				// 	//projectSpecificData.pastDueAmountsData.RelatedApplicationsFieldId = appData.getAppItemId(table.Fields, 'Expenses Related Applications');
				// 	projectSpecificData.pastDueAmountsData.RelatedExpensesFieldId = appData.getAppItemId(table.Fields, 'Related Expenses');
				// 	projectSpecificData.pastDueAmountsData.MonthMCID = appData.getAppItemId(table.Fields, 'Month');
				// 	break;
					
				case 'Applicant Invitations':
					projectSpecificData.applicantInvitationsData = new ApplicantInvitationsData();
					projectSpecificData.applicantInvitationsData.TableId = table.Id;
					projectSpecificData.applicantInvitationsData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					break;

				case 'Zip Codes':
					projectSpecificData.zipCodesData = new ZipCodesData();
					projectSpecificData.zipCodesData.TableId = table.Id;
					projectSpecificData.zipCodesData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					break;

				case 'Common Threads':
					projectSpecificData.commThreadsData = new CommThreadsData();
					projectSpecificData.commThreadsData.TableId = table.Id;
					projectSpecificData.commThreadsData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.commThreadsData.TicketTypeMCID = appData.getAppItemId(table.Fields, 'Ticket Type');
					projectSpecificData.commThreadsData.ElectricityCompaniesFieldId = appData.getAppItemId(table.Fields, 'Electricity Companies');
					break;
				
				case 'Outbound Messages':
					projectSpecificData.outboundMessagesData = new OutboundMessagesData();
					projectSpecificData.outboundMessagesData.TableId = table.Id;
					projectSpecificData.outboundMessagesData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					break;
				
				case 'Properties':
					projectSpecificData.propertiesData = new PropertiesData();
					projectSpecificData.propertiesData.TableId = table.Id;
					projectSpecificData.propertiesData.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.propertiesData.PropertyTypeMCID = appData.getAppItemId(table.Fields, 'Property Type');
					projectSpecificData.propertiesData.RelatedApplicationsFieldId = appData.getAppItemId(table.Fields, 'Related Applications');
					break;
				default:
					break;
			}

		}

		projectSpecificData.appData = JSON.parse(JSON.stringify(appData));
		// projectSpecificData.appData.Tables.forEach(t => t.Fields = null);


		// Saving in local storage //
		localStorage.setItem('dfaData', JSON.stringify(projectSpecificData));
	}
}