
import { Injectable, KeyValueDiffers } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectSpecificService } from 'src/app/services/project-specific.service';
import { Constants } from 'src/app/shared/constants';
import { MustMatch, startEndDateValidator } from 'src/app/shared/validators';

@Injectable()
export class ApplicationForms {
  startEndDateValidator = startEndDateValidator;
  constructor(
    private formBuilder: FormBuilder,
    private projectSpecificService: ProjectSpecificService
  ) { }

  setupBusinessContactsForm(obj: any = {}): FormGroup {
    return new FormGroup({
      contact_first_name: new FormControl(obj.contact_first_name || '', Validators.required),
      contact_last_name: new FormControl(obj.contact_last_name || '', Validators.required),
      contact_mi: new FormControl(obj.contact_mi || ''),
      contact_email: new FormControl(
        obj.contact_email, [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)]
      ),
      contact_phone: new FormControl(obj.contact_phone || '', Validators.required),
      ll_date_of_birth: new FormControl(obj.ll_date_of_birth || '', Validators.required),
      ll_race: new FormControl(obj.ll_race || '', Validators.required),
      ll_gender: new FormControl(obj.ll_gender || '', Validators.required),
    });
  }

  setupBusinessDetailsForm(obj: any = {}): FormGroup {

    return new FormGroup({
      physical_street: new FormControl(obj.physical_street || ''),
      physical_street_2: new FormControl(obj.physical_street_2 || ''),
      physical_city: new FormControl(obj.physical_city || ''),
      physical_state: new FormControl(obj.physical_state || ''),
      physical_zip_code: new FormControl(obj.physical_zip_code || ''),      
      physical_longitude: new FormControl(obj.physical_longitude || ''),      
      physical_latitude: new FormControl(obj.physical_latitude || ''), 
      mailing_street: new FormControl(obj.mailing_street || ''),
      mailing_street_2: new FormControl(obj.mailing_street_2 || ''),
      mailing_city: new FormControl(obj.mailing_city || ''),
      mailing_state: new FormControl(obj.mailing_state || ''),
      mailing_zip_code: new FormControl(obj.mailing_zip_code || ''),      
      mailing_longitude: new FormControl(obj.mailing_longitude || ''),      
      mailing_latitude: new FormControl(obj.mailing_latitude || ''),      
      file_w9: new FormControl(''),
      file_name_w9: new FormControl(''),
      minority_or_women_owned: new FormControl(obj.minority_or_women_owned || ''),
      minority_or_women_owned_registered: new FormControl(obj.minority_or_women_owned_registered || '')
    });
  }

  setupReceivedFundsForm(obj: any = {}): FormGroup {
    return new FormGroup({
      received_funds: new FormControl(obj.received_funds || '', Validators.required),
      received_funds_ppp: new FormControl(obj.received_funds_ppp || ''),
      received_funds_sba: new FormControl(obj.received_funds_sba || ''),
      received_funds_edc: new FormControl(obj.received_funds_edc || ''),
      received_funds_any: new FormControl(obj.received_funds_any || ''),
      received_funds_donations: new FormControl(obj.received_funds_donations || ''),
      received_funds_eidl_grant: new FormControl(obj.received_funds_eidl_grant || ''),
      file_funds_any: new FormControl(obj.file_funds_any || ''),
      file_funds_edc: new FormControl(obj.file_funds_edc || ''),
      file_funds_ppp: new FormControl(obj.file_funds_ppp || ''),
      file_funds_sba: new FormControl(obj.file_funds_sba || ''),
      file_funds_donations: new FormControl(obj.file_funds_donations || ''),
      file_funds_eidl_grant: new FormControl(obj.file_funds_eidl_grant || ''),
      received_funds_ppp_amount: new FormControl(
        obj.received_funds_ppp_amount || '0',
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_sba_amount: new FormControl(
        obj.received_funds_sba_amount || '0',
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_edc_amount: new FormControl(
        obj.received_funds_edc_amount || '0',
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_any_amount: new FormControl(
        obj.received_funds_any_amount || '0',
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_eidl_grant_amount: new FormControl(
        obj.received_funds_eidl_grant_amount || '0',
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_donations_amount: new FormControl(
        obj.received_funds_donations_amount || '0',
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      file_funds_any_name: new FormControl(obj.file_funds_any_name || ''),
      file_funds_edc_name: new FormControl(obj.file_funds_edc_name || ''),
      file_funds_ppp_name: new FormControl(obj.file_funds_ppp_name || ''),
      file_funds_sba_name: new FormControl(obj.file_funds_sba_name || ''),
      file_funds_donations_name: new FormControl(obj.file_funds_donations_name || ''),
      file_funds_eidl_grant_name: new FormControl(obj.file_funds_eidl_grant_name || '')
    })
  }
 
  setupAcknowledgementsForm(obj: any = {}): FormGroup {
    const userData = this.projectSpecificService.getUserData();
    const certify = Boolean(obj['certify'] && obj['certify'] === 'True');
    const ack = Boolean(obj['ack'] && obj['ack'] === 'True');
    const ack_2 = Boolean(obj['ack_2'] && obj['ack_2'] === 'True');
    const ack_3 = Boolean(obj['ack_3'] && obj['ack_3'] === 'True');
    const ack_4 = Boolean(obj['ack_4'] && obj['ack_4'] === 'True');
    const ack_5 = Boolean(obj['ack_5'] && obj['ack_5'] === 'True');
    //const ack_6 = Boolean(obj['ack_6'] && obj['ack_6'] === 'True');

    return new FormGroup({
      certifier_name: new FormControl(obj.certifier_name || '', Validators.required),
      certifier_title: new FormControl(obj.certifier_title || '', Validators.required),
      file_drivers_license: new FormControl(''),
      file_drivers_license_name: new FormControl(obj.file_drivers_license || ''),
      certified_by: new FormControl(userData.userName),
      certified_dt: new FormControl(new Date()),
      ack: new FormControl(ack, Validators.required),
      ack_2: new FormControl(ack_2, Validators.required),
      ack_3: new FormControl(ack_3, Validators.required),
      ack_4: new FormControl(ack_4, Validators.required),
      ack_5: new FormControl(ack_5, Validators.required),
      //ack_6: new FormControl(ack_6, Validators.required),
      certify: new FormControl(certify, Validators.required),
    })
  }

  setupPropertyForm(obj: any = {}): FormGroup {
    return this.formBuilder.group({
      property_name: new FormControl(obj.property_name || '', Validators.required),
      property_type: new FormControl(obj.property_type || '', Validators.required),
      related_applications: new FormControl(obj.related_applications || ''),
      property_description: new FormControl(obj.property_description || '', Validators.required),
      file_deed_or_taxes: new FormControl(obj.file_deed_or_taxes || ''),
      file_deed_or_taxes_name: new FormControl(obj.file_deed_or_taxes_name || ''),
      file_deed_or_taxes_url: new FormControl(obj.file_deed_or_taxes_url || ''),
      rid: new FormControl(obj.id || ''),
    })
  }

  setupBankDetailsForm(obj: any = {}): FormGroup {
    return this.formBuilder.group({
      ach_or_check: new FormControl(obj.ach_or_check || '', Validators.required),
      check_payee_name: new FormControl(obj.check_payee_name || '', Validators.required)
    })
  }

  setupApproveApplicantForm(rentId, applicants: any = {}): FormGroup {
    for (const iterator of applicants) {
      if (rentId === iterator.id)
      {
        return new FormGroup({
          id: new FormControl(rentId),
          contact_first_name: new FormControl(iterator.contact_first_name),
          contact_mi: new FormControl(iterator.contact_mi),
          contact_last_name: new FormControl(iterator.contact_last_name),
          physical_street: new FormControl(iterator.physical_street),
          physical_street_2: new FormControl(iterator.physical_street_2),
          physical_city: new FormControl(iterator.physical_city),
          physical_state: new FormControl(iterator.physical_state),
          physical_zip_code: new FormControl(iterator.physical_zip_code),
          parish: new FormControl(iterator.parish),
          preferred_parish_1: new FormControl('', iterator.preferred_parish_1),
          preferred_parish_2: new FormControl('', iterator.preferred_parish_2),
          preferred_parish_3: new FormControl('', iterator.preferred_parish_3),
          contact_email: new FormControl(iterator.contact_email),
          contact_phone: new FormControl(iterator.contact_phone),
          confirmation_number: new FormControl(iterator.confirmation_number),
          monthly_rent: new FormControl(iterator.total_monthly_rent),
          past_due_amount: new FormControl(iterator.total_rent_past_due),
          ll_confirmation_number: new FormControl('', Validators.required),
          ll_monthly_rent: new FormControl('', Validators.required),
          ll_amount_past_due: new FormControl('', Validators.required),
          ll_other_fed_fund_received: new FormControl('', Validators.required),
          verification: new FormControl(false, Validators.required),
          landlord_status: new FormControl('Request Sent'),
          landlord_id: new FormControl('', Validators.required),
          property_id: new FormControl('', Validators.required),
          file_lease_ll: new FormControl(''),
          file_name_lease_ll: new FormControl(''),
          landlord_contact_name: new FormControl(''),
        });
      }
    }
  }

  setupInviteApplicantForm(email, appId): FormGroup {
    return new FormGroup({
      landlord_email: new FormControl(email, [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)]),
      applicant_email: new FormControl('', [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)]),
      related_applications: new FormControl(appId, [Validators.required]),
    });
  }

  setupTicketForm(email, appId): FormGroup {
    return new FormGroup({
      subject: new FormControl('', [Validators.required]),
      details: new FormControl('', [Validators.required]),
      related_applications: new FormControl(appId, [Validators.required]),
      type: new FormControl('Ticket', [Validators.required]),
      status: new FormControl('Open', [Validators.required]),
      applicant_send_to: new FormControl(email, [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)])      
    });
  }
    

  // -------------------- RENTER FORMS ----------------
 
  setupHouseholdForm(householdNumber, household: Array<any>, applicationHousehold: Array<any>, applicationId: string, existingHH: any) {

    const formDataArray = new FormArray([]);
    var found = false;

    for (const iterator of household) {

      // let existingHH: any = null;

      // console.log(householdNumber)
      // console.log((applicationHousehold));
      
      //alert(householdNumber)
      // for (const innerIterator of applicationHousehold) {
      //   // console.log(innerIterator)
      //   if (!householdNumber) {
      //     householdNumber = "";
      //   }
      //   if (householdNumber.toString() === innerIterator.household_number.toString().replace("Household ","")) {
      //     found = true;
      //     existingHH = Object.assign({}, innerIterator);
      //     break;
      //   }
      //   if (found) {
      //     break;
      //   }
      // }

      // for (const innerIterator of applicationHousehold) {
      //     existingHH = Object.assign({}, innerIterator);
      //     break;
      // }

      const household_number = existingHH ? existingHH.household_number : ''; 
      const first_name = existingHH ? existingHH.first_name : '';
      const middle_name = existingHH ? existingHH.middle_name : '';
      const last_name = existingHH ? existingHH.last_name : '';
      const date_of_birth = existingHH ? existingHH.date_of_birth : '';
      const last_date_worked = existingHH ? existingHH.last_date_worked : '';
      const employment_status = existingHH ? existingHH.employment_status : 0;
      const race = existingHH ? existingHH.race : 0;
      const ethnicity = existingHH ? existingHH.ethnicity : 0;
      const veteran = existingHH ? existingHH.veteran : 0;
      const gender = existingHH ? existingHH.gender : 0;
      const income_gross = existingHH ? Boolean(existingHH.income_gross === 'True') ? 'True' : 'False' :'';
      const income_gross_amount = existingHH ? existingHH.income_gross_amount : 0;
      const income_gross_amount_2 = existingHH ? existingHH.income_gross_amount_2 : 0;
      const income_gross_amount_type = existingHH ? existingHH.income_gross_amount_type : 0;
      const income_gross_file_url = existingHH ? existingHH.income_gross_file_url : '';
      const income_gross_file_name = existingHH ? existingHH.income_gross_file : '';
      const income_gross_file_2_url = existingHH ? existingHH.income_gross_file_2_url : '';
      const income_gross_file_2_name = existingHH ? existingHH.income_gross_file_2 : '';
      const income_gross_file_3_url = existingHH ? existingHH.income_gross_file_3_url : '';
      const income_gross_file_3_name = existingHH ? existingHH.income_gross_file_3 : '';
      const income_gross_file_4_url = existingHH ? existingHH.income_gross_file_4_url : '';
      const income_gross_file_4_name = existingHH ? existingHH.income_gross_file_4 : '';
      const income_gross_file_5_url = existingHH ? existingHH.income_gross_file_5_url : '';
      const income_gross_file_5_name = existingHH ? existingHH.income_gross_file_5 : '';
      const income_in_lieu_of_earnings = existingHH ? Boolean(existingHH.income_in_lieu_of_earnings === 'True') ? 'True' : 'False' :'';
      const income_in_lieu_of_earnings_amount = existingHH ? existingHH.income_in_lieu_of_earnings_amount : 0;
      const income_in_lieu_of_earnings_file_url = existingHH ? existingHH.income_in_lieu_of_earnings_file_url : '';
      const income_in_lieu_of_earnings_file_name = existingHH ? existingHH.income_in_lieu_of_earnings_file : '';
      const income_payments_annuities = existingHH ? Boolean(existingHH.income_payments_annuities === 'True') ? 'True' : 'False' :'';
      const income_payments_annuities_amount = existingHH ? existingHH.income_payments_annuities_amount : 0;
      const income_payments_annuities_file_url = existingHH ? existingHH.income_payments_annuities_file_url : '';
      const income_payments_annuities_file_name = existingHH ? existingHH.income_payments_annuities_file : '';
      const income_government = existingHH ? Boolean(existingHH.income_government === 'True') ? 'True' : 'False' :'';
      const income_government_amount = existingHH ? existingHH.income_government_amount : 0;
      const income_government_file_url = existingHH ? existingHH.income_government_file_url : '';
      const income_government_file_name = existingHH ? existingHH.income_government_file : '';
      const income_alimony = existingHH ? Boolean(existingHH.income_alimony === 'True') ? 'True' : 'False' :'';
      const income_alimony_amount = existingHH ? existingHH.income_alimony_amount : 0;
      const income_alimony_file_url = existingHH ? existingHH.income_alimony_file_url : '';
      const income_alimony_file_name = existingHH ? existingHH.income_alimony_file : '';
      const income_other = existingHH ? Boolean(existingHH.income_other === 'True') ? 'True' : 'False' :'';
      const income_other_amount = existingHH ? existingHH.income_other_amount : 0;
      const income_other_file_url = existingHH ? existingHH.income_other_file_url : '';
      const income_other_file_name = existingHH ? existingHH.income_other_file : '';
      const used = existingHH ? true : false;
      const id = existingHH ? existingHH.id : null;
      const relation_to_applicant = existingHH ? (existingHH.relation_to_applicant || 0) : 0;
      const ssn_enc = existingHH ? existingHH.ssn_enc : '';
      const income_gross_no_file = existingHH ? Boolean(existingHH.income_gross_no_file === 'True') : 'False';
      const other_forms_of_income = existingHH ? Boolean(existingHH.other_forms_of_income === 'True') ? 'True' : 'False' :'';
      const status = existingHH ? Boolean(existingHH.status === 'True') ? 'True' : 'False' :'';
      //const url = existingHH ? existingHH.document_file_url : '';
      //const exstDocName = existingHH ? existingHH.document_file : '';

      const controls = new FormGroup({
        id: new FormControl(id),
        household_number: new FormControl(householdNumber),
        related_applications: new FormControl(applicationId),
        first_name: new FormControl(first_name),
        middle_name: new FormControl(middle_name),
        last_name: new FormControl(last_name),
        date_of_birth: new FormControl(date_of_birth),
        employment_status: new FormControl(employment_status ? employment_status : 0),
        race: new FormControl(race ? race : 0),
        ethnicity: new FormControl(ethnicity ? ethnicity : 0),
        veteran: new FormControl(veteran ? veteran : 0),
        gender: new FormControl(gender ? gender : 0),
        last_date_worked: new FormControl(last_date_worked), //Validators.pattern(Constants.AMOUNT_PATTERN)),
        income_gross: new FormControl(income_gross),
        income_gross_amount: new FormControl(income_gross_amount, Validators.pattern(Constants.AMOUNT_PATTERN)),
        income_gross_amount_2: new FormControl(income_gross_amount_2, Validators.pattern(Constants.AMOUNT_PATTERN)),
        income_gross_amount_type: new FormControl(income_gross_amount_type ? income_gross_amount_type : 0),
        income_gross_file: new FormControl(''),
        income_gross_file_url: new FormControl(income_gross_file_url),
        income_gross_file_name: new FormControl(income_gross_file_name),
        income_gross_file_2: new FormControl(''),
        income_gross_file_2_url: new FormControl(income_gross_file_2_url),
        income_gross_file_2_name: new FormControl(income_gross_file_2_name),
        income_gross_file_3: new FormControl(''),
        income_gross_file_3_url: new FormControl(income_gross_file_3_url),
        income_gross_file_3_name: new FormControl(income_gross_file_3_name),
        income_gross_file_4: new FormControl(''),
        income_gross_file_4_url: new FormControl(income_gross_file_4_url),
        income_gross_file_4_name: new FormControl(income_gross_file_4_name),
        income_gross_file_5: new FormControl(''),
        income_gross_file_5_url: new FormControl(income_gross_file_5_url),
        income_gross_file_5_name: new FormControl(income_gross_file_5_name),
        income_in_lieu_of_earnings: new FormControl(income_in_lieu_of_earnings),
        income_in_lieu_of_earnings_amount: new FormControl(income_in_lieu_of_earnings_amount, Validators.pattern(Constants.AMOUNT_PATTERN)),
        income_in_lieu_of_earnings_file: new FormControl(''),
        income_in_lieu_of_earnings_file_url: new FormControl(income_in_lieu_of_earnings_file_url),
        income_in_lieu_of_earnings_file_name: new FormControl(income_in_lieu_of_earnings_file_name),
        income_payments_annuities: new FormControl(income_payments_annuities),
        income_payments_annuities_amount: new FormControl(income_payments_annuities_amount, Validators.pattern(Constants.AMOUNT_PATTERN)),
        income_payments_annuities_file: new FormControl(''),
        income_payments_annuities_file_url: new FormControl(income_payments_annuities_file_url),
        income_payments_annuities_file_name: new FormControl(income_payments_annuities_file_name),
        income_government: new FormControl(income_government),
        income_government_amount: new FormControl(income_government_amount, Validators.pattern(Constants.AMOUNT_PATTERN)),
        income_government_file: new FormControl(''),
        income_government_file_url: new FormControl(income_government_file_url),
        income_government_file_name: new FormControl(income_government_file_name),
        income_alimony: new FormControl(income_alimony),
        income_alimony_amount: new FormControl(income_alimony_amount, Validators.pattern(Constants.AMOUNT_PATTERN)),
        income_alimony_file: new FormControl(''),
        income_alimony_file_url: new FormControl(income_alimony_file_url),
        income_alimony_file_name: new FormControl(income_alimony_file_name),
        income_other: new FormControl(income_other),
        income_other_amount: new FormControl(income_other_amount, Validators.pattern(Constants.AMOUNT_PATTERN)),  
        income_other_file: new FormControl(''),
        income_other_file_url: new FormControl(income_other_file_url),
        income_other_file_name: new FormControl(income_other_file_name),
        used: new FormControl(used),
        relation_to_applicant: new FormControl(relation_to_applicant),
        ssn_enc: new FormControl(ssn_enc),
        income_gross_no_file: new FormControl(income_gross_no_file),
        other_forms_of_income: new FormControl(other_forms_of_income),
        status: new FormControl(status)
      })
      formDataArray.push(controls);
    }

    return new FormGroup({
      household: formDataArray
    })
  }
  setupHouseholdAddForm(householdNumber, household: Array<any>, applicationId: string) {
    return new FormGroup({
      id: new FormControl(0),
      household_number: new FormControl(householdNumber),
      related_applications: new FormControl(applicationId),
      first_name: new FormControl(''),
      middle_name: new FormControl(''),
      last_name: new FormControl(''),
      date_of_birth: new FormControl(null),
      employment_status: new FormControl(0),
      race: new FormControl(0),
      ethnicity: new FormControl(0),
      veteran: new FormControl(0),
      gender: new FormControl(0),
      last_date_worked: new FormControl(null), //Validators.pattern(Constants.AMOUNT_PATTERN)),
      income_gross: new FormControl(false),
      income_gross_amount: new FormControl(0, Validators.pattern(Constants.AMOUNT_PATTERN)),
      income_gross_amount_2: new FormControl(0, Validators.pattern(Constants.AMOUNT_PATTERN)),
      income_gross_amount_type: new FormControl(0),
      income_gross_file: new FormControl(''),
      income_gross_file_url: new FormControl(''),
      income_gross_file_name: new FormControl(''),
      income_gross_file_2: new FormControl(''),
      income_gross_file_2_url: new FormControl(''),
      income_gross_file_2_name: new FormControl(''),
      income_gross_file_3: new FormControl(''),
      income_gross_file_3_url: new FormControl(''),
      income_gross_file_3_name: new FormControl(''),
      income_gross_file_4: new FormControl(''),
      income_gross_file_4_url: new FormControl(''),
      income_gross_file_4_name: new FormControl(''),
      income_gross_file_5: new FormControl(''),
      income_gross_file_5_url: new FormControl(''),
      income_gross_file_5_name: new FormControl(''),
      income_in_lieu_of_earnings: new FormControl(false),
      income_in_lieu_of_earnings_amount: new FormControl(0, Validators.pattern(Constants.AMOUNT_PATTERN)),
      income_in_lieu_of_earnings_file: new FormControl(''),
      income_in_lieu_of_earnings_file_url: new FormControl(''),
      income_in_lieu_of_earnings_file_name: new FormControl(''),
      income_payments_annuities: new FormControl(false),
      income_payments_annuities_amount: new FormControl(0, Validators.pattern(Constants.AMOUNT_PATTERN)),
      income_payments_annuities_file: new FormControl(''),
      income_payments_annuities_url: new FormControl(''),
      income_payments_annuities_file_name: new FormControl(''),
      income_government: new FormControl(false),
      income_government_amount: new FormControl(0, Validators.pattern(Constants.AMOUNT_PATTERN)),
      income_government_file: new FormControl(''),
      income_government_file_url: new FormControl(''),
      income_government_file_name: new FormControl(''),
      income_alimony: new FormControl(false),
      income_alimony_amount: new FormControl(0, Validators.pattern(Constants.AMOUNT_PATTERN)),
      income_alimony_file: new FormControl(''),
      income_alimony_file_url: new FormControl(''),
      income_alimony_file_name: new FormControl(''),
      income_other: new FormControl(false),
      income_other_amount: new FormControl(0, Validators.pattern(Constants.AMOUNT_PATTERN)),  
      income_other_file: new FormControl(''),
      income_other_file_url: new FormControl(''),
      income_other_file_name: new FormControl(''),
      used: new FormControl(true),
      relation_to_applicant: new FormControl(0),
      ssn_enc: new FormControl(''),
      income_gross_no_file: new FormControl(false),
      other_forms_of_income: new FormControl(),
      status: new FormControl(),
    })
  }
  setupHouseholdDeleteForm(householdNumber, household: Array<any>, applicationHousehold: Array<any>, applicationId: string, existingHH: any) {

    const formDataArray = new FormArray([]);
    var found = false;

    for (const iterator of household) {

      // let existingHH: any = null;

      // for (const innerIterator of applicationHousehold) {
      //   if (householdNumber.toString() === innerIterator.household_number.toString().replace("Household ","")) {
      //     found = true;
      //     existingHH = Object.assign({}, innerIterator);
      //     break;
      //   }
      //   if (found) {
      //     break;
      //   }
      // }

      const first_name = existingHH ? existingHH.first_name : '';
      const mi = existingHH ? existingHH.mi : '';
      const last_name = existingHH ? existingHH.last_name : '';
      const date_of_birth = existingHH ? existingHH.date_of_birth : '';
      const employment_status = existingHH ? existingHH.employment_status : 0;
      const race = existingHH ? existingHH.race : 0;
      const ethnicity = existingHH ? existingHH.ethnicity : 0;
      const veteran = existingHH ? existingHH.veteran : 0;
      const gender = existingHH ? existingHH.gender : 0;
      const id = existingHH ? existingHH.id : null;

      const controls = new FormGroup({
        id: new FormControl(id),
        related_applications: new FormControl(applicationId),
        first_name: new FormControl(first_name),
        mi: new FormControl(mi),
        last_name: new FormControl(last_name),
        date_of_birth: new FormControl(date_of_birth),
        employment_status: new FormControl(employment_status),
        race: new FormControl(race),
        ethnicity: new FormControl(ethnicity),
        veteran: new FormControl(veteran),
        gender: new FormControl(gender),
      })
      formDataArray.push(controls);
    }

    return new FormGroup({
      household: formDataArray
    })
  }
  
  setupExpensesForm(expenseNumber, expenses: Array<any>, applicationExpenses: Array<any>, applicationId: string) {

    const formDataArray = new FormArray([]);
    var found = false;

    for (const iterator of applicationExpenses) {

      let existingExp: any = null;

      for (const innerIterator of applicationExpenses) {
        if (expenseNumber.toString() === innerIterator.id) {
          found = true;
          existingExp = Object.assign({}, innerIterator);
          break;
        }
        if (found) {
          break;
        }
      }

      const expense_type = existingExp ? existingExp.expense_type : 0;
      const in_arrears = existingExp ? existingExp.in_arrears : '';
      const amount_monthly = existingExp ? existingExp.amount_monthly : 0;
      const lease_start_date = existingExp ? existingExp.lease_start_date : '';
      const lease_end_date = existingExp ? existingExp.lease_end_date : '';
      const months_in_arrears = existingExp ? existingExp.months_in_arrears : 0;
      const total_arrears = existingExp ? existingExp.total_arrears : 0;
      const amount_past_due = existingExp ? existingExp.amount_past_due : 0;
      const provider = existingExp ? existingExp.provider : '';
      const name_on_account = existingExp ? existingExp.name_on_account : '';
      const account_number = existingExp ? existingExp.account_number : '';
      //const provider_zip_code = existingExp ? existingExp.provider_zip_code : '';
      const used = existingExp ? true : false;
      const id = existingExp ? existingExp.id : null;
      const url = existingExp ? existingExp.document_file_url : '';
      const exstDocName = existingExp ? existingExp.document_file : '';
      const past_due_amount_april_2020 = existingExp ? existingExp.past_due_amount_april_2020 : 0;
      const past_due_amount_may_2020 = existingExp ? existingExp.past_due_amount_may_2020 : 0;
      const past_due_amount_june_2020 = existingExp ? existingExp.past_due_amount_june_2020 : 0;
      const past_due_amount_july_2020 = existingExp ? existingExp.past_due_amount_july_2020 : 0;
      const past_due_amount_august_2020 = existingExp ? existingExp.past_due_amount_august_2020 : 0;
      const past_due_amount_september_2020 = existingExp ? existingExp.past_due_amount_september_2020 : 0;
      const past_due_amount_october_2020 = existingExp ? existingExp.past_due_amount_october_2020 : 0;
      const past_due_amount_november_2020 = existingExp ? existingExp.past_due_amount_november_2020 : 0;
      const past_due_amount_december_2020 = existingExp ? existingExp.past_due_amount_december_2020 : 0;
      const past_due_amount_january_2021 = existingExp ? existingExp.past_due_amount_january_2021 : 0;
      const past_due_amount_february_2021 = existingExp ? existingExp.past_due_amount_february_2021 : 0;
      const past_due_amount_march_2021 = existingExp ? existingExp.past_due_amount_march_2021 : 0;
      const past_due_amount_april_2021 = existingExp ? existingExp.past_due_amount_april_2021 : 0;
      const previously_received_funds = existingExp ? existingExp.previously_received_funds : 0;
      const past_due_amount_may_2021 = existingExp ? existingExp.past_due_amount_may_2021 : 0;
      const past_due_amount_june_2021 = existingExp ? existingExp.past_due_amount_june_2021 : 0;
      const past_due_amount_july_2021 = existingExp ? existingExp.past_due_amount_july_2021 : 0;
      const past_due_amount_august_2021 = existingExp ? existingExp.past_due_amount_august_2021 : 0;
      const past_due_amount_september_2021 = existingExp ? existingExp.past_due_amount_september_2021 : 0;
      const past_due_amount_october_2021 = existingExp ? existingExp.past_due_amount_october_2021 : 0;
      const past_due_amount_november_2021 = existingExp ? existingExp.past_due_amount_november_2021 : 0;
      const past_due_amount_december_2021 = existingExp ? existingExp.past_due_amount_december_2021 : 0;
      const past_due_amount_january_2022 = existingExp ? existingExp.past_due_amount_january_2022 : 0;
      const past_due_amount_february_2022 = existingExp ? existingExp.past_due_amount_february_2022 : 0;  
      const past_due_amount_march_2022 = existingExp ? existingExp.past_due_amount_march_2022 : 0;
      const past_due_amount_april_2022 = existingExp ? existingExp.past_due_amount_april_2022 : 0;
      const past_due_amount_may_2022 = existingExp ? existingExp.past_due_amount_may_2022 : 0;
      const past_due_amount_june_2022 = existingExp ? existingExp.past_due_amount_june_2022 : 0;
      const past_due_amount_july_2022 = existingExp ? existingExp.past_due_amount_july_2022 : 0;
      const past_due_amount_august_2022 = existingExp ? existingExp.past_due_amount_august_2022 : 0;
      const past_due_amount_september_2022 = existingExp ? existingExp.past_due_amount_september_2022 : 0;
      const past_due_amount_october_2022 = existingExp ? existingExp.past_due_amount_october_2022 : 0;
      const past_due_amount_november_2022 = existingExp ? existingExp.past_due_amount_november_2022 : 0;
      const past_due_amount_december_2022 = existingExp ? existingExp.past_due_amount_december_2022 : 0;
      const past_due_amount_january_2023 = existingExp ? existingExp.past_due_amount_january_2023 : 0;
      const past_due_amount_february_2023 = existingExp ? existingExp.past_due_amount_february_2023 : 0;
      const past_due_amount_march_2023 = existingExp ? existingExp.past_due_amount_march_2023 : 0;
      const past_due_amount_april_2023 = existingExp ? existingExp.past_due_amount_april_2023 : 0;
      const electricity_company_other = existingExp ? existingExp.electricity_company_other : '';
      const utility_months_in_arrears = existingExp ? existingExp.utility_months_in_arrears : '';
      const name_on_account_hh_member = Boolean(existingExp.name_on_account_hh_member && existingExp.name_on_account_hh_member === 'True');
      
      const controls = new FormGroup({
        id: new FormControl(id),
        related_applications: new FormControl(applicationId),
        expense_type: new FormControl(expense_type),
        in_arrears: new FormControl(in_arrears || '', Validators.required),
        amount_monthly: new FormControl(amount_monthly, [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.min(1), Validators.required]),
        lease_start_date: new FormControl(lease_start_date),
        lease_end_date: new FormControl(lease_end_date),
        months_in_arrears: new FormControl(months_in_arrears, Validators.pattern(Constants.AMOUNT_PATTERN)),
        total_arrears: new FormControl(total_arrears, Validators.pattern(Constants.AMOUNT_PATTERN)),
        amount_past_due: new FormControl(amount_past_due, Validators.pattern(Constants.AMOUNT_PATTERN)),
        provider: new FormControl(provider),
        name_on_account: new FormControl(name_on_account),
        account_number: new FormControl(account_number),
        //provider_zip_code: new FormControl(provider_zip_code),      
        document_file: new FormControl(''),
        document_file_url: new FormControl(url),
        document_name: new FormControl(''),
        document_name_old: new FormControl(exstDocName),
        used: new FormControl(used),
        past_due_amount_april_2020: new FormControl(past_due_amount_april_2020),
        past_due_amount_may_2020: new FormControl(past_due_amount_may_2020),
        past_due_amount_june_2020: new FormControl(past_due_amount_june_2020),
        past_due_amount_july_2020: new FormControl(past_due_amount_july_2020),
        past_due_amount_august_2020: new FormControl(past_due_amount_august_2020),
        past_due_amount_september_2020: new FormControl(past_due_amount_september_2020),
        past_due_amount_october_2020: new FormControl(past_due_amount_october_2020),
        past_due_amount_november_2020: new FormControl(past_due_amount_november_2020),
        past_due_amount_december_2020: new FormControl(past_due_amount_december_2020),
        past_due_amount_january_2021: new FormControl(past_due_amount_january_2021),
        past_due_amount_february_2021: new FormControl(past_due_amount_february_2021),
        past_due_amount_march_2021: new FormControl(past_due_amount_march_2021),
        past_due_amount_april_2021: new FormControl(past_due_amount_april_2021),
        past_due_amount_may_2021: new FormControl(past_due_amount_may_2021),
        past_due_amount_june_2021: new FormControl(past_due_amount_june_2021),
        past_due_amount_july_2021: new FormControl(past_due_amount_july_2021),
        past_due_amount_august_2021: new FormControl(past_due_amount_august_2021),
        past_due_amount_september_2021: new FormControl(past_due_amount_september_2021),
        past_due_amount_october_2021: new FormControl(past_due_amount_october_2021),
        past_due_amount_november_2021: new FormControl(past_due_amount_november_2021),
        past_due_amount_december_2021: new FormControl(past_due_amount_december_2021),
        past_due_amount_january_2022: new FormControl(past_due_amount_january_2022),
        past_due_amount_february_2022: new FormControl(past_due_amount_february_2022),
        past_due_amount_march_2022: new FormControl(past_due_amount_march_2022),
        past_due_amount_april_2022: new FormControl(past_due_amount_april_2022),
        past_due_amount_may_2022: new FormControl(past_due_amount_may_2022),
        past_due_amount_june_2022: new FormControl(past_due_amount_june_2022),
        past_due_amount_july_2022: new FormControl(past_due_amount_july_2022),
        past_due_amount_august_2022: new FormControl(past_due_amount_august_2022),
        past_due_amount_september_2022: new FormControl(past_due_amount_september_2022),
        past_due_amount_october_2022: new FormControl(past_due_amount_october_2022),
        past_due_amount_november_2022: new FormControl(past_due_amount_november_2022),
        past_due_amount_december_2022: new FormControl(past_due_amount_december_2022),
        past_due_amount_january_2023: new FormControl(past_due_amount_january_2023),
        past_due_amount_february_2023: new FormControl(past_due_amount_february_2023),
        past_due_amount_march_2023: new FormControl(past_due_amount_march_2023),
        past_due_amount_april_2023: new FormControl(past_due_amount_april_2023),
        previously_received_funds: new FormControl(previously_received_funds),
        electricity_company_other: new FormControl(electricity_company_other),
        utility_months_in_arrears: new FormControl(utility_months_in_arrears, [Validators.min(0), Validators.max(99)]),
        name_on_account_hh_member: new FormControl(name_on_account_hh_member)
      }, {
        validators: [this.startEndDateValidator]
      })
      formDataArray.push(controls);
    }

    return new FormGroup({
      expenses: formDataArray
    })
  }
  setupExpensesAddForm(applicationId: string) { //expenseNumber, expenses: Array<any>, applicationExpenses: Array<any>, 
    return new FormGroup({
      id: new FormControl(0),
      related_applications: new FormControl(applicationId),
      expense_type: new FormControl('', [Validators.required]),
      account_number: new FormControl(''),
      in_arrears: new FormControl('', Validators.required),
      amount_monthly: new FormControl(0, [Validators.min(1), Validators.required, Validators.pattern(Constants.AMOUNT_PATTERN)]),
      lease_start_date: new FormControl(''),
      lease_end_date: new FormControl(''),
      months_in_arrears: new FormControl(0),
      total_arrears: new FormControl(0),
      name_on_account: new FormControl(''),
      provider: new FormControl(''),
      //provider_zip_code: new FormControl('')
      document_file: new FormControl(''),
      document_file_url: new FormControl(''),
      document_name: new FormControl(''),
      past_due_amount_april_2020: new FormControl(0),
      past_due_amount_may_2020: new FormControl(0),
      past_due_amount_june_2020: new FormControl(0),
      past_due_amount_july_2020: new FormControl(0),
      past_due_amount_august_2020: new FormControl(0),
      past_due_amount_september_2020: new FormControl(0),
      past_due_amount_october_2020: new FormControl(0),
      past_due_amount_november_2020: new FormControl(0),
      past_due_amount_december_2020: new FormControl(0),
      past_due_amount_january_2021: new FormControl(0),
      past_due_amount_february_2021: new FormControl(0),
      past_due_amount_march_2021: new FormControl(0),
      past_due_amount_april_2021: new FormControl(0),
      previously_received_funds: new FormControl(0),
      past_due_amount_may_2021: new FormControl(0),
      past_due_amount_june_2021: new FormControl(0),
      past_due_amount_july_2021: new FormControl(0),
      past_due_amount_august_2021: new FormControl(0),
      past_due_amount_september_2021: new FormControl(0),
      past_due_amount_october_2021: new FormControl(0),
      past_due_amount_november_2021: new FormControl(0),
      past_due_amount_december_2021: new FormControl(0),
      past_due_amount_january_2022: new FormControl(0),
      past_due_amount_february_2022: new FormControl(0),
      past_due_amount_march_2022: new FormControl(0),
      past_due_amount_april_2022: new FormControl(0),
      past_due_amount_may_2022: new FormControl(0),
      past_due_amount_june_2022: new FormControl(0),
      past_due_amount_july_2022: new FormControl(0),
      past_due_amount_august_2022: new FormControl(0),
      past_due_amount_september_2022: new FormControl(0),
      past_due_amount_october_2022: new FormControl(0),
      past_due_amount_november_2022: new FormControl(0),
      past_due_amount_december_2022: new FormControl(0),
      past_due_amount_january_2023: new FormControl(0),
      past_due_amount_february_2023: new FormControl(0),
      past_due_amount_march_2023: new FormControl(0),
      past_due_amount_april_2023: new FormControl(0),
      amount_past_due: new FormControl(null),
      electricity_company_other: new FormControl(null),
      utility_months_in_arrears: new FormControl(0, [Validators.min(0), Validators.max(99)]),
      name_on_account_hh_member: new FormControl(null)
    }, {
      validators: [this.startEndDateValidator]
    })
  }
  setupExpensesDeleteForm(id: any, expense: Array<any>, applicationExpenses: Array<any>, applicationId: string) {

    const expenseId = id;
    const formDataArray = new FormArray([]);
    var found = false;

    for (const iterator of expense) {

      let existingExp: any = null;

      for (const innerIterator of applicationExpenses) {
        if (id === innerIterator.id) {
          found = true;
          existingExp = Object.assign({}, innerIterator);
          break;
        }
        if (found) {
          break;
        }
      }

      const expense_type = existingExp ? existingExp.expense_type : '';
      const amount_monthly = existingExp ? existingExp.amount_monthly : '';
      // const provider = existingExp ? existingExp.provider : '';

      const controls = new FormGroup({
        id: new FormControl(expenseId),
        expense_type: new FormControl(expense_type),
        amount_monthly: new FormControl(amount_monthly),
        // provider: new FormControl(provider),
      })
      formDataArray.push(controls);
    }

    return new FormGroup({
      expense: formDataArray
    })
  }

  setupExpensesIncomeForm(applicationExpenses: any, applicationId: string) {

    const formDataArray = new FormArray([]);
    var found = false;
    const recert_applicant_signature_date = applicationExpenses?.recert_applicant_signature_date ? new Date(applicationExpenses?.recert_applicant_signature_date).toISOString().split('T')[0] : '';
    const controls = new FormGroup({
      id: new FormControl(applicationExpenses ? applicationExpenses?.id : null),
      related_applications: new FormControl(applicationId),
      proof_of_income_file_1:new FormControl(applicationExpenses?.proof_of_income_file_1 ||''),
      proof_of_income_file_1_url:new FormControl(applicationExpenses?.proof_of_income_file_1_url || '',Validators.required),
      proof_of_income_file_2:new FormControl(applicationExpenses?.proof_of_income_file_2 ||''),
      proof_of_income_file_2_url:new FormControl(applicationExpenses?.proof_of_income_file_2_url || ''),
      proof_of_income_file_3:new FormControl(applicationExpenses?.proof_of_income_file_3 ||''),
      proof_of_income_file_3_url:new FormControl(applicationExpenses?.proof_of_income_file_3_url || ''),
      proof_of_income_file_4:new FormControl(applicationExpenses?.proof_of_income_file_4 ||''),
      proof_of_income_file_4_url:new FormControl(applicationExpenses?.proof_of_income_file_4_url || ''),
      proof_of_income_file_5:new FormControl(applicationExpenses?.proof_of_income_file_5 ||''),
      proof_of_income_file_5_url:new FormControl(applicationExpenses?.proof_of_income_file_5_url || ''),
      proof_of_income_file_6:new FormControl(applicationExpenses?.proof_of_income_file_6 ||''),
      proof_of_income_file_6_url:new FormControl(applicationExpenses?.proof_of_income_file_6_url || ''),
      proof_of_rental_payment_file_1:new FormControl(applicationExpenses?.proof_of_rental_payment_file_1 ||''),
      proof_of_rental_payment_file_1_url:new FormControl(applicationExpenses?.proof_of_rental_payment_file_1_url || '',Validators.required),
      proof_of_rental_payment_file_2:new FormControl(applicationExpenses?.proof_of_rental_payment_file_2 ||''),
      proof_of_rental_payment_file_2_url:new FormControl(applicationExpenses?.proof_of_rental_payment_file_2_url || ''),
      proof_of_rental_payment_file_3:new FormControl(applicationExpenses?.proof_of_rental_payment_file_3 ||''),
      proof_of_rental_payment_file_3_url:new FormControl(applicationExpenses?.proof_of_rental_payment_file_3_url || ''),
      proof_of_rental_payment_file_4:new FormControl(applicationExpenses?.proof_of_rental_payment_file_4 ||''),
      proof_of_rental_payment_file_4_url:new FormControl(applicationExpenses?.proof_of_rental_payment_file_4_url || ''),
      recert_applicant_signature: new FormControl(applicationExpenses?.recert_applicant_signature || ''),
      recert_applicant_signature_date: new FormControl(recert_applicant_signature_date),
    })

    return controls;
  }
  // setupPastDueAmountsAddForm(months: Array<any>, applicationId: string) {

  //   const formDataArray = new FormArray([]);
  //   var found = false;

  //   for (const iterator of months) {

  //     // let existingPDA: any = null;

  //     // for (const innerIterator of applicationPastDueAmounts) {
  //     //   if (pastDueAmountNumber.toString() === innerIterator.id) {
  //     //     found = true;
  //     //     existingPDA = Object.assign({}, innerIterator);
  //     //     break;
  //     //   }
  //     //   if (found) {
  //     //     break;
  //     //   }
  //     // }

  //     //const household_number = existingPDA ? existingPDA.household_number : ''; 
  //     // const month = existingPDA ? existingPDA.month : '';
  //     // const past_due_amount = existingPDA ? existingPDA.past_due_amount : 0;
  //     // const used = existingPDA ? true : false;
  //     // const id = existingPDA ? existingPDA.id : null;

  //     const controls = new FormGroup({
  //       id: new FormControl(0),
  //       //household_number: new FormControl(pastDueAmountNumber),
  //       related_applications: new FormControl(applicationId),
  //       month: new FormControl(iterator),
  //       past_due_amount: new FormControl(0),
  //       month_type: new FormControl(0),
  //       used: new FormControl('')
  //     })
  //     formDataArray.push(controls);
  //   }

  //   return new FormGroup({
  //     past_due_amounts: formDataArray
  //   })
  // }

  setupAppForm(obj: any = {},expObj:any = {},expRecertification:any = {}): FormGroup {
    const homeless = Boolean(obj['homeless'] && obj['homeless'] === 'True');
    const elderly = Boolean(obj['elderly'] && obj['elderly'] === 'True');
    const disabled = Boolean(obj['disabled'] && obj['disabled'] === 'True');
    const accessibility = Boolean(obj['accessibility'] && obj['accessibility'] === 'True') ? 'True':'False';
    const willing_to_relocate = Boolean(obj['willing_to_relocate'] && obj['willing_to_relocate'] === 'True') ? 'True':'False';
    const fema = Boolean(obj['fema'] && obj['fema'] === 'True') ? 'True':'False';
    const sbca = Boolean(obj['sba'] && obj['sba'] === 'True') ? 'True':'False';
    const section_8 = Boolean(obj['section_8'] && obj['section_8'] === 'True') ? 'True':'False';
    const tbra = Boolean(obj['tbra'] && obj['tbra'] === 'True') ? 'True':'False';
    const insurance = Boolean(obj['insurance'] && obj['insurance'] === 'True') ? 'True':'False';
    const other_assistance = Boolean(obj['other_assistance'] && obj['other_assistance'] === 'True') ? 'True':'False';
    const family_seeking_housing = Boolean(obj['family_seeking_housing'] && obj['family_seeking_housing'] === 'True') ? 'True':'False';
    const hh_asset_giveaway = Boolean(obj['hh_asset_giveaway'] && obj['hh_asset_giveaway'] === 'True') ? 'True':'False';
    const hh_asset_owned_home = Boolean(obj['hh_asset_owned_home'] && obj['hh_asset_owned_home'] === 'True') ? 'True':'False';
    const hh_asset_owned_home_currently = Boolean(obj['hh_asset_owned_home_currently'] && obj['hh_asset_owned_home_currently'] === 'True') ? 'True':'False';
    const hh_asset_owned_home_rented = Boolean(obj['hh_asset_owned_home_rented'] && obj['hh_asset_owned_home_rented'] === 'True') ? 'True':'False';
    const hh_asset_owned_home_vacant = Boolean(obj['hh_asset_owned_home_vacant'] && obj['hh_asset_owned_home_vacant'] === 'True') ? 'True':'False';
    const hh_asset_owned_home_selling = Boolean(obj['hh_asset_owned_home_selling'] && obj['hh_asset_owned_home_selling'] === 'True') ? 'True':'False';

    const fema_date = obj.fema_date_received ? new Date(obj.fema_date_received).toISOString().split('T')[0] : '';
    const sba_date = obj.sba_date_received ? new Date(obj.sba_date_received).toISOString().split('T')[0] : '';
    const section_8_date = obj.section_8_date_received ? new Date(obj.section_8_date_received).toISOString().split('T')[0] : '';
    const tbra_date_received = obj.tbra_date_received ? new Date(obj.tbra_date_received).toISOString().split('T')[0] : '';
    const insurance_date_received = obj.insurance_date_received ? new Date(obj.insurance_date_received).toISOString().split('T')[0] : '';
    const other_assistance_date_received = obj.other_assistance_date_received ? new Date(obj.other_assistance_date_received).toISOString().split('T')[0] : '';
    const date_application_started = obj.date_application_started ? new Date(obj.date_application_started).toISOString().split('T')[0] : '';
    const applicant_signature_date = obj.applicant_signature_date ? new Date(obj.applicant_signature_date).toISOString().split('T')[0] : '';
    const lease_start_date = expObj.lease_start_date ? new Date(expObj.lease_start_date).toISOString().split('T')[0] : '';
    const lease_end_date = expObj.lease_end_date ? new Date(expObj.lease_end_date).toISOString().split('T')[0] : '';
    const recert_applicant_signature_date = expRecertification.recert_applicant_signature_date ? new Date(expRecertification.recert_applicant_signature_date).toISOString().split('T')[0] : '';

    return new FormGroup({
      contact_first_name: new FormControl(obj.contact_first_name || ''),
      contact_last_name: new FormControl(obj.contact_last_name || ''),
      contact_mi: new FormControl(obj.contact_mi || ''),
      contact_email: new FormControl(
        obj.contact_email, [Validators.pattern(Constants.EMAIL_PATTERN)]
      ),
      contact_phone: new FormControl(obj.contact_phone || ''),
      physical_street: new FormControl(obj.physical_street || ''),
      physical_street_2: new FormControl(obj.physical_street_2 || ''),
      physical_city: new FormControl(obj.physical_city || ''),
      physical_state: new FormControl(obj.physical_state || ''),
      physical_zip_code: new FormControl(obj.physical_zip_code || ''),   
      physical_longitude: new FormControl(obj.physical_longitude || ''),   
      physical_latitude: new FormControl(obj.physical_latitude || ''),   
      //parish: new FormControl(obj.parish || 0),   
      preferred_parish_1: new FormControl(obj.preferred_parish_1!='0' ? obj.preferred_parish_1:''),   
      preferred_parish_2: new FormControl(obj.preferred_parish_2!='0' ? obj.preferred_parish_2:''),   
      preferred_parish_3: new FormControl(obj.preferred_parish_3!='0' ? obj.preferred_parish_3:''),   
      //number_of_bedrooms: new FormControl(obj.number_of_bedrooms || '-1', [Validators.required, Validators.min(0)]), 
      //unit_size: new FormControl(obj.unit_size || 'Not Selected', Validators.required)     
      emergency_first_name: new FormControl(obj.emergency_first_name || ''),
      emergency_last_name: new FormControl(obj.emergency_last_name || ''),
      emergency_mi: new FormControl(obj.emergency_mi || ''),
      emergency_relationship: new FormControl(obj.emergency_relationship || ''),
      emergency_street: new FormControl(obj.emergency_street || ''),
      emergency_city: new FormControl(obj.emergency_city || ''),
      emergency_state: new FormControl(obj.emergency_state || ''),
      emergency_zip_code: new FormControl(obj.emergency_zip_code || ''),   
      emergency_home_phone: new FormControl(obj.emergency_home_phone || ''),
      emergency_work_phone: new FormControl(obj.emergency_work_phone || ''),
      emergency_email: new FormControl(
        obj.emergency_email, [Validators.pattern(Constants.EMAIL_PATTERN)]
      ),
      emergency_fax: new FormControl(obj.emergency_fax || ''),
      emergency_mobile_phone: new FormControl(obj.emergency_mobile_phone || ''),
      emergency_alt_phone: new FormControl(obj.emergency_alt_phone || ''),
      homeless: new FormControl(homeless),
      elderly: new FormControl(elderly),
      disabled: new FormControl(disabled),
      accessibility: new FormControl(accessibility),
      accessibility_explain: new FormControl(obj.accessibility_explain || ''),
      willing_to_relocate: new FormControl(willing_to_relocate),
      fema: new FormControl(fema),
      fema_amount: new FormControl(obj.fema_amount || ''),
      fema_date_received: new FormControl(fema_date),
      fema_reason: new FormControl(obj.fema_reason || ''),
      sba: new FormControl(sbca),
      sba_amount: new FormControl(obj.sba_amount || ''),
      sba_date_received: new FormControl(sba_date),
      sba_reason: new FormControl(obj.sba_reason || ''),
      section_8: new FormControl(section_8),
      section_8_amount: new FormControl(obj.section_8_amount || ''),
      section_8_date_received: new FormControl(section_8_date),
      section_8_reason: new FormControl(obj.section_8_reason || ''),
      tbra: new FormControl(tbra),
      tbra_amount: new FormControl(obj.tbra_amount || ''),
      tbra_date_received: new FormControl(tbra_date_received),
      tbra_reason: new FormControl(obj.tbra_reason || ''),
      insurance: new FormControl(insurance),
      insurance_amount: new FormControl(obj.insurance_amount || ''),
      insurance_date_received: new FormControl(insurance_date_received),
      insurance_reason: new FormControl(obj.insurance_reason || ''),
      other_assistance: new FormControl(other_assistance),
      other_assistance_amount: new FormControl(obj.other_assistance_amount || ''),
      other_assistance_date_received: new FormControl(other_assistance_date_received),
      other_assistance_reason: new FormControl(obj.other_assistance_reason || ''),
      prior_week_living_situation: new FormControl(obj.prior_week_living_situation),
      prior_week_living_situation_other: new FormControl(obj.prior_week_living_situation_other || ''),
      explain_living_situation: new FormControl(obj.explain_living_situation || ''),
      receiving_housing_assistance: new FormControl(obj.receiving_housing_assistance || ''),
      how_long_displaced: new FormControl(obj.how_long_displaced || ''),
      family_seeking_housing: new FormControl(family_seeking_housing),
      family_seeking_housing_number: new FormControl(Number(obj.family_seeking_housing_number) || '',[Validators.min(1)]),
      referral_name: new FormControl(obj.referral_name || ''),
      referral_agency: new FormControl(obj.referral_agency || ''),
      referral_address: new FormControl(obj.referral_address || ''),
      referral_phone: new FormControl(obj.referral_phone || ''),
      referral_email: new FormControl(obj.referral_email || ''),
      applicant_signature: new FormControl(obj.applicant_signature || ''),
      applicant_signature_date: new FormControl(applicant_signature_date),
      hh_asset_giveaway: new FormControl(hh_asset_giveaway || ''),
      hh_asset_giveaway_who: new FormControl(obj.hh_asset_giveaway_who || ''),
      hh_asset_giveaway_explanation: new FormControl(obj.hh_asset_giveaway_explanation || ''),
      hh_asset_owned_home: new FormControl(hh_asset_owned_home || ''),
      hh_asset_owned_home_who: new FormControl(obj.hh_asset_owned_home_who || ''),
      hh_asset_owned_home_currently: new FormControl(hh_asset_owned_home_currently || ''),
      hh_asset_owned_home_disposed: new FormControl(obj.hh_asset_owned_home_disposed || ''),
      hh_asset_owned_home_rented: new FormControl(hh_asset_owned_home_rented || ''),
      hh_asset_owned_home_vacant: new FormControl(hh_asset_owned_home_vacant || ''),
      hh_asset_owned_home_selling: new FormControl(hh_asset_owned_home_selling || ''),
      // file_drivers_license: new FormControl(''),
      // file_drivers_license_name: new FormControl(obj.file_drivers_license || ''),

      landlord_contact_name: new FormControl(expObj.landlord_contact_name || ''),
      landlord_phone: new FormControl(expObj.landlord_phone || ''),
      landlord_email_address: new FormControl(expObj.landlord_email_address || '',Validators.pattern(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)),
      new_address_1: new FormControl(expObj.new_address_1 || ''),
      new_address_2: new FormControl(expObj.new_address_2 || ''),
      new_city: new FormControl(expObj.new_city || ''),
      new_state: new FormControl(expObj.new_state || ''),
      new_zip: new FormControl(expObj.new_zip || '',Validators.maxLength(5)),
      parish: new FormControl(expObj.parish!='0' ? expObj.parish:''),   
      unit_size: new FormControl(expObj.unit_size!='0' ? this.setUnitSize(expObj.unit_size):0),   
      year_rental_unit_built: new FormControl((expObj.year_rental_unit_built>1968 &&  expObj.year_rental_unit_built< (new Date().getFullYear())) ? expObj.year_rental_unit_built : 0),
      lease_start_date: new FormControl(lease_start_date),
      lease_end_date: new FormControl(lease_end_date),
      monthly_rent_amount: new FormControl(expObj.monthly_rent_amount),
      security_deposit_amount: new FormControl(expObj.security_deposit_amount),
      mail_to: new FormControl(expObj.mail_to || 'Mail to Other'),
      mailing_address_1: new FormControl(expObj.mailing_address_1 || ''),
      mailing_address_2: new FormControl(expObj.mailing_address_2 || ''),
      mailing_city: new FormControl(expObj.mailing_city || ''),
      mailing_state: new FormControl(expObj.mailing_state || ''),
      mailing_zip: new FormControl(expObj.mailing_zip || '',Validators.maxLength(5)),

      recert_applicant_signature: new FormControl(expRecertification.recert_applicant_signature || ''),
      recert_applicant_signature_date: new FormControl(recert_applicant_signature_date),
      
    });
  }

  setupRentalDocumentsForm(obj: any = {}): FormGroup {
    return new FormGroup({
      file_lease: new FormControl('', Validators.required),
      file_name_lease: new FormControl(''),
      file_past_due_rent: new FormControl(''),
      file_name_past_due_rent: new FormControl(''),
      file_eviction_notice: new FormControl(''),
      file_name_eviction_notice: new FormControl(''),
      file_past_due_utility: new FormControl(''),
      file_name_past_due_utility: new FormControl(''),
      file_other: new FormControl(''),
      file_name_other: new FormControl(''),
    })
  }
  setupContactForm(obj: any = {}): FormGroup {
    return new FormGroup({
      contact_first_name: new FormControl(obj.contact_first_name || '', Validators.required),
      contact_last_name: new FormControl(obj.contact_last_name || '', Validators.required),
      contact_mi: new FormControl(obj.contact_mi || ''),
      contact_email: new FormControl(
        obj.contact_email, [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)]
      ),
      contact_phone: new FormControl(obj.contact_phone || '', Validators.required)
    });
  }
  setupAddressesForm(obj: any = {}): FormGroup {
    return new FormGroup({
      physical_street: new FormControl(obj.physical_street || ''),
      physical_street_2: new FormControl(obj.physical_street_2 || ''),
      physical_city: new FormControl(obj.physical_city || ''),
      physical_state: new FormControl(obj.physical_state || ''),
      physical_zip_code: new FormControl(obj.physical_zip_code || ''),   
      physical_longitude: new FormControl(obj.physical_longitude || ''),   
      physical_latitude: new FormControl(obj.physical_latitude || ''),   
      parish: new FormControl(obj.parish || 0),   
      number_of_bedrooms: new FormControl(obj.number_of_bedrooms || '-1', [Validators.required, Validators.min(0)]), 
      unit_size: new FormControl(obj.unit_size || 'Not Selected', Validators.required)     
    });
  }
  setupLandlordForm(obj: any = {}): FormGroup {
    return new FormGroup({
      landlord_first_name: new FormControl(obj.landlord_first_name || '', Validators.required),
      landlord_last_name: new FormControl(obj.landlord_last_name || '', Validators.required),
      landlord_email: new FormControl(
        obj.landlord_email, [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)]
      ),
      landlord_phone: new FormControl(obj.landlord_phone || '', Validators.required),   
      property_name: new FormControl(obj.property_name || '', Validators.required),
      property_type: new FormControl(obj.property_type || 0, [Validators.required, Validators.min(1)]),
      confirmation_number: new FormControl(obj.confirmation_number || '')
    });
  }

  setupApplicantAcknowledgementsForm(obj: any = {}): FormGroup {
    const userData = this.projectSpecificService.getUserData();
    const certify = Boolean(obj['certify'] && obj['certify'] === 'True');
    const ack = Boolean(obj['ack'] && obj['ack'] === 'True');
    const ack_2 = Boolean(obj['ack_2'] && obj['ack_2'] === 'True');
    const ack_3 = Boolean(obj['ack_3'] && obj['ack_3'] === 'True');
    const ack_4 = Boolean(obj['ack_4'] && obj['ack_4'] === 'True');
    const ack_5 = Boolean(obj['ack_5'] && obj['ack_5'] === 'True');

    return new FormGroup({
      certifier_name: new FormControl(obj.certifier_name || ''),
      file_drivers_license: new FormControl(''),
      file_drivers_license_name: new FormControl(obj.file_drivers_license || ''),
      certified_by: new FormControl(userData.userName),
      certified_dt: new FormControl(new Date()),
      ack: new FormControl(ack, Validators.required),
      ack_2: new FormControl(ack_2, Validators.required),
      ack_3: new FormControl(ack_3, Validators.required),
      ack_4: new FormControl(ack_4, Validators.required),
      ack_5: new FormControl(ack_5, Validators.required),
      certify: new FormControl(certify, Validators.required),
    })
  }

  setupApplicantAcknowledgementsFormTicket(obj: any = {}): FormGroup {
    const certify = Boolean(obj['certify' ] && obj['certify' ] === 'True');
    const ack = Boolean(obj['ack' ] && obj['ack' ] === 'True');
    const ack_2 = Boolean(obj['ack_2' ] && obj['ack_2' ] === 'True');
    const ack_3 = Boolean(obj['ack_3' ] && obj['ack_3' ] === 'True');
    const ack_4 = Boolean(obj['ack_4' ] && obj['ack_4' ] === 'True');
    const ack_5 = Boolean(obj['ack_5' ] && obj['ack_5' ] === 'True');

    return new FormGroup({
      ack: new FormControl(null, Validators.required),
      ack_2: new FormControl(null, Validators.required),
      ack_3: new FormControl(null, Validators.required),
      ack_4: new FormControl(null, Validators.required),
      ack_5: new FormControl(null, Validators.required),
      certify: new FormControl(null, Validators.required),
      certified_dt: new FormControl(new Date()),
    });
  }

  setUnitSize(rooms: string) {
    switch (rooms) {
      case "Efficiency":
        return "1";
      case "1 Bedroom":
        return "2";
      case "2 Bedroom":
        return "3";
      case "3 Bedroom":
        return "4";
      case "4 Bedroom":
        return "5";
      default:
        return "0";
    }
  }
}
