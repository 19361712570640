export const states = [
    {
        label: "Alabama",
        value: "Alabama",
        abbreviation: "AL",
    },
    {
        label: "Alaska",
        value: "Alaska",
        abbreviation: "AK",
    },
    {
        label: "Arizona",
        value: "Arizona",
        abbreviation: "AZ",
    },
    {
        label: "Arkansas",
        value: "Arkansas",
        abbreviation: "AR",
    },
    {
        label: "California",
        value: "California",
        abbreviation: "CA",
    },
    {
        label: "Colorado",
        value: "Colorado",
        abbreviation: "CO",
    },
    {
        label: "Connecticut",
        value: "Connecticut",
        abbreviation: "CT",
    },
    {
        label: "Delaware",
        value: "Delaware",
        abbreviation: "DE",
    },
    {
        label: "Florida",
        value: "Florida",
        abbreviation: "FL",
    },
    {
        label: "Georgia",
        value: "Georgia",
        abbreviation: "GA",
    },
    {
        label: "Hawaii",
        value: "Hawaii",
        abbreviation: "HI",
    },
    {
        label: "Idaho",
        value: "Idaho",
        abbreviation: "ID",
    },
    {
        label: "Illinois",
        value: "Illinois",
        abbreviation: "IL",
    },
    {
        label: "Indiana",
        value: "Indiana",
        abbreviation: "IN",
    },
    {
        label: "Iowa",
        value: "Iowa",
        abbreviation: "IA",
    },
    {
        label: "Kansas",
        value: "Kansas",
        abbreviation: "KS",
    },
    {
        label: "Kentucky",
        value: "Kentucky",
        abbreviation: "KY",
    },
    {
        label: "Louisiana",
        value: "Louisiana",
        abbreviation: "LA",
    },
    {
        label: "Maine",
        value: "Maine",
        abbreviation: "ME",
    },
    {
        label: "Maryland",
        value: "Maryland",
        abbreviation: "MD",
    },
    {
        label: "Massachusetts",
        value: "Massachusetts",
        abbreviation: "MA",
    },
    {
        label: "Michigan",
        value: "Michigan",
        abbreviation: "MI",
    },
    {
        label: "Minnesota",
        value: "Minnesota",
        abbreviation: "MN",
    },
    {
        label: "Mississippi",
        value: "Mississippi",
        abbreviation: "MS",
    },
    {
        label: "Missouri",
        value: "Missouri",
        abbreviation: "MO",
    },
    {
        label: "Montana",
        value: "Montana",
        abbreviation: "MT",
    },
    {
        label: "Nebraska",
        value: "Nebraska",
        abbreviation: "NE",
    },
    {
        label: "Nevada",
        value: "Nevada",
        abbreviation: "NV",
    },
    {
        label: "New Hampshire",
        value: "New Hampshire",
        abbreviation: "NH",
    },
    {
        label: "New Jersey",
        value: "New Jersey",
        abbreviation: "NJ",
    },
    {
        label: "New Mexico",
        value: "New Mexico",
        abbreviation: "NM",
    },
    {
        label: "New York",
        value: "New York",
        abbreviation: "NY",
    },
    {
        label: "North Carolina",
        value: "North Carolina",
        abbreviation: "NC",
    },
    {
        label: "North Dakota",
        value: "North Dakota",
        abbreviation: "ND",
    },
    {
        label: "Ohio",
        value: "Ohio",
        abbreviation: "OH",
    },
    {
        label: "Oklahoma",
        value: "Oklahoma",
        abbreviation: "OK",
    },
    {
        label: "Oregon",
        value: "Oregon",
        abbreviation: "OR",
    },
    {
        label: "Pennsylvania",
        value: "Pennsylvania",
        abbreviation: "PA",
    },
    {
        label: "Rhode Island",
        value: "Rhode Island",
        abbreviation: "RI",
    },
    {
        label: "South Carolina",
        value: "South Carolina",
        abbreviation: "SC",
    },
    {
        label: "South Dakota",
        value: "South Dakota",
        abbreviation: "SD",
    },
    {
        label: "Tennessee",
        value: "Tennessee",
        abbreviation: "TN",
    },
    {
        label: "Texas",
        value: "Texas",
        abbreviation: "TX",
    },
    {
        label: "Utah",
        value: "Utah",
        abbreviation: "UT",
    },
    {
        label: "Vermont",
        value: "Vermont",
        abbreviation: "VT",
    },
    {
        label: "Virginia",
        value: "Virginia",
        abbreviation: "VA",
    },
    {
        label: "Washington",
        value: "Washington",
        abbreviation: "WA",
    },
    {
        label: "West Virginia",
        value: "West Virginia",
        abbreviation: "WV",
    },
    {
        label: "Wisconsin",
        value: "Wisconsin",
        abbreviation: "WI",
    },
    {
        label: "Wyoming",
        value: "Wyoming",
        abbreviation: "WY",
    },
];

export const pastDueFields = [
    "past_due_amount_april_2020",
    "past_due_amount_may_2020",
    "past_due_amount_june_2020",
    "past_due_amount_july_2020",
    "past_due_amount_august_2020",
    "past_due_amount_september_2020",
    "past_due_amount_october_2020",
    "past_due_amount_november_2020",
    "past_due_amount_december_2020",
    "past_due_amount_january_2021",
    "past_due_amount_february_2021",
    "past_due_amount_march_2021",
    "past_due_amount_april_2021",
    "past_due_amount_may_2021",
    "past_due_amount_june_2021",
    "past_due_amount_july_2021",
    "past_due_amount_august_2021",
    "past_due_amount_september_2021",
    "past_due_amount_october_2021",
    "past_due_amount_november_2021",
    "past_due_amount_december_2021",
    "past_due_amount_january_2022",
    "past_due_amount_february_2022",
    "past_due_amount_march_2022",
    "past_due_amount_april_2022",
    "past_due_amount_may_2022",
    "past_due_amount_june_2022",
    "past_due_amount_july_2022",
    "past_due_amount_august_2022",
    "past_due_amount_september_2022",
    "past_due_amount_october_2022",
    "past_due_amount_november_2022",
    "past_due_amount_december_2022",
    "past_due_amount_january_2023",
    "past_due_amount_february_2023",
    "past_due_amount_march_2023",
    "past_due_amount_april_2023"];

export const rentalObligationFields = [
    'landlord_contact_name',
    'landlord_email_address',
    'new_address_1',
    'new_city',
    'new_state',
    'new_zip',
    'unit_size',
    'year_rental_unit_built',
    'lease_start_date',
    'lease_end_date',
    'monthly_rent_amount',
    'security_deposit_amount',
    'mail_to',
    'mailing_address_1',
    'mailing_city',
    'mailing_state',
    'mailing_zip',
]

export const expenseMinFields: any = {
    'unit_size': 1,
    'monthly_rent_amount': 1,
    'security_deposit_amount': 0
}