import moment from "moment";

export class Constants {
    public static MESSAGES = {
        APPLICATION_CREATE_SUCCESS: 'Application Created Successfully',
        APPLICATION_CREATE_ERROR: 'Error in Creating Application',
        APPLICATION_UPDATE_SUCCESS: 'Application Updated Successfully',
        APPLICATION_UPDATE_ERROR: 'Error Updating Application',
        APPLICATION_FETCH_ERROR: 'Error Fetching Application Data',
        PROPERTY_FETCH_ERROR: 'Error Fetching Property Data',
        RENTAL_FETCH_ERROR: 'Error Fetching Rental Data',
        EXPENSES_FETCH_ERROR: 'Error Fetching Expenses Data',
        EXPENSES_UPDATE_SUCCESS: 'Expenses Updated Successfully',
        EXPENSES_UPDATE_ERROR: 'Error Updating Expenses',
        EXPENSE_DELETE_SUCCESS: 'Expense Deleted Successfully',
        EXPENSE_DELETE_ERROR: 'Error Deleting Expense',
        HOUSEHOLD_FETCH_ERROR: 'Error Fetching Household Data',
        HOUSEHOLD_UPDATE_SUCCESS: 'Household Updated Successfully',
        HOUSEHOLD_UPDATE_ERROR: 'Error Updating Household',
        HOUSEHOLD_DELETE_SUCCESS: 'Household Deleted Successfully',
        HOUSEHOLD_DELETE_ERROR: 'Error Deleting Household',
        TIN_ALREADY_EXIST: 'Error: SSN / ITIN / EIN Already Registered',
        INVALID_NAICS: 'Error: Invalid NAICS Code',
        INVITATION_EMAIL_SUCCESS: 'Invitation Email Sent Successfully',
        INVITATION_EMAIL_ERROR: 'Error Sending Invite Email',
        VERIFICATION_MISMATCH: 'Verification data does not match Applicant data',
        VERIFICATION_NOT_CHECKED: 'You did not certify this approval',
        CREATE_TICKET_SUCCESS: 'Ticket Created Successfully',
        CREATE_TICKET_ERROR: 'Error Creating Ticket',
        FILE_UPLOAD_SUCCESS: 'File Uploaded Successfully',
        FILE_UPLOAD_ERROR: 'Error Uploading File',
        PROPERTY_UPDATE_SUCCESS: 'Property Updated Successfully',
        PROPERTY_UPDATE_ERROR: 'Error Updating Property',
        FILE_DELETE_SUCCESS: 'File Deleted Successfully',
        FILE_DELETE_ERROR: 'Error Deleting File',
    };

    public static EXPENSES_MAPPING = {
        "Rent": "RENT",
        "Utility - Electricity": "ELECTRICITY",
        "Utility - Gas": "GAS",
        "Utility - Water": "WATER",
        "Utility - Phone": "PHONE",
        "Utility - Internet Service": "INTERNET",
        "Utility - Other": "OTHER",
    };
    public static HOUSEHOLD_MAPPING = {
        "Household 0": "#1",
        "Household 1": "#2",
        "Household 2": "#3",
        "Household 3": "#4",
        "Household 4": "#5",
        "Household 5": "#6",
        "Household 6": "#7",
        "Household 7": "#8",
        "Household 8": "#9",
        "Household 9": "#10",
    };
    public static MONTHS_MAPPING = {
        "March 2020": "March 2020",
        "April 2020": "April 2020",
        "May 2020": "May 2020",
        "June 2020": "June 2020",
        "July 2020": "July 2020",
        "August 2020": "August 2020",
        "September 2020": "September 2020",
        "October 2020": "October 2020",
        "November 2020": "November 2020",
        "December 2020": "December 2020",
        "January 2021": "January 2021",
        "February 2021": "February 2021",
        "March 2021": "March 2021",
        "April 2021": "April 2021",
        "May 2021": "May 2021",
        "June 2021": "June 2021",
        "July 2021": "July 2021",
        "August 2021": "August 2021",
        "September 2021": "September 2021",
        "October 2021": "October 2021",
        "November 2021": "November 2021",
        "December 2021": "December 2021",
    };

    public static FILE_SIZE_LIMIT = 15 * 1000000; //(1 MB = 1000000 bytes)
    public static EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static AMOUNT_PATTERN = /^\d{1,8}(\.\d{1,2})?$/;
    public static PASSWORD_PATTERN = /^(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,50}$/;
    public static TWENTY_MB_IN_BYTE = 20000000;

    public static HOUSEHOLD_VALIDATION_RULES = [
        {
            prop: 'first_name',
            messageKey: 'NO_FIRST_NAME',
            condition: hh => !hh.first_name,
        },
        {
            prop: 'last_name',
            messageKey: 'NO_LAST_NAME',
            condition: hh => !hh.last_name,
        },
        {
            prop: 'gender',
            messageKey: 'NO_GENDER',
            condition: hh => !hh.gender,
        },
        {
            prop: 'race',
            messageKey: 'NO_RACE',
            condition: hh => !hh.race,
        },
        {
            prop: 'ethnicity',
            messageKey: 'NO_ETHNICITY',
            condition: hh => !hh.ethnicity,
        },
        {
            prop: 'veteran',
            messageKey: 'NO_VETERAN',
            condition: hh => !hh.veteran,
        },
        {
            prop: 'employment_status ',
            messageKey: 'NO_LAST_DATE_WORKED',
            condition: hh => hh.employment_status === "Unemployed" &&
            (!hh.last_date_worked || hh.last_date_worked === "Invalid date") ,
        },
        {
            prop: 'date_of_birth',
            messageKey: 'NO_DOB',
            condition: hh => !hh.date_of_birth || hh.date_of_birth === "Invalid date",
        },
        {
            prop: 'employment_status',
            messageKey: 'NO_EMPLOYMENT_STATUS',
            condition: hh => !hh.employment_status,
        },
        {
            prop: 'relation_to_applicant',
            messageKey: 'NO_RELATION_TO_APPLICANT',
            condition: hh => !hh.relation_to_applicant,
        },
        {
            prop: 'gross_file',
            messageKey: 'MISSING_WAGES_DOCS',
            condition: hh =>["Employed", "Other"].find((el) => el === hh.employment_status) &&
            hh.income_gross_file_url === "" &&
            hh.income_gross_file_2_url === "" &&
            (hh.income_gross_no_file === "False" || hh.income_gross_no_file === false),
        },
        {
            prop: 'missing_incoming_docs',
            messageKey: 'MISSING_INCOME_DOCS',
            condition: hh =>["Unemployed", "Under 18"].find((el) => el === hh.employment_status) &&
            ((hh.income_gross_file === "" ||
            hh.income_gross_file_name === "") &&
            (hh.income_gross_no_file === "False" || hh.income_gross_no_file === false)),
        },
        {
            prop: 'ssn_enc',
            messageKey: 'NO_SSN/ITIN',
            condition: hh => (hh.date_of_birth &&
            moment().diff(hh.date_of_birth, "years") >= 18) &&
            !hh.ssn_enc,
        },
        // {
        //     prop: 'missing_income',
        //     messageKey: 'MISSING_OTHER_INCOME_DOCS',
        //     condition: hh => (+hh.income_in_lieu_of_earnings_amount &&
        //             !hh.income_in_lieu_of_earnings_file_url) ||
        //           (+hh.income_payments_annuities_amount &&
        //             !hh.income_payments_annuities_file_url) ||
        //           (+hh.income_government_amount && !hh.income_government_file_url) ||
        //           (+hh.income_alimony_amount && !hh.income_alimony_file_url) ||
        //           (+hh.income_other_amount && !hh.income_other_file_url)
        // },
    ]

}

