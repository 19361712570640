import { Component, OnInit } from '@angular/core';
import { IgnatiusService } from 'src/app/services/ignatius.service';
import { ProjectSpecificService } from 'src/app/services/project-specific.service';

@Component({
  selector: 'app-case-manager',
  templateUrl: './case-manager.component.html',
  styleUrls: ['./case-manager.component.css']
})
export class CaseManagerComponent implements OnInit {
  applicationData: Array<any> = [];
  projectSpecificData: any = this.projectSpecificService.getProjectSpecificData();

  constructor(
    private projectSpecificService: ProjectSpecificService,
    private ignatiusService: IgnatiusService
  ) {}

  ngOnInit() {
    this.projectSpecificData = this.projectSpecificService.getProjectSpecificData();
    this.getData();
  }

  getData = async () => {
    this.applicationData = await this.ignatiusService
      .getQueryReportObservable(this.projectSpecificData.appData, {
        ApplicationTableId: this.projectSpecificData.outboundMessagesData.TableId,
      })
      .toPromise();
    if (this.applicationData.length) this.applicationData.reverse();
  };
}
