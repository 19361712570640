<div class="row">
  <div class="col-md-12">
    <div class="card text-center">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div>
              <h3 class="card-title text-dfa text-center">
                {{ applicationData.business_name || "-" }}
              </h3>
            </div>
            <h5 class="card-title text-dfa text-center">
              {{ applicationData.physical_street }}
              {{ applicationData.physical_street_2 }}<br />{{
                applicationData.physical_city
              }}, {{ applicationData.physical_state }}
              {{ applicationData.physical_zip_code }}
            </h5>
            <h5 class="card-title text-dfa text-center">
              <!-- <span class="label label-success1"> {{applicationData.tin_type}}</span>
							&nbsp;
							<span *ngIf="applicationData.tin_type === 'EIN'">
								##-###{{applicationData.tin.toString().substring(5,9)}}
							</span>
							<span *ngIf="applicationData.tin_type === 'SSN'">
								###-##-{{applicationData.tin.toString().substring(5,9)}}
							</span> -->
              <span class="label label-success1">APP ID</span>
              {{ applicationData.id }}
            </h5>
            <button
              routerLink="/application/list"
              class="btn big-btn float-left background-third-whitetext"
            >
              <i class="fa fa-arrow-circle-left">
                {{ "applications.HOME" | translate }}</i
              >
            </button>
          </div>
          <div class="col-md-4">
            <h3 class="card-title text-dfa text-center">
              {{ "applications.APPLICATION_COMPLETION_PERCENTAGE" | translate }}
            </h3>
            <h6 class="card-subtitle text-center">
              {{
                "applications.COMPLETE_APPLICATION_BY_ADDING_DETAILS"
                  | translate
              }}
            </h6>
            <circle-progress
              [percent]="completionStatus.total"
              [radius]="40"
              [outerStrokeWidth]="10"
              [innerStrokeWidth]="5"
              [titleFontSize]="'15'"
              [unitsFontSize]="'15'"
              [showSubtitle]="false"
              [outerStrokeColor]="'#ffc832'"
              [innerStrokeColor]="'#00b5bf'"
              [animation]="true"
              [animationDuration]="300"
            >
            </circle-progress>
          </div>
          <div class="col-md-4 text-left help-section">
            <label
              ><b>{{ "applications.NEED_HELP" | translate }}</b></label
            >
            <p>{{ "applications.NEED_HELP_TEXT" | translate }}</p>
            <!-- <p>{{ "applications.NEED_HELP_LINE_1" | translate }}</p>
            <p>{{ "applications.NEED_HELP_LINE_2" | translate }}</p> -->
            <app-create-ticket
              [appId]="+recordId"
              [template]="addTicket"
            ></app-create-ticket>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.CONTACT_DETAILS" | translate }}
              <i
                *ngIf="!completionStatus.BusinessContactStatus"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="completionStatus.BusinessContactStatus"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="!completionStatus.BusinessContactStatus"
                [template]="contactDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle">
              {{
                "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA"
                  | translate
              }}
            </h6>
          </div>
          <div class="ml-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                (click)="openBusinessContacts(businessContacts)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-edit"> {{ "applications.EDIT" | translate }}</i>
              </button>
            </div>
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-5">
            <label>{{ "applications.CONTACT_NAME" | translate }} :</label>
          </div>
          <div class="col-md-7">
            {{
              applicationData.contact_first_name +
                " " +
                applicationData.contact_mi +
                " " +
                applicationData.contact_last_name || "-"
            }}
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-5">
            <label>{{ "applications.EMAIL" | translate }} :</label>
          </div>
          <div class="col-md-7">{{ applicationData.contact_email || "-" }}</div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-5">
            <label>{{ "applications.PHONE_NUMBER" | translate }} :</label>
          </div>
          <div class="col-md-7">
            {{
              applicationData.contact_phone
                ? (applicationData.contact_phone | mask: "(000) 000-0000")
                : "-"
            }}
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-5">
            <label>{{ "applications.DATE_OF_BIRTH" | translate }} :</label>
          </div>
          <div class="col-md-7">
            {{ applicationData.ll_date_of_birth || "-" }}
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-5">
            <label>{{ "applications.GENDER" | translate }} :</label>
          </div>
          <div class="col-md-7">
            {{ (applicationData.ll_gender | transform | translate) || "-" }}
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-5">
            <label>{{ "applications.RACE" | translate }} :</label>
          </div>
          <div class="col-md-7">
            {{ (applicationData.ll_race | transform | translate) || "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.BUSINESS_DETAILS" | translate }}
              <i
                *ngIf="!completionStatus.BusinessDetailsStatus"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="completionStatus.BusinessDetailsStatus"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="!completionStatus.BusinessDetailsStatus"
                [template]="businessDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle">
              {{
                "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA"
                  | translate
              }}
            </h6>
          </div>
          <div class="ml-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                (click)="openBusinessDetails(businessDetails)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-edit"> {{ "applications.EDIT" | translate }}</i>
              </button>
            </div>
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-6">
            <label>{{ "applications.PHYSICAL" | translate }} :</label>
          </div>
          <div class="col-md-6">
            {{ applicationData.physical_street }}
            {{ applicationData.physical_street_2 }}<br />{{
              applicationData.physical_city
            }}, {{ applicationData.physical_state }}
            {{ applicationData.physical_zip_code }}
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-6">
            <label>{{ "applications.MAILING" | translate }} :</label>
          </div>
          <div class="col-md-6">
            {{ applicationData.mailing_street }}
            {{ applicationData.mailing_street_2 }}<br />{{
              applicationData.mailing_city
            }}, {{ applicationData.mailing_state }}
            {{ applicationData.mailing_zip_code }}
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-6">
            <label>{{ "applications.W9_COMPLETED" | translate }} :</label>
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2': applicationData.file_w9,
                'badge-light-danger text-danger': !applicationData.file_w9
              }"
            >
              <strong>{{
                "applications." + (applicationData.file_w9 | bool | uppercase)
                  | translate
              }}</strong>
            </span>
            &nbsp;
            <a
              *ngIf="applicationData.file_w9_url"
              data-toggle="tooltip"
              title="{{ applicationData.file_w9 }}"
              href="{{ getFileRouteLink(applicationData.file_w9_url) }}"
              target="_blank"
              class="text-dfa"
            >
              {{ applicationData.file_w9 }}
            </a>
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-6">
            <label
              >{{
                "applications.MINORITY_OR_WOMEN_OWNED_BUSINESS" | translate
              }}
              :</label
            >
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2':
                  applicationData.minority_or_women_owned === 'True',
                'badge-light-danger text-danger':
                  applicationData.minority_or_women_owned === 'False'
              }"
            >
              <strong>
                {{
                  applicationData.minority_or_women_owned
                    ? ("applications." +
                        (applicationData.minority_or_women_owned
                          | bool
                          | uppercase) | translate)
                    : ("applications.NOT_ANSWERED" | translate)
                }}
              </strong>
            </span>
          </div>
        </div>
        <div
          *ngIf="applicationData.minority_or_women_owned === 'True'"
          class="row col-md-12"
        >
          <div class="col-md-6">
            <label>{{ "applications.REGISTERED" | translate }} :</label>
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2':
                  applicationData.minority_or_women_owned_registered === 'True',
                'badge-light-danger text-danger':
                  applicationData.minority_or_women_owned_registered === 'False'
              }"
            >
              <strong>
                {{
                  applicationData.minority_or_women_owned_registered
                    ? ("applications." +
                        (applicationData.minority_or_women_owned_registered
                          | bool
                          | uppercase) | translate)
                    : "-"
                }}
              </strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.PAYMENT_INFORMATION" | translate }}
              <i
                *ngIf="!completionStatus.BankInformationStatus"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="completionStatus.BankInformationStatus"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="!completionStatus.BankInformationStatus"
                [template]="paymentDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle">
              {{
                "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA"
                  | translate
              }}
            </h6>
          </div>
          <div class="ml-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                (click)="openBankDetails(bank)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-edit"> {{ "applications.EDIT" | translate }}</i>
              </button>
            </div>
          </div>
        </div>

        <div class="row col-md-12">
          <div class="col-md-6">
            <label>{{ "applications.ACH_OR_CHECK" | translate }} :</label>
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2':
                  applicationData.ach_or_check === 'ACH' ||
                  applicationData.ach_or_check === 'Check'
              }"
            >
              <strong *ngIf="applicationData.ach_or_check === 'ACH'">{{
                "applications.ACH" | translate
              }}</strong>
              <strong *ngIf="applicationData.ach_or_check === 'Check'">{{
                "applications.CHECK" | translate
              }}</strong>
              <strong *ngIf="!applicationData.ach_or_check">{{
                "applications.NOT_ANSWERED" | translate
              }}</strong>
            </span>
          </div>
        </div>
        <div *ngIf="applicationData.ach_or_check" class="row col-md-12">
          <div class="col-md-6">
            <label>{{ "applications.CHECK_PAYEE_NAME1" | translate }} :</label>
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2':
                  applicationData.ach_or_check === 'ACH' ||
                  applicationData.ach_or_check === 'Check'
              }"
            >
              <strong>
                {{ applicationData.check_payee_name || "-" }}
              </strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.PROPERTIES" | translate }}
              <i
                *ngIf="!completionStatus.PropertiesStatus"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="completionStatus.PropertiesStatus"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="!completionStatus.PropertiesStatus"
                [template]="propertiesDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle">
              {{
                "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA"
                  | translate
              }}
            </h6>
          </div>
          <div class="ml-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                (click)="openProperty(property)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-plus"> {{ "applications.ADD" | translate }}</i>
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <label>{{ "applications.PROPERTY_COUNT" | translate }} :</label>
          </div>
          <div class="col-md-2">
            <span>{{ propertiesData.length }}</span>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-12">
            <hr class="hr_separator" />
          </div>
        </div>
        <h6 class="card-subtitle">
          {{
            "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA" | translate
          }}
        </h6>
        <ng-container *ngFor="let prop of propertiesData; let i = index">
          <div class="row mt-3">
            <div class="col-md-7">
              {{ i + 1 }}. {{ prop.property_name }} -
              {{ prop.property_type | transform | translate }}
            </div>
            <div class="col-md-2">
              <div class="ml-auto">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    (click)="openProperty(property, prop)"
                    type="button"
                    class="btn background-third-whitetext btn-sm"
                  >
                    <i class="fa fa-edit">
                      {{ "applications.EDIT" | translate }}</i
                    >
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ml-auto">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    (click)="openProperty(deleteprop, prop)"
                    type="button"
                    class="btn background-danger-third-whitetext btn-sm"
                  >
                    <!-- class="fa fa-exclamation-circle " -->
                    <i class="fa fa-trash">
                      {{ "applications.DELETE" | translate }}</i
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.ACKNOWLEDGEMENTS" | translate }}
              <i
                *ngIf="!completionStatus.AcknowledgementsStatus"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="completionStatus.AcknowledgementsStatus"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="!completionStatus.AcknowledgementsStatus"
                [template]="ackDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle">
              {{
                "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA"
                  | translate
              }}
            </h6>
          </div>
          <div class="ml-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                *ngIf="
                  completionStatus.total == 80 || completionStatus.total === 100
                "
                (click)="openAcknowledgements(ackw)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-edit">
                  {{
                    (viewMode ? "applications.VIEW" : "applications.EDIT")
                      | translate
                  }}</i
                >
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label>{{ "applications.CERTIFIED" | translate }} :</label>
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2': isCertified(),
                'badge-light-danger text-danger': !isCertified()
              }"
            >
              <strong>{{
                "applications." + (isCertified() | bool | uppercase) | translate
              }}</strong>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>{{ "applications.CERTIFIER_NAME" | translate }} :</label>
          </div>
          <div class="col-md-6">
            {{ applicationData.certifier_name || "-" }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>{{ "applications.CERTIFIER_TITLE" | translate }} :</label>
          </div>
          <div class="col-md-6">
            {{ applicationData.certifier_title || "-" }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label
              >{{ "applications.DRIVERS_LICENSE_IMAGE" | translate }} :</label
            >
          </div>
          <div class="col-md-6">
            <a
              *ngIf="applicationData.file_drivers_license_url"
              href="{{
                getFileRouteLink(applicationData.file_drivers_license_url)
              }}"
              target="_blank"
              class="fa fa-id-card text-info"
            >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ================================================================================================ -->
<!-------------------------------------------- Popups Start here---------------------------------------->
<!-- ================================================================================================ -->

<!------------------------ Business Contacts start ------------------------------------>

<ng-template #businessContacts let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.UPDATE_CONTACT_DETAILS" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="businessContactsFormGroup"
      (ngSubmit)="onBusinessContactsSubmit()"
    >
      <!-- CONTACT -->
      <!-- <div class="row col-md-12">
				<div class="col-md-1"></div>
				<div class="col-md-11"><h5>Application Contact Info</h5></div>
			</div> -->
      <div class="row col-md-12">
        <!-- <div class="col-md-2"></div> -->
        <div class="col-md-6">
          <label class="control-label"
            >{{ "applications.USER_FIRST_NAME" | translate
            }}<app-required
              [control]="
                getControl(businessContactsFormGroup, 'contact_first_name')
              "
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="contact_first_name"
            noSpecialChars
          />
          <small
            *ngIf="
              businessContactsFormControl.contact_first_name.touched &&
              businessContactsFormControl.contact_first_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
        <div class="col-md-2">
          <label class="control-label"
            >{{ "applications.USER_MI" | translate
            }}<app-required
              [control]="getControl(businessContactsFormGroup, 'contact_mi')"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="contact_mi"
            noSpecialChars
          />
          <small
            *ngIf="
              businessContactsFormControl.contact_mi.touched &&
              businessContactsFormControl.contact_mi.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
        <div class="col-md-4">
          <label class="control-label"
            >{{ "applications.USER_LAST_NAME" | translate
            }}<app-required
              [control]="
                getControl(businessContactsFormGroup, 'contact_last_name')
              "
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="contact_last_name"
            noSpecialChars
          />
          <small
            *ngIf="
              businessContactsFormControl.contact_last_name.touched &&
              businessContactsFormControl.contact_last_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>

      <div class="row col-md-12"><hr /></div>

      <div class="row col-md-12">
        <!-- <div class="col-md-2"></div> -->
        <div class="col-md-6">
          <label class="control-label"
            >{{ "applications.CONTACT_PHONE" | translate
            }}<app-required
              [control]="getControl(businessContactsFormGroup, 'contact_phone')"
            ></app-required
          ></label>
          <input
            mask="(000) 000-0000"
            type="text"
            class="form-control"
            formControlName="contact_phone"
          />
          <small
            *ngIf="
              businessContactsFormControl.contact_phone.touched &&
              businessContactsFormControl.contact_phone.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="
              businessContactsFormControl.contact_phone.touched &&
              businessContactsFormControl.contact_phone.errors?.pattern
            "
            class="text-danger"
          >
            {{ "errors.INVALID_PHONE" | translate }}
          </small>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4">
          <label class="control-label"
            >{{ "applications.CONTACT_EMAIL" | translate
            }}<app-required
              [control]="getControl(businessContactsFormGroup, 'contact_email')"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="contact_email"
          />
          <small
            *ngIf="
              businessContactsFormControl.contact_email.touched &&
              businessContactsFormControl.contact_email.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="
              businessContactsFormControl.contact_email.touched &&
              businessContactsFormControl.contact_email.errors?.pattern
            "
            class="text-danger"
          >
            {{ "errors.INVALID_EMAIL" | translate }}
          </small>
        </div>
      </div>

      <div class="row col-md-12"><hr /></div>

      <div class="input-group input-group-sm">
        <div class="row col-md-12">
          <!-- <div class="col-md-2"></div> -->
          <div class="col-md-3">
            <label class="control-label"
              >{{ "applications.DATE_OF_BIRTH" | translate }}
              <app-required
                [control]="
                  getControl(businessContactsFormGroup, 'll_date_of_birth')
                "
              ></app-required
            ></label>
            <div class="input-group input-group-sm">
              <input
                type="date"
                formControlName="ll_date_of_birth"
                class="form-control form-control-sm"
              />
            </div>
          </div>
          <div class="col-md-4">
            <label class="control-label"
              >{{ "applications.GENDER" | translate }}
              <app-required
                [control]="getControl(businessContactsFormGroup, 'll_gender')"
              ></app-required
            ></label>
            <div class="input-group input-group-sm">
              <select class="form-control" formControlName="ll_gender">
                <option value="" disabled>
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option *ngFor="let g of genders" value="{{ g.Value }}">
                  {{ g.Name | transform | translate }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.RACE" | translate }}
              <app-required
                [control]="getControl(businessContactsFormGroup, 'll_race')"
              ></app-required
            ></label>
            <div class="input-group input-group-sm">
              <select class="form-control" formControlName="ll_race">
                <option value="" disabled>
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option *ngFor="let r of races" value="{{ r.Value }}">
                  {{ r.Name | transform | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            [disabled]="businessContactsFormGroup.invalid"
            *ngIf="!modelConfig.busy"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!------------------------ Business Contacts end -------------------------------------->

<!------------------------ Business Details start ------------------------------------>

<ng-template #businessDetails let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.UPDATE_BUSINESS_DETAILS" | translate }}
    </h4>
    {{ "applications.FILE_LIMIT_SIZE" | translate }}
  </div>
  <div class="modal-body">
    <form
      [formGroup]="businessDetailsFormGroup"
      (ngSubmit)="onBusinessDetailsSubmit()"
    >
      <fieldset>
        <div class="row col-md-12">
          <div class="col-md-12">
            <h4>{{ "applications.PHYSICAL" | translate }}</h4>
            <hr />
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.PHYSICAL_STREET" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <input
              type="text"
              class="form-control"
              formControlName="physical_street"
              ngx-google-places-autocomplete
              [options]="options"
              (onAddressChange)="
                addressChangePhysical($event, 'physical_street')
              "
              placeholder="{{ 'applications.PLACEHOLDER_ADDRESS' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.physical_street.touched &&
                applicationData.physical_street.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
          <div class="col-md-5">
            <label class="control-label">{{
              "applications.PHYSICAL_STREET_2" | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              formControlName="physical_street_2"
              placeholder="{{ 'applications.PLACEHOLDER_UNIT' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.physical_street_2.touched &&
                applicationData.physical_street_2.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.PHYSICAL_CITY" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <input
              type="text"
              class="form-control"
              formControlName="physical_city"
              placeholder="{{ 'applications.PLACEHOLDER_CITY' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.physical_city.touched &&
                applicationData.physical_city.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.PHYSICAL_STATE" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <input
              type="text"
              class="form-control"
              formControlName="physical_state"
              placeholder="{{ 'applications.PLACEHOLDER_STATE' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.physical_state.touched &&
                applicationData.physical_state.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.PHYSICAL_ZIP_CODE" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <input
              type="text"
              class="form-control"
              formControlName="physical_zip_code"
              placeholder="{{ 'applications.PLACEHOLDER_ZIP' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.physical_zip_code.touched &&
                applicationData.physical_zip_code.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-12">
            <h4>
              <br />{{ "applications.MAILING" | translate }} (<input
                (change)="mailingSameAsPhysical($event.target.checked)"
                type="checkbox"
                class="mx-2"
              />
              <label>{{
                "applications.SAME_AS_PHYSICAL_ADDRESS" | translate
              }}</label>
              )
            </h4>
            <hr />
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.MAILING_STREET" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <input
              type="text"
              class="form-control"
              formControlName="mailing_street"
              ngx-google-places-autocomplete
              [options]="options"
              (onAddressChange)="addressChangeMailing($event, 'mailing_street')"
              placeholder="{{ 'applications.PLACEHOLDER_ADDRESS' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.mailing_street.touched &&
                applicationData.mailing_street.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
          <div class="col-md-5">
            <label class="control-label">{{
              "applications.MAILING_STREET_2" | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              formControlName="mailing_street_2"
              placeholder="{{ 'applications.PLACEHOLDER_UNIT' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.mailing_street_2.touched &&
                applicationData.mailing_street_2.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.MAILING_CITY" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <input
              type="text"
              class="form-control"
              formControlName="mailing_city"
              placeholder="{{ 'applications.PLACEHOLDER_CITY' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.mailing_city.touched &&
                applicationData.mailing_city.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.MAILING_STATE" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <input
              type="text"
              class="form-control"
              formControlName="mailing_state"
              placeholder="{{ 'applications.PLACEHOLDER_STATE' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.mailing_state.touched &&
                applicationData.mailing_state.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-2"></div>
          <div class="col-md-5">
            <label class="control-label"
              >{{ "applications.MAILING_ZIP_CODE" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <input
              type="text"
              class="form-control"
              formControlName="mailing_zip_code"
              placeholder="{{ 'applications.PLACEHOLDER_ZIP' | translate }}"
              noSpecialChars
            />
            <small
              *ngIf="
                applicationData.mailing_zip_code.touched &&
                applicationData.mailing_zip_code.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
        </div>
        <div class="row">
          <hr />
        </div>
        <div class="row">
          <div class="col-md-4">
            <label
              >{{ "applications.W9_COMPLETED" | translate }}
              <app-required [mark]="true"></app-required
            ></label>
          </div>
          <div class="col-md-4">
            <a
              style="text-decoration: underline"
              target="_blank"
              href="https://d3dr6irtq668i9.cloudfront.net/templates/W-9_Blank.pdf"
              class="text-dfa"
            >
              {{ "applications.DOWNLOAD_BLANK_W9" | translate }}
            </a>
          </div>
          <div class="col-md-4">
            <input
              class="form-control-file form-control-sm"
              type="file"
              (change)="onSelectFileUpload($event, 'file_name_w9', 'file_w9')"
            />
            <small *ngIf="applicationData.file_w9_url" class="ml-2">
              <a
                href="{{ getFileRouteLink(applicationData.file_w9_url) }}"
                target="_blank"
                class="text-dfa"
              >
                {{ applicationData.file_w9 }}
              </a>
              <app-file-delete
                [recordId]="recordId"
                [tableId]="projectSpecificData.applicationsData.TableId"
                [modalConfig]="modelConfig"
                [fields]="['file_w9', 'file_w9_url']"
                [tryFn]="tryW9"
              ></app-file-delete>
            </small>
            <small
              *ngIf="!applicationData.file_w9_url"
              class="text-warning ml-2"
            >
              {{ "applications.NOT_UPLOADED_YET" | translate }}
            </small>
          </div>
        </div>

        <div class="row col-md-12"><hr /></div>

        <div class="row">
          <div class="col-md-9">
            <label>
              <i
                [ngClass]="{
                  'fa fa-circle': true,
                  'text-success2':
                    businessDetailsFormGroup.value['minority_or_women_owned'],
                  'text-danger':
                    !businessDetailsFormGroup.value['minority_or_women_owned']
                }"
              >
              </i>
              {{ "applications.IS_MINORITY_OR_WOMEN_OWNED" | translate }}
              <app-required [mark]="true"></app-required>
            </label>
          </div>
          <div class="col-md-3">
            <mat-radio-group formControlName="minority_or_women_owned">
              <mat-radio-button value="True">
                <strong>{{ "applications.YES" | translate }}</strong>
              </mat-radio-button>
              <mat-radio-button value="False" class="ml-2">
                <strong>{{ "applications.NO" | translate }}</strong>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div
          *ngIf="
            businessDetailsFormGroup.value['minority_or_women_owned'] === 'True'
          "
          class="row"
        >
          <div class="col-md-9">
            <label>
              <i
                [ngClass]="{
                  'fa fa-circle': true,
                  'text-success2':
                    businessDetailsFormGroup.value[
                      'minority_or_women_owned_registered'
                    ],
                  'text-danger':
                    !businessDetailsFormGroup.value[
                      'minority_or_women_owned_registered'
                    ]
                }"
              >
              </i>
              {{
                "applications.IS_MINORITY_OR_WOMEN_OWNED_REGISTERED" | translate
              }}
              <app-required [mark]="true"></app-required>
            </label>
          </div>
          <div class="col-md-3">
            <mat-radio-group
              formControlName="minority_or_women_owned_registered"
            >
              <mat-radio-button value="True">
                <strong>{{ "applications.YES" | translate }}</strong>
              </mat-radio-button>
              <mat-radio-button value="False" class="ml-2">
                <strong>{{ "applications.NO" | translate }}</strong>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </fieldset>

      <hr />
      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            *ngIf="!modelConfig.busy"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!------------------------ Business Details end -------------------------------------->

<!------------------------ Acknowledgements start ------------------------------------>

<ng-template #ackw let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.UPDATE_ACKNOWLEDGEMENTS" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }}
    </span>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="acknowledgementsFormGroup"
      (ngSubmit)="onAcknowledgementsSubmit()"
    >
      <fieldset [disabled]="viewMode">
        <table class="table table-sm table-bordered">
          <tr>
            <td colspan="3">
              {{ "LL_CERT_ACK_INTRO"  | translate }}
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <label>
                <h4>{{ "applications.CERTIFICATIONS" | translate }}:</h4>
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <mat-checkbox formControlName="certify"></mat-checkbox>
            </td>
            <td>
              <label>
                {{ "CERTLANDLORD"  + ".CERT1" | translate }} <br />
              </label>
            </td>
            <td>
              <i
                [ngClass]="{
                  'fa fa-circle': true,
                  'text-success2': acknowledgementsFormGroup.value['certify'],
                  'text-danger': !acknowledgementsFormGroup.value['certify']
                }"
              >
              </i>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <label>
                <h4>{{ "applications.ACKNOWLEDGEMENTS" | translate }}:</h4>
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <mat-checkbox formControlName="ack"></mat-checkbox>
            </td>
            <td>
              <label>
                {{ "ACKLANDLORD"  + ".ACK1" | translate }}
              </label>
            </td>
            <td>
              <i
                [ngClass]="{
                  'fa fa-circle': true,
                  'text-success2': acknowledgementsFormGroup.value['ack'],
                  'text-danger': !acknowledgementsFormGroup.value['ack']
                }"
              >
              </i>
            </td>
          </tr>
          <tr>
            <td>
              <mat-checkbox formControlName="ack_2"></mat-checkbox>
            </td>
            <td>
              <label>
                {{ "ACKLANDLORD"  + ".ACK2" | translate }}
              </label>
            </td>
            <td>
              <i
                [ngClass]="{
                  'fa fa-circle': true,
                  'text-success2': acknowledgementsFormGroup.value['ack_2'],
                  'text-danger': !acknowledgementsFormGroup.value['ack_2']
                }"
              >
              </i>
            </td>
          </tr>
          <tr>
            <td>
              <mat-checkbox formControlName="ack_3"></mat-checkbox>
            </td>
            <td>
              <label>
                {{ "ACKLANDLORD"  + ".ACK3" | translate }}
              </label>
            </td>
            <td>
              <i
                [ngClass]="{
                  'fa fa-circle': true,
                  'text-success2': acknowledgementsFormGroup.value['ack_3'],
                  'text-danger': !acknowledgementsFormGroup.value['ack_3']
                }"
              >
              </i>
            </td>
          </tr>
          <tr>
            <td>
              <mat-checkbox formControlName="ack_4"></mat-checkbox>
            </td>
            <td>
              <label>
                {{ "ACKLANDLORD"  + ".ACK4" | translate }}
              </label>
            </td>
            <td>
              <i
                [ngClass]="{
                  'fa fa-circle': true,
                  'text-success2': acknowledgementsFormGroup.value['ack_4'],
                  'text-danger': !acknowledgementsFormGroup.value['ack_4']
                }"
              >
              </i>
            </td>
          </tr>
          <tr>
            <td>
              <mat-checkbox formControlName="ack_5"></mat-checkbox>
            </td>
            <td>
              <label>
                {{ "ACKLANDLORD"  + ".ACK5" | translate }}
              </label>
            </td>
            <td>
              <i
                [ngClass]="{
                  'fa fa-circle': true,
                  'text-success2': acknowledgementsFormGroup.value['ack_5'],
                  'text-danger': !acknowledgementsFormGroup.value['ack_5']
                }"
              >
              </i>
            </td>
          </tr>
        </table>

        <div class="row">
          <div class="col">
            {{ "LL_CERT_ACK_CONFIRM" | translate }}
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <label
              >{{ "applications.CERTIFIER_NAME" | translate
              }}<app-required
                [control]="acknowledgementsFormGroup.controls['certifier_name']"
              ></app-required
            ></label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="certifier_name"
            />
            <small
              *ngIf="
                acknowledgementsFormControl.certifier_name.touched &&
                acknowledgementsFormControl.certifier_name.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
          <div class="col-md-6">
            <label
              >{{ "applications.CERTIFIER_TITLE" | translate
              }}<app-required
                [control]="
                  acknowledgementsFormGroup.controls['certifier_title']
                "
              ></app-required
            ></label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="certifier_title"
            />
            <small
              *ngIf="
                acknowledgementsFormControl.certifier_title.touched &&
                acknowledgementsFormControl.certifier_title.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-3">
            <label
              >{{ "applications.DRIVERS_LICENSE_IMAGE" | translate }}
              <app-required [mark]="true"></app-required
            ></label>
          </div>
          <div class="col-md">
            <input type="file" (change)="handleDLUpoad($event)" />
            <br />
            <a
              *ngIf="applicationData.file_drivers_license_url"
              href="{{
                getFileRouteLink(applicationData.file_drivers_license_url)
              }}"
              target="_blank"
              class="text-success2"
            >
              {{ applicationData.file_drivers_license }}
            </a>
            <app-file-delete
              [recordId]="recordId"
              [tableId]="projectSpecificData.applicationsData.TableId"
              [modalConfig]="modelConfig"
              [fields]="['file_drivers_license_url', 'file_drivers_license']"
              [tryFn]="tryW9"
              [disabled]="viewMode"
            ></app-file-delete>
            <small
              *ngIf="!applicationData.file_drivers_license_url"
              class="text-danger"
            >
              {{ "errors.FILE_REQUIRED" | translate }}
            </small>
          </div>
        </div>
      </fieldset>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button
            type="button"
            (click)="closeModel(false)"
            class="btn btn-dark"
          >
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!------------------------ Acknowledgements end -------------------------------------->

<!------------------------ Bank Details start ------------------------------------>

<ng-template #bank let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.UPDATE_PAYMENT_INFORMATION" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="bankDetailsFormGroup" (ngSubmit)="onBankDetailsSubmit()">
      <div class="row">
        <div class="col-md-9">
          <label>
            <i
              [ngClass]="{
                'fa fa-circle': true,
                'text-success2': bankDetailsFormGroup.value['ach_or_check'],
                'text-danger': !bankDetailsFormGroup.value['ach_or_check']
              }"
            >
            </i>
            {{ "applications.IS_ACH_OR_CHECK" | translate }}
            <app-required [mark]="true"></app-required>
          </label>
        </div>
        <div class="col-md-3">
          <mat-radio-group formControlName="ach_or_check">
            <mat-radio-button value="ACH" disabled>
              <strong>{{ "applications.ACH" | translate }}</strong>
            </mat-radio-button>
            <mat-radio-button value="Check" class="ml-2">
              <strong>{{ "applications.CHECK" | translate }}</strong>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- <div
				*ngIf="bankDetailsFormGroup.value['ach_or_check'] === 'ACH'"
				class="row">
				<div class="col-md-9">
					<label>
						** {{ 'applications.ACH_INFO' | translate }} ** <app-required [mark]="true"></app-required>
					</label>
				</div>
			</div> -->
      <div *ngIf="bankDetailsFormGroup.value['ach_or_check']" class="row">
        <div class="col-md">
          <label>
            {{ "applications.CHECK_PAYEE_NAME" | translate }}
            <app-required [mark]="true"></app-required>
          </label>
        </div>
        <div class="col-md-6">
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="check_payee_name"
          />
          <small
            *ngIf="
              bankDetailsFormControl.check_payee_name.touched &&
              bankDetailsFormControl.check_payee_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>
      <!-- <div class="row">
				<div class="col-md-6">
					<label>{{ 'applications.BANK_NAME' | translate }}</label>
					<input type="text" class="form-control form-control-sm" formControlName="bank_name">
					<small
						*ngIf="bankDetailsFormControl.bank_name.touched &&
						bankDetailsFormControl.bank_name.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
				<div class="col-md-6">
					<label>
						{{ 'applications.BANK_BRANCH' | translate }}
						<i
							class="fa fa-question-circle"
							data-toggle="tooltip"
							title="Please provide the Bank Branch name and/or address information">
						</i>
					</label>
					<input type="text" class="form-control form-control-sm" formControlName="bank_location">
					<small
						*ngIf="bankDetailsFormControl.bank_location.touched &&
						bankDetailsFormControl.bank_location.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
			</div> -->

      <!-- <div class="row mt-2">
				<div class="col-md-6">
					<label>
						{{ 'applications.NAME_ON_ACCOUNT' | translate }}
						<i
							class="fa fa-question-circle"
							data-toggle="tooltip"
							title="Please provide the name on the bank account into which grant funds will be deposited ">
						</i>
					</label>
					<input type="text" class="form-control form-control-sm" formControlName="bank_account_name">
					<small
						*ngIf="bankDetailsFormControl.bank_account_name.touched &&
						bankDetailsFormControl.bank_account_name.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
				<div class="col-md-6">
					<label>{{ 'applications.ACCOUNT_TYPE' | translate }}</label>
					<select class="form-control form-control-sm" formControlName="bank_account_type">
						<option value="" hidden>- Select -</option>
						<option *ngFor="let optn of bankAccountTypes" value="{{optn.value}}">{{optn.name}}</option>
					</select>
					<small
						*ngIf="bankDetailsFormControl.bank_account_type.touched &&
						bankDetailsFormControl.bank_account_type.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
			</div> -->

      <!-- <div class="row mt-2">
				<div class="col-md-6">
					<label>{{ 'applications.ROUTING_NUMBER' | translate }}</label>
					<input
						type="text"
						mask="000000000"
						(change)="OnChangeRoutingNumber($event.target.value)"
						class="form-control form-control-sm"
						formControlName="bank_routing_number">
					<small
						*ngIf="bankDetailsFormControl.bank_routing_number.touched &&
						bankDetailsFormControl.bank_routing_number.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
					<small
						class="text-success2"
						*ngIf="bankName && !bankDetailsFormControl.bank_routing_number.errors?.required">
						{{ 'applications.BANK_NAME' | translate }} : {{bankName}}
					</small>
				</div>
				<div class="col-md-6">
					<label>{{ 'applications.CONFIRM_ROUTING_NUMBER' | translate }}</label>
					<input
						type="text"
						mask="000000000"
						class="form-control form-control-sm"
						formControlName="confirm_bank_routing_number">
					<small
						*ngIf="bankDetailsFormControl.confirm_bank_routing_number.touched &&
						bankDetailsFormControl.confirm_bank_routing_number.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
					<small
						*ngIf="bankDetailsFormControl.confirm_bank_routing_number.touched &&
						bankDetailsFormControl.confirm_bank_routing_number.errors?.mustMatch"
						class="text-danger">
						{{ 'errors.ROUTING_NUMBERS_DO_NOT_MATCH' | translate }}
					</small>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-md-6">
					<label>{{ 'applications.ACCOUNT_NUMBER' | translate }}</label>
					<input
						type="text"

						class="form-control form-control-sm"
						formControlName="bank_account_number">
					<small
						*ngIf="bankDetailsFormControl.bank_account_number.touched &&
						bankDetailsFormControl.bank_account_number.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
				<div class="col-md-6">
					<label>{{ 'applications.CONFIRM_ACCOUNT_NUMBER' | translate }}</label>
					<input
						type="text"
						class="form-control form-control-sm"
						formControlName="confirm_bank_account_number">
					<small
						*ngIf="bankDetailsFormControl.confirm_bank_account_number.touched &&
						bankDetailsFormControl.confirm_bank_account_number.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
					<small
						*ngIf="bankDetailsFormControl.confirm_bank_account_number.touched &&
						bankDetailsFormControl.confirm_bank_account_number.errors?.mustMatch"
						class="text-danger">
						{{ 'errors.ACCOUNT_NUMBERS_DO_NOT_MATCH' | translate }}
					</small>
				</div>
			</div> -->

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            [disabled]="bankDetailsFormGroup.invalid"
            *ngIf="!modelConfig.busy"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong>
              <i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}
            </strong>
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!------------------------ Bank Details end -------------------------------------->

<!------------------------ Property start -------------------------------------->

<ng-template #property let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4 class="modal-title" id="modal-basic-title">
        {{
          (MODE === "ADD"
            ? "applications.ADD_PROPERTY"
            : "applications.UPDATE_PROPERTY"
          ) | translate
        }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }}
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="propertyFormGroup" (ngSubmit)="propertySubmit()">
      <!-- CONTACT -->
      <!-- <div class="row col-md-12">
				<div class="col-md-1"></div>
				<div class="col-md-11"><h5>Application Contact Info</h5></div>
			</div> -->
      <div class="row col-md-12">
        <!-- <div class="col-md-2"></div> -->
        <div class="col-md-4">
          <label class="control-label"
            >{{ "applications.PROPERTY_NAME" | translate
            }}<app-required
              [control]="propertyFormGroup.controls['property_name']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="property_name"
          />
          <small
            *ngIf="
              propertyFormGroup.value.property_name.touched &&
              propertyFormGroup.value.property_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
        <div class="col-md-4">
          <label class="control-label"
            >{{ "applications.PROPERTY_TYPE" | translate
            }}<app-required
              [control]="propertyFormGroup.controls['property_type']"
            ></app-required
          ></label>
          <select class="form-control" formControlName="property_type">
            <option value="" disabled>
              - {{ "applications.SELECT" | translate }} -
            </option>
            <option *ngFor="let type of propertyTypes" [value]="type.Value">
              {{ type.Name | transform | translate }}
            </option>
          </select>
          <small
            *ngIf="
              propertyFormGroup.value.property_type.touched &&
              propertyFormGroup.value.property_type.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
        <div class="col-md-4">
          <label class="control-label"
            >{{ "applications.PROPERTY_DESCRIPTION" | translate
            }}<app-required
              [control]="propertyFormGroup.controls['property_description']"
            ></app-required
          ></label>
          <textarea
            class="form-control"
            formControlName="property_description"
          ></textarea>
          <small
            *ngIf="
              propertyFormGroup.value.property_type.touched &&
              propertyFormGroup.value.property_type.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div>
            <label class="control-label"
              >{{ "applications.PROOF_OWNERSHIP" | translate }}
            </label>
          </div>
          <div>
            <small>{{ "applications.PROOF_OWNERSHIP_INFO" | translate }}</small>
          </div>
          <div>
            <small>{{
              "applications.PROOF_OWNERSHIP_INFO_ADDITIONAL" | translate
            }}</small>
          </div>
          <input class="mt-2" type="file" (change)="selectFile($event)" />
          <span
            *ngIf="propertyFormGroup.value.file_deed_or_taxes_url"
            class="text-success2"
          >
            {{ "applications.UPLOADED" | translate }}
            <a
              href="{{
                getFileRouteLink(propertyFormGroup.value.file_deed_or_taxes_url)
              }}"
              target="_blank"
              >({{ propertyFormGroup.value.file_deed_or_taxes }})</a
            >
            <app-file-delete
              [recordId]="propertyFormGroup.value.rid"
              [tableId]="projectSpecificData.propertiesData.TableId"
              [modalConfig]="modelConfig"
              [fields]="['file_deed_or_taxes_url', 'file_deed_or_taxes']"
              [tryFn]="tryProp"
              [tryArgs]="[propertyFormGroup.value.rid]"
            ></app-file-delete>
          </span>
          <br />
          <span
            *ngIf="!propertyFormGroup.value.file_deed_or_taxes_url"
            class="text-warning"
            >{{ "applications.NOT_UPLOADED_YET" | translate }}</span
          >
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            [disabled]="propertyFormGroup.invalid"
            *ngIf="!modelConfig.busy"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{
              (MODE === "ADD" ? "applications.SAVE" : "applications.UPDATE")
                | translate
            }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            *ngIf="MODE === 'ADD'"
            [innerHTML]="'applications.SAVE_BTN_INFO_TEXT' | translate"
          ></small>
          <small
            *ngIf="MODE !== 'ADD'"
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!------------------------ Property end -------------------------------------->

<ng-template #deleteprop let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.DELETE_PROPERTY" | translate }}
      </h4>
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="propertyFormGroup" (ngSubmit)="deleteProperty()">
      <div>
        <div class="row col-md-12">
          <div class="col-md-12">
            {{ "applications.DELETE_PROPERTY_CONFIRMATION" | translate }}
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-3"></div>
          <div class="col-md-3">
            {{ "applications.PROPERTY_NAME" | translate }} :
          </div>
          <div class="col-md-6">
            {{ propertyFormGroup.value.property_name }}
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-3"></div>
          <div class="col-md-3">
            {{ "applications.PROPERTY_TYPE" | translate }} :
          </div>
          <div class="col-md-6">
            {{ propertyFormGroup.value.property_type }}
          </div>
        </div>
        <!-- <div class="row col-md-12">
					<div class="col-md-3"></div>
					<div class="col-md-3">{{ 'applications.BILL_TO_PROVIDER_NAME' | translate }} : </div>
					<div class="col-md-6">{{ exp.get('provider').value }}</div>
				</div> -->
      </div>

      <div class="row mt-3">
        <div class="col-md-8 btn-responsive">
          <button
            *ngIf="!modelConfig.busy"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.DELETE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--------------------------------- BUBBLES START --------------------------------->

<ng-template #contactDetailsInfo>
  <h3>{{ "applications.CONTACT_DETAILS" | translate }}</h3>
  <p>{{ "BUBBLE.CONTACT_DETAILS" | translate }}</p>
</ng-template>

<ng-template #businessDetailsInfo>
  <h3>{{ "applications.BUSINESS_DETAILS" | translate }}</h3>
  <p>{{ "BUBBLE.BUSINESS_DETAILS" | translate }}</p>
</ng-template>

<ng-template #paymentDetailsInfo>
  <h3>{{ "applications.PAYMENT_INFORMATION" | translate }}</h3>
  <p>{{ "BUBBLE.PAYMENT_INFORMATION" | translate }}</p>
</ng-template>

<ng-template #propertiesDetailsInfo>
  <h3>{{ "applications.PROPERTIES" | translate }}</h3>
  <p>{{ "BUBBLE.PROPERTIES" | translate }}</p>
</ng-template>

<ng-template #ackDetailsInfo>
  <h3>{{ "applications.ACKNOWLEDGEMENTS" | translate }}</h3>
  <p>{{ "BUBBLE.ACKNOWLEDGEMENTS" | translate }}</p>
</ng-template>

<ng-template #addTicket>
  <button class="float2-btn" style="top: 200px; z-index: 999">
    <i class="fa fa-envelope"></i>
  </button>
</ng-template>

<!--------------------------------- BUBBLES END --------------------------------->

<!-- ================================================================================================ -->
<!--------------------------------------------- Popups Ends here---------------------------------------->
<!-- ================================================================================================ -->
