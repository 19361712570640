<div class="main">
  <div><br /></div>
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitCriteria()">
    <div class="card">
      <div class="card-header background-secondary">
        <div *ngIf="applicantType === 'Landlord'"><h4 class="mb-0 ">{{ 'applications.DETERMINE_ELIGIBILITY' | translate }} - Landlord - {{ app.business_name }}</h4></div>
        <div *ngIf="applicantType === 'Applicant1'"><h4 class="mb-0 ">{{ 'applications.DETERMINE_ELIGIBILITY' | translate }} - Applicant - {{ app.contact_first_name }} {{ app.contact_last_name }}</h4></div>
        <div *ngIf="applicantType === 'Applicant'"><h4 class="mb-0 ">{{ 'applications.DETERMINE_ELIGIBILITY' | translate }} - Applicant</h4></div>
      </div>
      <div class="card-body p-0 pt-3">
        <ng-container *ngFor="let criteria of questions">
          <div class="row pb-3 pl-5 pr-5 m-0" [style.background]="green" *ngIf="showCriteria(criteria)">
            <div class="col-md-2 text-md-right float-center pr-4">
              <mat-radio-group [formControlName]="criteria.field">
                <mat-radio-button value="True">
                  <label class="mr-3">
                    <strong style="line-height: 19px; vertical-align: bottom;">{{ 'applications.YES' | translate }}</strong>
                  </label>
                </mat-radio-button>
                <mat-radio-button value="False">
                  <label>
                    <strong style="line-height: 19px; vertical-align: bottom;">{{ 'applications.NO' | translate }}
                    </strong>
                  </label>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-10" style="white-space: pre-wrap;">{{ criteria.question }}</div>
          </div>          
          <!-- <div class="row pb-3 pl-5 pr-5 m-0" [style.background]="green" *ngIf="showCriteria(criteria)">
            <div class="col-md-2 text-right pr-4"></div>
            <div class="col-10" style="white-space: pre-wrap;">
              {{ 'applications.PLEASE_EXPLAIN' | translate }}: <input type="text" class="form-control form-control-sm" [formControlName]="criteria.field_comments">
            </div>
          </div> -->
        </ng-container>

        <div *ngIf="applicantType === 'Applicant'">
          <div class="row pb-3 pl-5 pr-5 m-0" [style.background]="green" >
            <div class="col-md-2 text-md-right float-center pr-4">
              <mat-radio-group formControlName="nr_assistance_rcvd">
                <mat-radio-button value="True">
                  <label class="mr-3">
                    <strong style="line-height: 19px; vertical-align: bottom;">{{ 'applications.YES' | translate }}</strong>
                  </label>
                </mat-radio-button>
                <mat-radio-button value="False">
                  <label>
                    <strong style="line-height: 19px; vertical-align: bottom;">{{ 'applications.NO' | translate }}
                    </strong>
                  </label>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-10" style="white-space: pre-wrap;">{{ 'applications.NON_RENTAL_ASSISTANCE' | translate }}</div>
          </div>  
          <div class="row pb-3 pl-5 pr-5 m-0" >
            <div class="col-md-2 text-right pr-4"></div>
            <div class="col-10" style="white-space: pre-wrap;" >
              <input type="checkbox" formControlName="nr_assistance_snap" *ngIf="form.value['nr_assistance_rcvd'] === 'True'"> {{ 'applications.NON_RENTAL_ASSISTANCE_SNAP' | translate }}<br>
              <input type="checkbox" formControlName="nr_assistance_liheap" *ngIf="form.value['nr_assistance_rcvd'] === 'True'"> {{ 'applications.NON_RENTAL_ASSISTANCE_LIHEAP' | translate }}<br>
              <input type="checkbox" formControlName="nr_assistance_tanf" *ngIf="form.value['nr_assistance_rcvd'] === 'True'"> {{ 'applications.NON_RENTAL_ASSISTANCE_TANF' | translate }}
              <!-- <input type="checkbox" formControlName="nr_assistance_lwc" *ngIf="form.value['nr_assistance_rcvd'] === 'True'"> {{ 'applications.NON_RENTAL_ASSISTANCE_LWC' | translate }} -->
            </div>
          </div>     
          <!-- <div class="row pb-3 pl-5 pr-5 m-0" [style.background]="green">
            <div class="col-md-2 text-right pr-4"></div>
            <div class="col-10" style="white-space: pre-wrap;">
              {{ 'applications.PLEASE_EXPLAIN' | translate }}:
              <input type="text" class="form-control form-control-sm" formControlName="nr_assistance_comments">
            </div>
          </div>  -->
        </div>

      </div>
      <div class="card-footer text-center btn-responsive">
        <button type="submit" class="btn background-third-whitetext" [disabled]="form.invalid">
          {{ 'applications.SUBMIT' | translate }}
        </button>
        <button type="button" routerLink="/application/list" class="btn btn-dark">
          <i class="fa fa-times"> {{ 'applications.CANCEL' | translate }}</i>
        </button>
        <small class="m-md-3" [innerHTML]="'applications.SUBMIT_BTN_INFO_TEXT' | translate"></small>
      </div>
    </div>
  </form>
</div>
