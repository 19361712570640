import { Component, HostListener, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormControlName,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProjectSpecificService } from "../../../../../services/project-specific.service";
import { IgnatiusService } from "../../../../../services/ignatius.service";
import { CommonService } from "../../../services";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { forkJoin } from "rxjs";
import { Constants } from "src/app/shared/constants";
import en from "src/assets/i18n/en.json";

@Component({
  selector: "app-determine-eligibility",
  templateUrl: "./determine-eligibility.component.html",
  styleUrls: ["./determine-eligibility.component.css"],
})
export class DetermineEligibilityComponent implements OnInit {
  @HostListener("window:beforeunload", ["$event"])
  public onPageUnload($event: BeforeUnloadEvent) {
    if (this.hasUnsavedChanges()) {
      $event.returnValue = true;
    }
  }

  app;
  questions = [];
  form: FormGroup;
  index = 0;
  childIndex = 0;
  projectSpecificData;
  parentValue;
  isSubmitted = false;
  applicantType = "";
  isDefaultDataCreated: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ignatiusService: IgnatiusService,
    private commonService: CommonService,
    private projectSpecificService: ProjectSpecificService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.projectSpecificData =
      this.projectSpecificService.getProjectSpecificData();
    const componentData = this.route.snapshot.data["componentData"];
    this.app = componentData[0][0];
    if (
      !(
        this.app.status == "Pre-Eligibility" ||
        this.app.status == "Eligibility Questions Answered"
      )
    ) {
      this.router.navigate(["application/list"]);
    }

    const permissionTypeData = this.projectSpecificService.getPermissionType();
    if (JSON.stringify(permissionTypeData).toString().includes("Landlord"))
      this.applicantType = "Landlord";
    else this.applicantType = "Applicant";

    if (this.applicantType == "Landlord") {
      this.translate
        .stream("eligibilityLandlord.questions")
        .subscribe((questions) => {
          this.questions = questions;
          this.formSetup();
          this.spinner.hide();
        });
    } else {
      this.translate
        .stream("eligibilityApplicant.questions")
        .subscribe((questions) => {
          this.questions = questions;
          this.formSetup();
          this.spinner.hide();
        });
    }
  }

  formSetup() {
    const group = {};
    this.questions.forEach((criteria) => {
      group[criteria.field] = [""];
      group[criteria.field_comments] = [""];
      if (!criteria.parent) {
        group[criteria.field].push([Validators.required]);
      }
    });

    this.form = this.fb.group(group);
    if (this.app.status == "Eligibility Questions Answered") {
      this.form.patchValue(this.app);
    }
    if (this.applicantType == "Applicant") {
      this.form.addControl("nr_assistance_comments", new FormControl(""));
      this.form.addControl("nr_assistance_rcvd",new FormControl(this.app.nr_assistance_rcvd, Validators.required));
      this.form.addControl("nr_assistance_snap", new FormControl(""));
      this.form.addControl("nr_assistance_liheap", new FormControl(""));
      this.form.addControl("nr_assistance_tanf", new FormControl(""));
      // this.form.addControl("nr_assistance_lwc", new FormControl(""));
    }
  }

  showCriteria(criteria) {
    const parent = criteria.parent;
    if (parent) {
      const display = this.form.get(parent.field).value == parent.value;
      const field = this.form.get(criteria.field);
      if (!display && field.value) {
        field.setValue("");
      }
      const display_comments =
        this.form.get(parent.field_comments).value == parent.value;
      const field_comments = this.form.get(criteria.field_comments);
      if (!display && field_comments.value) {
        field_comments.setValue("");
      }
      return display;
    } else {
      return true;
    }
  }

  getIneligibleComment() {
    const form = this.form.value;
    let comments = "";

    const update = (comment) => {
      if (comments) {
        comments = comments + ";" + comment;
      } else {
        comments = comment;
      }
    };

    this.questions.forEach((criteria) => {
      if (!criteria.parent && form[criteria.field]) {
          if (form[criteria.field] == "True") {
          } else if(criteria.eligible !== form[criteria.field]){
            update(criteria.ineligible);
          }
        } else {
          if (criteria.eligible !== form[criteria.field]) {
            update(criteria.ineligible);
          }
        }
      }
    );
    return comments;
  }

  async submitCriteria() {
    const dialogData: { title: string; message: string } = {
      title: "applications.SAVE_CONTINUE",
      message: `applications.SAVE_CONTINUE_MESSAGE`,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData,
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) return;

    const ineligible_comment = this.getIneligibleComment();
    const status = "Eligibility Questions Answered";
    const tableId = this.projectSpecificData.applicationsData.TableId;
    const condition = Number(this.app.id);

    const obj = { ...this.form.value, ineligible_comment };
    const recordFAD = this.commonService.createPutData(tableId, obj, condition);
    this.spinner.show();
    this.ignatiusService.putData(recordFAD).subscribe(
      (success: any) => {
        this.spinner.hide();
        this.toastr.success(
          this.translate.instant("MESSAGES.ELIGIBILITY_ANSWERS_SAVED"),
          this.translate.instant("applications.SUCCESS")
        );
        this.isSubmitted = true;
        this.submitEligibilityAnswers({
          ...this.app,
          ...this.form.value,
          ineligible_comment,
        });
        // this.navigateByStatus(status, this.app.id);
        // this.router.navigate(['application/list']);
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("MESSAGES.ELIGIBILITY_ANSWERS_SAVE_ERROR"),
          this.translate.instant("applications.ERROR")
        );
        this.spinner.hide();
      }
    );
  }

  hasUnsavedChanges() {
    return this.form.dirty && !this.isSubmitted;
  }

  async submitEligibilityAnswers(app: any) {
    if (this.applicantType == "Applicant") {
      let questions: any = await this.translate.instant(
        "eligibilityApplicant.questions"
      );
      status = questions
        .filter((q) => q.eligible) // && q.field.includes(3))
        .map((q) => app[q.field] == "True")
        .some((x) => x == false)
        ? "Ineligible"
        : "Open";
      const eligibleFields = questions.filter((q) => q.field.includes(1) || q.field.includes(2));
      //if(status !== 'Ineligible'){
        status = eligibleFields.every((q) => app[q.field] == 'False') ? 'Ineligible' : 'Open'
      //}
        
    }
    if (this.applicantType == "Landlord") {
      /// ALWAYS ELIGIBLE
      let questions: any = await this.translate.instant(
        "eligibilityLandlord.questions"
      );
      questions = questions.slice(0, questions.length - 1);
      status = questions
        .map((q) => app[q.field] == "True")
        .some((x) => x == true)
        ? "Open"
        : "Open";
    }

    const tableId = this.projectSpecificData.applicationsData.TableId;
    const condition = Number(this.app.id);
    const obj = { status };
    const recordFAD = this.commonService.createPutData(tableId, obj, condition);

    try {
      this.spinner.show();
      await this.ignatiusService.putData(recordFAD).toPromise();
      if (
        this.applicantType === "Applicant" &&
        status === "Open" &&
        !this.isDefaultDataCreated
      ) {
        this.isDefaultDataCreated = true;
        // Remove create the applicant household recor 
        //await this.createHousehold();
      }
      this.navigateByStatus(status, this.app.id);
      this.spinner.hide();
    } catch {
      this.toastr.error(
        this.translate.instant("MESSAGES.ELIGIBILITY_ANSWERS_SAVE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
  }

  private navigateByStatus = (status: string, recordId: string) => {
    switch (status) {
      case "Open":
        // NES - route to list page for now ... application will come later
        this.router.navigate([
          "application",
          this.applicantType === "Applicant" ? "edit_applicant" : "edit_landlord",
          recordId,
        ]);
        this.router.navigate(["application/list"]);
        break;
      default:
      case "Ineligible":
        this.router.navigate(["application/list"]);
    }
  };

  /**
   * Create a new household
   */
  createHousehold = async () => {
    const tableId = this.projectSpecificData.householdData.TableId;
    const hhObservables = this.commonService.getHouseholdAddObservables(
      {
        first_name: this.app.contact_first_name,
        mi: this.app.contact_mi,
        last_name: this.app.contact_last_name,
        relation_to_applicant: "Applicant",
        household_number: "Household 0",
        //ssn_enc: this.app.tin,
        related_applications: this.app.id,
        income_gross_amount_type: "Annual",
        error_message: en.applications.NO_GENDER,
      },
      tableId
    );

    try {
      await forkJoin(hhObservables[0]).toPromise();
      this.toastr.success(
        // this.translate.instant("MESSAGES.HOUSEHOLD_UPDATE_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.HOUSEHOLD_UPDATE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
  };
}
