import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { take, exhaustMap, catchError, tap, switchMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { from, Observable, of, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  /**
   * Routes which don't require tokens
   */
  unAuthorizedUrls: Array<string> = [
    "/token",
    "/user/register",
    "api/user/login",
    "api/user/forgotpassword",
    "api/user/recoverpassword",
    "api/user/custom-register",
  ];

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      if (err.error.Message === "Please check your Email and Password") {
        this.toastr.error(`Invalid Credentials. ${err.error.Message}`);
        this.authService.logout();
        throw err;
      }
      if (err.error.includes("User is not authorized.")) {
        this.toastr.error(`User is not authorized`);
        this.authService.logout();
        throw err;
      }
      this.toastr.error("Please log back in.", "Your session has timed out");
      this.authService.logout();
    }
    if (err.status === 400) {
      return throwError(err.error);
    }

    if(err.status === 409){
      throw err;
    }

    return of<any>();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (new RegExp(this.unAuthorizedUrls.join("|")).test(req.url))
      return next
        .handle(req)
        .pipe(catchError((response) => this.handleAuthError(response)));
    return from(this.authService.refreshToken()).pipe(
      switchMap((user) => {
        req = req.clone({ url: req.url });
        if (!user) {
          return next.handle(req);
        }
        const modifiedReq = req.clone({
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          }),
        });

        return next
          .handle(modifiedReq)
          .pipe(catchError((response) => this.handleAuthError(response)));
      })
    );
  }
}
