import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { environment } from "src/environments/environment";
import moment from "moment";

@Injectable()
export class CommonService {
  constructor(
    private ignatiusService: IgnatiusService,
    private projectSpecificService: ProjectSpecificService
  ) {}

  validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });
  }

  getPostStructure(tableId) {
    return new FormActionData(0, tableId, null, new Array<FieldListItem>());
  }

  createPostData(tableId: number, data: any) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      null,
      new Array<FieldListItem>()
    );

    for (const key in data) {
      recordFAD.fieldsList.push(new FieldListItem(key, data[key], ""));
    }
    return recordFAD;
  }

  createPutData(tableId: number, data: any, condition: number) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(condition),
      new Array<FieldListItem>()
    );

    for (const key in data) {
      recordFAD.fieldsList.push(new FieldListItem(key, data[key], ""));
    }
    return recordFAD;
  }

  createPutDataApproval(tableId: number, data: any, condition: number) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(condition),
      new Array<FieldListItem>()
    );

    for (const key in data) {
      if (key === "file_lease_ll" && data[key]) {
        recordFAD.fieldsList.push(
          new FieldListItem(key, data["file_name_lease_ll"], data[key])
        );
        delete data["file_name_lease_ll"];
      } else if (key !== "file_lease_ll" && key !== "file_name_lease_ll")
        recordFAD.fieldsList.push(new FieldListItem(key, data[key], ""));
    }
    return recordFAD;
  }

  createAcknowledgementsData(
    data: any,
    tableId: number,
    recordId: number,
  ) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );
    recordFAD.fieldsList.push(
      new FieldListItem("certify", data["certify"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack", data["ack"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_2", data["ack_2"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_3", data["ack_3"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_4", data["ack_4"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_5", data["ack_5"], "")
    );
    //recordFAD.fieldsList.push(new FieldListItem('ack_6', data['ack_6'], ""));
    recordFAD.fieldsList.push(
      new FieldListItem("certifier_name", data["certifier_name"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("certifier_title", data["certifier_title"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("certified_by", data["certified_by"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("certified_dt", data["certified_dt"], "")
    );

    if (data.file_drivers_license && data.file_drivers_license_name) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "file_drivers_license",
          data["file_drivers_license_name"],
          data["file_drivers_license"]
        )
      );
    }

    return recordFAD;
  }

  createApplicantAcknowledgementsData(
    data: any,
    tableId: number,
    recordId: number,
  ) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );
    recordFAD.fieldsList.push(
      new FieldListItem("certify", data["certify"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack", data["ack"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_2", data["ack_2"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_3", data["ack_3"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_4", data["ack_4"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_5", data["ack_5"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("certifier_name", data["certifier_name"], "")
    );
    // recordFAD.fieldsList.push(new FieldListItem('certifier_title', data['certifier_title'], ""));
    recordFAD.fieldsList.push(
      new FieldListItem("certified_by", data["certified_by"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("certified_dt", data["certified_dt"], "")
    );

    if (data.file_drivers_license && data.file_drivers_license_name) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "file_drivers_license",
          data["file_drivers_license_name"],
          data["file_drivers_license"]
        )
      );
    }

    return recordFAD;
  }

  createApplicantAcknowledgementsDataTicket(
    data: any,
    tableId: number,
    recordId: number,
  ) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );
    recordFAD.fieldsList.push(
      new FieldListItem("certify", data["certify"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack", data["ack"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_2", data["ack_2"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_3", data["ack_3"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_4", data["ack_4"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("ack_5", data["ack_5"], "")
    );
    // recordFAD.fieldsList.push(new FieldListItem('certified_dt', data['certified_dt'], ""));
    return recordFAD;
  }

  getFileData(
    file: any
  ): Promise<{ filestr: string; filesize: number; filename: string }> {
    const fileData = {
      filestr: "",
      filesize: file.size,
      filename: file.name
        ? file.name.replaceAll(/[&\/\\#,+()$~%'":!*?<>{}]/g, "")
        : file.name,
    };
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        fileData["filestr"] = reader.result.toString().split(",")[1];
        resolve(fileData);
      };
    });
  }

  async getApplicationDocuments(applicationId: string) {
    try {
      const projectSpecificData =
        this.projectSpecificService.getProjectSpecificData();

      let documents = await this.ignatiusService
        .getQueryReportObservable(projectSpecificData.appData, {
          ApplicationTableId: projectSpecificData.documentsData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: projectSpecificData.documentsData
                      .RelatedApplicationsFieldId,
                  },
                  OperationType: "is equal",
                  Value: applicationId,
                },
                {
                  ConditionField: {
                    Id: projectSpecificData.documentsData.documentTypeFieldId,
                  },
                  OperationType: "is equal",
                  Value: "Application Attachment",
                },
              ],
            },
          ],
        })
        .toPromise();

      return documents;
    } catch (error) {
      throw error;
    }
  }

  getCompletionStatusApplicant(
    data: any,
    expenseData: Array<any>,
    householdData: Array<any>
  ) {
    const status = {
      ContactStatus: this.getContactStatus(data),
      //AddressesStatus: this.getAddressesStatus(data),
      //LandlordStatus: this.getLandlordStatus(data),
      //ExpensesStatus: this.getExpensesStatus(expenseData),
      HouseholdStatus: this.getHouseholdStatus(householdData, 10),
      //RentalDocumentsStatus: this.getRentalDocumentsStatus(data),
      AcknowledgementsStatus: this.getApplicantAcknowledgementsStatus(data),
      //ReceivedFundsStatus: this.getReceivedFundsStatus(data),
      //BankInformationStatus: this.getBankInformationStatus(data)
    };

    status["total"] = this.getTotalPercentageApplicant(status);
    return status;
  }

  getCompletionStatusLandlord(
    data: any,
    expenseData: Array<any>,
    householdData: Array<any>,
    propertiesData: Array<any> = []
  ) {
    const status = {
      BusinessContactStatus: this.getBusinessContactStatus(data),
      BusinessDetailsStatus: this.getBusinessDetailsStatus(data),
      AcknowledgementsStatus: this.getAcknowledgementsStatus(data),
      BankInformationStatus: this.getBankInformationStatus(data),
      PropertiesStatus: this.getPropertiesStatus(propertiesData),
    };

    status["total"] = this.getTotalPercentageLandlord(status);
    return status;
  }

  private getPropertiesStatus(data: Array<any>) {
    let status = true;
    if (data.length === 0) status = false;

    data.forEach((item) => {
      status = status && item.property_name && item.property_type; // && item.file_deed_or_taxes_url;
    });

    return status;
  }

  private getBusinessContactStatus(data: any) {
    const condition =
      data.contact_first_name &&
      data.contact_last_name &&
      data.contact_phone &&
      data.contact_email &&
      data.ll_date_of_birth &&
      data.ll_race &&
      data.ll_gender;
    return Boolean(condition);
  }

  private getBusinessDetailsStatus(data: any) {
    const condition =
      data.physical_street &&
      data.physical_city &&
      data.physical_state &&
      data.physical_zip_code &&
      data.mailing_street &&
      data.mailing_city &&
      data.mailing_state &&
      data.mailing_zip_code &&
      (data.minority_or_women_owned === "False" ||
        (data.minority_or_women_owned === "True" &&
          (data.minority_or_women_owned_registered === "False" ||
            data.minority_or_women_owned_registered === "True")));
    return Boolean(condition);
  }

  createBusinessDetailsDocsData(data: any, tableId: number, recordId: number) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );

    recordFAD.fieldsList.push(
      new FieldListItem("physical_street", data["physical_street"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("physical_street_2", data["physical_street_2"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("physical_city", data["physical_city"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("physical_state", data["physical_state"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("physical_zip_code", data["physical_zip_code"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("physical_latitude", data["physical_latitude"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("physical_longitude", data["physical_longitude"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("mailing_street", data["mailing_street"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("mailing_street_2", data["mailing_street_2"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("mailing_city", data["mailing_city"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("mailing_state", data["mailing_state"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("mailing_zip_code", data["mailing_zip_code"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("mailing_latitude", data["mailing_latitude"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem("mailing_longitude", data["mailing_longitude"], "")
    );
    recordFAD.fieldsList.push(
      new FieldListItem(
        "minority_or_women_owned",
        data["minority_or_women_owned"],
        ""
      )
    );
    recordFAD.fieldsList.push(
      new FieldListItem(
        "minority_or_women_owned_registered",
        data["minority_or_women_owned_registered"],
        ""
      )
    );

    if (data.file_w9 && data.file_name_w9) {
      recordFAD.fieldsList.push(
        new FieldListItem("file_w9", data["file_name_w9"], data["file_w9"])
      );
    }
    return recordFAD;
  }

  createBusinessDetailsData(data: any, tableId: number, recordId: number) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );
    if (data.file_w9 && data.file_name_w9) {
      recordFAD.fieldsList.push(
        new FieldListItem("file_w9", data["file_name_w9"], data["file_w9"])
      );
    }
    return recordFAD;
  }

  private getAcknowledgementsStatus(data: any) {
    const condition =
      data["certify"] === "True" &&
      data["ack"] === "True" &&
      data["ack_2"] === "True" &&
      data["ack_3"] === "True" &&
      data["ack_4"] === "True" &&
      data["ack_5"] === "True" &&
      data.certifier_name &&
      data.certifier_title &&
      data.file_drivers_license_url;
    return Boolean(condition);
  }

  private getApplicantAcknowledgementsStatus(data: any) {
    const condition =
      data["certify"] === "True" &&
      data["ack"] === "True" &&
      data["ack_2"] === "True" &&
      data["ack_3"] === "True" &&
      data["ack_4"] === "True" &&
      data.certifier_name &&
      data.file_drivers_license_url;

    return Boolean(condition);
  }

  private getBankInformationStatus(data: any) {
    const condition =
      (data.ach_or_check === "ACH" || data.ach_or_check === "Check") &&
      data.check_payee_name;
    // data.bank_name &&
    // data.bank_location &&
    // data.bank_account_name &&
    // data.bank_account_number &&
    // data.bank_routing_number &&
    // data.bank_account_type
    return Boolean(condition);
  }

  private getContactStatus(data: any) {
    const condition =
      data.contact_first_name &&
      data.contact_last_name &&
      data.contact_phone &&
      data.contact_email;
    return Boolean(condition);
  }

  private getAddressesStatus(data: any) {
    const condition =
      data.physical_street &&
      data.physical_city &&
      data.physical_state &&
      data.physical_zip_code &&
      data.parish &&
      data.number_of_bedrooms != "-1" &&
      data.unit_size != "" &&
      data.unit_size != "Not Selected";
    return Boolean(condition);
  }

  private getLandlordStatus(data: any) {
    const condition =
      data.landlord_first_name &&
      data.landlord_last_name &&
      data.landlord_phone &&
      data.landlord_email &&
      data.property_name &&
      data.property_type;
    return Boolean(condition);
  }

  private getExpensesStatus(dataArr: any) {
    let status = true;
    let hasRent = false;
    if (dataArr.length === 0) status = false;
    for (const iterator of dataArr) {
      if (iterator.expense_type === "Rent") {
        hasRent = true;
        status =
          status &&
          Boolean(iterator.amount_monthly && iterator.amount_monthly != "0") &&
          iterator.lease_start_date &&
          iterator.lease_end_date;
      }
    }
    return status && hasRent;
  }
  getExpensesObservables(data: any, tableId: number, applicationId: string) {
    const { expense } = data.expenses;
    const { past_due } = data.expenses;

    const reqObservables: Array<any> = [];

    for (const iterator of data.expenses) {
      if (!iterator.used && !iterator.id) continue;

      const recordFAD = new FormActionData(
        0,
        tableId,
        null,
        new Array<FieldListItem>()
      );

      recordFAD.fieldsList = [
        new FieldListItem(
          "related_applications",
          iterator["related_applications"],
          ""
        ),
      ];
      if (iterator["expense_type"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("expense_type", iterator["expense_type"], "")
        );
      }
      if (iterator["amount_monthly"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("amount_monthly", iterator["amount_monthly"], "")
        );
      }
      if (iterator["lease_start_date"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "lease_start_date",
            iterator["lease_start_date"],
            ""
          )
        );
      }
      if (iterator["lease_end_date"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("lease_end_date", iterator["lease_end_date"], "")
        );
      }
      if (iterator["in_arrears"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("in_arrears", iterator["in_arrears"], "")
        );
      }
      if (iterator["months_in_arrears"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "months_in_arrears",
            iterator["months_in_arrears"],
            ""
          )
        );
      }
      if (iterator["total_arrears"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("total_arrears", iterator["total_arrears"], "")
        );
      }

      if (iterator["account_number"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("account_number", iterator["account_number"], "")
        );
      }
      if (iterator["name_on_account"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("name_on_account", iterator["name_on_account"], "")
        );
      }
      if (iterator["provider"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("provider", iterator["provider"], "")
        );
      }
      if (iterator["electricity_company_other"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "electricity_company_other",
            iterator["electricity_company_other"],
            ""
          )
        );
      }
      recordFAD.fieldsList.push(
        new FieldListItem(
          "name_on_account_hh_member",
          iterator["name_on_account_hh_member"],
          ""
        )
      );
      if (iterator["document_file"] && iterator["document_name"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "document_file",
            iterator["document_name"],
            iterator["document_file"]
          )
        );
      }
      if (iterator["past_due_amount_april_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_april_2020",
            iterator["past_due_amount_april_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_may_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_may_2020",
            iterator["past_due_amount_may_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_june_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_june_2020",
            iterator["past_due_amount_june_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_july_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_july_2020",
            iterator["past_due_amount_july_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_august_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_august_2020",
            iterator["past_due_amount_august_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_september_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_september_2020",
            iterator["past_due_amount_september_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_october_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_october_2020",
            iterator["past_due_amount_october_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_november_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_november_2020",
            iterator["past_due_amount_november_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_december_2020"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_december_2020",
            iterator["past_due_amount_december_2020"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_january_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_january_2021",
            iterator["past_due_amount_january_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_february_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_february_2021",
            iterator["past_due_amount_february_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_march_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_march_2021",
            iterator["past_due_amount_march_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_april_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_april_2021",
            iterator["past_due_amount_april_2021"],
            ""
          )
        );
      }
      if (iterator["previously_received_funds"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "previously_received_funds",
            iterator["previously_received_funds"],
            ""
          )
        );
      }
      if (iterator["amount_past_due"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("amount_past_due", iterator["amount_past_due"], "")
        );
      }
      if (iterator["past_due_amount_may_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_may_2021",
            iterator["past_due_amount_may_2021"],
            ""
          )
        );
      }
      if (iterator["utility_months_in_arrears"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "utility_months_in_arrears",
            iterator["utility_months_in_arrears"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_june_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_june_2021",
            iterator["past_due_amount_june_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_july_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_july_2021",
            iterator["past_due_amount_july_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_august_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_august_2021",
            iterator["past_due_amount_august_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_september_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_september_2021",
            iterator["past_due_amount_september_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_october_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_october_2021",
            iterator["past_due_amount_october_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_november_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_november_2021",
            iterator["past_due_amount_november_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_december_2021"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_december_2021",
            iterator["past_due_amount_december_2021"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_january_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_january_2022",
            iterator["past_due_amount_january_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_february_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_february_2022",
            iterator["past_due_amount_february_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_march_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_march_2022",
            iterator["past_due_amount_march_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_april_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_april_2022",
            iterator["past_due_amount_april_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_may_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_may_2022",
            iterator["past_due_amount_may_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_june_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_june_2022",
            iterator["past_due_amount_june_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_july_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_july_2022",
            iterator["past_due_amount_july_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_august_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_august_2022",
            iterator["past_due_amount_august_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_september_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_september_2022",
            iterator["past_due_amount_september_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_october_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_october_2022",
            iterator["past_due_amount_october_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_november_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_november_2022",
            iterator["past_due_amount_november_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_december_2022"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_december_2022",
            iterator["past_due_amount_december_2022"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_january_2023"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_january_2023",
            iterator["past_due_amount_january_2023"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_february_2023"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_february_2023",
            iterator["past_due_amount_february_2023"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_march_2023"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_march_2023",
            iterator["past_due_amount_march_2023"],
            ""
          )
        );
      }
      if (iterator["past_due_amount_april_2023"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "past_due_amount_april_2023",
            iterator["past_due_amount_april_2023"],
            ""
          )
        );
      }

      if (iterator.id) {
        recordFAD.where = new Where(iterator.id);
        if (iterator.used) {
          reqObservables.push(this.ignatiusService.putData(recordFAD));
        } else {
          recordFAD.fieldsList = null;
          reqObservables.push(this.ignatiusService.deleteData(recordFAD));
        }
      } else {
        reqObservables.push(this.ignatiusService.postData(recordFAD));
      }
    }
    return reqObservables;
  }
  getExpensesAddObservables(data: any, tableId: number, applicationId: string) {
    const expense = data;
    const reqObservables: Array<any> = [];
    const recordFAD = new FormActionData(
      0,
      tableId,
      null,
      new Array<FieldListItem>()
    );

    recordFAD.fieldsList = [
      new FieldListItem(
        "related_applications",
        expense["related_applications"],
        ""
      ),
    ];

    if (expense["expense_type"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("expense_type", expense["expense_type"], "")
      );
    }
    if (expense["amount_monthly"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("amount_monthly", expense["amount_monthly"], "")
      );
    }
    if (expense["lease_start_date"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("lease_start_date", expense["lease_start_date"], "")
      );
    }
    if (expense["lease_end_date"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("lease_end_date", expense["lease_end_date"], "")
      );
    }
    if (expense["in_arrears"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("in_arrears", expense["in_arrears"], "")
      );
    }
    if (expense["months_in_arrears"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("months_in_arrears", expense["months_in_arrears"], "")
      );
    }
    if (expense["total_arrears"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("total_arrears", expense["total_arrears"], "")
      );
    }
    if (expense["account_number"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("account_number", expense["account_number"], "")
      );
    }
    if (expense["name_on_account"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("name_on_account", expense["name_on_account"], "")
      );
    }
    if (expense["provider"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("provider", expense["provider"], "")
      );
    }
    if (expense["electricity_company_other"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "electricity_company_other",
          expense["electricity_company_other"],
          ""
        )
      );
    }
    if (expense["name_on_account_hh_member"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "name_on_account_hh_member",
          expense["name_on_account_hh_member"],
          ""
        )
      );
    }
    // if(expense['provider_zip_code']){
    //   recordFAD.fieldsList.push(new FieldListItem('provider_zip_code', expense['provider_zip_code'], ""));
    // }

    if (expense["document_file"] && expense["document_name"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "document_file",
          expense["document_name"],
          expense["document_file"]
        )
      );
    }

    if (expense["past_due_amount_april_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_april_2020",
          expense["past_due_amount_april_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_may_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_may_2020",
          expense["past_due_amount_may_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_june_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_june_2020",
          expense["past_due_amount_june_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_july_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_july_2020",
          expense["past_due_amount_july_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_august_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_august_2020",
          expense["past_due_amount_august_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_september_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_september_2020",
          expense["past_due_amount_september_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_october_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_october_2020",
          expense["past_due_amount_october_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_november_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_november_2020",
          expense["past_due_amount_november_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_december_2020"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_december_2020",
          expense["past_due_amount_december_2020"],
          ""
        )
      );
    }
    if (expense["past_due_amount_january_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_january_2021",
          expense["past_due_amount_january_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_february_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_february_2021",
          expense["past_due_amount_february_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_march_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_march_2021",
          expense["past_due_amount_march_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_april_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_april_2021",
          expense["past_due_amount_april_2021"],
          ""
        )
      );
    }
    if (expense["previously_received_funds"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "previously_received_funds",
          expense["previously_received_funds"],
          ""
        )
      );
    }
    if (expense["amount_past_due"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("amount_past_due", expense["amount_past_due"], "")
      );
    }
    if (expense["past_due_amount_may_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_may_2021",
          expense["past_due_amount_may_2021"],
          ""
        )
      );
    }
    if (expense["utility_months_in_arrears"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "utility_months_in_arrears",
          expense["utility_months_in_arrears"],
          ""
        )
      );
    }
    if (expense["past_due_amount_june_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_june_2021",
          expense["past_due_amount_june_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_july_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_july_2021",
          expense["past_due_amount_july_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_august_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_august_2021",
          expense["past_due_amount_august_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_september_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_september_2021",
          expense["past_due_amount_september_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_october_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_october_2021",
          expense["past_due_amount_october_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_november_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_november_2021",
          expense["past_due_amount_november_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_december_2021"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_december_2021",
          expense["past_due_amount_december_2021"],
          ""
        )
      );
    }
    if (expense["past_due_amount_january_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_january_2022",
          expense["past_due_amount_january_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_february_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_february_2022",
          expense["past_due_amount_february_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_march_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_march_2022",
          expense["past_due_amount_march_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_april_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_april_2022",
          expense["past_due_amount_april_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_may_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_may_2022",
          expense["past_due_amount_may_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_june_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_june_2022",
          expense["past_due_amount_june_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_july_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_july_2022",
          expense["past_due_amount_july_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_august_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_august_2022",
          expense["past_due_amount_august_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_september_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_september_2022",
          expense["past_due_amount_september_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_october_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_october_2022",
          expense["past_due_amount_october_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_november_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_november_2022",
          expense["past_due_amount_november_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_december_2022"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_december_2022",
          expense["past_due_amount_december_2022"],
          ""
        )
      );
    }
    if (expense["past_due_amount_january_2023"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_january_2023",
          expense["past_due_amount_january_2023"],
          ""
        )
      );
    }
    if (expense["past_due_amount_february_2023"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_february_2023",
          expense["past_due_amount_february_2023"],
          ""
        )
      );
    }
    if (expense["past_due_amount_march_2023"]) {
      recordFAD.fieldsList.push( 
        new FieldListItem(
          "past_due_amount_march_2023",
          expense["past_due_amount_march_2023"],
          ""
        )
      );
    }
    if (expense["past_due_amount_april_2023"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "past_due_amount_april_2023",
          expense["past_due_amount_april_2023"],
          ""
        )
      );
    }

    if(expense['recert_expense']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_expense', expense['recert_expense'], ""));
    }
    if(expense['recert_address_1']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_address_1', expense['recert_address_1'], ""));
    }
    if(expense['recert_address_2']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_address_2', expense['recert_address_2'], ""));
    }
    if(expense['recert_city']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_city', expense['recert_city'], ""));
    }
    if(expense['recert_state']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_state', expense['recert_state'], ""));
    }
    if(expense['recert_zip']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_zip', expense['recert_zip'], ""));
    }
    if(expense['recert_num_bedrooms']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_num_bedrooms', expense['recert_num_bedrooms'], ""));
    }
    if(expense['recert_phone']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_phone', expense['recert_phone'], ""));
    }
    if(expense['recert_monthly_rent']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_monthly_rent', expense['recert_monthly_rent'], ""));
    }
    if(expense['recert_landlord_name']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_landlord_name', expense['recert_landlord_name'], ""));
    }
    if(expense['recert_address_changed']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_address_changed', expense['recert_address_changed'], ""));
    }
    if(expense['recert_monthly_rent_changed']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_monthly_rent_changed', expense['recert_monthly_rent_changed'], ""));
    }
    if(expense['recert_phone_changed']){ 
      recordFAD.fieldsList.push(new FieldListItem('recert_phone_changed', expense['recert_phone_changed'], ""));
    }
   
    
    reqObservables.push(this.ignatiusService.postData(recordFAD));
    return reqObservables;
  }
  getExpensesDeleteObservables(data: any, tableId: number) {
    const expense = data;
    const reqObservables: Array<any> = [];

    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(expense["id"]),
      null
    );

    reqObservables.push(this.ignatiusService.deleteData(recordFAD));

    return reqObservables;
  }
  getExpenseDisplayData(dataArr: Array<any>) {
    const data = {
      expensesCount: dataArr.length,
      total_amount_monthly: 0,
      total_amount_past_due: 0,
      total_months_past_due: 0,
      docsCount: 0,
    };

    if (dataArr.length === 0) return data;

    for (const iterator of dataArr) {
      data["total_amount_monthly"] += iterator.amount_monthly
        ? Number(iterator.amount_monthly)
        : 0;
      data["total_amount_past_due"] += iterator.total_arrears
        ? Number(iterator.total_arrears)
        : 0;
      data["total_months_past_due"] += iterator.months_in_arrears
        ? Number(iterator.months_in_arrears)
        : 0;
      data["docsCount"] += iterator.document_file_url ? 1 : 0;
    }
    return data;
  }

  private getHouseholdStatus(dataArr: Array<any>, count) {
    let status = true;
    let applicantFound = false;

    if (dataArr.length === 0) status = false;

    for (const iterator of dataArr) {
      // requires an Applicant relation
      if (iterator.relation_to_applicant === "Applicant") applicantFound = true;

      status =
        status &&
        Boolean(
          iterator.first_name != "" &&
            iterator.last_name != "" &&
            iterator.date_of_birth != "" &&
            iterator.date_of_birth != "Invalid date" &&
            iterator.relation_to_applicant != "" &&
            iterator.gender != "" &&
            iterator.race != "" &&
            iterator.ethnicity != "" &&
            iterator.veteran != "" &&
            iterator.employment_status
        );

      /// Diksha 2024-04-02 - there is nothing in iterator.ssn_enc
      // if (
      //   iterator.date_of_birth &&
      //   moment().diff(iterator.date_of_birth, "years") >= 18
      // )
      //   status = status && Boolean(iterator.ssn_enc);



      
      // if gross amount exists
      // if (+iterator.income_gross_amount)
      //   status = status && Boolean (iterator.income_gross_file_url
      //     && iterator.income_gross_file_name && iterator.income_gross_file_2_url
      //     && iterator.income_gross_file_2_name);

      // if lieu exists
      // if (+iterator.income_in_lieu_of_earnings_amount)
      //   status = status && iterator.income_in_lieu_of_earnings_file_url != "";

      // if annuities exists
      // if (+iterator.income_payments_annuities_amount)
      //   status = status && iterator.income_payments_annuities_file_url != "";

      // if government exists
      // if (+iterator.income_government_amount)
      //   status = status && iterator.income_government_file_url != "";

      // if alimony exists
      // if (+iterator.income_alimony_amount)
      //   status = status && iterator.income_alimony_file_url != "";

      // if other exists
      // if (+iterator.income_other_amount)
      //   status = status && iterator.income_other_file_url != "";

      // if unemployed/other
      if (iterator.employment_status === "Unemployed") {
        status =
          status &&
          iterator.last_date_worked != "" &&
          iterator.last_date_worked != "Invalid date";
      } else if (
        ["Employed", "Other"].find((el) => el === iterator.employment_status)
      )
        status =
          status &&
          Boolean(
            iterator.income_gross_file_url != "" ||
              iterator.income_gross_file_2_url != "" ||
              iterator.income_gross_no_file
          );

      if (count === 1) {
        return status;
      }
    }

    status = status && applicantFound;

    return status;
  }

  getHouseholdObservables(data: any, tableId: number) {
    const { household } = data;
    const reqObservables: Array<any> = [];
    const rowArr: Array<any> = [];
    var rowStatus = false;

    for (const iterator of household) {
      if (!iterator.used && !iterator.id) continue;

      const recordFAD = new FormActionData(
        0,
        tableId,
        null,
        new Array<FieldListItem>()
      );

      recordFAD.fieldsList = [
        new FieldListItem(
          "related_applications",
          iterator["related_applications"],
          ""
        ),
      ];
      if (
        iterator["household_number"] == 0 ||
        iterator["household_number"] === "Household 0"
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem("household_number", "Household 0", "")
        );
      } else {
        if (environment.production) {
          if (iterator["household_number"]) {
            recordFAD.fieldsList.push(
              new FieldListItem(
                "household_number",
                iterator["household_number"],
                ""
              )
            );
          }
        } else {
          if (iterator["household_number"]) {
            recordFAD.fieldsList.push(
              new FieldListItem(
                "household_number",
                `${+iterator["household_number"] + 1}`,
                ""
              )
            );
          }
        }
      }

      if (iterator["first_name"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("first_name", iterator["first_name"], "")
        );
      }
      if (iterator["mi"]) {
        recordFAD.fieldsList.push(new FieldListItem("mi", iterator["mi"], ""));
      }
      if (iterator["last_name"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("last_name", iterator["last_name"], "")
        );
      }
      if (iterator["date_of_birth"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("date_of_birth", iterator["date_of_birth"], "")
        );
      }
      if (iterator["race"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("race", iterator["race"], "")
        );
      }
      if (iterator["ethnicity"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("ethnicity", iterator["ethnicity"], "")
        );
      }
      if (iterator["veteran"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("veteran", iterator["veteran"], "")
        );
      }
      if (iterator["gender"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("gender", iterator["gender"], "")
        );
      }
      if (iterator["employment_status"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "employment_status",
            iterator["employment_status"],
            ""
          )
        );
      }
      if (iterator["income_gross"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("income_gross", iterator["income_gross"], "")
        );
      }
      if (iterator["income_gross_amount"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_gross_amount",
            iterator["income_gross_amount"],
            ""
          )
        );
      }
      if (iterator["income_gross_amount_2"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_gross_amount_2",
            iterator["income_gross_amount_2"],
            ""
          )
        );
      }
      if (iterator["income_gross_amount_type"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_gross_amount_type",
            iterator["income_gross_amount_type"],
            ""
          )
        );
      }
      if (iterator["income_gross_file"] && iterator["income_gross_file_name"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_gross_file",
            iterator["income_gross_file_name"],
            iterator["income_gross_file"]
          )
        );
      }
      if (
        iterator["income_gross_file_2"] &&
        iterator["income_gross_file_2_name"]
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_gross_file_2",
            iterator["income_gross_file_2_name"],
            iterator["income_gross_file_2"]
          )
        );
      }
      if (
        iterator["income_gross_file_3"] &&
        iterator["income_gross_file_3_name"]
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_gross_file_3",
            iterator["income_gross_file_3_name"],
            iterator["income_gross_file_3"]
          )
        );
      }
      if (
        iterator["income_gross_file_4"] &&
        iterator["income_gross_file_4_name"]
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_gross_file_4",
            iterator["income_gross_file_4_name"],
            iterator["income_gross_file_4"]
          )
        );
      }
      if (
        iterator["income_gross_file_5"] &&
        iterator["income_gross_file_5_name"]
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_gross_file_5",
            iterator["income_gross_file_5_name"],
            iterator["income_gross_file_5"]
          )
        );
      }
      if (iterator["income_in_lieu_of_earnings"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_in_lieu_of_earnings",
            iterator["income_in_lieu_of_earnings"],
            ""
          )
        );
      }
      if (iterator["income_in_lieu_of_earnings_amount"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_in_lieu_of_earnings_amount",
            iterator["income_in_lieu_of_earnings_amount"],
            ""
          )
        );
      }
      if (
        iterator["income_in_lieu_of_earnings_file"] &&
        iterator["income_in_lieu_of_earnings_file_name"]
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_in_lieu_of_earnings_file",
            iterator["income_in_lieu_of_earnings_file_name"],
            iterator["income_in_lieu_of_earnings_file"]
          )
        );
      }
      if (iterator["income_payments_annuities"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_payments_annuities",
            iterator["income_payments_annuities"],
            ""
          )
        );
      }
      if (iterator["income_payments_annuities_amount"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_payments_annuities_amount",
            iterator["income_payments_annuities_amount"],
            ""
          )
        );
      }
      if (
        iterator["income_payments_annuities_file"] &&
        iterator["income_payments_annuities_file_name"]
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_payments_annuities_file",
            iterator["income_payments_annuities_file_name"],
            iterator["income_payments_annuities_file"]
          )
        );
      }
      if (iterator["income_government"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_government",
            iterator["income_government"],
            ""
          )
        );
      }
      if (iterator["income_government_amount"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_government_amount",
            iterator["income_government_amount"],
            ""
          )
        );
      }
      if (
        iterator["income_government_file"] &&
        iterator["income_government_file_name"]
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_government_file",
            iterator["income_government_file_name"],
            iterator["income_government_file"]
          )
        );
      }
      if (iterator["income_alimony"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("income_alimony", iterator["income_alimony"], "")
        );
      }
      if (iterator["income_alimony_amount"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_alimony_amount",
            iterator["income_alimony_amount"],
            ""
          )
        );
      }
      if (
        iterator["income_alimony_file"] &&
        iterator["income_alimony_file_name"]
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_alimony_file",
            iterator["income_alimony_file_name"],
            iterator["income_alimony_file"]
          )
        );
      }
      if (iterator["income_other"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("income_other", iterator["income_other"], "")
        );
      }
      if (iterator["income_other_amount"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_other_amount",
            iterator["income_other_amount"],
            ""
          )
        );
      }
      if (iterator["income_other_file"] && iterator["income_other_file_name"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "income_other_file",
            iterator["income_other_file_name"],
            iterator["income_other_file"]
          )
        );
      }
      if (iterator["last_date_worked"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "last_date_worked",
            iterator["last_date_worked"],
            ""
          )
        );
      }
      if (iterator["relation_to_applicant"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "relation_to_applicant",
            iterator["relation_to_applicant"],
            ""
          )
        );
      }
      if (iterator["ssn_enc"]) {
        recordFAD.fieldsList.push(
          new FieldListItem("ssn_enc", iterator["ssn_enc"], "")
        );
      }
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_no_file",
          iterator["income_gross_no_file"],
          ""
        )
      );
      recordFAD.fieldsList.push(
        new FieldListItem(
          "other_forms_of_income", 
          iterator["other_forms_of_income"], 
          ""
        )
      );

      if (iterator["error_message"] !== undefined) {
        recordFAD.fieldsList.push(
          new FieldListItem("error_message", iterator["error_message"], "")
        );
      }
      rowArr[0] = iterator;
      iterator["status"] = this.getHouseholdStatus(rowArr, 1);

      recordFAD.fieldsList.push(
        new FieldListItem("status", iterator["status"], "")
      );

      // if (iterator['document_file'] && iterator['document_name']) {
      //   recordFAD.fieldsList.push(new FieldListItem(
      //     'document_file',
      //     iterator['document_name'],
      //     iterator['document_file'])
      //   );
      // }

      if (iterator.id) {
        recordFAD.where = new Where(iterator.id);
        if (iterator.used) {
          reqObservables.push(this.ignatiusService.putData(recordFAD));
        } else {
          recordFAD.fieldsList = null;
          reqObservables.push(this.ignatiusService.deleteData(recordFAD));
        }
      } else {
        reqObservables.push(this.ignatiusService.postData(recordFAD));
      }

      return reqObservables;
    }
    return reqObservables;
  }

  getHouseholdAddObservables(data: any, tableId: number) {
    const household = data;
    const reqObservables: Array<any> = [];

    const recordFAD = new FormActionData(
      0,
      tableId,
      null,
      new Array<FieldListItem>()
    );

    recordFAD.fieldsList = [
      new FieldListItem(
        "related_applications",
        household["related_applications"],
        ""
      ),
    ];

    if (
      household["household_number"] === 0 ||
      household["household_number"] === "Household 0"
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem("household_number", "Household 0", "")
      );
    } else {
      if (environment.production) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "household_number",
            household["household_number"],
            ""
          )
        );
      } else {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "household_number",
            `${+household["household_number"] + 1}`,
            ""
          )
        );
      }
    }

    if (household["first_name"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("first_name", household["first_name"], "")
      );
    }
    if (household["mi"]) {
      recordFAD.fieldsList.push(new FieldListItem("mi", household["mi"], ""));
    }
    if (household["last_name"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("last_name", household["last_name"], "")
      );
    }
    if (household["date_of_birth"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("date_of_birth", household["date_of_birth"], "")
      );
    }
    if (household["race"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("race", household["race"], "")
      );
    }
    if (household["ethnicity"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("ethnicity", household["ethnicity"], "")
      );
    }
    if (household["veteran"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("veteran", household["veteran"], "")
      );
    }
    if (household["gender"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("gender", household["gender"], "")
      );
    }
    if (household["employment_status"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "employment_status",
          household["employment_status"],
          ""
        )
      );
    }
    if (household["income_gross"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("income_gross", household["income_gross"], "")
      );
    }
    if (household["income_gross_amount"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_amount",
          household["income_gross_amount"],
          ""
        )
      );
    }
    if (household["income_gross_amount_2"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_amount_2",
          household["income_gross_amount_2"],
          ""
        )
      );
    }
    if (household["income_gross_amount_type"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_amount_type",
          household["income_gross_amount_type"],
          ""
        )
      );
    }
    if (household["income_gross_file"] && household["income_gross_file_name"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_file",
          household["income_gross_file_name"],
          household["income_gross_file"]
        )
      );
    }
    if (
      household["income_gross_file_2"] &&
      household["income_gross_file_2_name"]
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_file_2",
          household["income_gross_file_2_name"],
          household["income_gross_file_2"]
        )
      );
    }
    if (
      household["income_gross_file_3"] &&
      household["income_gross_file_3_name"]
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_file_3",
          household["income_gross_file_3_name"],
          household["income_gross_file_3"]
        )
      );
    }
    if (
      household["income_gross_file_4"] &&
      household["income_gross_file_4_name"]
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_file_4",
          household["income_gross_file_4_name"],
          household["income_gross_file_4"]
        )
      );
    }
    if (
      household["income_gross_file_5"] &&
      household["income_gross_file_5_name"]
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_file_5",
          household["income_gross_file_5_name"],
          household["income_gross_file_5"]
        )
      );
    }
    if (household["income_in_lieu_of_earnings"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_in_lieu_of_earnings",
          household["income_in_lieu_of_earnings"],
          ""
        )
      );
    }
    if (household["income_in_lieu_of_earnings_amount"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_in_lieu_of_earnings_amount",
          household["income_in_lieu_of_earnings_amount"],
          ""
        )
      );
    }
    if (
      household["income_in_lieu_of_earnings_file"] &&
      household["income_in_lieu_of_earnings_file_name"]
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_in_lieu_of_earnings_file",
          household["income_in_lieu_of_earnings_file_name"],
          household["income_in_lieu_of_earnings_file"]
        )
      );
    }
    if (household["income_payments_annuities"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_payments_annuities",
          household["income_payments_annuities"],
          ""
        )
      );
    }
    if (household["income_payments_annuities_amount"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_payments_annuities_amount",
          household["income_payments_annuities_amount"],
          ""
        )
      );
    }
    if (
      household["income_payments_annuities_file"] &&
      household["income_payments_annuities_file_name"]
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_payments_annuities_file",
          household["income_payments_annuities_file_name"],
          household["income_payments_annuities_file"]
        )
      );
    }
    if (household["income_government"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_government",
          household["income_government"],
          ""
        )
      );
    }
    if (household["income_government_amount"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_government_amount",
          household["income_government_amount"],
          ""
        )
      );
    }
    if (
      household["income_government_file"] &&
      household["income_government_file_name"]
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_government_file",
          household["income_government_file_name"],
          household["income_government_file"]
        )
      );
    }
    if (household["income_alimony"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("income_alimony", household["income_alimony"], "")
      );
    }
    if (household["income_alimony_amount"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_alimony_amount",
          household["income_alimony_amount"],
          ""
        )
      );
    }
    if (
      household["income_alimony_file"] &&
      household["income_alimony_file_name"]
    ) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_alimony_file",
          household["income_alimony_file_name"],
          household["income_alimony_file"]
        )
      );
    }
    if (household["income_other"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("income_other", household["income_other"], "")
      );
    }
    if (household["income_other_amount"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_other_amount",
          household["income_other_amount"],
          ""
        )
      );
    }
    if (household["income_other_file"] && household["income_other_file_name"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_other_file",
          household["income_other_file_name"],
          household["income_other_file"]
        )
      );
    }
    if (household["last_date_worked"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("last_date_worked", household["last_date_worked"], "")
      );
    }
    if (household["relation_to_applicant"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "relation_to_applicant",
          household["relation_to_applicant"],
          ""
        )
      );
    }
    if (household["ssn_enc"]) {
      recordFAD.fieldsList.push(
        new FieldListItem("ssn_enc", household["ssn_enc"], "")
      );
    }
    if (household["income_gross_no_file"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "income_gross_no_file",
          household["income_gross_no_file"],
          ""
        )
      );
    }
    if (household["other_forms_of_income"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "other_forms_of_income",
          household["other_forms_of_income"],
          ""
        )
      );
    }
    if (household["confirmation_number"]) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "confirmation_number",
          household["confirmation_number"],
          ""
        )
      );
    }

    if (household["error_message"] !== undefined) {
      recordFAD.fieldsList.push(
        new FieldListItem("error_message", household["error_message"], "")
      );
    }

    if(household?.["status"] || !household["error_message"]){
      recordFAD?.fieldsList?.push(
        new FieldListItem("status", "True", "")
      );
    }

    // if (iterator['document_file'] && iterator['document_name']) {
    //   recordFAD.fieldsList.push(new FieldListItem(
    //     'document_file',
    //     iterator['document_name'],
    //     iterator['document_file'])
    //   );
    // }

    // if (iterator.id) {
    //   recordFAD.where = new Where(iterator.id);
    //   if (iterator.used) {
    //     reqObservables.push(this.ignatiusService.putData(recordFAD));
    //   } else {
    //     recordFAD.fieldsList = null;
    //     reqObservables.push(this.ignatiusService.deleteData(recordFAD));
    //   }
    // } else {
    reqObservables.push(this.ignatiusService.postData(recordFAD));

    return reqObservables;
  }
  getHouseholdDeleteObservables(data: any, tableId: number) {
    const household = data;
    const reqObservables: Array<any> = [];

    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(household["id"]),
      null
    );

    // recordFAD.fieldsList = [
    //   new FieldListItem('related_applications', household['related_applications'], ""),
    // ]

    // recordFAD.fieldsList.push(new FieldListItem('id', household['id'], ""));

    // recordFAD.fieldsList = [
    //   new FieldListItem('id', household['id'], ""),
    // ]

    reqObservables.push(this.ignatiusService.deleteData(recordFAD));

    return reqObservables;
  }
  getHouseholdDisplayData(dataArr: Array<any>) {
    const data = {
      householdCount: dataArr.length,
      total_income_annual: 0,
      total_income_month_1: 0,
      total_income_month_2: 0,
      docsCount: 0,
      id: 0,
    };

    if (dataArr.length === 0) return data;

    for (const iterator of dataArr) {
      if (iterator.income_gross_amount_type === "Monthly") {
        data["total_income_month_1"] +=
          Number(iterator.income_gross_amount) +
          Number(iterator.income_in_lieu_of_earnings_amount) +
          Number(iterator.income_payments_annuities_amount) +
          Number(iterator.income_government_amount) +
          Number(iterator.income_alimony_amount) +
          Number(iterator.income_other_amount);
        data["total_income_month_2"] +=
          Number(iterator.income_gross_amount_2) +
          Number(iterator.income_in_lieu_of_earnings_amount) +
          Number(iterator.income_payments_annuities_amount) +
          Number(iterator.income_government_amount) +
          Number(iterator.income_alimony_amount) +
          Number(iterator.income_other_amount);
      } else if (iterator.income_gross_amount_type === "Annual") {
        data["total_income_annual"] +=
          Number(iterator.income_gross_amount) +
          Number(iterator.income_in_lieu_of_earnings_amount) * 12 +
          Number(iterator.income_payments_annuities_amount) * 12 +
          Number(iterator.income_government_amount) * 12 +
          Number(iterator.income_alimony_amount) * 12 +
          Number(iterator.income_other_amount) * 12;
      }

      //data['total_income'] += iterator.income ? Number(iterator.income) : 0;

      /////////////// CHECK FOR CHECKED
      // if (iterator.income_gross_amount_type === "Monthly")
      //   data['total_income1'] += iterator.income_gross_amount ? Number(iterator.income_gross_amount) : 0;
      // else
      //   data['total_income1'] += iterator.income_gross_amount ? Number(iterator.income_gross_amount)/12 : 0;
      // data['total_income1'] += iterator.income_in_lieu_of_earnings_amount ? Number(iterator.income_in_lieu_of_earnings_amount)*12 : 0;
      // data['total_income1'] += iterator.income_payments_annuities_amount ? Number(iterator.income_payments_annuities_amount)*12 : 0;
      // data['total_income1'] += iterator.income_government_amount ? Number(iterator.income_government_amount)*12 : 0;
      // data['total_income1'] += iterator.income_alimony_amount ? Number(iterator.income_alimony_amount)*12 : 0;
      // data['total_income1'] += iterator.income_other_amount ? Number(iterator.income_other_amount)*12 : 0;

      // if (iterator.income_gross_amount_type === "Monthly") {
      //   data['total_income2'] += iterator.income_gross_amount_2 ? Number(iterator.income_gross_amount_2) : 0;
      //   data['total_income2'] += iterator.income_in_lieu_of_earnings_amount ? Number(iterator.income_in_lieu_of_earnings_amount) : 0;
      //   data['total_income2'] += iterator.income_payments_annuities_amount ? Number(iterator.income_payments_annuities_amount) : 0;
      //   data['total_income2'] += iterator.income_government_amount ? Number(iterator.income_government_amount) : 0;
      //   data['total_income2'] += iterator.income_alimony_amount ? Number(iterator.income_alimony_amount) : 0;
      //   data['total_income2'] += iterator.income_other_amount ? Number(iterator.income_other_amount) : 0;
      // }
      data["docsCount"] += iterator.document_file_url ? 1 : 0;
    }
    return data;
  }

  private getRentalDocumentsStatus(data: any) {
    const condition = data.file_lease_url;
    return Boolean(condition);
  }

  createRentalDocumentsData(data: any, tableId: number, recordId: number) {
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );

    if (data.file_lease && data.file_name_lease) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "file_lease",
          data["file_name_lease"],
          data["file_lease"]
        )
      );
    }

    if (data.file_past_due_rent && data.file_name_past_due_rent) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "file_past_due_rent",
          data["file_name_past_due_rent"],
          data["file_past_due_rent"]
        )
      );
    }

    if (data.file_eviction_notice && data.file_name_eviction_notice) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "file_eviction_notice",
          data["file_name_eviction_notice"],
          data["file_eviction_notice"]
        )
      );
    }

    if (data.file_past_due_utility && data.file_name_past_due_utility) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "file_past_due_utility",
          data["file_name_past_due_utility"],
          data["file_past_due_utility"]
        )
      );
    }

    if (data.file_other && data.file_name_other) {
      recordFAD.fieldsList.push(
        new FieldListItem(
          "file_other",
          data["file_name_other"],
          data["file_other"]
        )
      );
    }

    return recordFAD;
  }

  createExpenseFileUploadData(tableId:any,recordId:any,fieldName:any,fileName:any,fileData:any, relatedId:any){
    const recordFAD = new FormActionData(
      0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );
    recordFAD.fieldsList.push(
      new FieldListItem(
        fieldName,
        fileName,
        fileData
      )
    );

    recordFAD.fieldsList.push(
      new FieldListItem(
        'related_applications',
        relatedId,
        ''
      )
    );
    recordFAD.fieldsList.push(
      new FieldListItem(
        'expense_type',
        'Recertification',
        ''
      )
    );

    return recordFAD;
  }

  getPropertyObservable = (data: any, tableId: number, MODE: string) => {
    let recordFAD = new FormActionData(
      0,
      tableId,
      null,
      new Array<FieldListItem>()
    );

    if (MODE !== "ADD")
      recordFAD = new FormActionData(
        0,
        tableId,
        new Where(data.rid),
        new Array<FieldListItem>()
      );

    delete data.rid;

    for (let item in data) {
      if (item === "file_deed_or_taxes" && data["file_deed_or_taxes_name"]) {
        recordFAD.fieldsList.push(
          new FieldListItem(item, data["file_deed_or_taxes_name"], data[item])
        );
        delete data.file_deed_or_taxes_name;
        delete data.file_deed_or_taxes_url;
      } else if (
        data[item] &&
        item !== "file_deed_or_taxes_name" &&
        item !== "file_deed_or_taxes_url" &&
        item !== "file_deed_or_taxes"
      )
        recordFAD.fieldsList.push(new FieldListItem(item, data[item], ""));
    }

    switch (MODE) {
      case "ADD":
        return this.ignatiusService.postData(recordFAD);
      case "EDIT":
        return this.ignatiusService.putData(recordFAD);
      case "DELETE":
        recordFAD.fieldsList = null;
        return this.ignatiusService.deleteData(recordFAD);
    }
  };

  private getTotalPercentageApplicant(obj: any) {
    let total: number = 0;
    for (const key in obj) {
      if (obj[key]) total += 14.3;
    }
    return Math.round(total);
  }
  private getTotalPercentageLandlord(obj: any) {
    let total: number = 0;
    for (const key in obj) {
      if (obj[key]) total += 20;
    }
    return total;
  }
}
