import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { IgnatiusService } from 'src/app/services/ignatius.service';
import { ProjectSpecificService } from 'src/app/services/project-specific.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { Constants } from 'src/app/shared/constants';
import { PopupModel } from '../../../models/popup';
import { CommonService } from '../../../services';
import { ApplicationForms } from '../forms';
import moment from "moment";
import { FieldListItem, FormActionData, Where } from 'src/app/models/form-action-data';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'application-edit-landlord',
  templateUrl: './edit_landlord.component.html',
  styleUrls: ['./edit_landlord.component.css'],
  providers: [ApplicationForms],
})
export class ApplicationEditLandlordComponent implements OnInit {

  constructor(
    private ignatiusService: IgnatiusService,
    private projectSpecificService: ProjectSpecificService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private ngbModal: NgbModal,
    private appForms: ApplicationForms,
    private common: CommonService,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService
  ) { }

  businessTypes: Array<any>;
  bankAccountTypes: Array<any>;
  races: Array<any>;
  genders: Array<any>;
  projectSpecificData:any;
  applicationData: any = {};
  recordId: string;
  modelConfig: PopupModel;
  completionStatus: any = {};
  //bankName: string = null;
  applicantType = "";
  viewMode: boolean = false;
  propertiesData: Array<any> = [];
  MODE: string = '';
  propertyTypes: Array<any> = [];

  //mapping = Constants.EXPENSES_MAPPING;
  options = {
    componentRestrictions: {
      country: ["US"]
    }
  };

  businessContactsFormGroup: FormGroup;
  businessDetailsFormGroup: FormGroup;
  acknowledgementsFormGroup: FormGroup;
  bankDetailsFormGroup: FormGroup;
  propertyFormGroup: FormGroup;
  @ViewChild('property', { static: true }) property: ElementRef;
  //businessOwnersFormGroup: FormGroup;
  //businessDocsFormGroup: FormGroup;
  //receivedFundsFormGroup: FormGroup;
  //expensesFormGroup: FormGroup;
  //minorityOrWomenFormGroup: FormGroup;
  //executiveOrderFormGroup: FormGroup;

  get businessContactsFormControl() { return this.businessContactsFormGroup.controls }
  get businessDetailsFormControl() { return this.businessDetailsFormGroup.controls }
  get acknowledgementsFormControl() { return this.acknowledgementsFormGroup.controls }
  get bankDetailsFormControl() { return this.bankDetailsFormGroup.controls }
  //get businessOwnersFormControl() { return this.businessOwnersFormGroup.controls }
  //get businessDocsFormControl() { return this.businessDocsFormGroup.controls }
  //get receivedFundsFormControl() { return this.receivedFundsFormGroup.controls }
  //get expensesFormControl() { return this.expensesFormGroup.controls }
  //get minorityOrWomenFormControl() { return this.minorityOrWomenFormGroup.controls }
  //get executiveOrderFormControl() { return this.executiveOrderFormGroup.controls }

  ngOnInit() {
    this.projectSpecificData = this.projectSpecificService.getProjectSpecificData();
    this.recordId = this.route.snapshot.paramMap.get('id');
    const componentData = this.route.snapshot.data['componentData'];
    this.businessTypes = componentData[0];
    this.bankAccountTypes = componentData[2];
    this.races = componentData[9];
    this.genders = componentData[10];
    this.propertyTypes = componentData[15];
    //this.expensesTypes = componentData[3];
  }

  async ngAfterContentInit() {
    await this.getApplicationData();
    //await this.getExpensesData();
    await this.getPropertiesData();
    this.spinner.hide();
  }

  private fetchAppData = async (sync: boolean = false) => {
    await this.getApplicationData(sync);
    await this.getPropertiesData(sync);
  }

  /**
   * Sync status of individual sections
   * @param status object of completion status
   */
  private syncStatus = async (status: any) => {
    let reqData = {
      is_valid_ll_acks: !!status.AcknowledgementsStatus,
      is_valid_ll_business: !!status.BusinessDetailsStatus,
      is_valid_ll_contact: !!status.BusinessContactStatus,
      is_valid_ll_payment: !!status.BankInformationStatus,
      is_valid_ll_properties: !!status.PropertiesStatus
    };
    try {
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = Number(this.recordId);
      const recordFAD = this.common.createPutData(tableId, reqData, condition);
      await this.ignatiusService.putData(recordFAD).toPromise();
    } catch (error) {
    }
  }

  private async getApplicationData(sync: boolean = false) {
    try {
      const applicationData = await this.ignatiusService.getTargetTableObservable(
        this.projectSpecificData.appData,
        this.recordId,
        this.projectSpecificData.applicationsData.TableId as number,
        this.projectSpecificData.applicationsData.RecordIdFieldId as number
      ).toPromise();
      this.applicationData = (applicationData && applicationData.length > 0)
        ? applicationData[0]
        : {}
      if (!Object.keys(this.applicationData).length) return this.router.navigate(["../"]);
      const userData = this.projectSpecificService.getUserData();
      const roleData = this.projectSpecificService.getRoleData();
      if (roleData.Id === 'super_admin' || roleData.Id === 'admin') {
        // pass
      }
      // else if (this.applicationData.createdby.toString().toUpperCase() != userData.userName.toString().toUpperCase()) return this.router.navigate(["../"]);

      this.completionStatus = this.common.getCompletionStatusLandlord(this.applicationData, null, null);
        if (sync) {
        this.syncCompletionPercentageWithDB(this.completionStatus.total);
      }
      this.askUserToSubmit ();
      this.setViewMode(this.applicationData.status);
      const permissionTypeData = this.projectSpecificService.getPermissionType();
      if (JSON.stringify(permissionTypeData).toString().includes("Landlord")) {
        this.applicantType = "Landlord";
      }
      else
        this.applicantType = "Applicant";
    } catch (error) {
      this.toastr.error(this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"), this.translate.instant("applications.ERROR"));
    }
  }

  private async getPropertiesData(sync: boolean = false) {
    try {
      this.propertiesData = await this.ignatiusService
        .getQueryReportObservable(
          this.projectSpecificData.appData,
          {
            "ApplicationTableId": this.projectSpecificData.propertiesData.TableId,
            "ConditionGroups": [
              {
                "Type": "all",
                "Conditions": [
                  {
                    "ConditionField": {
                      "Id": this.projectSpecificData.propertiesData.RelatedApplicationsFieldId
                    },
                    "OperationType": "is equal",
                    "Value": this.recordId
                  }
                ]
              }
            ]
          }).toPromise();

      // this.householdDisplayData = this.common.getHouseholdDisplayData(this.householdData);
      this.completionStatus = this.common.getCompletionStatusLandlord(this.applicationData, null, null, this.propertiesData);
      this.syncStatus(this.completionStatus);
      if (sync) {
        this.syncCompletionPercentageWithDB(this.completionStatus.total);
      }
      this.askUserToSubmit ();

    } catch (error) {
      this.toastr.error(this.translate.instant("MESSAGES.HOUSEHOLD_FETCH_ERROR"), this.translate.instant("applications.ERROR"));
    }
  }

  async askUserToSubmit() {
    if (+this.completionStatus.total !== 100 || this.applicationData.status !== 'Open') return;
    const dialogData: { title: string, message: string, hasSubmit: boolean } = {
      title: "applications.SUBMIT_APPLICATION",
      message: `applications.SUBMIT_APPLICATION_MESSAGE`,
      hasSubmit: true
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) {return};
    this.ngbModal.dismissAll();
    this.submitApplication(+this.recordId);
  }

  async submitApplication(recordId: number) {
    const dialogData: { title: string, message: string } = {
      title: "applications.SAVE_CONTINUE",
      message: `applications.SAVE_CONTINUE_MESSAGE_APP`
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData
    });

    const confirmation = await dialogRef.afterClosed().toPromise();

    if (!confirmation) return;

    try {
      const reqData = { status: 'Submitted' }
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = recordId;
      const recordFAD = this.common.createPutData(tableId, reqData, condition);

      this.spinner.show();
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.getApplicationData();
      this.spinner.hide();
      this.router.navigate(['../']);
    } catch (error) {
      this.toastr.error(this.translate.instant("MESSAGES.ERROR_SYNC_PERCENTAGE"), this.translate.instant("applications.ERROR"));
      this.spinner.hide();
    }
  }

  // private async getExpensesData(sync: boolean = false) {
  //   try {
  //     this.expensesData = await this.ignatiusService
  //       .getQueryReportObservable(
  //         this.projectSpecificData.appData,
  //         {
  //           "ApplicationTableId": this.projectSpecificData.expensesData.TableId,
  //           "ConditionGroups": [
  //             {
  //               "Type": "all",
  //               "Conditions": [
  //                 {
  //                   "ConditionField": {
  //                     "Id": this.projectSpecificData.expensesData.RelatedApplicationsFieldId
  //                   },
  //                   "OperationType": "is equal",
  //                   "Value": this.recordId
  //                 }
  //               ]
  //             }
  //           ]
  //         }).toPromise();

  //     this.expenseDisplayData = this.common.getExpenseDisplayData(this.expensesData);
  //     this.completionStatus = this.common.getCompletionStatusLL(this.applicationData, this.expensesData, null);
  //     if (sync) {
  //       this.syncCompletionPercentageWithDB(this.completionStatus.total);
  //     }

  //   } catch (error) {
  //     this.toastr.error(Constants.MESSAGES.EXPENSES_FETCH_ERROR, 'Error');
  //   }
  // }

  openBusinessContacts(content: any) {
    this.parseDates('ll_date_of_birth');
    this.businessContactsFormGroup = this.appForms.setupBusinessContactsForm(this.applicationData);
    this.modelConfig = new PopupModel();
    this.ngbModal.open(content, this.modelConfig.settings);
    // this.setFormState(this.businessContactsFormGroup);
  }

    /**
   * Format dates
   */
  parseDates = (key: string) => {
    if (this.applicationData[key])
      this.applicationData[key] = moment(this.applicationData[key]).format('YYYY-MM-DD');
  }

  onBusinessContactsSubmit() {
    if (!this.businessContactsFormGroup.valid) {
      this.common.validateAllFormFields(this.businessContactsFormGroup);
      return;
    }
    this.updateApplication(this.businessContactsFormGroup.value);
  }

  openBusinessDetails(content: any) {
    this.businessDetailsFormGroup = this.appForms.setupBusinessDetailsForm(this.applicationData);
    this.modelConfig = new PopupModel();
    this.ngbModal.open(content, this.modelConfig.settings);
    // this.setFormState(this.businessDetailsFormGroup);
  }

  onBusinessDetailsSubmit() {
    if (!this.businessDetailsFormGroup.valid) {
      this.common.validateAllFormFields(this.businessDetailsFormGroup);
      return;
    }
    const tableId = this.projectSpecificData.applicationsData.TableId;
    const recordId = Number(this.recordId);
    const updateData = this.common.createBusinessDetailsDocsData(this.businessDetailsFormGroup.getRawValue(), tableId, recordId)
    this.updateApplicationAndDocs(updateData);
  }

  async handleW9Upload(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"), this.translate.instant("applications.ERROR"));
      this.businessDetailsFormGroup.patchValue({
        file_w9: "",
        file_name_w9: ""
      })
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i=0; i < filename.split(".").length-2; i++) {
        for (var j=0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".","_");
            j = filename.length;
          }
      }
    }

    this.businessDetailsFormGroup.patchValue({
      file_w9: filestr,
      file_name_w9: strFilename
    })
  }

  openAcknowledgements(content) {
    this.acknowledgementsFormGroup = this.appForms.setupAcknowledgementsForm(this.applicationData);
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);
    this.setFormState(this.acknowledgementsFormGroup);
  }

  onAcknowledgementsSubmit() {
    if (!this.acknowledgementsFormGroup.valid) {
      this.common.validateAllFormFields(this.acknowledgementsFormGroup);
      return;
    }

    const tableId = this.projectSpecificData.applicationsData.TableId;
    const recordId = Number(this.recordId);
    const updateData = this.common.createAcknowledgementsData(this.acknowledgementsFormGroup.value, tableId, recordId)
    this.updateApplicationAndDocs(updateData);
  }

  async handleDLUpoad(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);


    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"), this.translate.instant("applications.ERROR"));
      this.acknowledgementsFormGroup.patchValue({
        file_drivers_license: "",
        file_drivers_license_name: ""
      })
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i=0; i < filename.split(".").length-2; i++) {
        for (var j=0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".","_");
            j = filename.length;
          }
      }
    }

    this.acknowledgementsFormGroup.patchValue({
      file_drivers_license: filestr,
      file_drivers_license_name: strFilename
    })
  }

  openBankDetails(content) {
    this.bankDetailsFormGroup = this.appForms.setupBankDetailsForm(this.applicationData);
    this.modelConfig = new PopupModel();
    this.ngbModal.open(content, this.modelConfig.settings);
    // this.setFormState(this.bankDetailsFormGroup);
  }

  onBankDetailsSubmit() {
    if (!this.bankDetailsFormGroup.valid) {
      this.common.validateAllFormFields(this.bankDetailsFormGroup);
      return;
    }
    this.updateApplication(this.bankDetailsFormGroup.value);
  }

  private async updateApplication(reqDada: any) {
    try {
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = Number(this.recordId);
      const recordFAD = this.common.createPutData(tableId, reqDada, condition);
      this.modelConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.fetchAppData(true);
      this.updateApplicationCompleted(true);
    } catch (error) {
      this.updateApplicationCompleted(false);
    }
  }

  private async updateApplicationAndDocs(recordFAD: any) {
    try {
      this.modelConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.fetchAppData(true);
      this.updateApplicationCompleted(true);
    } catch (error) {
      this.updateApplicationCompleted(false);
    }
  }

  private updateApplicationCompleted(statue: boolean) {
    if (statue) {
      this.toastr.success(this.translate.instant("MESSAGES.APPLICATION_UPDATE_SUCCESS"), this.translate.instant("applications.SUCCESS"));
      this.ngbModal.dismissAll();
    }
    else {
      this.toastr.error(this.translate.instant("MESSAGES.APPLICATION_UPDATE_ERROR"), this.translate.instant("applications.ERROR"));
    }
    this.modelConfig.busy = false;
  }

  async syncCompletionPercentageWithDB(cp) {
    try {
      const reqData = { percent_complete: cp }
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = Number(this.recordId);
      const recordFAD = this.common.createPutData(tableId, reqData, condition);
      await this.ignatiusService.putData(recordFAD).toPromise();
    } catch (error) {
      this.toastr.error(this.translate.instant("MESSAGES.ERROR_SYNC_PERCENTAGE"), this.translate.instant("applications.ERROR"))
    }

  }

  async closeModel(confirm: boolean = true) {
    if (this.viewMode && !confirm) {
      this.ngbModal.dismissAll();
      return;
    }
    const dialogData: { title: string, message: string } = {
      title: "applications.CONFIRM_DISCARD",
      message: `applications.CONFIRM_DISCARD_MESSAGE`
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) return;

    this.ngbModal.dismissAll();
  }

  addressChangeBusinessContacts(address: any, field: string) {
    this.businessContactsFormControl[field].setValue(address.formatted_address);
  }
  addressChangeBusinessDetails(address: any, field: string) {
    this.businessDetailsFormControl[field].setValue(address.formatted_address);
  }

  addressChangePhysical(address: any, field: string) {
    this.businessDetailsFormGroup.get(field).setValue(address.formatted_address);
    var streetNumber = "";
    this.latLngToAddress(address);
    if (address.address_components[0]) {
      for (var i = 0; i < address.address_components.length; i++) {
        var addressType = address.address_components[i].types[0];
        switch (addressType) {
          case "street_number":
            streetNumber = address.address_components[i].long_name;
            break;
          case "route":
            this.businessDetailsFormGroup.get('physical_street').setValue(streetNumber + " " + address.address_components[i].long_name);
            break;
          case "locality":
            this.businessDetailsFormGroup.get('physical_city').setValue(address.address_components[i].long_name);
            break;
          case "administrative_area_level_1":
            this.businessDetailsFormGroup.get('physical_state').setValue(address.address_components[i].long_name);
            break;
          case "postal_code":
            this.businessDetailsFormGroup.get('physical_zip_code').setValue(address.address_components[i].long_name);
            break;
          case "longitude":
            this.businessDetailsFormGroup.get('physical_longitude').setValue(address.address_components[i].long_name);
            break;
          case "latitude":
            this.businessDetailsFormGroup.get('physical_latitude').setValue(address.address_components[i].long_name);
            break;
        }
      }
    }
  }
  addressChangeMailing(address: any, field: string) {
    this.businessDetailsFormGroup.get(field).setValue(address.formatted_address);
    var streetNumber = "";
    this.latLngToAddress(address);
    if (address.address_components[0]) {
      for (var i = 0; i < address.address_components.length; i++) {
        var addressType = address.address_components[i].types[0];
        switch (addressType) {
          case "street_number":
            streetNumber = address.address_components[i].long_name;
            break;
          case "route":
            this.businessDetailsFormGroup.get('mailing_street').setValue(streetNumber + " " + address.address_components[i].long_name);
            break;
          case "locality":
            this.businessDetailsFormGroup.get('mailing_city').setValue(address.address_components[i].long_name);
            break;
          case "administrative_area_level_1":
            this.businessDetailsFormGroup.get('mailing_state').setValue(address.address_components[i].long_name);
            break;
          case "postal_code":
            this.businessDetailsFormGroup.get('mailing_zip_code').setValue(address.address_components[i].long_name);
            break;
          case "longitude":
            this.businessDetailsFormGroup.get('mailing_longitude').setValue(address.address_components[i].long_name);
            break;
          case "latitude":
            this.businessDetailsFormGroup.get('mailing_latitude').setValue(address.address_components[i].long_name);
            break;
        }
      }
    }
  }

  /**
   * Add lat, long to address component
   */
  latLngToAddress = (address) => {
    address.address_components.push(...[{
      long_name: address.geometry.location.lat(),
      short_name: address.geometry.location.lat(),
      types: [
        "latitude"
      ]
    }, {
      long_name: address.geometry.location.lng(),
      short_name: address.geometry.location.lng(),
      types: [
        "longitude"
      ]
    }]);
  }

  // async OnChangeRoutingNumber(value: number) {
  //   try {
  //     const routingData = await fetch(`https://www.routingnumbers.info/api/data.json?rn=${value}`);
  //     const data = await routingData.json();
  //     this.bankName = (data && data.customer_name) || '';
  //   } catch (error) {
  //     console.log('Error in fetching routing data', error);
  //   }

  // }


  // openMinorityOrWomen(content) {
  //   this.minorityOrWomenFormGroup = this.appForms.setupMinorityOrWomenForm(this.applicationData);
  //   this.modelConfig = new PopupModel();
  //   this.ngbModal.open(content, this.modelConfig.settings)
  // }

  // onMinorityOrWomenSubmit() {
  //   if (!this.minorityOrWomenFormGroup.valid) {
  //     this.common.validateAllFormFields(this.minorityOrWomenFormGroup);
  //     return;
  //   }
  //   this.updateApplication(this.minorityOrWomenFormGroup.value);
  // }

  // openBusinessOwners(content: any) {
  //   this.businessOwnersFormGroup = this.appForms.setupBusinessOwnersForm(this.applicationData);
  //   this.modelConfig = new PopupModel();
  //   this.ngbModal.open(content, this.modelConfig.settings)
  // }

  // onBusinessOwnersSubmit() {
  //   if (!this.businessOwnersFormGroup.valid) {
  //     this.common.validateAllFormFields(this.businessOwnersFormGroup);
  //     return;
  //   }
  //   this.updateApplication(this.businessOwnersFormGroup.value);
  // }

  // openBusinessDocs(content: any) {
  //   this.businessDocsFormGroup = this.appForms.setupBusinessDocumentsForm(this.applicationData);
  //   this.modelConfig = new PopupModel();
  //   this.ngbModal.open(content, this.modelConfig.settings)
  // }

  // onBusinessDocsSubmit() {
  //   if (!this.businessDocsFormGroup.valid) {
  //     this.common.validateAllFormFields(this.businessDocsFormGroup);
  //     return;
  //   }
  //   const tableId = this.projectSpecificData.applicationsData.TableId;
  //   const recordId = Number(this.recordId);
  //   const updateData = this.common.createBusinessDocsData(this.businessDocsFormGroup.value, tableId, recordId)
  //   this.updateApplicationAndDocs(updateData);
  // }


  // async handleBusinessExistenceUpload(event: any) {
  //   const file = event.target.files[0];
  //   if (!file) return;
  //   const { filesize, filestr, filename } = await this.common.getFileData(file);

  //   if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //     this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //     this.businessDocsFormGroup.patchValue({
  //       file_business_existence: "",
  //       file_name_business_existence: ""
  //     })
  //     return;
  //   }

  //   this.businessDocsFormGroup.patchValue({
  //     file_business_existence: filestr,
  //     file_name_business_existence: filename
  //   })
  // }


  // openReceivedFunds(content) {
  //   this.receivedFundsFormGroup = this.appForms.setupReceivedFundsForm(this.applicationData);
  //   this.modelConfig = new PopupModel();
  //   this.modelConfig.settings.windowClass = "xlModal";
  //   this.ngbModal.open(content, this.modelConfig.settings)
  // }

  // onReceivedFundsSubmit() {
  //   if (!this.receivedFundsFormGroup.valid) {
  //     this.common.validateAllFormFields(this.receivedFundsFormGroup);
  //     return;
  //   }

  //   const tableId = this.projectSpecificData.applicationsData.TableId;
  //   const recordId = Number(this.recordId);
  //   const updateData = this.common.createFundsDocsData(this.receivedFundsFormGroup.value, tableId, recordId)
  //   this.updateApplicationAndDocs(updateData);
  // }

  // async handleRefundUpload(event: any, type: string) {
  //   const file = event.target.files[0];
  //   if (!file) return;
  //   const { filesize, filestr, filename } = await this.common.getFileData(file);

  //   switch (type) {
  //     case 'ppp':

  //       if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //         this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //         this.receivedFundsFormGroup.patchValue({
  //           file_funds_ppp: "",
  //           file_funds_ppp_name: ""
  //         })
  //         break;
  //       }

  //       this.receivedFundsFormGroup.patchValue({
  //         file_funds_ppp: filestr,
  //         file_funds_ppp_name: filename
  //       })
  //       break;

  //     case 'sba':

  //       if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //         this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //         this.receivedFundsFormGroup.patchValue({
  //           file_funds_sba: "",
  //           file_funds_sba_name: ""
  //         })
  //         break;
  //       }

  //       this.receivedFundsFormGroup.patchValue({
  //         file_funds_sba: filestr,
  //         file_funds_sba_name: filename
  //       })
  //       break;

  //     case 'edc':

  //       if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //         this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //         this.receivedFundsFormGroup.patchValue({
  //           file_funds_edc: "",
  //           file_funds_edc_name: ""
  //         })
  //         break;
  //       }

  //       this.receivedFundsFormGroup.patchValue({
  //         file_funds_edc: filestr,
  //         file_funds_edc_name: filename
  //       })
  //       break;

  //     case 'any':

  //       if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //         this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //         this.receivedFundsFormGroup.patchValue({
  //           file_funds_any: "",
  //           file_funds_any_name: ""
  //         })
  //         break;
  //       }

  //       this.receivedFundsFormGroup.patchValue({
  //         file_funds_any: filestr,
  //         file_funds_any_name: filename
  //       })
  //       break;

  //     case 'eidl_grant':

  //       if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //         this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //         this.receivedFundsFormGroup.patchValue({
  //           file_funds_eidl_grant: "",
  //           file_funds_eidl_grant_name: ""
  //         })
  //         break;
  //       }

  //     this.receivedFundsFormGroup.patchValue({
  //       file_funds_eidl_grant: filestr,
  //       file_funds_eidl_grant_name: filename
  //     })
  //     break;

  //     case 'donations':

  //       if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //         this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //         this.receivedFundsFormGroup.patchValue({
  //           file_funds_donations: "",
  //           file_funds_donations_name: ""
  //         })
  //         break;
  //       }

  //       this.receivedFundsFormGroup.patchValue({
  //         file_funds_donations: filestr,
  //         file_funds_donations_name: filename
  //       })
  //       break;



  //     default:
  //       break
  //   }
  // }


  // onFundChange(value: string, type: string) {
  //   switch (type) {
  //     case 'ppp':
  //       this.receivedFundsFormGroup.get('file_funds_ppp').reset();
  //       this.receivedFundsFormGroup.get('received_funds_ppp_amount').setValue('0');
  //       this.receivedFundsFormGroup.get('file_funds_ppp_name').reset();
  //       break;

  //     case 'sba':
  //       this.receivedFundsFormGroup.get('file_funds_sba').reset();
  //       this.receivedFundsFormGroup.get('received_funds_sba_amount').setValue('0');
  //       this.receivedFundsFormGroup.get('file_funds_sba_name').reset();
  //       break;

  //     case 'edc':
  //       this.receivedFundsFormGroup.get('file_funds_edc').reset();
  //       this.receivedFundsFormGroup.get('received_funds_edc_amount').setValue('0');
  //       this.receivedFundsFormGroup.get('file_funds_edc_name').reset();
  //       break;

  //     case 'any':
  //       this.receivedFundsFormGroup.get('file_funds_any').reset();
  //       this.receivedFundsFormGroup.get('received_funds_any_amount').setValue('0');
  //       this.receivedFundsFormGroup.get('file_funds_any_name').reset();
  //       break;

  //     case 'eidl_grant':
  //       this.receivedFundsFormGroup.get('file_funds_eidl_grant').reset();
  //       this.receivedFundsFormGroup.get('received_funds_eidl_grant_amount').setValue('0');
  //       this.receivedFundsFormGroup.get('file_funds_eidl_grant_name').reset();
  //       break;

  //     case 'donations':
  //       this.receivedFundsFormGroup.get('file_funds_donations').reset();
  //       this.receivedFundsFormGroup.get('received_funds_donations_amount').setValue('0');
  //       this.receivedFundsFormGroup.get('file_funds_donations_name').reset();
  //       break;

  //     default:
  //       break;
  //   }
  // }

  // openExpenses(content) {
  //   this.expensesFormGroup = this.appForms.setupExpensesForm(this.expensesTypes, this.expensesData, this.recordId);
  //   this.modelConfig = new PopupModel();
  //   this.modelConfig.settings.windowClass = "xlModal";
  //   this.ngbModal.open(content, this.modelConfig.settings)
  // }

  // onExpensesSubmit() {
  //   if (!this.expensesFormGroup.valid) {
  //     this.common.validateAllFormFields(this.expensesFormGroup);
  //     return;
  //   }

  //   const tableId = this.projectSpecificData.expensesData.TableId;
  //   const expObservables = this.common.getExpensesObservables(this.expensesFormGroup.value, tableId)
  //   this.addUpdateExpenses(expObservables);
  // }

  // async handleExpenseUpload(event: any, index: number) {
  //   const file = event.target.files[0];
  //   if (!file) return;
  //   const { filesize, filestr, filename } = await this.common.getFileData(file);

  //   if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //     this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //     (<FormArray>this
  //       .expensesFormGroup
  //       .get('expenses'))
  //       .at(index)
  //       .patchValue({
  //         document_file: "",
  //         document_name: ""
  //       })
  //     return;
  //   }

  //   (<FormArray>this
  //     .expensesFormGroup
  //     .get('expenses'))
  //     .at(index)
  //     .patchValue({
  //       document_file: filestr,
  //       document_name: filename
  //     })
  // }

  // openExecutiveOrder(content) {
  //   this.executiveOrderFormGroup = this.appForms.setupExecutiveOrderForm(this.applicationData);
  //   this.modelConfig = new PopupModel();
  //   this.ngbModal.open(content, this.modelConfig.settings)
  // }

  // onExecutiveOrderSubmit() {
  //   if (!this.executiveOrderFormGroup.valid) {
  //     this.common.validateAllFormFields(this.executiveOrderFormGroup);
  //     return;
  //   }

  //   const tableId = this.projectSpecificData.applicationsData.TableId;
  //   const recordId = Number(this.recordId);
  //   const updateData = this.common.createExecutiveOrderDocsData(this.executiveOrderFormGroup.value, tableId, recordId)
  //   this.updateApplicationAndDocs(updateData);
  // }

  // async handleExecutiveOrderUpload(event: any) {
  //   const file = event.target.files[0];
  //   if (!file) return;
  //   const { filesize, filestr, filename } = await this.common.getFileData(file);

  //   if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //     this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //     this.executiveOrderFormGroup.patchValue({
  //       file_executive_order: "",
  //       file_executive_order_name: ""
  //     })
  //     return;
  //   }

  //   this.executiveOrderFormGroup.patchValue({
  //     file_executive_order: filestr,
  //     file_executive_order_name: filename
  //   })
  // }

  // async addUpdateExpenses(obs: any) {
  //   try {
  //     this.modelConfig.busy = true;
  //     await forkJoin(obs).toPromise();
  //     await this.getExpensesData(true);
  //     this.toastr.success(Constants.MESSAGES.EXPENSES_UPDATE_SUCCESS, 'Success');
  //     this.ngbModal.dismissAll();
  //   } catch (error) {
  //     this.toastr.error(Constants.MESSAGES.EXPENSES_UPDATE_ERROR, 'Error');
  //   }
  //   finally {
  //     this.modelConfig.busy = false;
  //   }
  // }

  setViewMode = (applicationStatus: string) => {
    this.viewMode = ![
      "Referred",
      "Ineligible",
      "Pre-Eligibility",
      "Eligibility Questions Answered",
      "Open",
      "Registered"
    ].find(mode => mode === applicationStatus);
  }

  setFormState = (form : FormGroup) => {
    if (this.viewMode) form.disable();
    else form.enable();
  }

    /**
   * Upload selected file instantly
   * @param field_name is name of field in which file name is stored
   * @param field_file is name of field in which file is stored
   */
  onSelectFileUpload = async (event: any, field_name: string, field_file: string) => {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"), this.translate.instant("applications.ERROR"));
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i=0; i < filename.split(".").length-2; i++) {
        for (var j=0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".","_");
            j = filename.length;
          }
      }
    }

    this.spinner.show();
    const tableId = this.projectSpecificData.applicationsData.TableId;
    const recordId = Number(this.recordId);
    // const updateData = this.common.createBusinessDetailsDocsData(this.businessDetailsFormGroup.value, tableId, recordId)

    const recordFAD = new FormActionData(0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );
    recordFAD.fieldsList.push(new FieldListItem(
      field_file,
      strFilename,
      filestr)
    );

    this.uploadFile(recordFAD, event);
  }

  private async uploadFile(recordFAD: any, event: any) {
    try {
      this.modelConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.fetchAppData(true);
      this.updateFileCompleted(true);
      event.target.value = null;
    } catch (error) {
      this.updateFileCompleted(false);
    } finally {
      this.spinner.hide();
    }
  }

  private updateFileCompleted(statue: boolean) {
    if (statue)
      this.toastr.success(this.translate.instant("MESSAGES.FILE_UPLOAD_SUCCESS"), this.translate.instant("applications.SUCCESS"));
    else
      this.toastr.error(this.translate.instant("MESSAGES.FILE_UPLOAD_ERROR"), this.translate.instant("applications.ERROR"));
    this.modelConfig.busy = false;
  }

  /**
   * Open property
   */
  openProperty = (content: any, property: any = {}) => {
    if (!Object.keys(property).length) this.MODE = 'ADD';
    else this.MODE = 'EDIT';
    property['related_applications'] = this.recordId;
    this.propertyFormGroup = this.appForms.setupPropertyForm(property);
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);
    // this.setFormState(this.propertyFormGroup);
    this.propertyFormGroup.controls['property_name'].setValidators(Validators.required);
    this.propertyFormGroup.controls['property_type'].setValidators(Validators.required);
    this.propertyFormGroup.controls['property_description'].setValidators(Validators.required);
    this.propertyFormGroup.updateValueAndValidity();
  }

  /**
   * On property submit
   */
  propertySubmit = async (validationBypass?: boolean) => {
    if (!this.propertyFormGroup.valid && !validationBypass) {
      this.common.validateAllFormFields(this.propertyFormGroup);
      return;
    }
    const tableId = this.projectSpecificData.propertiesData.TableId;
    const obs =
      this.common.getPropertyObservable(this.propertyFormGroup.value, tableId, this.MODE);
    try {
      this.modelConfig.busy = true;
      await forkJoin(obs).toPromise();
      await this.fetchAppData(true);
      this.toastr.success(this.translate.instant("MESSAGES.PROPERTY_UPDATE_SUCCESS"), this.translate.instant("applications.SUCCESS"));
      this.ngbModal.dismissAll();
    } catch (error) {
      this.toastr.error(this.translate.instant("MESSAGES.PROPERTY_UPDATE_ERROR"), this.translate.instant("applications.ERROR"));
    }
    finally {
      this.modelConfig.busy = false;
    }
  }

  deleteProperty = () => {
    this.MODE = 'DELETE';
    this.propertySubmit(true);
  }

  selectFile = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"), this.translate.instant("applications.ERROR"));
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i=0; i < filename.split(".").length-2; i++) {
        for (var j=0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".","_");
            j = filename.length;
          }
      }
    }

    this.spinner.show();
    const tableId = this.projectSpecificData.propertiesData.TableId;
    const obs =
      this.common.getPropertyObservable({
        ...this.propertyFormGroup.value,
        file_deed_or_taxes: filestr,
        file_deed_or_taxes_name: strFilename
      }, tableId, this.MODE);
    try {
      this.modelConfig.busy = true;
      await forkJoin(obs).toPromise();
      await this.fetchAppData(true);
      if (this.MODE === 'ADD')
        this.openProperty (this.property, { ...this.propertiesData[this.propertiesData.length -1] });
      else
        this.propertyFormGroup = this.appForms.setupPropertyForm({ ...this.propertiesData.find(record => record.id === this.propertyFormGroup.value.rid) });
      this.toastr.success(this.translate.instant("MESSAGES.PROPERTY_UPDATE_SUCCESS"), this.translate.instant("applications.SUCCESS"));
    } catch (error) {
      this.toastr.error(this.translate.instant("MESSAGES.PROPERTY_UPDATE_ERROR"), this.translate.instant("applications.ERROR"));
    }
    finally {
      this.modelConfig.busy = false;
      this.spinner.hide();
    }
  }

  /**
   * Set mailing same as physical address
   */
  mailingSameAsPhysical = (isChecked: boolean) => {
    let mailingObject = {};
    if (isChecked) {
      for (let field in this.businessDetailsFormGroup.getRawValue()) {
        if (field.includes ('physical_'))
          mailingObject[`mailing_${field.replace ('physical_', '')}`]
            = this.businessDetailsFormGroup.value[field];
        if (field.includes ('mailing_'))
          this.businessDetailsFormGroup.controls[field].disable({emitEvent: false});
      }
    } else {
      mailingObject = { ...this.businessDetailsFormGroup.getRawValue() };
      for (let field in mailingObject) {
        if (field.includes ('mailing_'))
          mailingObject[field] = '';
      }
      this.businessDetailsFormGroup.enable({emitEvent: false});
    }
    this.businessDetailsFormGroup.patchValue(mailingObject, { emitEvent: false });
  }

  deleteFile = async (recordId: number, ...fields: Array<string>) => {
    const tableId = this.projectSpecificData.propertiesData.TableId;
    const recordFAD = new FormActionData(0,
      tableId,
      new Where(recordId),
      new Array<FieldListItem>()
    );

    fields.forEach ((field) => {
        recordFAD.fieldsList.push(new FieldListItem(field, '', ''));
    });

    try {
      this.modelConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.fetchAppData(true);
      this.propertyFormGroup = this.appForms.setupPropertyForm({ ...this.propertiesData.find(record => record.id === recordId) });
      this.toastr.success(this.translate.instant("MESSAGES.FILE_DELETE_SUCCESS"), this.translate.instant("applications.SUCCESS"));
    } catch (error) {
      this.toastr.error(this.translate.instant("MESSAGES.FILE_DELETE_ERROR"), this.translate.instant("applications.ERROR"));
    }
    finally {
      this.modelConfig.busy = false;
    }
  }

  /**
   * Try function for property files
   */
  tryProp = async (args: Array<any>) => {
    await this.fetchAppData(true);
    this.propertyFormGroup = this.appForms.setupPropertyForm({ ...this.propertiesData.find(record => record.id === args[0]) });
  }

  /**
   * Try function for w9
   */
  tryW9 = async () => {
    await this.fetchAppData(true);
  }

  /**
   * Is certified
   */
  isCertified = () => {
    return this.applicationData['certify'] === "True" &&
      this.applicationData['ack'] === "True" &&
      this.applicationData['ack_2'] === "True" &&
      this.applicationData['ack_3'] === "True" &&
      this.applicationData['ack_4'] === "True" &&
      this.applicationData['ack_5'] === "True"; 
  }

  /* create file view url
   */
  getFileRouteLink(url: string): string {
    if (!url) return "";
    const urlSplit = url.split("tables");
    if (urlSplit.length < 1) return "";
    return "application" + urlSplit[1];
  }

  getControl(form:FormGroup, control:string){
    return form.controls[control] as FormControl
  }
}
