<!-------------------------- TOP ROW --------------------------->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <!-- <div>
              <h3 class="card-title text-dfa text-center">
                {{ applicationData.contact_last_name }},
                {{ applicationData.contact_first_name }}
                {{ applicationData.contact_mi }}
              </h3>
            </div> -->

            <!-- <h5 class="card-title text-dfa text-center">
              {{ applicationData.property_name || "-" }}
            </h5>
            <h5 class="card-title text-dfa text-center">
              {{ applicationData.physical_street }}
              {{ applicationData.physical_street_2 }}<br />{{
                applicationData.physical_city
              }}, {{ applicationData.physical_state }}
              {{ applicationData.physical_zip_code }}
            </h5> -->
            <h5 class="card-title text-dfa">
              <!-- <span class="label label-success1"> {{applicationData.tin_type}}</span>
							&nbsp;
							<span *ngIf="applicationData.tin_type === 'EIN'">
								{{applicationData.tin | mask: '00-0000000'}}
							</span>
							<span *ngIf="applicationData.tin_type === 'SSN'">
								###-##-{{applicationData.tin.toString().substring(5,9)}}
							</span> -->
              <p></p>
              <span class="label label-success1">APP ID</span>
              {{ applicationData.id }}
            </h5>
            <button
              routerLink="/application/list"
              class="btn btn-dark float-left big-btn"
            >
              <i class="fa fa-arrow-circle-left">
                {{ "applications.HOME" | translate }}</i
              >
            </button>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-12">
            <h3 class="card-title text-dfa text-center">
              {{ "applications.APPLICATION_COMPLETION_PERCENTAGE" | translate }}
            </h3>
            <h6 class="card-subtitle text-center">
              {{
                "applications.COMPLETE_APPLICATION_BY_ADDING_DETAILS"
                  | translate
              }}
            </h6>
            <circle-progress
              [percent]="completionStatus.total"
              [radius]="40"
              [outerStrokeWidth]="10"
              [innerStrokeWidth]="5"
              [titleFontSize]="15"
              [unitsFontSize]="15"
              [showSubtitle]="false"
              [outerStrokeColor]="'#ffc832'"
              [innerStrokeColor]="'#00b5bf'"
              [animation]="true"
              [animationDuration]="300"
            >
            </circle-progress>
          </div> -->
          <!-- <div class="col-md-4 text-left help-section">
            <label
              ><b>{{ "applications.NEED_HELP" | translate }}</b></label
            >
            <p>{{ "applications.NEED_HELP_TEXT" | translate }}</p>
            <p>{{ "applications.NEED_HELP_LINE_1" | translate }}</p>
            <p>{{ "applications.NEED_HELP_LINE_2" | translate }}</p>
            <app-create-ticket
             
              [template]="addTicket"
            ></app-create-ticket>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="appFormGroup" (ngSubmit)="onApplicationSubmit($event)">
  <!-------------------------- UNIT REQUEST FORM --------------------------->
  <div class="row" *ngIf="isCommitmentLetterIssued() || isUnitRequestFormSubmitted()">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="d-md-fle">
            <div class="row mt-12">
              <div class="col-md-12">
                <h3 class="card-title text-dfa">
                  {{ "DROPDOWNS.UNIT_REQUEST_FORM" | translate }}
                </h3>     
              </div>     
            </div>
            <div class="row mt-12">
              <div class="col-md-12">
                <ul>
                  <li>
                    <h4 class="card-title text-dfa">
                      {{ "applications.EXPENSES_COMMENTS_1" | translate }}
                    </h4>   
                  </li>
                  <li>
                    <h4 class="card-title text-dfa">
                      {{ "applications.EXPENSES_COMMENTS_2" | translate }}
                    </h4>    
                  </li>
                </ul>
              </div>       
            </div>
          </div>

          <div class="row mt-12">
            <div class="col-md-12">
              <hr>
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <h4 class="card-title text-dfa">
                {{ "applications.NEW_LANDLORD_INFORMATION" | translate }}
              </h4> 
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <label>{{ "applications.LANDLORD_CONTACT_NAME" | translate }}<app-required
                [control]="appFormControl.landlord_contact_name"
              ></app-required></label>
              <input
                type="text"
                class="form-control"
                formControlName="landlord_contact_name"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />        
              <small
                *ngIf="
                  appFormControl.landlord_contact_name.touched &&
                  appFormControl.landlord_contact_name.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-6">
              <label>{{ "applications.LANDLORD_CONTACT_PHONE" | translate }}<app-required
                [control]="appFormControl.landlord_phone"
              ></app-required></label>
              <input
                type="tel"
                class="form-control"
                formControlName="landlord_phone"
                mask="(000) 000-0000"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />     
              <small
                *ngIf="
                  appFormControl.landlord_phone.touched &&
                  appFormControl.landlord_phone.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>   
              <small
                *ngIf="
                  appFormControl.landlord_phone.touched &&
                  appFormControl.landlord_phone.errors?.mask
                "
                class="text-danger"
              >
                {{ "errors.INVALID" | translate }}
              </small>   
            </div>
            <div class="col-md-6">
              <label>{{ "applications.LANDLORD_EMAIL_ADDRESS" | translate }}<app-required
                [control]="appFormControl.landlord_email_address"
              ></app-required></label>
              <input
                type="text"
                class="form-control"
                formControlName="landlord_email_address"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />        
              <small
                *ngIf="
                  appFormControl.landlord_email_address.touched &&
                  appFormControl.landlord_email_address.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
              <small
                *ngIf="
                  appFormControl.landlord_email_address.touched &&
                  appFormControl.landlord_email_address.errors?.pattern
                "
                class="text-danger"
              >
                {{ "errors.INVALID_EMAIL" | translate }}
              </small>
            </div>
          </div>

          <div class="row mt-12">
            <div class="col-md-12">
              <hr>
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <h4 class="card-title text-dfa">
                {{ "applications.NEW_ADDRESS_DETAILS" | translate }}
              </h4> 
            </div>
          </div>          
          <div class="row mt-12">
            <div class="col-md-6">
              <label
                >{{ "applications.CURRENT_RENT_ADDRESS_1" | translate
                }}<app-required
                [control]="appFormControl.new_address_1"
                ></app-required
              ></label>
              <input
                type="text"
                class="form-control"
                formControlName="new_address_1"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />
              <small
                *ngIf="
                  appFormControl.new_address_1.touched &&
                  appFormControl.new_address_1.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-6">
              <label
                >{{ "applications.CURRENT_RENT_ADDRESS_2" | translate
                }}
              </label>
              <input
                type="text"
                class="form-control"
                formControlName="new_address_2"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <label
                >{{ "applications.CURRENT_RENT_CITY" | translate
                }}<app-required
                [control]="appFormControl.new_city"
                ></app-required
              ></label>
              <input
                type="text"
                class="form-control"
                formControlName="new_city"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />
              <small
                *ngIf="
                  appFormControl.new_city.touched &&
                  appFormControl.new_city.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-4">
              <label
                >{{ "applications.CURRENT_RENT_STATE" | translate
                }}<app-required
                [control]="appFormControl.new_state"
                ></app-required
              ></label>
              <select 
                class="form-control"
                formControlName="new_state"
                [attr.disabled]="viewMode2 ? 'True' : null"
              >
                <option value="0">
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option *ngFor="let state of stateOptions" [value]="state.value">{{ state.label }}</option>
              </select>
              <small
                *ngIf="
                  appFormControl.new_state.touched &&
                  appFormControl.new_state.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-4">
              <label
                >{{ "applications.CURRENT_RENT_ZIP" | translate
                }}<app-required
                [control]="appFormControl.new_zip"
                ></app-required
              ></label>
              <input
                numbersOnly
                type="text"
                mask="00000"
                class="form-control"
                formControlName="new_zip"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />
              <small
                *ngIf="
                  appFormControl.new_zip.touched &&
                  appFormControl.new_zip.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
              <small
                *ngIf="
                  appFormControl.landlord_phone.touched &&
                  appFormControl.landlord_phone.errors?.maxlength
                "
                class="text-danger"
              >
                {{ "errors.INVALID" | translate }}
              </small> 
            </div>
          </div>

          <div class="row mt-12">
            <div class="col-md-12">
              <hr>
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <h4 class="card-title text-dfa">
                {{ "applications.BEDROOMS" | translate }}
              </h4> 
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <label
                >{{ "applications.BEDROOMS" | translate
                }}
                <app-required
                  [control]="appFormControl['unit_size']"
                ></app-required
              >
            </label>
              <select
                [attr.disabled]="viewMode2 ? 'True' : null"
                class="form-control form-control-sm"
                formControlName="unit_size"
              >
                <option value="0">
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option value="1">
                  {{ "Efficiency" | transform | translate }}
                </option>
                <option value="2">{{ "1 Bedroom" | transform | translate }}</option>
                <option value="3">{{ "2 Bedroom" | transform | translate }}</option>
                <option value="4">{{ "3 Bedroom" | transform | translate }}</option>
                <option value="5">{{ "4 Bedroom" | transform | translate }}</option>
              </select>
              <small
                *ngIf="
                  appFormControl.unit_size.touched &&
                  appFormControl.unit_size.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
          </div>

          <div class="row mt-12">
            <div class="col-md-12">
              <hr>
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <h4 class="card-title text-dfa">
                {{ "applications.RENTAL_OBLIGATION_DETAIL" | translate }}
              </h4> 
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <label
                >{{ "applications.YEAR_RENTAL_UNIT_BUILT" | translate
                }}<app-required
                [control]="appFormControl.year_rental_unit_built"
                ></app-required
              ></label><app-info-pop
              [visible]="true"
              [template]="yearBuildInfo"
            ></app-info-pop>
              <select 
                class="form-control"
                formControlName="year_rental_unit_built"
                [attr.disabled]="viewMode2 ? 'True' : null"
              >
                <option value="0">
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option value="1969">
                  {{ "applications.PRIOR_TO_1970" | translate }}
                </option>
                <option *ngFor="let year of yearRange" [value]="year">{{ year }}</option>
              </select>
              
              <small
                *ngIf="
                  appFormControl.year_rental_unit_built.touched &&
                  appFormControl.year_rental_unit_built.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
          </div> 
          <div class="row mt-12">
            <div class="col-md-6">
              <label
                >{{ "applications.LEASE_START" | translate }}
                <app-required
                [control]="appFormControl.lease_start_date"
                ></app-required>
              </label>
              <!-- [ngClass]="{ 'date-error': exp.hasError('date') }" -->
              <input
                type="date"
                formControlName="lease_start_date"
                class="form-control form-control-sm"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />
              <small
                *ngIf="
                  appFormControl.lease_start_date.touched &&
                  appFormControl.lease_start_date.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-6">
              <label
                >{{ "applications.LEASE_END" | translate
                }}
                  <app-required
                [control]="appFormControl.lease_end_date"
                ></app-required
                >
              </label>
              <input
                type="date"
                formControlName="lease_end_date"
                class="form-control form-control-sm"
                [attr.disabled]="viewMode2 ? 'True' : null"
              />
              <small
                *ngIf="
                  appFormControl.lease_end_date.touched &&
                  appFormControl.lease_end_date.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
          </div> 
          <div class="row mt-12">
            <div class="col-md-6">
              <label
                >{{ "applications.VALIDATE_MONTHLY_RENT" | translate
                }}<app-required
                [control]="appFormControl.monthly_rent_amount"
                ></app-required
              ></label>
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
                <input
                  numbersOnly
                  type="text"
                  formControlName="monthly_rent_amount"
                  maxlength="7"
                  class="form-control form-control-sm"
                  [attr.disabled]="viewMode2 ? 'True' : null"
                  (input)="onInput($event,'monthly_rent_amount')"
                />
              </div>
              <small
                *ngIf="
                  appFormControl.monthly_rent_amount.touched &&
                  appFormControl.monthly_rent_amount.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-6">
              <label
                >{{ "applications.SECURITY_DEPOSIT_AMOUNT" | translate
                }}<app-required
                [control]="appFormControl.security_deposit_amount"
                ></app-required
              ></label>
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
                <input
                  numbersOnly
                  type="text"
                  formControlName="security_deposit_amount"
                  class="form-control form-control-sm"
                  maxlength="7"
                  [attr.disabled]="viewMode2 ? 'True' : null"
                  (input)="onInput($event,'security_deposit_amount')"
                />
              </div>
              <small
                *ngIf="
                  appFormControl.security_deposit_amount.touched &&
                  appFormControl.security_deposit_amount.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
          </div> 



          
          <div class="row mt-12">
            <div class="col-md-12">
              <hr>
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-6">
              <h4 class="card-title text-dfa">
                {{ "applications.LEASE_DOCUMENT" | translate }} 
              </h4> 
              <div *ngIf="!(documents && documents.length)">
                <small
                  class="text-danger">
                  {{ "errors.FILE_REQUIRED" | translate }}
                </small>
              </div>
              <input type="file" (change)="handleFileUpload($event,'')" multiple [attr.disabled]="viewMode2 ? 'True' : null">
            </div>
          </div>
          
          <div *ngIf="documents && documents.length">
            <ng-container  *ngFor="let doc of documents; let i = index">
              <div class="row mt-12">
                <div class="col-md-1">
                </div>
                <div class="col-md-4">
                  <a target="_blank" [href]="getFileRouteLink(doc.file_url)">{{
                    doc.file
                  }}</a>
                </div>
                <div class="col-md-4">
                  <button type="button" [attr.disabled]="viewMode2 ? 'True' : null" class="btn background-danger-third-whitetext btn-sm" (click)="removeFile(doc, i)">
                    <i class="fa fa-trash">
                      {{ "applications.DELETE" | translate }}</i
                    >
                  </button><p></p>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="row mt-12">
            <div class="col-md-12">
              <hr>
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-12">
              <h4 class="card-title text-dfa">
                {{ "DROPDOWNS.CONFIRM_MAILING_ADDRESS_DETAILS" | translate }}
              </h4> 
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-12">
              <h5 class="card-title text-dfa">
                {{ "DROPDOWNS.CONFIRM_MAILING_IMPORTANT" | translate }}
              </h5> 
            </div>
          </div>

          <div class="row mt-12">
            <div class="col-md-12">
              <div class="input-group input-group-sm">
                <div class="col-md-12">
                  <br />
                  <mat-radio-group formControlName="mail_to" (change)="mailConfirmationChanged($event)" [disabled]="viewMode2 ? 'True':null">
                    <mat-radio-button value="Mail to New Residence Address">
                      <strong>{{ "DROPDOWNS.MAILING_NEW" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button class="ml-3" value="Mail to Other">
                      <strong>{{ "DROPDOWNS.MAIL_TO_OTHER" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-12">
            <div class="col-md-6">
              <label
                >{{ "applications.MAILING_STREET" | translate
                }}<app-required
                [control]="appFormControl.mailing_address_1"
                ></app-required
              ></label>
              <input
                type="text"
                class="form-control"
                formControlName="mailing_address_1"
                [attr.disabled]="(viewMode2 || isMailingToNewAddress()) ? 'True' : null"
              />
              <small
                *ngIf="
                  appFormControl.mailing_address_1.touched &&
                  appFormControl.mailing_address_1.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-6">
              <label
                >{{ "applications.MAILING_STREET_2" | translate
                }}
              </label>
              <input
                type="text"
                class="form-control"
                formControlName="mailing_address_2"
                [attr.disabled]="(viewMode2 || isMailingToNewAddress()) ? 'True' : null"
              />
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-4">
              <label
                >{{ "applications.MAILING_CITY" | translate
                }}<app-required
                [control]="appFormControl.mailing_city"
                ></app-required
              ></label>
              <input
                type="text"
                class="form-control"
                formControlName="mailing_city"
                [attr.disabled]="(viewMode2 || isMailingToNewAddress()) ? 'True' : null"
              />
              <small
                *ngIf="
                  appFormControl.mailing_city.touched &&
                  appFormControl.mailing_city.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-4">
              <label
                >{{ "applications.MAILING_STATE" | translate
                }}<app-required
                [control]="appFormControl.mailing_state"
                ></app-required
              ></label>
              <select 
                class="form-control"
                formControlName="mailing_state"
                [attr.disabled]="(viewMode2 || isMailingToNewAddress()) ? 'True' : null"
              >
                <option value="0">
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option *ngFor="let state of stateOptions" [value]="state.value">{{ state.label }}</option>
              </select>
              <small
                *ngIf="
                  appFormControl.mailing_state.touched &&
                  appFormControl.mailing_state.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
            </div>
            <div class="col-md-4">
              <label
                >{{ "applications.MAILING_ZIP_CODE" | translate
                }}<app-required
                [control]="appFormControl.mailing_zip"
                ></app-required
              ></label>
              <input
                numbersOnly
                type="text"
                mask="00000"
                class="form-control"
                formControlName="mailing_zip"
                maxlength="5"
                [attr.disabled]="(viewMode2 || isMailingToNewAddress()) ? 'True' : null"
              />
              <small
                *ngIf="
                  appFormControl.mailing_zip.touched &&
                  appFormControl.mailing_zip.errors?.required
                "
                class="text-danger"
              >
                {{ "errors.REQUIRED" | translate }}
              </small>
              <small
                *ngIf="
                  appFormControl.landlord_phone.touched &&
                  appFormControl.landlord_phone.errors?.maxlength
                "
                class="text-danger"
              >
                {{ "errors.INVALID" | translate }}
              </small> 
            </div>
          </div>



          <div class="row mt-3">
            <button
              name = "Save"
              *ngIf="!viewMode"
              type="save"
              class="btn background-third-whitetext"
            >
              {{ "applications.SAVE_AND_CONTINUE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-------------------------- RECERTIFICATION FORM --------------------------->
  <div class="row" *ngIf="isRecertification() || isRecertificationSubmitted() || isRecertificationApproved()">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="d-md-fle">
            <div class="row mt-12">
              <div class="col-md-12">
                <h3 class="card-title text-dfa">
                  {{ "applications.RECERTIFICATION_FORM" | translate }}
                </h3>     
              </div>     
            </div>
            <div class="row mt-12">
              <div class="col-md-6">
                <br />
                <h4 class="card-title text-dfa">
                  {{ "applications.PROOF_OF_INCOME" | translate }}
                </h4>   
                <label>
                  {{ "applications.PROOF_OF_INCOME_NOTES" | translate }}
                </label> 
                <ng-container *ngFor="let hh of householdAdultData; let i = index;">
                  <div class="row mt-12">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-6">
                      {{i+1}}. {{ hh.first_name }} {{ hh.middle_name }} {{ hh.last_name }}
                    </div>
                    <!-- <div class="col-md-2">
                      {{ hh.date_of_birth | translate }}
                    </div> -->
                  </div>
                </ng-container>   
                <h5>
                  <b>{{ "applications.PROOF_OF_INCOME_NOTES_1" | translate }}</b>
                </h5>
                <ul>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_2" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_3" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_4" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_5" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_6" | translate }}</li>
                </ul>
                <h5>
                  <b>{{ "applications.PROOF_OF_INCOME_NOTES_7" | translate }}</b>
                </h5>
                <ul>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_8" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_9" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_10" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_11" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_12" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_13" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_14" | translate }}</li>
                </ul>
              </div>       
            </div>
            <!-- PROOF OF INCOME DOCUMENTS START -->            
            <div class="row mt-12">
              <div class="col-md-12">
                <h5>
                  <b>{{ "applications.PROOF_OF_INCOME_FILES" | translate }}</b>
                </h5>
                <!-- <div *ngIf="!(recertificationExpenseFormControl?.['proof_of_income_file_1'])">
                  <small
                    class="text-danger">
                    {{ "errors.FILE_REQUIRED" | translate }}
                  </small>
                </div> -->
                <ng-container *ngFor="let fileName of proof_of_income; let i = index">
                  <div class="row mt-12">
                    <div class="col-md-1">
                    </div>
                    <!-- <div class="col-md-1">
                      <label class="mr-2"> {{ fileName.split('_').join(' ') | titlecase}}</label>
                      <label class="mr-2"> {{ i+1 }}.</label>
                    </div> -->
                    <div class="col-md-2">
                      <input type="file" (change)="selectIncomeExpenseFile($event,fileName)" [attr.disabled]="viewMode3 ? 'True' : null">
                    </div>
                    <div class="col-md-4">
                      <div class="d-flex">
                        <div *ngIf="!appExpenseRecertificationData?.[fileName+'_url'] && i === 0">
                          <small
                            class="text-danger">
                            {{ "errors.FILE_REQUIRED" | translate }}
                          </small>
                        </div>
                        <a
                          *ngIf="appExpenseRecertificationData?.[fileName+'_url']"
                          href="{{ getFileRouteLink(appExpenseRecertificationData?.[fileName+'_url']) }}"
                          target="_blank"
                          class="text-success2"
                        >
                          {{ appExpenseRecertificationData?.[fileName] }}
                        </a>
                        <app-file-delete
                          *ngIf="appExpenseRecertificationData?.[fileName+'_url']"
                          [recordId]="appExpenseRecertificationData.id"
                          [tableId]="projectSpecificData.expensesData.TableId"
                          [modalConfig]="modelConfig"
                          [fields]="[fileName, fileName+'_url']"
                          [tryFn]="tryRentalDoc"
                          [disabled]="viewMode3 ? true : false"
                          (fileDeleted)="proofOfExpenseDeleted()"
                        ></app-file-delete>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            
            <div class="row mt-12">
              <div class="col-md-12">
                <hr>
                <br/>
              </div>
            </div>
            
            <div class="row mt-12">
              <div class="col-md-6">
                <h4 class="card-title text-dfa">
                  {{ "applications.PROOF_OF_RENTAL_PAYMENTS" | translate }}
                </h4>   
                <label>
                  {{ "applications.PROOF_OF_RENTAL_PAYMENTS_NOTES" | translate }}
                </label>   
                <h5>
                  <b>{{ "applications.PROOF_OF_INCOME_NOTES_1" | translate }}</b>
                </h5>
                <ul>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_2" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_3" | translate }}</li>
                  <li>{{ "applications.PROOF_OF_INCOME_NOTES_4" | translate }}</li>
                </ul>

              </div>       
            </div>

            <!-- PROOF OF RENTAL PAYMENTS DOCUMENTS START -->
            <div class="row mt-12">
              <div class="col-md-12">
                <h5>
                  <b>{{ "applications.PROOF_OF_RENTAL_PAYMENTS_FILES" | translate }}</b>
                </h5>
                <!-- <div *ngIf="!(recertificationExpenseFormControl?.['proof_of_rental_payment_file_1'])">
                  <small
                    class="text-danger">
                    {{ "errors.FILE_REQUIRED" | translate }}
                  </small>
                </div> -->
                <ng-container *ngFor="let fileName of proof_of_rental; let i = index">
                  
                  <div class="row mt-12">
                    <div class="col-md-1">
                    </div>
                    <!-- <div class="col-md-1">
                      <label class="mr-1"> {{ i+1 }}.</label>
                    </div> -->
                    <div class="col-md-2">
                      <input type="file" (change)="selectIncomeExpenseFile($event,fileName)" [attr.disabled]="viewMode3 ? 'True' : null">
                    </div>
                    <div class="col-md-6">
                      <div *ngIf="!appExpenseRecertificationData?.[fileName+'_url'] && i === 0">
                        <small
                          class="text-danger">
                          {{ "errors.FILE_REQUIRED" | translate }}
                        </small>
                      </div>
                      <div clas="d-flex">
                        <a
                          *ngIf="appExpenseRecertificationData?.[fileName+'_url']"
                          href="{{ getFileRouteLink(appExpenseRecertificationData?.[fileName+'_url']) }}"
                          target="_blank"
                          class="text-success2"
                        >
                          {{ appExpenseRecertificationData?.[fileName] }}
                        </a>
                        <app-file-delete
                          *ngIf="appExpenseRecertificationData?.[fileName+'_url']"
                          [recordId]="appExpenseRecertificationData.id"
                          [tableId]="projectSpecificData.expensesData.TableId"
                          [modalConfig]="modelConfig"
                          [fields]="[fileName, fileName+'_url']"
                          [tryFn]="tryRentalDoc"
                          [disabled]="viewMode3 ? true : false"
                          (fileDeleted)="proofOfExpenseDeleted()"
                        ></app-file-delete>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- PROOF OF RENTAL PAYMENTS DOCUMENTS END -->

            <div class="row mt-12">
              <div class="col-md-12">
                <hr>
                <br/>
              </div>
            </div>

            <div class="row mt-12">
              <div class="col-md-6">
                <h4 class="card-title text-dfa">
                  {{ "applications.RECERTIFICATION_CERTFICATIONS" | translate }}
                </h4>   
                <label>
                  <b>{{ "applications.RECERTIFICATION_CERTFICATIONS_TEXT_1" | translate }}</b>
                </label>    
                <label>
                  {{ "applications.RECERTIFICATION_CERTFICATIONS_TEXT_2" | translate }}
                </label>    
                <label>
                  {{ "applications.RECERTIFICATION_CERTFICATIONS_TEXT_3" | translate }}
                </label>    
                <label>
                  {{ "applications.RECERTIFICATION_CERTFICATIONS_TEXT_4" | translate }}
                </label>    
                <label>
                  {{ "applications.RECERTIFICATION_CERTFICATIONS_TEXT_5" | translate }}
                </label>    
                <label>
                  {{ "applications.RECERTIFICATION_CERTFICATIONS_TEXT_6" | translate }}
                </label>    
              </div>       
            </div>


            <div class="row mt-12">
              <div class="col-md-12">
                <hr>
                <br/>
              </div>
            </div>


            <div class="row mt-12">
              <div class="col-md-12">
                <h4 class="card-title text-dfa">
                  {{ "applications.APPLICANT_STATEMENT" | translate }}
                </h4> 
                <label>
                  {{ "applications.APPLICANT_STATEMENT_TEXT" | translate }}
                </label> 
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-12">
                <small class="text-danger">{{ "applications.APPLICANT_SIGNATURE_3" | translate }}</small>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-6">
                <label>{{ "applications.APPLICANT_SIGNATURE" | translate }}<app-required [mark]="true"></app-required><app-required
                  [control]="appFormControl.recert_applicant_signature"
                ></app-required></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="recert_applicant_signature"
                  (change)="isApplicationValid()"
                  [attr.disabled]="viewMode3 ? 'True' : null"
                />        
                <small
                  *ngIf="
                    appFormControl.recert_applicant_signature.touched &&
                    appFormControl.recert_applicant_signature.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
                
              </div>
              <div class="col-md-4">
                <label>{{ "applications.APPLICANT_SIGNATURE_DATE" | translate }} <app-required [mark]="true"></app-required></label>
                <input
                  type="date"
                  class="form-control"
                  formControlName="recert_applicant_signature_date"
                  [attr.disabled]="viewMode3 ? 'True' : null"
                />        
              </div>
            </div>
          </div>



          <!-- <div class="row mt-3">
            <button
              name = "Save"
              *ngIf="!viewMode3"
              type="save"
              class="btn background-third-whitetext"
            >
              {{ "applications.SAVE_AND_CONTINUE" | translate }}
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-------------------------- BUTTONS --------------------------->
  <div class="row" *ngIf="isCommitmentLetterIssued() || isUnitRequestFormSubmitted() || isRecertification() || isRecertificationSubmitted() || isRecertificationApproved()">
    <div class="col-md-8 btn-responsive">
      <button
        name = "Save"
        *ngIf="!viewMode || !viewMode2 || !viewMode3"
        type="save"
        class="btn background-third-whitetext"
      >
        {{ "applications.SAVE_AND_CONTINUE" | translate }}
      </button>
      <button type="button" routerLink="/application/list"  class="btn btn-dark">
        {{ "applications.HOME" | translate }}
      </button>
      <button
        name = "Submit"
        [disabled]="!isApplicationValid()"
        *ngIf="!viewMode || !viewMode2 || !viewMode3"
        type="submit"
        class="btn btn-success1"
      >
        {{ "applications.SUBMIT" | translate }}
      </button>
      <br />
      <small
        [innerHTML]="'applications.SUBMIT_BTN_INFO_TEXT' | translate"
      ></small>
      <br />
      <div><br /></div>
    </div>
  </div>
<!-------------------------- CONTACT AND ADDRESS INFORMATION --------------------------->
  <div *ngIf="!isCommitmentLetterIssued() && !isUnitRequestFormSubmitted() && !isRecertification() && !isRecertificationSubmitted() && !isRecertificationApproved()">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.CONTACT_DETAILS" | translate }}
                  <!-- <i
                    *ngIf="!completionStatus.ContactStatus"
                    class="fa fa-exclamation-circle text-danger"
                    data-toggle="tooltip"
                    title="Please add the required details"
                    aria-hidden="true"
                  >
                  </i>
                  <i
                    *ngIf="completionStatus.ContactStatus"
                    class="fa fa-check-circle text-success2"
                    data-toggle="tooltip"
                    title="Good"
                    aria-hidden="true"
                  >
                  </i>
                  <app-info-pop
                    [visible]="!completionStatus.ContactStatus"
                  ></app-info-pop> -->
                </h3>          
              </div>
            </div>
          
            <div class="row mt-12">
              <div class="col-md-4">
                <label>{{ "applications.USER_FIRST_NAME" | translate }}<app-required [mark]="true"></app-required><app-required
                  [control]="appFormControl.contact_first_name"
                ></app-required></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="contact_first_name"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
                <small
                  *ngIf="
                    appFormControl.contact_first_name.touched &&
                    appFormControl.contact_first_name.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label>{{ "applications.USER_MI" | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="contact_mi"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
              </div>
              <div class="col-md-4">
                <label>{{ "applications.USER_LAST_NAME" | translate }}<app-required [mark]="true"></app-required><app-required
                  [control]="appFormGroup.controls['contact_last_name']"
                ></app-required></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="contact_last_name"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
                <small
                  *ngIf="
                    appFormControl.contact_last_name.touched &&
                    appFormControl.contact_last_name.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <label
                  >{{ "applications.CONTACT_EMAIL" | translate
                  }}<app-required [mark]="true"></app-required><app-required
                    [control]="appFormGroup.controls['contact_email']"
                  ></app-required
                ></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="contact_email"
                  [attr.disabled]="viewMode ? 'True' : null"
                />
                <small
                  *ngIf="
                    appFormControl.contact_email.touched &&
                    appFormControl.contact_email.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
                <small
                  *ngIf="
                    appFormControl.contact_email.touched &&
                    appFormControl.contact_email.errors?.pattern
                  "
                  class="text-danger"
                >
                  {{ "errors.INVALID_EMAIL" | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label
                  >{{ "applications.MOBILE_PHONE" | translate
                  }}<app-required [mark]="true"></app-required><app-required
                    [control]="appFormGroup.controls['contact_phone']"
                  ></app-required
                ></label>
                <input
                  type="text"
                  mask="(000) 000-0000"
                  class="form-control"
                  formControlName="contact_phone"
                  [attr.disabled]="viewMode ? 'True' : null"
                />
                <small
                  *ngIf="
                    appFormControl.contact_phone.touched &&
                    appFormControl.contact_phone.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-3">
              <button
                name = "Save"
                *ngIf="!viewMode"
                type="save"
                class="btn background-third-whitetext"
              >
                {{ "applications.SAVE_AND_CONTINUE" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-------------------------- HOUSEHOLD INFORMATION --------------------------->
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="d-md-flex align-items-center">
            <div>
              <h3 class="card-title text-dfa">
                {{ "applications.HOUSEHOLD_INFORMATION" | translate }}
              </h3> 
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-12">
              <div class="input-group input-group-sm">
                <label>{{ "applications.FAMILY_SEEKING_YES" | translate }}&nbsp;<app-required [mark]="true"></app-required></label>
              </div>
            </div>
          </div>
          <div class="row mt-12">
            <div class="col-md-1">
              <input
                numbersOnly
                type="text"
                class="form-control"
                formControlName="family_seeking_housing_number"
                [ngStyle]="{'border-color':isHHNumberError() ? 'red':'rgb(134 142 150)'}"
                [attr.disabled]="viewMode ? 'True' : null"
              />     
            </div>
            <div class="col-md-12" *ngIf="isHHNumberZeroError()">
              <small class="text-danger">{{"errors.INVALID_HOUSEHOLD_0" | translate}}</small>
            </div>
          </div>
          <div class="row mt-3">
            <button
              name = "Save"
              *ngIf="!viewMode"
              type="save"
              class="btn background-third-whitetext"
            >
              {{ "applications.SAVE_AND_CONTINUE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-------------------------- HOUSEHOLD INFORMATION AND EXPENSES INFORMATION --------------------------->
  <div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.HOUSEHOLD_DETAILS" | translate }}
              <i
                *ngIf="isHHTopErrorVisible()"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="!isHHTopErrorVisible()"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="isHHTopErrorVisible()"
                [template]="hhDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle">
              {{ "applications.CLICK_ON_ADD_BUTTON_TO_ADD_RECORD" | translate }}
            </h6>
          </div>
          <div class="ml-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                *ngIf="!viewMode && householdData.length < 10"
                (click)="openHouseholdAdd(householdAddTemp)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-plus"> {{ "applications.ADD" | translate }}</i>
              </button>
            </div>
          </div>
        </div>
        
        <div>
          <small class="text-danger" *ngIf="isHHTopErrorVisible()">
            {{ "applications.HH_MEMBERS_DATA_ERROR" | translate }}
          </small>
        </div>
        <div>
          <small class="text-danger" *ngIf="isApplicantAMember()">
            {{ "applications.HH_RELATION_APPLICANT_ERROR" | translate }}
          </small>
        </div>
        <div class="row col-md-12">
          <div class="col-md-12" *ngIf="isHHNumberError()">
            <small class="text-danger">{{"errors.INVALID_HOUSEHOLD" | translate}}</small>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-12">
            <hr class="hr_separator" />
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-12">
            <h6 class="card-subtitle">
              {{
                "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA" | translate
              }}
            </h6>
          </div>
        </div>
        <ng-container *ngFor="let hh of householdData; let i = index; small">
          <div class="row mt-3">
            <div class="col-md-1">
              <i
                *ngIf="hh.status === 'False' || getHHErrorRowMessage(hh)"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="hh.status === 'True' && !getHHErrorRowMessage(hh)"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
            </div>
            <div class="col-md-3">
              {{ hh.first_name }} {{ hh.middle_name }} {{ hh.last_name }}
            </div>
            <div class="col-md-2">
              {{ hh.relation_to_applicant | transform | translate }}
            </div>
            <div class="col-md-2">
              {{ hh.employment_status | transform | translate }}
            </div>
            <div class="col-md-2">
              <div class="ml-auto">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    (click)="openHousehold(householdTemp, i, hh)"
                    type="button"
                    class="btn background-third-whitetext btn-sm"
                  >
                    <i class="fa fa-edit">
                      {{
                        (viewMode ? "applications.VIEW" : "applications.EDIT")
                          | translate
                      }}</i
                    >
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="ml-auto">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    *ngIf="
                      !viewMode 
                    "
                    (click)="openHouseholdDelete(householdDeleteTemp, i, hh)"
                    type="button"
                    class="btn background-danger-third-whitetext btn-sm"
                  >
                  <!-- &&
                      !(
                        hh.household_number === 'Household 0' &&
                        hh.relation_to_applicant === 'Applicant'
                      ) -->

                    <i class="fa fa-trash">
                      {{ "applications.DELETE" | translate }}</i
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
              <small class="text-danger">{{
                getHHErrorRowMessage(hh)
              }}</small>
            </div>
          </div>
        </ng-container>
        <div class="row mt-3">
          <button
            name = "Save"
            *ngIf="!viewMode"
            type="save"
            class="btn background-third-whitetext"
          >
            {{ "applications.SAVE_AND_CONTINUE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>


    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <fieldset [disabled]="viewMode">
              <div class="d-md-flex align-items-center">
                <div>
                  <h3 class="card-title text-dfa">
                    {{ "applications.RELOCATE" | translate }}
                  </h3>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <label
                    >{{ "applications.RELOCATE_1" | translate }}
                  </label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-3">
                  <mat-radio-group formControlName="willing_to_relocate">
                    <mat-radio-button value="True">
                      <strong>{{ "applications.YES" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button class="ml-3" value="False">
                      <strong>{{ "applications.NO" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-md-9" *ngIf="appFormControl.willing_to_relocate.value.toString() === 'True'">
                  <label> {{ "applications.RELOCATE_2" | translate }}</label>
                </div>
              </div>
              <div class="row mt-3" *ngIf="appFormControl.willing_to_relocate.value.toString() === 'True'">
                <div class="col-md-3"></div>
                <div class="col-md-2">
                  <label class="control-label">{{ 'applications.PARISH' | translate }} 1<span class="text-danger"> *</span></label>
                  <select
                    class="form-control"
                    formControlName="preferred_parish_1">
                    <option value="">- {{"applications.SELECT" | translate}} -</option>
                    <option *ngFor="let p of parishes" value="{{p.Value}}">{{p.Name | transform | translate}}
                    </option>
                  </select>
                  <!-- <small
                    *ngIf="appFormControl.willing_to_relocate.value.toString() === 'True'"
                    class="text-danger"
                  >
                    {{ "errors.REQUIRED" | translate }}
                  </small> -->
                </div>
                <div class="col-md-2">
                  <label class="control-label">{{ 'applications.PARISH' | translate }} 2</label>
                  <select
                    class="form-control"
                    formControlName="preferred_parish_2">
                    <option value="">- {{"applications.SELECT" | translate}} -</option>
                    <option *ngFor="let p of parishes" value="{{p.Value}}">{{p.Name | transform | translate}}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label class="control-label">{{ 'applications.PARISH' | translate }} 3</label>
                  <select
                    class="form-control"
                    formControlName="preferred_parish_3">
                    <option value="">- {{"applications.SELECT" | translate}} -</option>
                    <option *ngFor="let p of parishes" value="{{p.Value}}">{{p.Name | transform | translate}}
                    </option>
                  </select>
                </div>
              </div>
            </fieldset>
            <div class="row mt-3">
              <button
                name = "Save"
                *ngIf="!viewMode"
                type="save"
                class="btn background-third-whitetext"
              >
                {{ "applications.SAVE_AND_CONTINUE" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.ADDRESS_DETAILS" | translate }}
                </h3>          
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.STREET' | translate }}<app-required [control]="appFormControl.physical_street"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_street"
                  ngx-google-places-autocomplete [options]="options"
                  (onAddressChange)="addressChangePhysical($event, 'physical_street')"
                  placeholder="{{'applications.PLACEHOLDER_ADDRESS' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.physical_street.touched && appFormControl.physical_street.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.STREET_2' | translate }}<app-required [control]="appFormControl.physical_street_2"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_street_2" placeholder="{{'applications.PLACEHOLDER_UNIT' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.physical_street_2.touched && appFormControl.physical_street_2.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.CITY' | translate }}<app-required [control]="appFormControl.physical_city"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_city" placeholder="{{'applications.PLACEHOLDER_CITY' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.physical_city.touched && appFormControl.physical_city.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.STATE' | translate }}<app-required [control]="appFormControl.physical_state"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_state" placeholder="{{'applications.PLACEHOLDER_STATE' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.physical_state.touched && appFormControl.physical_state.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label class="control-label">{{ 'applications.ZIP_CODE' | translate }}<app-required [control]="appFormControl.physical_zip_code"></app-required></label>
                <input type="text" class="form-control" formControlName="physical_zip_code" placeholder="{{'applications.PLACEHOLDER_ZIP' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.physical_zip_code.touched && appFormControl.physical_zip_code.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>


              <div class="col-md-6">
                <label class="control-label">{{ 'applications.PARISH' | translate }}<app-required [control]="appFormControl.parish"></app-required></label>
                <select
                  class="form-control"
                  formControlName="parish">
                  <option value="">- {{"applications.SELECT" | translate}} -</option>
                  <option *ngFor="let p of parishes" value="{{p.Value}}">{{p.Name | transform | translate}}
                  </option>
                </select>
                <small
                  *ngIf="appFormControl.parish.touched && appFormControl.parish.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-------------------------- EMERGENCY CONTACT INFORMATION --------------------------->
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.EMERGENCY_CONTACT_INFORMATION" | translate }}
                </h3> 
                <h4 class="card-title text-dfa">
                  {{ "applications.EMERGENCY_OPTIONAL" | translate }}
                </h4>          
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-4">
                <label>{{ "applications.EMERGENCY_FIRST_NAME" | translate }}<app-required
                  [control]="appFormControl.emergency_first_name"
                ></app-required></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="emergency_first_name"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
                <small
                  *ngIf="
                    appFormControl.emergency_first_name.touched &&
                    appFormControl.emergency_first_name.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label>{{ "applications.EMERGENCY_MI" | translate }} :</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="emergency_mi"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
              </div>
              <div class="col-md-4">
                <label>{{ "applications.EMERGENCY_LAST_NAME" | translate }}<app-required
                  [control]="appFormControl.emergency_last_name"
                ></app-required></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="emergency_last_name"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
                <small
                  *ngIf="
                    appFormControl.emergency_last_name.touched &&
                    appFormControl.emergency_last_name.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-12">
                <label class="control-label">{{ 'applications.EMERGENCY_RELATIONSHIP' | translate }}<app-required [control]="appFormControl.emergency_relationship"></app-required></label>
                <input type="text" class="form-control" formControlName="emergency_relationship"
                  placeholder="{{'applications.PLACEHOLDER_RELATIONSHIP' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.emergency_relationship.touched && appFormControl.emergency_relationship.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-12">
                <label class="control-label">{{ 'applications.EMERGENCY_STREET' | translate }}<app-required [control]="appFormControl.emergency_street"></app-required></label>
                <input type="text" class="form-control" formControlName="emergency_street"
                  placeholder="{{'applications.PLACEHOLDER_ADDRESS' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.emergency_street.touched && appFormControl.emergency_street.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.EMERGENCY_CITY' | translate }}<app-required [control]="appFormControl.emergency_city"></app-required></label>
                <input type="text" class="form-control" formControlName="emergency_city" placeholder="{{'applications.PLACEHOLDER_CITY' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.emergency_city.touched && appFormControl.emergency_city.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.EMERGENCY_STATE' | translate }}<app-required [control]="appFormControl.emergency_state"></app-required></label>
                <input type="text" class="form-control" formControlName="emergency_state" placeholder="{{'applications.PLACEHOLDER_STATE' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.emergency_state.touched && appFormControl.emergency_state.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
              <div class="col-md-4">
                <label class="control-label">{{ 'applications.EMERGENCY_ZIP_CODE' | translate }}<app-required [control]="appFormControl.emergency_zip_code"></app-required></label>
                <input type="text" class="form-control" formControlName="emergency_zip_code" placeholder="{{'applications.PLACEHOLDER_ZIP' | translate}}" noSpecialChars>
                <small
                  *ngIf="appFormControl.emergency_zip_code.touched && appFormControl.emergency_zip_code.errors?.required"
                  class="text-danger">
                  {{ 'errors.REQUIRED' | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-6">
                <label
                  >{{ "applications.EMERGENCY_HOME" | translate
                  }}<app-required
                    [control]="appFormControl.emergency_home_phone"
                  ></app-required
                ></label>
                <input
                  type="text"
                  mask="(000) 000-0000"
                  class="form-control"
                  formControlName="emergency_home_phone"
                />
                <small
                  *ngIf="
                    appFormControl.emergency_home_phone.touched &&
                    appFormControl.emergency_home_phone.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label
                  >{{ "applications.EMERGENCY_WORK" | translate
                  }}<app-required
                    [control]="appFormControl.emergency_work_phone"
                  ></app-required
                ></label>
                <input
                  type="text"
                  mask="(000) 000-0000"
                  class="form-control"
                  formControlName="emergency_work_phone"
                />
                <small
                  *ngIf="
                    appFormControl.emergency_work_phone.touched &&
                    appFormControl.emergency_work_phone.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-6">
                <label
                  >{{ "applications.EMERGENCY_EMAIL" | translate
                  }}<app-required
                    [control]="appFormControl.emergency_email"
                  ></app-required
                ></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="emergency_email"
                />
                <small
                  *ngIf="
                    appFormControl.emergency_email.touched &&
                    appFormControl.emergency_email.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
                <small
                  *ngIf="
                    appFormControl.emergency_email.touched &&
                    appFormControl.emergency_email.errors?.pattern
                  "
                  class="text-danger"
                >
                  {{ "errors.INVALID_EMAIL" | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label
                  >{{ "applications.EMERGENCY_FAX" | translate
                  }}<app-required
                    [control]="appFormGroup.controls['emergency_fax']"
                  ></app-required
                ></label>
                <input
                  type="text"
                  mask="(000) 000-0000"
                  class="form-control"
                  formControlName="emergency_fax"
                />
                <small
                  *ngIf="
                    appFormControl.emergency_fax.touched &&
                    appFormControl.emergency_fax.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-6">
                <label
                  >{{ "applications.EMERGENCY_MOBILE_PHONE" | translate
                  }}<app-required
                    [control]="appFormControl.emergency_mobile_phone"
                  ></app-required
                ></label>
                <input
                  type="text"
                  mask="(000) 000-0000"
                  class="form-control"
                  formControlName="emergency_mobile_phone"
                />
                <small
                  *ngIf="
                    appFormControl.emergency_mobile_phone.touched &&
                    appFormControl.emergency_mobile_phone.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label
                  >{{ "applications.EMERGENCY_ALT_PHONE" | translate
                  }}<app-required
                    [control]="appFormControl.emergency_alt_phone"
                  ></app-required
                ></label>
                <input
                  type="text"
                  mask="(000) 000-0000"
                  class="form-control"
                  formControlName="emergency_alt_phone"
                />
                <small
                  *ngIf="
                    appFormControl.emergency_alt_phone.touched &&
                    appFormControl.emergency_alt_phone.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-------------------------- HOUSEHOLD ASSET INFORMATION --------------------------->
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.HOUSEHOLD_ASSET_INFORMATION" | translate }}
                </h3> 
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_GIVEAWAY" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <mat-radio-group formControlName="hh_asset_giveaway">
                      <mat-radio-button value="True">
                        <strong>{{ "applications.YES" | translate }}</strong>
                      </mat-radio-button>
                      <mat-radio-button class="ml-3" value="False">
                        <strong>{{ "applications.NO" | translate }}</strong>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <label>{{ "applications.HH_ASSET_GIVEWAY_WHO" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="hh_asset_giveaway_who"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />     
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_GIVEAWAY_EXPLANATION" | translate }}&nbsp;</label>
                  <div class="col-md-8">
                    <textarea
                      type="text"
                      class="form-control"
                      formControlName="hh_asset_giveaway_explanation"
                      [attr.disabled]="viewMode ? 'True' : null"
                    ></textarea>     
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_OWNED_HOME" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <mat-radio-group formControlName="hh_asset_owned_home">
                      <mat-radio-button value="True">
                        <strong>{{ "applications.YES" | translate }}</strong>
                      </mat-radio-button>
                      <mat-radio-button class="ml-3" value="False">
                        <strong>{{ "applications.NO" | translate }}</strong>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_OWNED_HOME_WHO" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="hh_asset_owned_home_who"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />     
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_OWNED_HOME_CURRENTLY" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <mat-radio-group formControlName="hh_asset_owned_home_currently">
                      <mat-radio-button value="True">
                        <strong>{{ "applications.YES" | translate }}</strong>
                      </mat-radio-button>
                      <mat-radio-button class="ml-3" value="False">
                        <strong>{{ "applications.NO" | translate }}</strong>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_OWNED_HOME_DISPOSED" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <input
                      type="date"
                      class="form-control"
                      formControlName="hh_asset_owned_home_disposed"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />     
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_OWN_HOME_RENTED" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <mat-radio-group formControlName="hh_asset_owned_home_rented">
                      <mat-radio-button value="True">
                        <strong>{{ "applications.YES" | translate }}</strong>
                      </mat-radio-button>
                      <mat-radio-button class="ml-3" value="False">
                        <strong>{{ "applications.NO" | translate }}</strong>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_OWN_HOME_VACANT" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <mat-radio-group formControlName="hh_asset_owned_home_vacant">
                      <mat-radio-button value="True">
                        <strong>{{ "applications.YES" | translate }}</strong>
                      </mat-radio-button>
                      <mat-radio-button class="ml-3" value="False">
                        <strong>{{ "applications.NO" | translate }}</strong>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HH_ASSET_OWN_HOME_SELLING" | translate }}&nbsp;</label>
                  <div class="col-md-3">
                    <mat-radio-group formControlName="hh_asset_owned_home_selling">
                      <mat-radio-button value="True">
                        <strong>{{ "applications.YES" | translate }}</strong>
                      </mat-radio-button>
                      <mat-radio-button class="ml-3" value="False">
                        <strong>{{ "applications.NO" | translate }}</strong>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-------------------------- PREFERENCE INFORMATION --------------------------->
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.PREFERENCE" | translate }}
                </h3> 
                <h4 class="card-title text-dfa">
                  {{ "applications.PREFERENCE_TEXT" | translate }}
                </h4>          
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.HOMELESS" | translate }}&nbsp;</label>
                  
                  <div class="d-flex align-items-center">
                      <input
                        class="form-control" 
                        formControlName="homeless"
                        type="checkbox"
                        name="form-control"
                      />
                    </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.ELDERLY" | translate }}&nbsp;</label>
                  
                  <div class="d-flex align-items-center">
                      <input
                        class="form-control" 
                        formControlName="elderly"
                        type="checkbox"
                        name="form-control"
                      />
                    </div>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.DISABLED" | translate }}&nbsp;</label>
                  
                  <div class="d-flex align-items-center">
                      <input
                        class="form-control" 
                        formControlName="disabled"
                        type="checkbox"
                        name="form-control"
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-------------------------- ACCESSIBILITY INFORMATION --------------------------->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <fieldset [disabled]="viewMode">
              <div class="d-md-flex align-items-center">
                <div>
                  <h3 class="card-title text-dfa">
                    {{ "applications.ACCESSIBILITY" | translate }}
                  </h3> 
                </div>
              </div>
              <div class="row mt-12">
                <div class="col-md-12">
                  <label>
                    {{ "applications.ACCESSIBILITY_TEXT" | translate }}
                  </label>  
                </div>
              </div>
              <div class="row mt-12">
                <div class="col-md-3">
                  <mat-radio-group formControlName="accessibility">
                    <mat-radio-button value="True">
                      <strong>{{ "applications.YES" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button class="ml-3" value="False">
                      <strong>{{ "applications.NO" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-md-9" *ngIf="appFormControl.accessibility.value.toString() === 'True'">
                  <div class="input-group input-group-sm">
                    <label>{{ "applications.ACCESSIBILITY_EXPLAIN" | translate }}</label>
                    <app-required
                    [control]="appFormGroup.controls['accessibility_explain']"
                  ></app-required
                >
                  </div>
                </div>
              </div>
              <div class="row mt-3" *ngIf="appFormControl.accessibility.value.toString() === 'True'">
                <div class="col-md-3"></div>
                <div class="col-md-9">
                    <textarea
                      class="form-control" 
                      formControlName="accessibility_explain"
                      rows="3"
                      name="form-control"
                    ></textarea>
                </div>
              </div>
            </fieldset>
            <div class="row mt-3">
              <button
                name = "Save"
                *ngIf="!viewMode"
                type="save"
                class="btn background-third-whitetext"
              >
                {{ "applications.SAVE_AND_CONTINUE" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-------------------------- HOUSING ASSISTANCE INFORMATION --------------------------->
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.HOUSING_ASSISTANCE" | translate }}
                </h3> 
                <h4 class="card-title text-dfa">
                  {{ "applications.HOUSING_ASSISTANCE_TEXT" | translate }}
                </h4>          
              </div>
            </div>
            <table class="table table-sm table-bordered mt-2">
              <thead>
                <tr>
                  <th scope="col">{{ 'applications.SOURCE' | translate }}</th>
                  <th scope="col">{{ 'applications.AMOUNT' | translate }}</th>
                  <th scope="col">{{ 'applications.DATE_RECEIVED' | translate }}</th>
                  <th scope="col">{{ 'applications.REASON' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ 'applications.FEMA' | translate }}
                      <mat-radio-group formControlName="fema">
                        <mat-radio-button value="True">
                          <strong>{{ "applications.YES" | translate }}</strong>
                        </mat-radio-button>
                        <mat-radio-button class="ml-3" value="False">
                          <strong>{{ "applications.NO" | translate }}</strong>
                        </mat-radio-button>
                      </mat-radio-group>
                    <br/>{{ 'applications.FEMA_2' | translate }}
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="fema_amount"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />      
                  </td>
                  <td>
                    <input
                        [ngClass]="{ 'date-error': appFormGroup.hasError('fema_date_received') }"
                        type="date"
                        formControlName="fema_date_received"
                        class="form-control form-control-sm"
                      />
                  </td>
                  <td><textarea
                    class="form-control" 
                    formControlName="fema_reason"
                    rows="3"
                    name="form-control"
                  ></textarea></td>
                </tr>
                <tr>
                  <td>{{ 'applications.SBA' | translate }}
                      <mat-radio-group formControlName="sba">
                        <mat-radio-button value="True">
                          <strong>{{ "applications.YES" | translate }}</strong>
                        </mat-radio-button>
                        <mat-radio-button class="ml-3" value="False">
                          <strong>{{ "applications.NO" | translate }}</strong>
                        </mat-radio-button>
                      </mat-radio-group>
                    <br/>{{ 'applications.SBA_2' | translate }}
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="sba_amount"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />      
                  </td>
                  <td>
                    <input
                        [ngClass]="{ 'date-error': appFormGroup.hasError('sba_date_received') }"
                        type="date"
                        formControlName="sba_date_received"
                        class="form-control form-control-sm"
                      />
                  </td>
                  <td><textarea
                    class="form-control" 
                    formControlName="sba_reason"
                    rows="3"
                    name="form-control"
                  ></textarea></td>
                </tr>
                <tr>
                  <td>{{ 'applications.SECTION8' | translate }}
                      <mat-radio-group formControlName="section_8">
                        <mat-radio-button value="True">
                          <strong>{{ "applications.YES" | translate }}</strong>
                        </mat-radio-button>
                        <mat-radio-button class="ml-3" value="False">
                          <strong>{{ "applications.NO" | translate }}</strong>
                        </mat-radio-button>
                      </mat-radio-group>
                    <br/>{{ 'applications.SECTION8_2' | translate }}
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="section_8_amount"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />      
                  </td>
                  <td>
                    <input
                        [ngClass]="{ 'date-error': appFormGroup.hasError('section_8_date_received') }"
                        type="date"
                        formControlName="section_8_date_received"
                        class="form-control form-control-sm"
                      />
                  </td>
                  <td><textarea
                    class="form-control" 
                    formControlName="section_8_reason"
                    rows="3"
                    name="form-control"
                  ></textarea></td>
                </tr>
                <tr>
                  <td>{{ 'applications.TBRA' | translate }}
                      <mat-radio-group formControlName="tbra">
                        <mat-radio-button value="True">
                          <strong>{{ "applications.YES" | translate }}</strong>
                        </mat-radio-button>
                        <mat-radio-button class="ml-3" value="False">
                          <strong>{{ "applications.NO" | translate }}</strong>
                        </mat-radio-button>
                      </mat-radio-group>
                    <br/>{{ 'applications.TBRA_2' | translate }}
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="tbra_amount"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />      
                  </td>
                  <td>
                    <input
                        [ngClass]="{ 'date-error': appFormGroup.hasError('tbra_date_received') }"
                        type="date"
                        formControlName="tbra_date_received"
                        class="form-control form-control-sm"
                      />
                  </td>
                  <td><textarea
                    class="form-control" 
                    formControlName="tbra_reason"
                    rows="3"
                    name="form-control"
                  ></textarea></td>
                </tr>
                <tr>
                  <td>{{ 'applications.INSURANCE' | translate }}
                      <mat-radio-group formControlName="insurance">
                        <mat-radio-button value="True">
                          <strong>{{ "applications.YES" | translate }}</strong>
                        </mat-radio-button>
                        <mat-radio-button class="ml-3" value="False">
                          <strong>{{ "applications.NO" | translate }}</strong>
                        </mat-radio-button>
                      </mat-radio-group>
                    <br/>{{ 'applications.INSURANCE_2' | translate }}
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="insurance_amount"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />      
                  </td>
                  <td>
                    <input
                        [ngClass]="{ 'date-error': appFormGroup.hasError('insurance_date_received') }"
                        type="date"
                        formControlName="insurance_date_received"
                        class="form-control form-control-sm"
                      />
                  </td>
                  <td><textarea
                    class="form-control" 
                    formControlName="insurance_reason"
                    rows="3"
                    name="form-control"
                  ></textarea></td>
                </tr>
                <tr>
                  <td>{{ 'applications.OTHER' | translate }}
                      <mat-radio-group formControlName="other_assistance">
                        <mat-radio-button value="True">
                          <strong>{{ "applications.YES" | translate }}</strong>
                        </mat-radio-button>
                        <mat-radio-button class="ml-3" value="False">
                          <strong>{{ "applications.NO" | translate }}</strong>
                        </mat-radio-button>
                      </mat-radio-group>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="other_assistance_amount"
                      [attr.disabled]="viewMode ? 'True' : null"
                    />      
                  </td>
                  <td>
                    <input
                        [ngClass]="{ 'date-error': appFormGroup.hasError('other_assistance_date_received') }"
                        type="date"
                        formControlName="other_assistance_date_received"
                        class="form-control form-control-sm"
                      />
                  </td>
                  <td><textarea
                    class="form-control" 
                    formControlName="other_assistance_reason"
                    rows="3"
                    name="form-control"
                  ></textarea></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
    <!-------------------------- CURRENT LIVING SITUATION INFORMATION --------------------------->
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.CURRENT_LIVING_SITUATION" | translate }}
                </h3> 
                <h4 class="card-title text-dfa">
                  {{ "applications.CURRENT_LIVING_SITUATION_TEXT" | translate }}
                </h4>          
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-12">
                <mat-radio-group formControlName="prior_week_living_situation">
                  <div class="row">
                    <div class="col-md-6">
                      <mat-radio-button value="Non-housing">
                        <strong>{{ "applications.CLS_NON_HOUSING" | translate }}</strong>
                      </mat-radio-button>
                    </div>
                    <div class="col-md-6">
                      <mat-radio-button class="ml-3" value="Emergency shelter">
                        <strong>{{ "applications.CLS_SHELTER" | translate }}</strong>
                      </mat-radio-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-radio-button value="Psychiatric facility">
                        <strong>{{ "applications.CLS_FACILITY" | translate }}</strong>
                      </mat-radio-button>
                    </div>
                    <div class="col-md-6">
                      <mat-radio-button class="ml-3" value="Transitional housing for homeless persons">
                        <strong>{{ "applications.CLS_TRANSITIONAL" | translate }}</strong>
                      </mat-radio-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-radio-button value="Substance abuse treatment facility">
                        <strong>{{ "applications.CLS_SUBSTANCE_ABUSE" | translate }}</strong>
                      </mat-radio-button>
                    </div>
                    <div class="col-md-6">
                      <mat-radio-button class="ml-3" value="Hospital">
                        <strong>{{ "applications.CLS_HOSPITAL" | translate }}</strong>
                      </mat-radio-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-radio-button value="Jail/prison">
                        <strong>{{ "applications.CLS_JAIL" | translate }}</strong>
                      </mat-radio-button>
                    </div>
                    <div class="col-md-6">
                      <mat-radio-button class="ml-3" value="Other">
                        <strong>{{ "applications.CLS_OTHER" | translate }}</strong>
                      </mat-radio-button>
                    </div>
                  </div>
                </mat-radio-group>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.CLS_OTHER_SPECIFY" | translate }}&nbsp;</label>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                    <textarea
                      class="form-control" 
                      formControlName="prior_week_living_situation_other"
                      rows="3"
                      name="form-control"
                    ></textarea>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.CLS_EXPLAIN_LIVING" | translate }}&nbsp;</label>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                    <textarea
                      class="form-control" 
                      formControlName="explain_living_situation"
                      rows="3"
                      name="form-control"
                    ></textarea>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.CLS_RECEIVING_ASSISTANCE" | translate }}&nbsp;</label>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                    <textarea
                      class="form-control" 
                      formControlName="receiving_housing_assistance"
                      rows="3"
                      name="form-control"
                    ></textarea>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                  <label>{{ "applications.CLS_DISPLACED_TIME" | translate }}&nbsp;</label>
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-8">
                <div class="input-group input-group-sm">
                    <textarea
                      class="form-control" 
                      formControlName="how_long_displaced"
                      rows="3"
                      name="form-control"
                    ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  
    <!-------------------------- REFERRAL SOURCE INFORMATION --------------------------->
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.REFERRAL_INFORMATION" | translate }}
                </h3> 
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-6">
                <label>{{ "applications.REFERRAL_NAME" | translate }}<app-required
                  [control]="appFormControl.referral_name"
                ></app-required></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="referral_name"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
                <small
                  *ngIf="
                    appFormControl.referral_name.touched &&
                    appFormControl.referral_name.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label>{{ "applications.REFERRAL_AGENCY" | translate }} :</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="referral_agency"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-6">
                <label>{{ "applications.REFERRAL_ADDRESS" | translate }}<app-required
                  [control]="appFormControl.referral_address"
                ></app-required></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="referral_address"
                  [attr.disabled]="viewMode ? 'True' : null"
                />        
                <small
                  *ngIf="
                    appFormControl.referral_address.touched &&
                    appFormControl.referral_address.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
              <div class="col-md-6">
                <label
                  >{{ "applications.REFERRAL_PHONE" | translate
                  }}<app-required
                    [control]="appFormControl.referral_phone"
                  ></app-required
                ></label>
                <input
                  type="text"
                  mask="(000) 000-0000"
                  class="form-control"
                  formControlName="referral_phone"
                />
                <small
                  *ngIf="
                    appFormControl.referral_phone.touched &&
                    appFormControl.referral_phone.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-md-6">
                <label
                  >{{ "applications.REFERRAL_EMAIL" | translate
                  }}<app-required
                    [control]="appFormControl.referral_email"
                  ></app-required
                ></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="referral_email"
                />
                <small
                  *ngIf="
                    appFormControl.referral_email.touched &&
                    appFormControl.referral_email.errors?.required
                  "
                  class="text-danger"
                >
                  {{ "errors.REQUIRED" | translate }}
                </small>
                <small
                  *ngIf="
                    appFormControl.referral_email.touched &&
                    appFormControl.referral_email.errors?.pattern
                  "
                  class="text-danger"
                >
                  {{ "errors.INVALID_EMAIL" | translate }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-------------------------- CERTIFICATIONS INFORMATION --------------------------->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <fieldset [disabled]="viewMode">
              <div class="d-md-flex align-items-center">
                <div>
                  <h3 class="card-title text-dfa">
                    {{ "applications.CERTIFICATIONS" | translate }}
                  </h3> 
                  <!-- <label>
                    {{ "applications.CERTIFICATION_TEXT_2" | translate }}
                  </label>  -->
                </div>
              </div>
              <div class="d-md-flex align-items-center">
                <label><u>{{ "CERTRENTER.CERT1" | translate }}</u></label>
              </div>
              <div class="d-md-flex align-items-center">
                <label>{{ "CERTRENTER.CERT2" | translate }}</label> 
              </div>
              <!-- <div class="d-md-flex align-items-center">
                <ol>
                  <li><label>{{ "CERTRENTER.CERT2a" | translate }}</label></li>
                  <li><label>{{ "CERTRENTER.CERT2b" | translate }}</label></li>
                  <li><label>{{ "CERTRENTER.CERT2c" | translate }}</label></li>
                </ol>
              </div> -->
              <div class="d-md-flex align-items-center">
                <label><u>{{ "CERTRENTER.CERT3" | translate }}</u></label> 
              </div>
              <div class="d-md-flex align-items-center">
                <label>{{ "CERTRENTER.CERT4" | translate }}</label> 
              </div>
              <div class="d-md-flex align-items-center">
                <label>{{ "CERTRENTER.CERT5" | translate }}</label> 
              </div>
              <div class="d-md-flex align-items-center">
                <label>{{ "CERTRENTER.CERT6" | translate }}</label> 
              </div>
              <div class="d-md-flex align-items-center">
                <label>{{ "CERTRENTER.CERT7" | translate }}</label> 
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-md-flex align-items-center">
              <div>
                <h3 class="card-title text-dfa">
                  {{ "applications.ACKNOWLEDGEMENTS" | translate }}
                </h3> 
              </div>
            </div>
            <div class="d-md-flex align-items-center">
              <label>{{ "ACKRENTER.ACK1" | translate }}</label>
            </div>
            <div class="d-md-flex align-items-center">
              <label>{{ "ACKRENTER.ACK2" | translate }}</label> 
            </div>
            <div class="d-md-flex align-items-center">
              <label>{{ "ACKRENTER.ACK3" | translate }}</label> 
            </div>
            <div class="d-md-flex align-items-center">
              <label>{{ "ACKRENTER.ACK4" | translate }}</label> 
            </div>
            <div class="d-md-flex align-items-center">
              <label>{{ "ACKRENTER.ACK5" | translate }}</label> 
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <fieldset [disabled]="viewMode">
              <div class="d-md-flex align-items-center">
                <div>
                  <h3 class="card-title text-dfa">
                    {{ "applications.APPLICANT_STATEMENT" | translate }}
                  </h3> 
                  <label>
                    {{ "applications.APPLICANT_STATEMENT_TEXT" | translate }}
                  </label> 
                </div>
              </div>
              <div class="row mt-12">
                <div class="col-md-12">
                  <small class="text-danger">{{ "applications.APPLICANT_SIGNATURE_2" | translate }}</small>
                </div>
              </div>
              <div class="row mt-12">
                <div class="col-md-4">
                  <label>{{ "applications.APPLICANT_SIGNATURE" | translate }}<app-required [mark]="true"></app-required><app-required
                    [control]="appFormControl.applicant_signature"
                  ></app-required></label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="applicant_signature"
                    (change)="isApplicationValid()"
                    [attr.disabled]="viewMode ? 'True' : null"
                  />        
                  <small
                    *ngIf="
                      appFormControl.applicant_signature.touched &&
                      appFormControl.applicant_signature.errors?.required
                    "
                    class="text-danger"
                  >
                    {{ "errors.REQUIRED" | translate }}
                  </small>
                  
                </div>
                <div class="col-md-4">
                  <label>{{ "applications.APPLICANT_SIGNATURE_DATE" | translate }} <app-required [mark]="true"></app-required></label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="applicant_signature_date"
                    [attr.disabled]="viewMode ? 'True' : null"
                  />        
                </div>
                <div class="col-md-4">
                  <label
                    >{{ "applications.DRIVERS_LICENSE_IMAGE" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <br />
                  <input type="file" (change)="handleDLUpoad($event)" />
                  <br />
                  <span *ngIf="applicationData.file_drivers_license_url" class="text-success2">
                    {{ "applications.UPLOADED" | translate }}
                  <a
                    *ngIf="applicationData.file_drivers_license_url"
                    href="{{
                      getFileRouteLink(applicationData.file_drivers_license_url)
                    }}"
                    target="_blank"
                    class="text-success2"
                  >
                    ({{ applicationData.file_drivers_license }})
                  </a>
                </span>
                  <app-file-delete
                    *ngIf="applicationData.file_drivers_license_url"
                    [recordId]="recordId"
                    [tableId]="projectSpecificData.applicationsData.TableId"
                    [modalConfig]="modelConfig"
                    [fields]="['file_drivers_license', 'file_drivers_license_name']"
                    [tryFn]="tryDriving"
                    [disabled]="viewMode"
                    (fileDeleted)="fileDeleted(acknowledgementsFormGroup,['file_drivers_license', 'file_drivers_license_name'])"
                  ></app-file-delete>
                  <!-- <small
                    *ngIf="!applicationData.file_drivers_license_url"
                    class="text-danger"
                  >
                    {{ "errors.FILE_REQUIRED" | translate }}
                  </small> -->
                </div>
              </div>
              
            </fieldset>
          </div>
        </div>
      </div>
    </div>

    <!-------------------------- BUTTONS --------------------------->
    <div class="row mt-3" *ngIf="!isCommitmentLetterIssued() && !isUnitRequestFormSubmitted()">
      <div class="col-md-8 btn-responsive">
        <button
          name = "Save"
          *ngIf="!viewMode || !viewMode2"
          type="save"
          class="btn background-third-whitetext"
        >
          {{ "applications.SAVE_AND_CONTINUE" | translate }}
        </button>
        <button type="button" routerLink="/application/list"  class="btn btn-dark">
          {{ "applications.HOME" | translate }}
        </button>
        <button
          name = "Submit"
          [disabled]="!isApplicationValid()"
          *ngIf="!viewMode || !viewMode2"
          type="submit"
          class="btn btn-success1"
        >
          {{ "applications.SUBMIT" | translate }}
        </button>
        <br />
        <small
          [innerHTML]="'applications.SUBMIT_BTN_INFO_TEXT' | translate"
        ></small>
      </div>
    </div>
  </div>
</form>






<!-------------------------- LANDLORD AND RENTAL DOCUMENTS INFORMATION --------------------------->
<!-- <div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.LANDLORD_DETAILS" | translate }}
              <i
                *ngIf="!completionStatus.LandlordStatus"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="completionStatus.LandlordStatus"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="!completionStatus.LandlordStatus"
                [template]="LLDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle">
              {{
                "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA"
                  | translate
              }}
            </h6>
          </div>
          <div class="ml-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                (click)="openLandlord(landlord)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-edit">
                  {{
                    (viewMode ? "applications.VIEW" : "applications.EDIT")
                      | translate
                  }}</i
                >
              </button>
            </div>
          </div>
        </div>

        <div class="row mt-12">
          <div class="col-md-4">
            <label>{{ "applications.LANDLORD_NAME" | translate }} :</label>
          </div>
          <div class="col-md-8">
            {{
              applicationData.landlord_first_name +
                " " +
                applicationData.landlord_last_name || "-"
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label>{{ "applications.LANDLORD_EMAIL" | translate }} :</label>
          </div>
          <div class="col-md-8">
            {{ applicationData.landlord_email || "-" }}
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label>{{ "applications.LANDLORD_PHONE" | translate }} :</label>
          </div>
          <div class="col-md-8">
            {{ applicationData.landlord_phone | mask: "(000) 000-0000" || "-" }}
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label>{{ "applications.PROPERTY_NAME" | translate }} :</label>
          </div>
          <div class="col-md-8">
            {{ applicationData.property_name || "-" }}
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label>{{ "applications.PROPERTY_TYPE" | translate }} :</label>
          </div>
          <div class="col-md-8">
            {{ (applicationData.property_type | transform | translate) || "-" }}
          </div>
        </div>

        <div
          class="row mt-2 mb-3"
          *ngIf="
            applicationData.landlord_email &&
            (applicationData.landlord_status === 'Request Sent' ||
              applicationData.landlord_status === 'Request Not Sent')
          "
        >
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <button
              [disabled]="!applicationData.landlord_email"
              type="button"
              (click)="inviteLandlord(applicationData.landlord_email)"
              class="btn btn-success1"
            >
              {{ "applications.LANDLORD_INVITE" | translate }}
            </button>
            <app-required [mark]="true"></app-required>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label>{{ "applications.LANDLORD_STATUS" | translate }} :</label>
          </div>
          <div class="col-md-8">
            {{ applicationData.landlord_status | transform | translate }}
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label
              >{{ "applications.CONFIRMATION_NUMBER" | translate }} :</label
            >
          </div>
          <div class="col-md-8">
            {{ applicationData.confirmation_number || "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.RENTAL_DOCUMENTATION" | translate }}
              <i
                *ngIf="!completionStatus.RentalDocumentsStatus"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="completionStatus.RentalDocumentsStatus"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="!completionStatus.RentalDocumentsStatus"
                [template]="rentalDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle">
              {{
                "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA"
                  | translate
              }}
            </h6>
          </div>
          <div class="ml-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                (click)="openRentalDocuments(rentalDocuments)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-edit">
                  {{
                    (viewMode ? "applications.VIEW" : "applications.EDIT")
                      | translate
                  }}</i
                >
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <label>{{ "applications.LEASE_COMPLETED" | translate }} :</label>
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2': applicationData.file_lease,
                'badge-light-danger text-danger': !applicationData.file_lease
              }"
            >
              <strong>{{
                "applications." +
                  (applicationData.file_lease | bool | uppercase) | translate
              }}</strong>
            </span>
            &nbsp;
            <a
              *ngIf="applicationData.file_lease_url"
              data-toggle="tooltip"
              title="{{ applicationData.file_lease }}"
              href="{{ getFileRouteLink(applicationData.file_lease_url) }}"
              target="_blank"
              class="text-dfa"
            >
              {{ applicationData.file_lease }}
            </a>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <label
              >{{ "applications.PAST_DUE_RENT_COMPLETED" | translate }} :</label
            >
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2':
                  applicationData.file_past_due_rent,
                'badge-light-danger text-danger':
                  !applicationData.file_past_due_rent
              }"
            >
              <strong>{{
                "applications." +
                  (applicationData.file_past_due_rent | bool | uppercase)
                  | translate
              }}</strong>
            </span>
            &nbsp;
            <a
              *ngIf="applicationData.file_past_due_rent_url"
              data-toggle="tooltip"
              title="{{ applicationData.file_past_due_rent }}"
              href="{{
                getFileRouteLink(applicationData.file_past_due_rent_url)
              }}"
              target="_blank"
              class="text-dfa"
            >
              {{ applicationData.file_past_due_rent }}
            </a>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <label>{{ "applications.EVICTION_COMPLETED" | translate }} :</label>
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2':
                  applicationData.file_eviction_notice,
                'badge-light-danger text-danger':
                  !applicationData.file_eviction_notice
              }"
            >
              <strong>{{
                "applications." +
                  (applicationData.file_eviction_notice | bool | uppercase)
                  | translate
              }}</strong>
            </span>
            &nbsp;
            <a
              *ngIf="applicationData.file_eviction_notice_url"
              data-toggle="tooltip"
              title="{{ applicationData.file_eviction_notice }}"
              href="{{
                getFileRouteLink(applicationData.file_eviction_notice_url)
              }}"
              target="_blank"
              class="text-dfa"
            >
              {{ applicationData.file_eviction_notice }}
            </a>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <label
              >{{
                "applications.PAST_DUE_UTILITY_COMPLETED" | translate
              }}
              :</label
            >
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2':
                  applicationData.file_past_due_utility,
                'badge-light-danger text-danger':
                  !applicationData.file_past_due_utility
              }"
            >
              <strong>{{
                "applications." +
                  (applicationData.file_past_due_utility | bool | uppercase)
                  | translate
              }}</strong>
            </span>
            &nbsp;
            <a
              *ngIf="applicationData.file_past_due_utility_url"
              data-toggle="tooltip"
              title="{{ applicationData.file_past_due_utility }}"
              href="{{
                getFileRouteLink(applicationData.file_past_due_utility_url)
              }}"
              class="text-dfa"
              target="_blank"
            >
              {{ applicationData.file_past_due_utility }}
            </a>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <label>{{ "applications.OTHER_COMPLETED" | translate }} :</label>
          </div>
          <div class="col-md-6">
            <span
              class="badge"
              [ngClass]="{
                'badge-light-success text-success2': applicationData.file_other,
                'badge-light-danger text-danger': !applicationData.file_other
              }"
            >
              <strong>{{
                "applications." +
                  (applicationData.file_other | bool | uppercase) | translate
              }}</strong>
            </span>
            &nbsp;
            <a
              *ngIf="applicationData.file_other_url"
              data-toggle="tooltip"
              title="{{ applicationData.file_other }}"
              href="{{ getFileRouteLink(applicationData.file_other_url) }}"
              class="text-dfa"
              target="_blank"
            >
              {{ applicationData.file_other }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<!-- <div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="d-md-flex align-items-center">
          <div>
            <h3 class="card-title text-dfa">
              {{ "applications.EXPENSES" | translate }}
              <i
                *ngIf="!completionStatus.ExpensesStatus"
                class="fa fa-exclamation-circle text-danger"
                data-toggle="tooltip"
                title="Please add the required details"
                aria-hidden="true"
              >
              </i>
              <i
                *ngIf="completionStatus.ExpensesStatus"
                class="fa fa-check-circle text-success2"
                data-toggle="tooltip"
                title="Good"
                aria-hidden="true"
              >
              </i>
              <app-info-pop
                [visible]="!completionStatus.ExpensesStatus"
                [template]="expensesDetailsInfo"
              ></app-info-pop>
            </h3>
            <h6 class="card-subtitle" *ngIf="expensesData.length === 0">
              {{ "applications.CLICK_ON_ADD_BUTTON_TO_ADD_RECORD" | translate }}
            </h6>
          </div>
          <div class="ml-auto" style="vertical-align: top">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                *ngIf="!viewMode"
                (click)="openExpensesAdd(expensesAddTemp)"
                type="button"
                class="btn background-third-whitetext btn-sm"
              >
                <i class="fa fa-plus"> {{ "applications.ADD" | translate }}</i>
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-1 float-right">
            <h6 class="small float-right">
              <em>* </em>
            </h6>
          </div>
          <div class="col-md-11">
            <h6 class="small">
              <em>{{ "applications.EXPENSES_COMMENTS_1" | translate }}</em>
            </h6>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-1 float-right">
            <h6 class="small float-right">
              <em>* </em>
            </h6>
          </div>
          <div class="col-md-11">
            <h6 class="small">
              <em>{{ "applications.EXPENSES_COMMENTS_3" | translate }}</em>
            </h6>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-1 float-right">
            <h6 class="small float-right">
              <em>* </em>
            </h6>
          </div>
          <div class="col-md-11">
            <h6 class="small">
              <em>{{ "applications.EXPENSES_COMMENTS_2" | translate }}</em>
            </h6>
          </div>
        </div>
        <div class="row mt-3 text-danger" *ngIf="!hasExpenseRent()">
          <div class="col-sm-1 float-right">
            <h6 class="small float-right">
              <em>* </em>
            </h6>
          </div>
          <div class="col-md-11">
            <h6 class="small">
              <em>{{ "applications.EXPENSES_COMMENTS_4" | translate }}</em>
            </h6>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-12">
            <hr class="hr_separator" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h5 class="small text-center">
              {{ "applications.AMOUNT_MONTHLY_RENT" | translate }} :
              {{ expenseDisplayData.total_amount_monthly | currency }}
            </h5>
          </div>
          <div class="col-md-4">
            <h5 class="small text-center">
              {{ "applications.MONTHS_PAST_DUE" | translate }} :
              {{ expenseDisplayData.total_months_past_due }}
            </h5>
          </div>
          <div class="col-md-4">
            <h5 class="small text-center">
              {{ "applications.AMOUNT_PAST_DUE" | translate }} :
              {{ expenseDisplayData.total_amount_past_due | currency }}
            </h5>
          </div>
        </div>
        <div class="row col-md-12">
          <div class="col-md-12">
            <hr class="hr_separator" />
          </div>
        </div>
        <h6 class="card-subtitle" *ngIf="expensesData.length > 0">
          {{
            "applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA" | translate
          }}
        </h6>
        <div class="row">
          <div class="col-md">Expense</div>
          <div class="col-md">Requested Amt</div>
          <div class="col-md">Final Amt</div>
          <div class="col-md">Status</div>
          <div class="col-md">Edit</div>
        </div>
        <div class="row mt-3" *ngFor="let exp of expensesData; let i = index">
          <div class="col-md">
            {{ exp.expense_type | transform | translate }}
          </div>
          <div *ngIf="exp.expense_type === 'Rent'" class="col-md">
            {{ exp.amount_monthly | currency }} / month
          </div>
          <div *ngIf="exp.expense_type !== 'Rent'" class="col-md">
            {{ exp.amount_past_due | currency }}
          </div>
          <div class="col-md">
            {{
              exp.expense_type === "Rent"
                ? "-"
                : (exp.final_utility_amount | currency)
            }}
          </div>
          <div class="col-md">
            {{ exp.expense_type === "Rent" ? "-" : exp.utility_status || "-" }}
          </div>
          <div class="col-md">
            <div class="ml-auto">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button
                  (click)="openExpenses(expensesTemp, exp.id)"
                  type="button"
                  class="btn background-third-whitetext btn-sm"
                >
                  <i class="fa fa-edit">
                    {{
                      (viewMode ? "applications.VIEW" : "applications.EDIT")
                        | translate
                    }}</i
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="row">
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<div class="d-md-flex align-items-center">
					<div>
						<h3 class="card-title text-dfa">{{ 'applications.BUSINESS_IDENTIFICATION' | translate }}
							<i
								*ngIf="!completionStatus.BusinessContactStatus"
								class="fa fa-exclamation-circle text-danger"
								data-toggle="tooltip"
								title="Please add the required details"
								aria-hidden="true">
							</i>
							<i
								*ngIf="completionStatus.BusinessContactStatus"
								class="fa fa-check-circle text-success2"
								data-toggle="tooltip"
								title="Good"
								aria-hidden="true">
							</i>
						</h3>
						<h6 class="card-subtitle">
							{{ 'applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA' | translate }}</h6>
					</div>
					<div class="ml-auto">
						<div class="btn-group" role="group" aria-label="Basic example">
							<button
								(click)="openBusinessContacts(businessContacts)"
								type="button"
								class="btn background-third-whitetext btn-sm">
								<i class="fa fa-edit"> {{ 'applications.EDIT' | translate }}</i>
							</button>
						</div>
					</div>
				</div>
				<div class="mt-3">
					<div class="row">
						<div class="col-md-8">
							<label>{{ 'applications.HEADQUARTERS' | translate }} :</label>
						</div>
						<div class="col-md-4">
							<span
								class="badge"
								[ngClass]="{
									'badge-light-success text-success2': applicationData.headquarters_in_arkansas === 'True',
									'badge-light-danger text-danger': applicationData.headquarters_in_arkansas === 'False'}">
								<strong>
									{{applicationData.headquarters_in_arkansas ? (applicationData.headquarters_in_arkansas | bool) : '-' }}
								</strong>
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col-md-8">
							<label>{{ 'applications.BUSINESS_STARTED_DATE' | translate }} :</label>
						</div>
						<div class="col-md-4">{{applicationData.date_business_started || '-'}}</div>
					</div>

				</div>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<div class="d-md-flex align-items-center">
					<div>
						<h3 class="card-title text-dfa">{{ 'applications.BUSINESS_DETAILS' | translate }}
							<i
								*ngIf="!completionStatus.BusinessDetailsStatus"
								class="fa fa-exclamation-circle text-danger"
								data-toggle="tooltip"
								title="Please add the required details"
								aria-hidden="true">
							</i>
							<i
								*ngIf="completionStatus.BusinessDetailsStatus"
								class="fa fa-check-circle text-success2"
								data-toggle="tooltip"
								title="Good"
								aria-hidden="true">
							</i>
						</h3>
						<h6 class="card-subtitle">
							{{ 'applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA' | translate }}</h6>
					</div>
					<div class="ml-auto">
						<div class="btn-group" role="group" aria-label="Basic example">
							<button
								(click)="openBusinessDetails(businessDetails)"
								type="button"
								class="btn background-third-whitetext btn-sm">
								<i class="fa fa-edit"> {{ 'applications.EDIT' | translate }}</i>
							</button>
						</div>
					</div>
				</div>

				<div class="mt-3">
					<div class="row">
						<div class="col-md-8">
							<label>{{ 'applications.NUMBER_OF_EMPLOYEES' | translate }} :</label>
						</div>
						<div class="col-md-4">{{applicationData.number_of_employees || '-'}}</div>
					</div>

					<div class="row">
						<div class="col-md-8">
							<label>{{ 'applications.COLLECT_SALES_TAX' | translate }} :</label>
						</div>
						<div class="col-md-4">
							<span
								class="badge"
								[ngClass]="{
							'badge-light-success text-success2': applicationData.collect_sales_tax === 'True',
							'badge-light-danger text-danger': applicationData.collect_sales_tax === 'False'}">
								<strong>
									{{applicationData.collect_sales_tax ? (applicationData.collect_sales_tax | bool): '-'}}
								</strong>
							</span>
						</div>
					</div>

					<div class="row" *ngIf="applicationData.collect_sales_tax === 'True'">
						<div class="col-md-8">
							<label>{{ 'applications.SALES_TAX_ID' | translate }} :</label>
						</div>
						<div class="col-md-4">
							{{applicationData.sales_tax_id || '-'}}
						</div>
					</div>


					<div class="row">
						<div class="col-md-8">
							<label>{{ 'applications.ARE_YOU_REGISTERED_WITH' | translate }} :</label>
						</div>
						<div class="col-md-4">
							<span
								class="badge"
								[ngClass]="{
							'badge-light-success text-success2': applicationData.registered_with_sos === 'True',
							'badge-light-danger text-danger': applicationData.registered_with_sos === 'False'}">
								<strong>
									{{applicationData.registered_with_sos ? (applicationData.registered_with_sos | bool) : '-'}}
								</strong>
							</span>
						</div>
					</div>

					<div class="row" *ngIf="applicationData.registered_with_sos === 'True'">
						<div class="col-md-8">
							<label>{{ 'applications.SECRETARY_OF_STATE_FILING' | translate }} :</label>
						</div>
						<div class="col-md-4">
							{{applicationData.filing_number || '-'}}
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div> -->

<!-- <div class="row">
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<div class="d-md-flex align-items-center">
					<div>
						<h3 class="card-title text-dfa">
							{{ 'applications.MINORITY_OR_WOMEN_OWNED_BUSINESS' | translate }}
							<i
								*ngIf="!completionStatus.MinorityOrWomenStatus"
								class="fa fa-exclamation-circle text-danger"
								data-toggle="tooltip"
								title="Please add the required details"
								aria-hidden="true">
							</i>
							<i
								*ngIf="completionStatus.MinorityOrWomenStatus"
								class="fa fa-check-circle text-success2"
								data-toggle="tooltip"
								title="Good"
								aria-hidden="true">
							</i>
						</h3>
						<h6 class="card-subtitle">
							{{ 'applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA' | translate }}</h6>
					</div>
					<div class="ml-auto">
						<div class="btn-group" role="group" aria-label="Basic example">
							<button
								(click)="openMinorityOrWomen(minorityWomen)"
								type="button"
								class="btn background-third-whitetext btn-sm">
								<i class="fa fa-edit"> {{ 'applications.EDIT' | translate }}</i>
							</button>
						</div>
					</div>
				</div>
				<div class="row mt-3">
					<div class="col-md-8">
						<label>{{ 'applications.MINORITY_OR_WOMEN_OWNED_BUSINESS' | translate }}</label></div>
					<div class="col-md-4">
						<span
							class="badge"
							[ngClass]="{
								'badge-light-success text-success2': applicationData.minority_or_women_owned === 'True',
								'badge-light-danger text-danger': applicationData.minority_or_women_owned === 'False'}">
							<strong>
								{{applicationData.minority_or_women_owned ? (applicationData.minority_or_women_owned | bool): "Not answered"}}
							</strong>
						</span>
					</div>
				</div>
				<div *ngIf="applicationData.minority_or_women_owned === 'True'" class="row">
					<div class="col-md-8"><label>{{ 'applications.REGISTERED' | translate }}</label></div>
					<div class="col-md-4">
						<span
							class="badge"
							[ngClass]="{
								'badge-light-success text-success2': applicationData.minority_or_women_owned_registered === 'True',
								'badge-light-danger text-danger': applicationData.minority_or_women_owned_registered === 'False'}">
							<strong>
								{{applicationData.minority_or_women_owned_registered ? (applicationData.minority_or_women_owned_registered | bool): '-'}}
							</strong>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<div class="d-md-flex align-items-center">
					<div>
						<h3 class="card-title text-dfa">
							{{ 'applications.RECEIVED_FUNDS' | translate }}
							<i
								*ngIf="!completionStatus.ReceivedFundsStatus"
								class="fa fa-exclamation-circle text-danger"
								data-toggle="tooltip"
								title="Please add the required details"
								aria-hidden="true">
							</i>
							<i
								*ngIf="completionStatus.ReceivedFundsStatus"
								class="fa fa-check-circle text-success2"
								data-toggle="tooltip"
								title="Good"
								aria-hidden="true">
							</i>
						</h3>
						<h6 class="card-subtitle">
							{{ 'applications.CLICK_ON_EDIT_BUTTON_TO_MANAGE_INPUT_DATA' | translate }}</h6>
					</div>
					<div class="ml-auto">
						<div class="btn-group" role="group" aria-label="Basic example">
							<button
								(click)="openReceivedFunds(fundsTemp)"
								type="button"
								class="btn background-third-whitetext btn-sm">
								<i class="fa fa-edit"> {{ 'applications.EDIT' | translate }}</i>
							</button>
						</div>
					</div>
				</div>
				<div class="row mt-3">
					<div class="col-md-8">
						<label>
							{{ 'applications.HAVE_YOU_RECEIVED_ANY_EMERGENCY_AID' | translate }}
						</label>
					</div>
					<div class="col-md-4">
						<span
							class="badge"
							[ngClass]="{
							'badge-light-success text-success2': applicationData.received_funds === 'True',
							'badge-light-danger text-danger': applicationData.received_funds === 'False'}">
							<strong>
								{{applicationData.received_funds ? (applicationData.received_funds | bool): '-'}}
							</strong>
						</span>
					</div>
				</div>

				<table class="table table-sm table-bordered mt-2">
					<thead>
						<tr>
							<th scope="col">{{ 'applications.TYPE' | translate }}</th>
							<th scope="col">{{ 'applications.AMOUNT' | translate }}</th>
							<th scope="col">{{ 'applications.FILE' | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngIf="applicationData.received_funds_ppp === 'True'">
							<td>{{ 'applications.RECEIVED_FUNDS_PPP' | translate }}</td>
							<td>{{applicationData.received_funds_ppp_amount | currency}}</td>
							<td>
								<a
									*ngIf="applicationData.file_funds_ppp_url"
									href="{{applicationData.file_funds_ppp_url}}"
									class="fa fa-file text-dfa">
								</a>
							</td>
						</tr>
						<tr *ngIf="applicationData.received_funds_sba === 'True'">
							<td>{{ 'applications.RECEIVED_FUNDS_SBA' | translate }}</td>
							<td>{{applicationData.received_funds_sba_amount | currency}}</td>
							<td>
								<a
									*ngIf="applicationData.file_funds_sba_url"
									href="{{applicationData.file_funds_sba_url}}"
									class="fa fa-file text-dfa">
								</a>
							</td>
						</tr>
						<tr *ngIf="applicationData.received_funds_eidl_grant === 'True'">
							<td>{{ 'applications.RECEIVED_FUNDS_SBA_GRANT' | translate }}</td>
							<td>{{applicationData.received_funds_eidl_grant_amount | currency}}</td>
							<td>
								<a
									*ngIf="applicationData.file_funds_eidl_grant_url"
									href="{{applicationData.file_funds_eidl_grant_url}}"
									class="fa fa-file text-dfa">
								</a>
							</td>
						</tr>
						<tr *ngIf="applicationData.received_funds_edc === 'True'">
							<td>{{ 'applications.RECEIVED_FUNDS_ARDC' | translate }}</td>
							<td>{{applicationData.received_funds_edc_amount | currency}}</td>
							<td>
								<a
									*ngIf="applicationData.file_funds_edc_url"
									href="{{applicationData.file_funds_edc_url}}"
									class="fa fa-file text-dfa">
								</a>
							</td>
						</tr>
						<tr *ngIf="applicationData.received_funds_any === 'True'">
							<td>{{ 'applications.RECEIVED_FUNDS_ANY_GRANT' | translate }}</td>
							<td>{{applicationData.received_funds_any_amount | currency}}</td>
							<td>
								<a
									*ngIf="applicationData.file_funds_any_url"
									href="{{applicationData.file_funds_any_url}}"
									class="fa fa-file text-dfa">
								</a>
							</td>
						</tr>
						<tr *ngIf="applicationData.received_funds_donations === 'True'">
							<td>{{ 'applications.RECEIVED_FUNDS_DONATIONS' | translate }}</td>
							<td>{{applicationData.received_funds_donations_amount | currency}}</td>
							<td>
								<a
									*ngIf="applicationData.file_funds_donations_url"
									href="{{applicationData.file_funds_donations_url}}"
									class="fa fa-file text-dfa">
								</a>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
		</div>
	</div>


</div> -->

<!-- ================================================================================================ -->
<!-------------------------------------------- Popups Start here---------------------------------------->
<!-- ================================================================================================ -->

<!------------------------ Expenses start ------------------------------------>
<ng-template #expensesTemp let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.UPDATE_EXPENSES" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }} and
      {{
        "applications.SELECTED_EXPENSES_MUST_HAVE_AMOUNT_AND_DOC" | translate
      }}
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="expensesFormGroup" (ngSubmit)="onExpensesSubmit()">
      <fieldset [disabled]="viewMode">
        <div formArrayName="expenses">
          <div
            *ngFor="
              let exp of expensesFormGroup.get('expenses').controls;
              let i = index
            "
            [formGroupName]="i"
          >
            <div>
              <label
                ><h3>
                  {{ "applications.EXPENSE_TYPE" | translate }}:
                  {{ exp.value["expense_type"] }}
                </h3></label
              >
            </div>
            <ng-container *ngIf="i === 0 && exp.value.expense_type === 'Rent'">
              <div class="row">
                <div class="col-lg-2">
                  <label
                    >{{ "applications.AMOUNT_MONTHLY_RENT" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      numbersOnly
                      type="text"
                      formControlName="amount_monthly"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-lg">
                  <label
                    >{{ "applications.LEASE_START" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <input
                      [ngClass]="{ 'date-error': exp.hasError('date') }"
                      type="date"
                      formControlName="lease_start_date"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-lg">
                  <label
                    >{{ "applications.LEASE_END" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <input
                      [ngClass]="{ 'date-error': exp.hasError('date') }"
                      type="date"
                      formControlName="lease_end_date"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <label
                    >{{ "applications.TYPE_OF_ASSISTANCE" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <select class="form-control" formControlName="in_arrears">
                    <option value="" selected>
                      - {{ "applications.SELECT" | translate }} -
                    </option>
                    <option
                      *ngFor="let optn of inArrearsOptions"
                      value="{{ optn.Value }}"
                    >
                      {{ optn.Name | transform | translate }}
                    </option>
                  </select>
                </div>
                <div class="col-lg">
                  <label>{{
                    "applications.MONTHS_PAST_DUE" | translate
                  }}</label>
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      disabled
                      formControlName="months_in_arrears"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-lg">
                  <label>{{ "applications.TOTAL_PAST_DUE" | translate }}</label>
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      type="text"
                      disabled
                      formControlName="total_arrears"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <label style="margin-left: 10px">{{
                  "applications.previously_received_funds" | translate
                }}</label>
                <div class="col-md-3">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                      <input
                        numbersOnly
                        type="text"
                        formControlName="previously_received_funds"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  exp.value['in_arrears'] ===
                  'Past-Due Rental and Rental Advance'
                "
              >
                <h4>
                  {{ "applications.ITEMIZED_HEADER" | translate }}
                  <app-required [mark]="true"></app-required>
                </h4>
              </div>
              <div
                *ngIf="
                  exp.value['in_arrears'] ===
                  'Past-Due Rental and Rental Advance'
                "
              >
                <td colspan="1"></td>
                <h5>{{ "applications.ITEMIZED_COMMENT" | translate }}</h5>
              </div>
              <div
                *ngIf="
                  exp.value['in_arrears'] ===
                  'Past-Due Rental and Rental Advance'
                "
              >
                <h5>
                  <span style="color: red" *ngIf="isPastDue(exp)">{{
                    "applications.monthly_amount_error" | translate
                  }}</span>
                </h5>
              </div>
              <div
                *ngIf="
                  exp.value['in_arrears'] ===
                  'Past-Due Rental and Rental Advance'
                "
              >
                <h5>
                  <span style="color: red" *ngIf="dueAmount0">{{
                    "applications.DUE_AMOUNT_0_ERROR" | translate
                  }}</span>
                </h5>
              </div>
              <div
                class="border-div"
                *ngIf="
                  exp.value['in_arrears'] ===
                  'Past-Due Rental and Rental Advance'
                "
              >
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.MONTH" | translate }}</label>
                  </div>
                  <div class="col">
                    <label>{{
                      "applications.PAST_DUE_AMOUNT" | translate
                    }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.APRIL_2020" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_april_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_april_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.MAY_2020" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_may_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_may_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JUNE_2020" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_june_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_june_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JULY_2020" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_july_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_july_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.AUGUST_2020" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_august_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_august_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{
                      "applications.SEPTEMBER_2020" | translate
                    }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_september_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_september_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.OCTOBER_2020" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_october_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_october_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{
                      "applications.NOVEMBER_2020" | translate
                    }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_november_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_november_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{
                      "applications.DECEMBER_2020" | translate
                    }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_december_2020',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_december_2020"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JANUARY_2021" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_january_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_january_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{
                      "applications.FEBRUARY_2021" | translate
                    }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_february_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_february_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.MARCH_2021" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_march_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_march_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.APRIL_2021" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_april_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_april_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.MAY_2021" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_may_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_may_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JUNE_2021" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_june_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_june_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JULY_2021" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_july_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_july_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.AUGUST_2021" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_august_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_august_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{
                      "applications.SEPTEMBER_2021" | translate
                    }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_september_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_september_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.OCTOBER_2021" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_october_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_october_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{
                      "applications.NOVEMBER_2021" | translate
                    }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_november_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_november_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{
                      "applications.DECEMBER_2021" | translate
                    }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_december_2021',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_december_2021"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JANUARY_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_january_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_january_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{
                      "applications.FEBRUARY_2022" | translate
                    }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_february_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_february_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.MARCH_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_march_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_march_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.APRIL_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_april_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_april_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.MAY_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_may_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_may_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JUNE_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_june_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_june_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JULY_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_july_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_july_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.AUGUST_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_august_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_august_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.SEPTEMBER_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_september_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_september_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.OCTOBER_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_october_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_october_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.NOVEMBER_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_november_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_november_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.DECEMBER_2022" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_december_2022',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_december_2022"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.JANUARY_2023" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_january_2023',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_january_2023"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div> 

                <div class="row">
                  <div class="col">
                    <label>{{ "applications.FEBRUARY_2023" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_february_2023',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_february_2023"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div> 
                
                <!-- <div class="row">
                  <div class="col">
                    <label>{{ "applications.MARCH_2023" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_march_2023',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_march_2023"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div> 
                <div class="row">
                  <div class="col">
                    <label>{{ "applications.APRIL_2023" | translate }}</label>
                  </div>
                  <div class="col">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        [ngStyle]="{
                          'border-color': getFieldState(
                            'past_due_amount_april_2023',
                            exp
                          )
                        }"
                        numbersOnly
                        type="text"
                        formControlName="past_due_amount_april_2023"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>  -->
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                i === 0 &&
                exp.value.expense_type &&
                exp.value.expense_type !== 'Rent'
              "
            >
              <div class="row">
                <ng-container
                  *ngIf="exp.value.expense_type === 'Utility - Electricity'"
                >
                  <div class="col-lg">
                    <label
                      >{{ "applications.UTILITY_PROVIDER" | translate }}
                      <app-required [mark]="true"></app-required
                    ></label>
                    <div class="input-group input-group-sm">
                      <select class="form-control" formControlName="provider">
                        <option value="">
                          - {{ "applications.SELECT" | translate }} -
                        </option>
                        <option
                          *ngFor="let p of utilityProvider"
                          value="{{ p.Value }}"
                        >
                          {{ p.Name | transform | translate }}
                        </option>
                      </select>
                      <input
                        *ngIf="exp.value.provider === 'Other'"
                        type="text"
                        formControlName="electricity_company_other"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </ng-container>
                <div
                  class="col-lg"
                  *ngIf="exp.value.expense_type !== 'Utility - Electricity'"
                >
                  <label
                    >{{ "applications.UTILITY_PROVIDER" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      formControlName="provider"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-lg">
                  <label
                    >{{ "applications.NAME_ON_ACCOUNT" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      formControlName="name_on_account"
                      class="form-control form-control-sm"
                    />
                    <div class="d-flex align-items-center">
                      <input
                        formControlName="name_on_account_hh_member"
                        type="checkbox"
                        name="form-control"
                      />
                      <small class="ml-1">{{
                        "applications.IS_ACC_HOLDER_MEMBER" | translate
                      }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg">
                  <label
                    >{{ "applications.ACCOUNT_NUMBER" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      formControlName="account_number"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-lg">
                  <label
                    >{{ "applications.AMOUNT_PAST_DUE" | translate }}
                    <app-required
                      [control]="exp.controls.amount_past_due"
                    ></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <input
                      [attr.disabled]="
                        exp.value.expense_type === 'Utility - Electricity' &&
                        exp.value.provider === 'Entergy'
                          ? true
                          : null
                      "
                      type="number"
                      formControlName="amount_past_due"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-lg">
                  <label
                    >{{ "applications.PAST_DUE_UTILITY_NOTICE" | translate }}
                    <app-required
                      [control]="exp.controls.document_file"
                    ></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <input
                      (change)="selectExpenseFile($event, exp)"
                      type="file"
                    />
                    <a
                      target="_blank"
                      [href]="getFileRouteLink(exp.value.document_file_url)"
                      >{{ exp.value.document_name_old }}</a
                    >
                    <!-- <app-file-delete
											[recordId]="exp.value.id"
											[tableId]="projectSpecificData.expensesData.TableId"
											[modalConfig]="modelConfig"
											[fields]="['document_name', 'document_url']"
											[tryFn]="tryHH"
											[disabled]="viewMode"
										></app-file-delete> -->
                    <small class="ml-1">{{
                      "applications.EXAMPLE_OF_DOCS_COMM" | translate
                    }}</small>
                  </div>
                </div>
              </div>
              <label class="mt-2"
                >{{ "applications.NO_MONTH_APPLY_FOR_PAST_DUE" | translate }}
                <app-required [mark]="true"></app-required
              ></label>
              <div class="row">
                <div class="col-4">
                  <div class="input-group input-group-sm col-4">
                    <input
                      min="0"
                      max="99"
                      type="number"
                      formControlName="utility_months_in_arrears"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </fieldset>

      <div class="row mt-3">
        <div class="col-md-8 btn-responsive">
          <button
            [disabled]="
              (expensesFormGroup.controls['expenses'].get('0').value
                .expense_type === 'Rent' &&
                (isPastDueValid ||
                  expensesFormGroup.controls['expenses']
                    .get('0')
                    .hasError('date') ||
                  expensesFormGroup.controls['expenses'].get('0').invalid)) ||
              (expensesFormGroup.controls['expenses'].get('0').value
                .expense_type !== 'Rent' &&
                expensesFormGroup.controls['expenses'].get('0').invalid)
            "
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Expenses end -------------------------------------->

<!------------------------ Expense Add start ------------------------------------>
<ng-template #expensesAddTemp let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.ADD_EXPENSE" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }} and
      {{
        "applications.SELECTED_EXPENSES_MUST_HAVE_AMOUNT_AND_DOC" | translate
      }}
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="expensesAddFormGroup" (ngSubmit)="onExpensesAddSubmit()">
      <div>
        <div>
          <div class="row mb-2">
            <div class="col-md-3">
              <label>{{ "applications.EXPENSE_TYPE" | translate }}</label>
              <select class="form-control" formControlName="expense_type">
                <option value="" disabled>
                  - {{ "applications.SELECT" | translate }} -
                </option>
                <option
                  *ngFor="let optn of expenseTypes"
                  value="{{ optn.Value }}"
                >
                  {{ optn.Name | transform | translate }}
                </option>
              </select>
            </div>
          </div>
          <ng-container
            *ngIf="expensesAddFormGroup.value.expense_type === 'Rent'"
          >
            <div class="row">
              <div class="col-lg-2">
                <label
                  >{{ "applications.AMOUNT_MONTHLY_RENT" | translate }}
                  <app-required [mark]="true"></app-required
                ></label>
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    numbersOnly
                    type="text"
                    formControlName="amount_monthly"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-lg">
                <label
                  >{{ "applications.LEASE_START" | translate }}
                  <app-required [mark]="true"></app-required
                ></label>
                <div class="input-group input-group-sm">
                  <input
                    [ngClass]="{
                      'date-error': expensesAddFormGroup.hasError('date')
                    }"
                    type="date"
                    formControlName="lease_start_date"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-lg">
                <label
                  >{{ "applications.LEASE_END" | translate }}
                  <app-required [mark]="true"></app-required
                ></label>
                <div class="input-group input-group-sm">
                  <input
                    [ngClass]="{
                      'date-error': expensesAddFormGroup.hasError('date')
                    }"
                    type="date"
                    formControlName="lease_end_date"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <label
                  >{{ "applications.TYPE_OF_ASSISTANCE" | translate }}
                  <app-required [mark]="true"></app-required
                ></label>
                <select class="form-control" formControlName="in_arrears">
                  <option value="">
                    - {{ "applications.SELECT" | translate }} -
                  </option>
                  <option
                    *ngFor="let optn of inArrearsOptions"
                    value="{{ optn.Value }}"
                  >
                    {{ optn.Name | transform | translate }}
                  </option>
                </select>
              </div>
              <div class="col-lg">
                <label>{{ "applications.MONTHS_PAST_DUE" | translate }}</label>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    disabled
                    formControlName="months_in_arrears"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-lg">
                <label>{{ "applications.TOTAL_PAST_DUE" | translate }}</label>
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    type="text"
                    disabled
                    formControlName="total_arrears"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label style="margin-left: 10px">{{
                "applications.previously_received_funds" | translate
              }}</label>
              <div class="col-md-3">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                    <input
                      numbersOnly
                      type="text"
                      formControlName="previously_received_funds"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                expensesAddFormGroup.value['in_arrears'] ===
                'Past-Due Rental and Rental Advance'
              "
            >
              <h4>
                {{ "applications.ITEMIZED_HEADER" | translate }}
                <app-required [mark]="true"></app-required>
              </h4>
            </div>
            <div
              *ngIf="
                expensesAddFormGroup.value['in_arrears'] ===
                'Past-Due Rental and Rental Advance'
              "
            >
              <td colspan="1"></td>
              <h5>{{ "applications.ITEMIZED_COMMENT" | translate }}</h5>
            </div>
            <div
              *ngIf="
                expensesAddFormGroup.value['in_arrears'] ===
                'Past-Due Rental and Rental Advance'
              "
            >
              <h5>
                <span
                  style="color: red"
                  *ngIf="isPastDue(expensesAddFormGroup)"
                  >{{ "applications.monthly_amount_error" | translate }}</span
                >
              </h5>
            </div>
            <div
              *ngIf="
                expensesAddFormGroup.value['in_arrears'] ===
                'Past-Due Rental and Rental Advance'
              "
            >
              <h5>
                <span style="color: red" *ngIf="dueAmount0">{{
                  "applications.DUE_AMOUNT_0_ERROR" | translate
                }}</span>
              </h5>
            </div>
            <div
              class="border-div"
              *ngIf="
                expensesAddFormGroup.value['in_arrears'] ===
                'Past-Due Rental and Rental Advance'
              "
            >
              <div class="row">
                <div class="col">
                  <label>{{ "applications.MONTH" | translate }}</label>
                </div>
                <div class="col">
                  <label>{{
                    "applications.PAST_DUE_AMOUNT" | translate
                  }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.APRIL_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_april_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_april_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.MAY_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_may_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_may_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JUNE_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_june_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_june_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JULY_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_july_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_july_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.AUGUST_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_august_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_august_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.SEPTEMBER_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_september_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_september_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.OCTOBER_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_october_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_october_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.NOVEMBER_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_november_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_november_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.DECEMBER_2020" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_december_2020',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_december_2020"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JANUARY_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_january_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_january_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.FEBRUARY_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_february_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_february_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.MARCH_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_march_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_march_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.APRIL_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_april_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_april_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.MAY_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_may_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_may_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JUNE_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_june_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_june_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JULY_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_july_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_july_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.AUGUST_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_august_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_august_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.SEPTEMBER_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_september_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_september_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.OCTOBER_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_october_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_october_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.NOVEMBER_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_november_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_november_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.DECEMBER_2021" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_december_2021',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_december_2021"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JANUARY_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_january_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_january_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.FEBRUARY_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_february_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_february_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.MARCH_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_march_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_march_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.APRIL_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_april_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_april_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.MAY_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_may_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_may_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JUNE_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_june_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_june_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JULY_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_july_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_july_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.AUGUST_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_august_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_august_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.SEPTEMBER_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_september_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_september_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.OCTOBER_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_october_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_october_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.NOVEMBER_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_november_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_november_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.DECEMBER_2022" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_december_2022',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_december_2022"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>{{ "applications.JANUARY_2023" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_january_2023',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_january_2023"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label>{{ "applications.JFEBRUARY_2023" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_february_2023',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_february_2023"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label>{{ "applications.MARCH_2023" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_march_2023',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_march_2023"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div> 

              <div class="row">
                <div class="col">
                  <label>{{ "applications.APRIL_2023" | translate }}</label>
                </div>
                <div class="col">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      [ngStyle]="{
                        'border-color': getFieldState(
                          'past_due_amount_april_2023',
                          expensesAddFormGroup
                        )
                      }"
                      numbersOnly
                      type="text"
                      formControlName="past_due_amount_april_2023"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

            </div>
          </ng-container>
          <ng-container
            *ngIf="
              expensesAddFormGroup.value.expense_type &&
              expensesAddFormGroup.value.expense_type !== 'Rent'
            "
          >
            <div class="row">
              <ng-container
                *ngIf="
                  expensesAddFormGroup.value.expense_type ===
                  'Utility - Electricity'
                "
              >
                <div class="col-lg">
                  <label
                    >{{ "applications.UTILITY_PROVIDER" | translate }}
                    <app-required [mark]="true"></app-required
                  ></label>
                  <div class="input-group input-group-sm">
                    <select class="form-control" formControlName="provider">
                      <option value="">
                        - {{ "applications.SELECT" | translate }} -
                      </option>
                      <option
                        *ngFor="let p of utilityProvider"
                        value="{{ p.Value }}"
                      >
                        {{ p.Name | transform | translate }}
                      </option>
                    </select>
                    <input
                      *ngIf="expensesAddFormGroup.value.provider === 'Other'"
                      type="text"
                      formControlName="electricity_company_other"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </ng-container>
              <div
                *ngIf="
                  expensesAddFormGroup.value.expense_type !==
                  'Utility - Electricity'
                "
                class="col-lg"
              >
                <label
                  >{{ "applications.UTILITY_PROVIDER" | translate }}
                  <app-required [mark]="true"></app-required
                ></label>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    formControlName="provider"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-lg">
                <label
                  >{{ "applications.NAME_ON_ACCOUNT" | translate }}
                  <app-required [mark]="true"></app-required
                ></label>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    formControlName="name_on_account"
                    class="form-control form-control-sm"
                  />
                  <div class="d-flex align-items-center">
                    <input
                      formControlName="name_on_account_hh_member"
                      type="checkbox"
                      name="form-control"
                    />
                    <small class="ml-1">{{
                      "applications.IS_ACC_HOLDER_MEMBER" | translate
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="col-lg">
                <label
                  >{{ "applications.ACCOUNT_NUMBER" | translate }}
                  <app-required [mark]="true"></app-required
                ></label>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    formControlName="account_number"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-lg">
                <label
                  >{{ "applications.AMOUNT_PAST_DUE" | translate }}
                  <app-required
                    [control]="expensesAddFormGroup.controls.amount_past_due"
                  ></app-required
                ></label>
                <div class="input-group input-group-sm">
                  <input
                    [attr.disabled]="
                      expensesAddFormGroup.value.expense_type ===
                        'Utility - Electricity' &&
                      expensesAddFormGroup.value.provider === 'Entergy'
                        ? true
                        : null
                    "
                    type="number"
                    formControlName="amount_past_due"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-lg">
                <label
                  >{{ "applications.PAST_DUE_UTILITY_NOTICE" | translate }}
                  <app-required
                    [control]="expensesAddFormGroup.controls.document_file"
                  ></app-required
                ></label>
                <div class="input-group input-group-sm">
                  <input
                    (change)="selectExpenseFile($event, expensesAddFormGroup)"
                    type="file"
                  />
                  <br />
                  <small class="ml-1">{{
                    "applications.EXAMPLE_OF_DOCS_COMM" | translate
                  }}</small>
                </div>
              </div>
            </div>
            <label class="mt-2"
              >{{ "applications.NO_MONTH_APPLY_FOR_PAST_DUE" | translate }}
              <app-required [mark]="true"></app-required
            ></label>
            <div class="row">
              <div class="col-4">
                <div class="input-group input-group-sm col-4">
                  <input
                    min="0"
                    max="99"
                    type="number"
                    formControlName="utility_months_in_arrears"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-8 btn-responsive">
          <button
            [disabled]="
              (expensesAddFormGroup.value.expense_type === 'Rent' &&
                (isPastDueValid ||
                  expensesAddFormGroup.hasError('date') ||
                  expensesAddFormGroup.invalid)) ||
              (expensesAddFormGroup.value.expense_type !== 'Rent' &&
                expensesAddFormGroup.invalid)
            "
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.SAVE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.SAVE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Expense Add end ------------------------------------>

<!------------------------ Expense Delete start ------------------------------------>
<ng-template #expensesDeleteTemp let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.DELETE_EXPENSE" | translate }}
      </h4>
    </span>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="expensesDeleteFormGroup"
      (ngSubmit)="onExpensesDeleteSubmit()"
    >
      <div formArrayName="expense">
        <div
          *ngFor="
            let exp of expensesDeleteFormGroup.get('expense').controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <div *ngIf="i === 0">
            <div class="row col-md-12">
              <div class="col-md-12">
                Are you sure you want to delete the expense item?
              </div>
            </div>
            <div class="row col-md-12">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                {{ "applications.EXPENSE_TYPE" | translate }} :
              </div>
              <div class="col-md-6">
                {{ exp.get("expense_type").value | transform | translate }}
              </div>
            </div>
            <div class="row col-md-12">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                {{ "applications.AMOUNT_MONTHLY" | translate }} :
              </div>
              <div class="col-md-6">
                {{ exp.get("amount_monthly").value | currency }}
              </div>
            </div>
            <!-- <div class="row col-md-12">
							<div class="col-md-3"></div>
							<div class="col-md-3">{{ 'applications.BILL_TO_PROVIDER_NAME' | translate }} : </div>
							<div class="col-md-6">{{ exp.get('provider').value }}</div>
						</div> -->
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-8 btn-responsive">
          <button
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.DELETE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Expense Delete end -------------------------------------->

<!------------------------ Household start ------------------------------------>
<ng-template #householdTemp let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.UPDATE_HOUSEHOLD" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }} and
      {{
        "applications.SELECTED_EXPENSES_MUST_HAVE_AMOUNT_AND_DOC" | translate
      }}
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="householdFormGroup" (ngSubmit)="onHouseholdSubmit()">
      <fieldset [disabled]="viewMode">
        <div formArrayName="household">
          <div
            class="row col-md-12"
            *ngFor="
              let hh of householdFormGroup.get('household').controls;
              let i = index
            "
            [formGroupName]="i"
          >
            <div class="col-md-12" *ngIf="i === 0">
              <div class="card text-center bg-light">
                <div class="card-body">
                  <div class="row col-md-12">
                    <div class="col-md-12">
                      {{ "applications.HOUSEHOLD_MEMBER" | translate }}
                    </div>
                  </div>
                  <div class="row col-md-12 hr_separator"><br /></div>
                  <div class="row col-md-12">
                    <div class="col-md-3">
                      {{ "applications.USER_FIRST_NAME" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <input
                          type="text"
                          formControlName="first_name"
                          placeholder="{{
                            'applications.USER_FIRST_NAME' | translate
                          }}"
                          class="form-control form-control-sm"
                          noSpecialChars
                        />
                      </div>
                    </div>
                    <div class="col-md-1">
                      {{ "applications.USER_MI" | translate }}
                      <div class="input-group input-group-sm">
                        <input
                          type="text"
                          formControlName="middle_name"
                          placeholder="MI"
                          class="form-control form-control-sm"
                          noSpecialChars
                        /><br />
                      </div>
                    </div>
                    <div class="col-md-3">
                      {{ "applications.USER_LAST_NAME" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <input
                          type="text"
                          formControlName="last_name"
                          placeholder="{{
                            'applications.USER_LAST_NAME' | translate
                          }}"
                          class="form-control form-control-sm"
                          noSpecialChars
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      {{ "applications.SSN_OR_ITIN" | translate }}
                      <app-required
                        [mark]="requiredFields.ssn_enc"
                      ></app-required>
                      <div class="d-flex">
                        <input
                          style="height: 31px"
                          #ssn_enc
                          [mask]="tinMask"
                          type="text"
                          class="form-control pw"
                          formControlName="ssn_enc"
                        />
                        <button
                          type="button"
                          class="btn"
                          (click)="viewProtected(ssn_enc,hh)"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </div>
                    </div>
                    <div class="col-md-2">
                      {{ "applications.DATE_OF_BIRTH" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <input
                          *ngIf="hh.get('used').value"
                          type="date"
                          formControlName="date_of_birth"
                          class="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row col-md-12"><p></p></div>
                  <div class="row col-md-12">
                    <div class="col-md-3">
                      {{ "applications.relation_to_applicant" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <!-- [attr.disabled]="hh.value.relation_to_applicant === 'Applicant' ? 'disabled' : null" -->
                        <!-- <option *ngIf="g.value !== 'Applicant' || hh.value.relation_to_applicant === 'Applicant' || isApplicantAMember()" value="{{g.value}}">{{g.name | transform | translate}} -->
                        <!-- <select
                                                    [attr.disabled]="hh.value.relation_to_applicant === 'Applicant' ? 'disabled' : null"
                                                    class="form-control"
                                                    formControlName="relation_to_applicant">
                                                    <option value="0">- {{"applications.SELECT" | translate}} -</option>
                                                    <ng-container *ngFor="let g of relationToApplicant">
                                                        <option *ngIf="g.value !== 'Applicant' || hh.value.relation_to_applicant === 'Applicant' || isApplicantAMember()" value="{{g.value}}">{{g.name | transform | translate}}
                                                        </option>
                                                    </ng-container>
                                                </select>	 -->
                        <select
                          class="form-control"
                          formControlName="relation_to_applicant"
                        >
                        <!-- [attr.disabled]="
                            hh.value.relation_to_applicant === 'Applicant'
                              ? 'disabled'
                              : null
                          " -->

                          <option value="0">
                            - {{ "applications.SELECT" | translate }} -
                          </option>
                                                                              <ng-container
                            *ngFor="let g of relationToApplicant"
                          >

                            <option
                              *ngIf="
                                g.value !== 'Applicant' ||
                                hh.value.relation_to_applicant ===
                                  'Applicant' ||
                                isApplicantAMember()
                              "
                              value="{{ g.Value }}"
                            >
                              {{ g.Name | transform | translate }}

                            </option>
                                                                                </ng-container
                          >

                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      {{ "applications.GENDER" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <select class="form-control" formControlName="gender">
                          <option value="0">
                            - {{ "applications.SELECT" | translate }} -
                          </option>
                          <option
                            *ngFor="let g of genders"
                            value="{{ g.Value }}"
                          >
                            {{ g.Name | transform | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      {{ "applications.RACE" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <select class="form-control" formControlName="race">
                          <option value="0">
                            - {{ "applications.SELECT" | translate }} -
                          </option>
                          <option *ngFor="let r of races" value="{{ r.Value }}">
                            {{ r.Name | transform | translate }}
                          </option>
                        </select>
                      </div>
                    </div>                    
                    <div class="col-md-3">
                      {{ "applications.ETHNICITY" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <select class="form-control" formControlName="ethnicity">
                          <option value="0">
                            - {{ "applications.SELECT" | translate }} -
                          </option>
                          <option *ngFor="let e of ethnicities" value="{{ e.Value }}">
                            {{ e.Name | transform | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      {{ "applications.VETERAN" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <select class="form-control" formControlName="veteran">
                          <option value="0">
                            - {{ "applications.SELECT" | translate }} -
                          </option>
                          <option *ngFor="let v of veterans" value="{{ v.Value }}">
                            {{ v.Name | transform | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      {{ "applications.EMPLOYMENT_STATUS" | translate }}
                      <app-required [mark]="true"></app-required>
                      <div class="input-group input-group-sm">
                        <select
                          class="form-control"
                          formControlName="employment_status"
                        >
                          <option value="0">
                            - {{ "applications.SELECT" | translate }} -
                          </option>
                          <option
                            *ngFor="let e of employmentStatuses"
                            value="{{ e.Value }}"
                          >
                            {{ e.Name | transform | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      {{ "applications.LAST_DATE_WORKED" | translate }}
                      <app-required
                        [mark]="requiredFields.lastDateWorked"
                      ></app-required>
                      <div class="input-group input-group-sm">
                        <input
                          type="date"
                          formControlName="last_date_worked"
                          class="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card text-center bg-light">
                <div class="card-body">
                  <div class="row col-md-12">
                    <div class="col-md-12">
                      {{ "applications.WAGES_INCOME" | translate }}
                      <app-required
                        *ngIf="isHouseHoldEditFormValid()"
                        [mark]="requiredFields.wagesIncome"
                      ></app-required>
                    </div>
                  </div>
                  <div class="row col-md-12 hr_separator"><br /></div>
                  <div class="row col-md-12"><p></p></div>
                  <div class="row col-md-12">
                    <div class="col-md-12">
                      <div class="input-group input-group-sm">
                        <strong
                          >{{
                            "applications.INCOME_GROSS" | translate
                          }} </strong
                        ><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                          class="small"
                          >{{
                            "applications.INCOME_DOES_NOT_INCLUDE" | translate
                          }}</span
                        >
                        <!-- <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                          class="small"
                          >{{
                            "applications.INCOME_DOES_NOT_INCLUDE2" | translate
                          }}</span
                        > -->
                      </div>
                    </div>
                  </div>
                  <div class="row col-md-12"><p></p></div>
                  <div class="row col-md-12">
                    <div class="col-md-12">
                      <div class="input-group input-group-sm">
                        <span class="small">
                          <div class="row col-md-12">
                            <!-- <div class="col-md-1"></div> -->
                            <div class="col-md-3">
                              <label class="control-label"
                                >{{
                                  "applications.INCOME_GROSS_AMOUNT_TYPE"
                                    | translate
                                }}
                                <app-required [mark]="true"></app-required
                              ></label>
                              <div class="input-group input-group-sm">
                                <!-- (change)="incomeTypeChange()" -->
                                <select
                                  class="form-control"
                                  formControlName="income_gross_amount_type"
                                >
                                  <!-- <option value="0">- Select -</option> -->
                                  <option
                                    *ngFor="let i of incomeTypes"
                                    value="{{ i.Value }}"
                                  >
                                    {{ i.Name | transform | translate }}
                                  </option>
                                </select>
                              </div>
                              <div
                                class="mt-2"
                                *ngIf="
                                  hh.value['income_gross_amount_type'] ===
                                  'Annual'
                                "
                              >
                                <label class="control-label">{{
                                  "applications.AMOUNT" | translate
                                }}</label>
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                  </div>
                                  <input
                                    numbersOnly
                                    type="text"
                                    formControlName="income_gross_amount"
                                    class="form-control form-control-sm"
                                  />
                                </div>
                              </div>
                              <div
                                class="mt-2"
                                *ngIf="
                                  hh.value['income_gross_amount_type'] ===
                                  'Monthly'
                                "
                              >
                                <label class="control-label">{{
                                  "applications.MONTH_1_AMOUNT" | translate
                                }}</label>
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                  </div>
                                  <input
                                    numbersOnly
                                    type="text"
                                    formControlName="income_gross_amount"
                                    class="form-control form-control-sm"
                                  />
                                </div>
                              </div>
                              <div
                                class="mt-2"
                                *ngIf="
                                  hh.value['income_gross_amount_type'] ===
                                  'Monthly'
                                "
                              >
                                <label class="control-label">{{
                                  "applications.MONTH_2_AMOUNT" | translate
                                }}</label>
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                  </div>
                                  <input
                                    numbersOnly
                                    type="text"
                                    formControlName="income_gross_amount_2"
                                    class="form-control form-control-sm"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5 text-left">
                              <br />
                              <div
                                *ngIf="
                                  hh.value['income_gross_amount_type'] ===
                                  'Annual'
                                "
                              >
                                {{
                                  "applications.INCOME_AMOUNT_DOCUMENT_1"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_DOCUMENT_2"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_DOCUMENT_3"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_DOCUMENT_4"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_DOCUMENT_5"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_DOCUMENT_6"
                                    | translate
                                }}<br />
                              </div>
                              <div
                                *ngIf="
                                  hh.value['income_gross_amount_type'] ===
                                  'Monthly'
                                "
                              >
                                {{
                                  "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_1"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_2"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_3"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_4"
                                    | translate
                                }}<br />
                                &nbsp;&nbsp;&nbsp;{{
                                  "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_5"
                                    | translate
                                }}<br />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div><br />
                                <input
                                  type="file"
                                  (change)="
                                    onSelectFileUploadHousehold(
                                      $event,
                                      'income_gross_file_name',
                                      'income_gross_file'
                                    )
                                  "
                                />
                                <br />
                                <span
                                  *ngIf="hh.value['income_gross_file_url']"
                                  class="text-success2"
                                >
                                  {{ "applications.UPLOADED" | translate }}
                                  <a
                                    href="{{
                                      getFileRouteLink(
                                        hh.value['income_gross_file_url']
                                      )
                                    }}"
                                    target="_blank"
                                    >({{
                                      hh.value["income_gross_file_name"]
                                    }})</a
                                  >
                                  <app-file-delete
                                    [recordId]="
                                      householdFormGroup.value.household[0].id
                                    "
                                    [tableId]="
                                      projectSpecificData.householdData.TableId
                                    "
                                    [modalConfig]="modelConfig"
                                    [fields]="[
                                      'income_gross_file',
                                      'income_gross_file_url'
                                    ]"
                                    [tryFn]="tryHH"
                                    [disabled]="viewMode"
                                  ></app-file-delete>
                                </span>
                                <br />
                                <span
                                  *ngIf="!hh.value['income_gross_file_url']"
                                  class="text-warning"
                                  >{{
                                    "applications.NOT_UPLOADED_YET" | translate
                                  }}</span
                                >
                              </div>
                              <!-- <div class="mt-2">
                                <input
                                  type="file"
                                  (change)="
                                    onSelectFileUploadHousehold(
                                      $event,
                                      'income_gross_file_2_name',
                                      'income_gross_file_2'
                                    )
                                  "
                                />
                                <br />
                                <span
                                  *ngIf="hh.value['income_gross_file_2_url']"
                                  class="text-success2"
                                >
                                  {{ "applications.UPLOADED" | translate }}
                                  <a
                                    href="{{
                                      getFileRouteLink(
                                        hh.value['income_gross_file_2_url']
                                      )
                                    }}"
                                    target="_blank"
                                    >({{
                                      hh.value["income_gross_file_2_name"]
                                    }})</a
                                  >
                                  <app-file-delete
                                    [recordId]="
                                      householdFormGroup.value.household[0].id
                                    "
                                    [tableId]="
                                      projectSpecificData.householdData.TableId
                                    "
                                    [modalConfig]="modelConfig"
                                    [fields]="[
                                      'income_gross_file_2',
                                      'income_gross_file_2_url'
                                    ]"
                                    [tryFn]="tryHH"
                                    [disabled]="viewMode"
                                  ></app-file-delete>
                                </span>
                                <br />
                                <span
                                  *ngIf="!hh.value['income_gross_file_2_url']"
                                  class="text-warning"
                                  >{{
                                    "applications.NOT_UPLOADED_YET" | translate
                                  }}</span
                                >
                              </div> -->
                              <br />
                              <div class="input-group input-group-sm">
                                <table class="table table-sm table-bordered text-left">
                                  <tr valign="top">
                                    <td>    
                                      <input
                                        id="income_gross_no_file"
                                        type="checkbox"
                                        formControlName="income_gross_no_file"
                                      />
                                    </td>
                                    <td>
                                      <label
                                        class="ml-2 text-danger"
                                        for="income_gross_no_file"
                                        ><b>{{
                                          "applications.NO_SUPPORTING_DOC"
                                            | translate
                                        }}</b></label
                                      >
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row col-md-12"><br /></div>      
                <div class="card text-center bg-light">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-9 text-left">
                        <label>
                          {{ "applications.OTHER_FORMS_OF_INCOME" | translate }}
                        </label>
                      </div>
                      <div class="col-md-3">
                        <mat-radio-group formControlName="other_forms_of_income">
                          <mat-radio-button value="True">
                            <strong>{{ "applications.YES" | translate }}</strong>
                          </mat-radio-button>
                          <mat-radio-button class="ml-3" value="False">
                            <strong>{{ "applications.NO" | translate }}</strong>
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="row col-md-12 text-center" *ngIf="householdFormGroup.value.household[0].other_forms_of_income === 'True'">
                      <div class="row col-md-12">
                        <div class="input-group input-group-sm">
                          {{ "applications.OTHER_INCOME" | translate }}<br />
                        </div>
                      </div>
                      <div class="row col-md-12 hr_separator"><br /></div>
                      <div class="row col-md-12"><p></p></div>
                      <div class="row col-md-12">
                      <!-- <td *ngIf="hh.get('used').value" width="98%" colspan="6"> -->
                      <div class="col-md-12">
                        <div class="input-group input-group-sm">
                          <span class="small">
                            <div class="row col-md-12">
                              <div class="col-md-7">
                                <div class="input-group input-group-sm text-left">
                                  <!-- <mat-checkbox formControlName="income_in_lieu_of_earnings"> -->
                                  <label
                                    ><strong
                                      >{{
                                        "applications.INCOME_IN_LIEU_OF_EARNINGS"
                                          | translate
                                      }}<br />{{
                                        "applications.INCOME_IN_LIEU_OF_EARNINGS2"
                                          | translate
                                      }}
                                    </strong>
                                    <app-required
                                      [mark]="requiredFields.income_lieu"
                                    ></app-required
                                  ></label>
                                  <!-- </mat-checkbox> -->
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                  </div>
                                  <input
                                    numbersOnly
                                    type="text"
                                    formControlName="income_in_lieu_of_earnings_amount"
                                    class="form-control form-control-sm"
                                  />
                                  <small>{{
                                    "applications.PER_MONTH" | translate
                                  }}</small>
                                </div>
                              </div>
                              <!-- <div class="col-md-3">
                                <input
                                  type="file"
                                  (change)="
                                    onSelectFileUploadHousehold(
                                      $event,
                                      'income_in_lieu_of_earnings_file_name',
                                      'income_in_lieu_of_earnings_file'
                                    )
                                  "
                                />
                                <span
                                  *ngIf="
                                    hh.value[
                                      'income_in_lieu_of_earnings_file_url'
                                    ]
                                  "
                                  class="text-success2"
                                >
                                  {{ "applications.UPLOADED" | translate }}
                                  <a
                                    href="{{
                                      getFileRouteLink(
                                        hh.value[
                                          'income_in_lieu_of_earnings_file_url'
                                        ]
                                      )
                                    }}"
                                    target="_blank"
                                    >({{
                                      hh.value[
                                        "income_in_lieu_of_earnings_file_name"
                                      ]
                                    }})</a
                                  >
                                  <app-file-delete
                                    [recordId]="
                                      householdFormGroup.value.household[0].id
                                    "
                                    [tableId]="
                                      projectSpecificData.householdData.TableId
                                    "
                                    [modalConfig]="modelConfig"
                                    [fields]="[
                                      'income_in_lieu_of_earnings_file',
                                      'income_in_lieu_of_earnings_file_url'
                                    ]"
                                    [tryFn]="tryHH"
                                    [disabled]="viewMode"
                                  ></app-file-delete> </span
                                ><br />
                                <span
                                  *ngIf="
                                    !hh.value[
                                      'income_in_lieu_of_earnings_file_url'
                                    ]
                                  "
                                  class="text-warning"
                                  >{{
                                    "applications.NOT_UPLOADED_YET" | translate
                                  }}</span
                                >
                              </div> -->
                            </div>
                            <div class="row col-md-12">
                              <div class="col-md-7">
                                <div class="input-group input-group-sm text-left">
                                  <!-- <mat-checkbox formControlName="income_payments_annuities"> -->
                                  <label
                                    ><strong
                                      >{{
                                        "applications.INCOME_PAYMENTS_ANNUITIES"
                                          | translate
                                      }}<br />{{
                                        "applications.INCOME_PAYMENTS_ANNUITIES2"
                                          | translate
                                      }}</strong
                                    ><app-required
                                      [mark]="requiredFields.income_pymts"
                                    ></app-required
                                  ></label>
                                  <!-- </mat-checkbox> -->
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                  </div>
                                  <input
                                    numbersOnly
                                    type="text"
                                    formControlName="income_payments_annuities_amount"
                                    class="form-control form-control-sm"
                                  />
                                  <small>{{
                                    "applications.PER_MONTH" | translate
                                  }}</small>
                                </div>
                              </div>
                              <!-- <div class="col-md-3">
                                <input
                                  type="file"
                                  (change)="
                                    onSelectFileUploadHousehold(
                                      $event,
                                      'income_payments_annuities_file_name',
                                      'income_payments_annuities_file'
                                    )
                                  "
                                />
                                <span
                                  *ngIf="
                                    hh.value['income_payments_annuities_file_url']
                                  "
                                  class="text-success2"
                                >
                                  {{ "applications.UPLOADED" | translate }}
                                  <a
                                    href="{{
                                      getFileRouteLink(
                                        hh.value[
                                          'income_payments_annuities_file_url'
                                        ]
                                      )
                                    }}"
                                    target="_blank"
                                    >({{
                                      hh.value[
                                        "income_payments_annuities_file_name"
                                      ]
                                    }})</a
                                  >
                                  <app-file-delete
                                    [recordId]="
                                      householdFormGroup.value.household[0].id
                                    "
                                    [tableId]="
                                      projectSpecificData.householdData.TableId
                                    "
                                    [modalConfig]="modelConfig"
                                    [fields]="[
                                      'income_payments_annuities_file',
                                      'income_payments_annuities_file_url'
                                    ]"
                                    [tryFn]="tryHH"
                                    [disabled]="viewMode"
                                  ></app-file-delete> </span
                                ><br />
                                <span
                                  *ngIf="
                                    !hh.value[
                                      'income_payments_annuities_file_url'
                                    ]
                                  "
                                  class="text-warning"
                                  >{{
                                    "applications.NOT_UPLOADED_YET" | translate
                                  }}</span
                                >
                              </div> -->
                            </div>
                            <div class="row col-md-12">
                              <div class="col-md-7">
                                <div class="input-group input-group-sm text-left">
                                  <!-- <mat-checkbox formControlName="income_government"> -->
                                  <label
                                    ><strong
                                      >{{
                                        "applications.INCOME_GOVERNMENT"
                                          | translate
                                      }}<br />{{
                                        "applications.INCOME_GOVERNMENT2"
                                          | translate
                                      }}</strong
                                    ><app-required
                                      [mark]="requiredFields.income_government"
                                    ></app-required
                                  ></label>
                                  <!-- </mat-checkbox> -->
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                  </div>
                                  <input
                                    numbersOnly
                                    type="text"
                                    formControlName="income_government_amount"
                                    class="form-control form-control-sm"
                                  />
                                  <small>{{
                                    "applications.PER_MONTH" | translate
                                  }}</small>
                                </div>
                              </div>
                              <!-- <div class="col-md-3">
                                <input
                                  type="file"
                                  (change)="
                                    onSelectFileUploadHousehold(
                                      $event,
                                      'income_government_file_name',
                                      'income_government_file'
                                    )
                                  "
                                />
                                <span
                                  *ngIf="hh.value['income_government_file_url']"
                                  class="text-success2"
                                >
                                  {{ "applications.UPLOADED" | translate }}
                                  <a
                                    href="{{
                                      getFileRouteLink(
                                        hh.value['income_government_file_url']
                                      )
                                    }}"
                                    target="_blank"
                                    >({{
                                      hh.value["income_government_file_name"]
                                    }})</a
                                  >
                                  <app-file-delete
                                    [recordId]="
                                      householdFormGroup.value.household[0].id
                                    "
                                    [tableId]="
                                      projectSpecificData.householdData.TableId
                                    "
                                    [modalConfig]="modelConfig"
                                    [fields]="[
                                      'income_government_file',
                                      'income_government_file_url'
                                    ]"
                                    [tryFn]="tryHH"
                                    [disabled]="viewMode"
                                  ></app-file-delete> </span
                                ><br />
                                <span
                                  *ngIf="!hh.value['income_government_file_url']"
                                  class="text-warning"
                                  >{{
                                    "applications.NOT_UPLOADED_YET" | translate
                                  }}</span
                                >
                              </div> -->
                            </div>
                            <div class="row col-md-12">
                              <div class="col-md-7">
                                <div class="input-group input-group-sm text-left">
                                  <!-- <mat-checkbox formControlName="income_alimony"> -->
                                  <label
                                    ><strong
                                      >{{
                                        "applications.INCOME_ALIMONY" | translate
                                      }} </strong
                                    ><app-required
                                      [mark]="requiredFields.income_alimony"
                                    ></app-required
                                  ></label>
                                  <!-- </mat-checkbox> -->
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                  </div>
                                  <input
                                    numbersOnly
                                    type="text"
                                    formControlName="income_alimony_amount"
                                    class="form-control form-control-sm"
                                  />
                                  <small>{{
                                    "applications.PER_MONTH" | translate
                                  }}</small>
                                </div>
                              </div>
                              <!-- <div class="col-md-3">
                                <input
                                  type="file"
                                  (change)="
                                    onSelectFileUploadHousehold(
                                      $event,
                                      'income_alimony_file_name',
                                      'income_alimony_file'
                                    )
                                  "
                                />
                                <span
                                  *ngIf="hh.value['income_alimony_file_url']"
                                  class="text-success2"
                                >
                                  {{ "applications.UPLOADED" | translate }}
                                  <a
                                    href="{{
                                      getFileRouteLink(
                                        hh.value['income_alimony_file_url']
                                      )
                                    }}"
                                    target="_blank"
                                    >({{
                                      hh.value["income_alimony_file_name"]
                                    }})</a
                                  >
                                  <app-file-delete
                                    [recordId]="
                                      householdFormGroup.value.household[0].id
                                    "
                                    [tableId]="
                                      projectSpecificData.householdData.TableId
                                    "
                                    [modalConfig]="modelConfig"
                                    [fields]="[
                                      'income_alimony_file',
                                      'income_alimony_file_url'
                                    ]"
                                    [tryFn]="tryHH"
                                    [disabled]="viewMode"
                                  ></app-file-delete> </span
                                ><br />
                                <span
                                  *ngIf="!hh.value['income_alimony_file_url']"
                                  class="text-warning"
                                  >{{
                                    "applications.NOT_UPLOADED_YET" | translate
                                  }}</span
                                >
                              </div> -->
                            </div>
                            <div class="row col-md-12">
                              <div class="col-md-7">
                                <div class="input-group input-group-sm text-left">
                                  <!-- <mat-checkbox formControlName="income_other"> -->
                                  <label
                                    ><strong
                                      >{{
                                        "applications.INCOME_OTHER" | translate
                                      }}
                                    </strong></label
                                  >
                                  <!-- </mat-checkbox> -->
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="input-group input-group-sm">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                  </div>
                                  <input
                                    numbersOnly
                                    type="text"
                                    formControlName="income_other_amount"
                                    class="form-control form-control-sm"
                                  />
                                  <small>{{
                                    "applications.PER_MONTH" | translate
                                  }}</small>
                                </div>
                              </div>
                              <!-- <div class="col-md-3">
                                <input
                                  type="file"
                                  (change)="
                                    onSelectFileUploadHousehold(
                                      $event,
                                      'income_other_file_name',
                                      'income_other_file'
                                    )
                                  "
                                />
                                <span
                                  *ngIf="hh.value['income_other_file_url']"
                                  class="text-success2"
                                >
                                  {{ "applications.UPLOADED" | translate }}
                                  <a
                                    href="{{
                                      getFileRouteLink(
                                        hh.value['income_other_file_url']
                                      )
                                    }}"
                                    target="_blank"
                                    >({{ hh.value["income_other_file_name"] }})</a
                                  >
                                  <app-file-delete
                                    [recordId]="
                                      householdFormGroup.value.household[0].id
                                    "
                                    [tableId]="
                                      projectSpecificData.householdData.TableId
                                    "
                                    [modalConfig]="modelConfig"
                                    [fields]="[
                                      'income_other_file',
                                      'income_other_file_url'
                                    ]"
                                    [tryFn]="tryHH"
                                    [disabled]="viewMode"
                                  ></app-file-delete> </span
                                ><br />
                                <span
                                  *ngIf="!hh.value['income_other_file_url']"
                                  class="text-warning"
                                  >{{
                                    "applications.NOT_UPLOADED_YET" | translate
                                  }}</span
                                >
                              </div> -->
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="row mt-3">
        <div class="col-md-8 btn-responsive">
          <button
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <br />
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Household end -------------------------------------->

<!------------------------ Household Add start ------------------------------------>
<ng-template #householdAddTemp let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.ADD_HOUSEHOLD" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }} and
      {{
        "applications.SELECTED_EXPENSES_MUST_HAVE_AMOUNT_AND_DOC" | translate
      }}
    </span>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="householdAddFormGroup"
      (ngSubmit)="onHouseholdAddSubmit()"
    >
      <div class="card text-center bg-light">
        <div class="card-body">
          <div class="row col-md-12">
            <div class="col-md-12">
              {{ "applications.HOUSEHOLD_MEMBER" | translate }}
            </div>
          </div>
          <div class="row col-md-12 hr_separator"><br /></div>
          <div class="row col-md-12">
            <div class="col-md-3">
              {{ "applications.USER_FIRST_NAME" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <input
                  type="text"
                  formControlName="first_name"
                  placeholder="{{ 'applications.USER_FIRST_NAME' | translate }}"
                  class="form-control form-control-sm"
                  noSpecialChars
                />
              </div>
            </div>
            <div class="col-md-1">
              {{ "applications.USER_MI" | translate }}
              <div class="input-group input-group-sm">
                <input
                  type="text"
                  formControlName="middle_name"
                  placeholder="MI"
                  class="form-control form-control-sm"
                  noSpecialChars
                /><br />
              </div>
            </div>
            <div class="col-md-3">
              {{ "applications.USER_LAST_NAME" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <input
                  type="text"
                  formControlName="last_name"
                  placeholder="{{ 'applications.USER_LAST_NAME' | translate }}"
                  class="form-control form-control-sm"
                  noSpecialChars
                />
              </div>
            </div>
            <div class="col-md-3">
              {{ "applications.SSN_OR_ITIN" | translate }}
              <app-required [mark]="requiredFields.ssn_enc"></app-required>
              <div class="d-flex">
                <input
                  style="height: 31px"
                  #ssn_enc
                  [mask]="tinMask"
                  type="text"
                  class="form-control pw"
                  formControlName="ssn_enc"
                />
                <button
                  type="button"
                  class="btn"
                  (click)="viewProtected(ssn_enc)"
                >
                  <i class="fa fa-eye"></i>
                </button>
              </div>
            </div>
            <div class="col-md-2">
              {{ "applications.DATE_OF_BIRTH" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <input
                  type="date"
                  formControlName="date_of_birth"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <div class="row col-md-12"><p></p></div>
          <div class="row col-md-12">
            <div class="col-md-3">
              {{ "applications.relation_to_applicant" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <!-- <option *ngIf="g.value !== 'Applicant' || householdAddFormGroup.value.relation_to_applicant === 'Applicant' || isApplicantAMember()" value="{{g.value}}">{{g.name | transform | translate}} -->

                <select
                  class="form-control"
                  formControlName="relation_to_applicant"
                >
                  <option value="0">
                    - {{ "applications.SELECT" | translate }} -
                  </option>
                  <ng-container *ngFor="let g of relationToApplicant">
                    <option
                      *ngIf="
                        g.Value !== 'Applicant' ||
                        householdAddFormGroup.value.relation_to_applicant ===
                          'Applicant' ||
                        isApplicantAMember()
                      "
                      value="{{ g.Value }}"
                    >
                      {{ g.Name | transform | translate }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              {{ "applications.GENDER" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <select class="form-control" formControlName="gender">
                  <option value="0">
                    - {{ "applications.SELECT" | translate }} -
                  </option>
                  <option *ngFor="let g of genders" value="{{ g.Value }}">
                    {{ g.Name | transform | translate }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              {{ "applications.RACE" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <select class="form-control" formControlName="race">
                  <option value="0">
                    - {{ "applications.SELECT" | translate }} -
                  </option>
                  <option *ngFor="let r of races" value="{{ r.Value }}">
                    {{ r.Name | transform | translate }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              {{ "applications.ETHNICITY" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <select class="form-control" formControlName="ethnicity">
                  <option value="0">
                    - {{ "applications.SELECT" | translate }} -
                  </option>
                  <option *ngFor="let e of ethnicities" value="{{ e.Value }}">
                    {{ e.Name | transform | translate }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              {{ "applications.VETERAN" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <select class="form-control" formControlName="veteran">
                  <option value="0">
                    - {{ "applications.SELECT" | translate }} -
                  </option>
                  <option *ngFor="let v of veterans" value="{{ v.Value }}">
                    {{ v.Name | transform | translate }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              {{ "applications.EMPLOYMENT_STATUS" | translate }}
              <app-required [mark]="true"></app-required>
              <div class="input-group input-group-sm">
                <select
                  class="form-control"
                  formControlName="employment_status"
                >
                  <option value="0">
                    - {{ "applications.SELECT" | translate }} -
                  </option>
                  <option
                    *ngFor="let e of employmentStatuses"
                    value="{{ e.Value }}"
                  >
                    {{ e.Name | transform | translate }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              {{ "applications.LAST_DATE_WORKED" | translate }}
              <app-required
                [mark]="requiredFields.lastDateWorked"
              ></app-required>
              <div class="input-group input-group-sm">
                <input
                  type="date"
                  formControlName="last_date_worked"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-center bg-light">
        <div class="card-body">
          <div class="row col-md-12">
            <div class="col-md-12">
              {{ "applications.WAGES_INCOME" | translate }}
              <app-required *ngIf="isHouseHoldAddFormValid()" [mark]="requiredFields.wagesIncome"></app-required>
            </div>
          </div>
          <div class="row col-md-12 hr_separator"><br /></div>
          <div class="row col-md-12"><p></p></div>
          <div class="row col-md-12">
            <div class="col-md-12">
              <div class="input-group input-group-sm">
                <strong>{{ "applications.INCOME_GROSS" | translate }} </strong
                ><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="small">{{
                  "applications.INCOME_DOES_NOT_INCLUDE" | translate
                }}</span
                ><br />
                <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="small">{{
                  "applications.INCOME_DOES_NOT_INCLUDE2" | translate
                }}</span> -->
              </div>
            </div>
          </div>
          <div class="row col-md-12">
            <div class="col-md-12">
              <div class="input-group input-group-sm">
                <span class="small">
                  <div class="row col-md-12">
                    <div class="col-md-3">
                      <label class="control-label"
                        >{{
                          "applications.INCOME_GROSS_AMOUNT_TYPE" | translate
                        }}
                        <app-required [mark]="true"></app-required
                      ></label>
                      <div class="input-group input-group-sm">
                        <!-- (change)="incomeTypeChange()" -->
                        <select
                          class="form-control"
                          formControlName="income_gross_amount_type"
                        >
                          <!-- <option value="0">- Select -</option> -->
                          <option
                            *ngFor="let i of incomeTypes"
                            value="{{ i.Value }}"
                          >
                            {{ i.Name | transform | translate }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="mt-2"
                        *ngIf="
                          householdAddFormGroup.value[
                            'income_gross_amount_type'
                          ] === 'Annual'
                        "
                      >
                        <label class="control-label">{{
                          "applications.AMOUNT" | translate
                        }}</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            numbersOnly
                            type="text"
                            formControlName="income_gross_amount"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div
                        class="mt-2"
                        *ngIf="
                          householdAddFormGroup.value[
                            'income_gross_amount_type'
                          ] === 'Monthly'
                        "
                      >
                        <label class="control-label">{{
                          "applications.MONTH_1_AMOUNT" | translate
                        }}</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            numbersOnly
                            type="text"
                            formControlName="income_gross_amount"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div
                        class="mt-2"
                        *ngIf="
                          householdAddFormGroup.value[
                            'income_gross_amount_type'
                          ] === 'Monthly'
                        "
                      >
                        <label class="control-label">{{
                          "applications.MONTH_2_AMOUNT" | translate
                        }}</label>
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            numbersOnly
                            type="text"
                            formControlName="income_gross_amount_2"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5 text-left">
                      <br />
                      <div
                        *ngIf="
                          householdAddFormGroup.value[
                            'income_gross_amount_type'
                          ] === 'Annual'
                        "
                      >
                        {{ "applications.INCOME_AMOUNT_DOCUMENT_1" | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_DOCUMENT_2" | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_DOCUMENT_3" | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_DOCUMENT_4" | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_DOCUMENT_5" | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_DOCUMENT_6" | translate
                        }}<br />
                      </div>
                      <div
                        *ngIf="
                          householdAddFormGroup.value[
                            'income_gross_amount_type'
                          ] === 'Monthly' 
                        "
                      >
                        {{
                          "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_1"
                            | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_2"
                            | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_3"
                            | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_4"
                            | translate
                        }}<br />
                        &nbsp;&nbsp;&nbsp;{{
                          "applications.INCOME_AMOUNT_MONTHLY_DOCUMENT_5"
                            | translate
                        }}<br />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <br />
                      <div>
                        <input
                          type="file"
                          (change)="
                            onSelectFileUploadHousehold(
                              $event,
                              'income_gross_file_name',
                              'income_gross_file',
                              true
                            )
                          "
                        />
                      </div>
                      <!-- <div class="mt-2">
                        <input
                          type="file"
                          (change)="
                            onSelectFileUploadHousehold(
                              $event,
                              'income_gross_file_2_name',
                              'income_gross_file_2',
                              true
                            )
                          "
                        />
                      </div> -->
                      <br />
                      <div class="input-group input-group-sm">
                        <table class="table table-sm table-bordered text-left">
                          <tr valign="top"><td><input
                              id="income_gross_no_file"
                              type="checkbox"
                              formControlName="income_gross_no_file"
                            /></td>
                            <td>
                            <label
                              class="text-danger"
                              for="income_gross_no_file"
                              ><b>{{
                                "applications.NO_SUPPORTING_DOC" | translate
                              }}</b></label
                            >
                          </td></tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-md-12"><br /></div>      
      <div class="card text-center bg-light">
        <div class="card-body">
          <div class="row">
            <div class="col-md-9 text-left">
              <label>
                {{ "applications.OTHER_FORMS_OF_INCOME" | translate }}
              </label>
            </div>
            <div class="col-md-3">
              <mat-radio-group formControlName="other_forms_of_income">
                <mat-radio-button value="True">
                  <strong>{{ "applications.YES" | translate }}</strong>
                </mat-radio-button>
                <mat-radio-button class="ml-3" value="False">
                  <strong>{{ "applications.NO" | translate }}</strong>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row col-md-12 text-center" *ngIf="householdAddFormGroup.value.other_forms_of_income === 'True'">
            <div class="row col-md-12">
              <div class="input-group input-group-sm">
                {{ "applications.OTHER_INCOME" | translate }}<br />
              </div>
            </div>
            <div class="row col-md-12 hr_separator"><br /></div>
            <div class="row col-md-12"><p></p></div>
            <div class="row col-md-12">
              <div class="col-md-12">
                <div class="input-group input-group-sm">
                  <span class="small">
                    <div class="row col-md-12">
                      <div class="col-md-7">
                        <div class="input-group input-group-sm text-left">
                          <label
                            ><strong
                              >{{
                                "applications.INCOME_IN_LIEU_OF_EARNINGS"
                                  | translate
                              }}<br />{{
                                "applications.INCOME_IN_LIEU_OF_EARNINGS2"
                                  | translate
                              }} </strong
                            ><app-required
                              [mark]="requiredFields.income_lieu"
                            ></app-required
                          ></label>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            numbersOnly
                            type="text"
                            formControlName="income_in_lieu_of_earnings_amount"
                            class="form-control form-control-sm"
                          />
                          <!-- <small class="ip-bottom"></small> -->
                          <small>{{
                            "applications.PER_MONTH" | translate
                          }}</small>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                        <input
                          type="file"
                          (change)="
                            onSelectFileUploadHousehold(
                              $event,
                              'income_in_lieu_of_earnings_file_name',
                              'income_in_lieu_of_earnings_file',
                              true
                            )
                          "
                        />
                      </div> -->
                    </div>
                    <div class="row col-md-12">
                      <div class="col-md-7">
                        <div class="input-group input-group-sm text-left">
                          <label
                            ><strong
                              >{{
                                "applications.INCOME_PAYMENTS_ANNUITIES"
                                  | translate
                              }}<br />{{
                                "applications.INCOME_PAYMENTS_ANNUITIES2"
                                  | translate
                              }}</strong
                            >
                            <app-required
                              [mark]="requiredFields.income_pymts"
                            ></app-required
                          ></label>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            numbersOnly
                            type="text"
                            formControlName="income_payments_annuities_amount"
                            class="form-control form-control-sm"
                          />
                          <small>{{
                            "applications.PER_MONTH" | translate
                          }}</small>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                        <input
                          type="file"
                          (change)="
                            onSelectFileUploadHousehold(
                              $event,
                              'income_payments_annuities_file_name',
                              'income_payments_annuities_file'
                            )
                          "
                        />
                      </div> -->
                    </div>
                    <div class="row col-md-12">
                      <div class="col-md-7">
                        <div class="input-group input-group-sm text-left">
                          <label
                            ><strong
                              >{{ "applications.INCOME_GOVERNMENT" | translate
                              }}<br />{{
                                "applications.INCOME_GOVERNMENT2" | translate
                              }}</strong
                            >
                            <app-required
                              [mark]="requiredFields.income_government"
                            ></app-required
                          ></label>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            numbersOnly
                            type="text"
                            formControlName="income_government_amount"
                            class="form-control form-control-sm"
                          />
                          <small>{{
                            "applications.PER_MONTH" | translate
                          }}</small>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                        <input
                          type="file"
                          (change)="
                            onSelectFileUploadHousehold(
                              $event,
                              'income_government_file_name',
                              'income_government_file'
                            )
                          "
                        />
                      </div> -->
                    </div>
                    <div class="row col-md-12 mb-3">
                      <div class="col-md-7">
                        <div class="input-group input-group-sm text-left">
                          <label
                            ><strong
                              >{{ "applications.INCOME_ALIMONY" | translate }}
                            </strong>
                            <app-required
                              [mark]="requiredFields.income_alimony"
                            ></app-required
                          ></label>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            numbersOnly
                            type="text"
                            formControlName="income_alimony_amount"
                            class="form-control form-control-sm"
                          />
                          <small>{{
                            "applications.PER_MONTH" | translate
                          }}</small>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                        <input
                          type="file"
                          (change)="
                            onSelectFileUploadHousehold(
                              $event,
                              'income_alimony_file_name',
                              'income_alimony_file'
                            )
                          "
                        />
                      </div> -->
                    </div>
                    <div class="row col-md-12">
                      <div class="col-md-7">
                        <div class="input-group input-group-sm text-left">
                          <label
                            ><strong
                              >{{ "applications.INCOME_OTHER" | translate }}
                            </strong></label
                          >
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="input-group input-group-sm">
                          <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                          </div>
                          <input
                            numbersOnly
                            type="text"
                            formControlName="income_other_amount"
                            class="form-control form-control-sm"
                          />
                          <small>{{
                            "applications.PER_MONTH" | translate
                          }}</small>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                        <input
                          type="file"
                          (change)="
                            onSelectFileUploadHousehold(
                              $event,
                              'income_other_file_name',
                              'income_other_file'
                            )
                          "
                        />
                      </div> -->
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-8 btn-responsive">
          <button
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.SAVE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.SAVE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Household Add end ------------------------------------>

<!------------------------ Household Delete start ------------------------------------>
<ng-template #householdDeleteTemp let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.DELETE_HOUSEHOLD" | translate }}
      </h4>
    </span>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="householdDeleteFormGroup"
      (ngSubmit)="onHouseholdDeleteSubmit()"
    >
      <div formArrayName="household">
        <div
          *ngFor="
            let hh of householdDeleteFormGroup.get('household').controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <div *ngIf="i === 0">
            <div class="row col-md-12">
              <div class="col-md-12">
                Are you sure you want to delete the household member?
              </div>
            </div>
            <div class="row col-md-12">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                {{ "applications.NAME" | translate }} :
              </div>
              <div class="col-md-6">
                {{ hh.get("first_name").value }}
                {{ hh.get("last_name").value }}
              </div>
            </div>
            <div class="row col-md-12">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                {{ "applications.DATE_OF_BIRTH" | translate }} :
              </div>
              <div class="col-md-6">{{ hh.get("date_of_birth").value }}</div>
            </div>
            <div class="row col-md-12">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                {{ "applications.EMPLOYMENT_STATUS" | translate }} :
              </div>
              <div class="col-md-6">
                {{ hh.get("employment_status").value }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-8 btn-responsive">
          <button
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.DELETE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Household Delete end -------------------------------------->

<!------------------------ Contact start ------------------------------------>
<ng-template #contact let-modal>
  <div class="modal-header bg-light" style="flex-direction: column">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.CONTACT_DETAILS" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="contactFormGroup" (ngSubmit)="onContactSubmit()">
      <div class="row">
        <div class="col-md-4">
          <label
            >{{ "applications.USER_FIRST_NAME" | translate
            }}<app-required
              [control]="contactFormGroup.controls['contact_first_name']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="contact_first_name"
            noSpecialChars
            (change)="isApplicationValid()"
          />
          <small
            *ngIf="
              contactFormControl.contact_first_name.touched &&
              contactFormControl.contact_first_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
        <div class="col-md-4">
          <label
            >{{ "applications.USER_MI" | translate
            }}<app-required
              [control]="contactFormGroup.controls['contact_mi']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="contact_mi"
            noSpecialChars
          />
        </div>
        <div class="col-md-4">
          <label
            >{{ "applications.USER_LAST_NAME" | translate
            }}<app-required
              [control]="contactFormGroup.controls['contact_last_name']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="contact_last_name"
            noSpecialChars
            (change)="isApplicationValid()"
          />
          <small
            *ngIf="
              contactFormControl.contact_last_name.touched &&
              contactFormControl.contact_last_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label
            >{{ "applications.CONTACT_EMAIL" | translate
            }}<app-required
              [control]="contactFormGroup.controls['contact_email']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="contact_email"
            (change)="isApplicationValid()"
          />
          <small
            *ngIf="
              contactFormControl.contact_email.touched &&
              contactFormControl.contact_email.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="
              contactFormControl.contact_email.touched &&
              contactFormControl.contact_email.errors?.pattern
            "
            class="text-danger"
          >
            {{ "errors.INVALID_EMAIL" | translate }}
          </small>
        </div>
        <div class="col-md-6">
          <label
            >{{ "applications.CONTACT_PHONE" | translate
            }}<app-required
              [control]="contactFormGroup.controls['contact_phone']"
            ></app-required
          ></label>
          <input
            type="text"
            mask="(000) 000-0000"
            class="form-control"
            formControlName="contact_phone"
            (change)="isApplicationValid()"
          />
          <small
            *ngIf="
              contactFormControl.contact_phone.touched &&
              contactFormControl.contact_phone.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            *ngIf="!modelConfig.busy && !viewMode && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Contact end -------------------------------------->

<!------------------------ Addresses start ------------------------------------>
<ng-template #addresses let-modal>
  <div class="modal-header bg-light" style="flex-direction: column">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.ADDRESS_DETAILS" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="addressesFormGroup" (ngSubmit)="onAddressesSubmit()">
      <div class="row">
        <div class="col-md-6">
          <label class="control-label">{{
            "applications.RENTAL" | translate
          }}</label>
          <h5>
            {{ applicationData.physical_street }}
            {{ applicationData.physical_street_2 }}<br />{{
              applicationData.physical_city
            }}, {{ applicationData.physical_state }}
            {{ applicationData.physical_zip_code }}
          </h5>
        </div>
        <div class="col-md-6">
          <label class="control-label">{{
            "applications.PARISH" | translate
          }}</label>
          <h5>{{ applicationData.parish }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label
            >{{ "applications.BEDROOMS" | translate
            }}<app-required
              [control]="addressesFormGroup.controls['number_of_bedrooms']"
            ></app-required
          ></label>
          <select
            class="form-control form-control-sm"
            formControlName="number_of_bedrooms"
            (change)="
              setUnitSize(addressesFormControl.number_of_bedrooms.value)
            "
          >
            <option value="-1" disabled>
              - {{ "applications.SELECT" | translate }} -
            </option>
            <option value="0">
              {{ "Efficiency" | transform | translate }}
            </option>
            <option value="1">{{ "1 Bedroom" | transform | translate }}</option>
            <option value="2">{{ "2 Bedroom" | transform | translate }}</option>
            <option value="3">{{ "3 Bedroom" | transform | translate }}</option>
            <option value="4">{{ "4 Bedroom" | transform | translate }}</option>
          </select>
          <!-- <input numbersOnly type="text" class="form-control" formControlName="number_of_bedrooms"> -->
          <small
            *ngIf="
              addressesFormControl.number_of_bedrooms.touched &&
              addressesFormControl.number_of_bedrooms.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            [disabled]="addressesFormGroup.invalid"
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Addresses end -------------------------------------->

<!------------------------ Landlord start ------------------------------------>
<!-- <ng-template #landlord let-modal>
  <div class="modal-header bg-light" style="flex-direction: column">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.LANDLORD_DETAILS" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="landlordFormGroup" (ngSubmit)="onLandlordSubmit()">
      <div class="row">
        <div class="col-md-4">
          <label
            >{{ "applications.LANDLORD_FIRST_NAME" | translate
            }}<app-required
              [control]="landlordFormGroup.controls['landlord_first_name']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="landlord_first_name"
            noSpecialChars
          />
          <small
            *ngIf="
              landlordFormControl.landlord_first_name.touched &&
              landlordFormControl.landlord_first_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
        <div class="col-md-4">
          <label
            >{{ "applications.LANDLORD_LAST_NAME" | translate
            }}<app-required
              [control]="landlordFormGroup.controls['landlord_last_name']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="landlord_last_name"
            noSpecialChars
          />
          <small
            *ngIf="
              landlordFormControl.landlord_last_name.touched &&
              landlordFormControl.landlord_last_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label
            >{{ "applications.LANDLORD_EMAIL" | translate
            }}<app-required
              [control]="landlordFormGroup.controls['landlord_email']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="landlord_email"
          />
          <small
            *ngIf="
              landlordFormControl.landlord_email.touched &&
              landlordFormControl.landlord_email.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="
              landlordFormControl.landlord_email.touched &&
              landlordFormControl.landlord_email.errors?.pattern
            "
            class="text-danger"
          >
            {{ "errors.INVALID_EMAIL" | translate }}
          </small>
        </div>
        <div class="col-md-6">
          <label
            >{{ "applications.LANDLORD_PHONE" | translate
            }}<app-required
              [control]="landlordFormGroup.controls['landlord_phone']"
            ></app-required
          ></label>
          <input
            type="text"
            mask="(000) 000-0000"
            class="form-control"
            formControlName="landlord_phone"
          />
          <small
            *ngIf="
              landlordFormControl.landlord_phone.touched &&
              landlordFormControl.landlord_phone.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <label
            >{{ "applications.PROPERTY_BUSINESS_NAME" | translate
            }}<app-required
              [control]="landlordFormGroup.controls['property_name']"
            ></app-required
          ></label>
          <input
            type="text"
            class="form-control"
            formControlName="property_name"
            placeholder="{{
              'applications.PROPERTY_BUSINESS_NAME_PLACEHOLDER' | translate
            }}"
          />
          <small
            *ngIf="
              landlordFormControl.property_name.touched &&
              landlordFormControl.property_name.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
        <div class="col-md-6">
          <label class="control-label"
            >{{ "applications.PROPERTY_TYPE" | translate
            }}<app-required
              [control]="landlordFormGroup.controls['property_type']"
            ></app-required
          ></label>
          <select class="form-control" formControlName="property_type">
            <option value="0" disabled>
              - {{ "applications.SELECT" | translate }} -
            </option>
            <option *ngFor="let pt of propertyTypes" value="{{ pt.Value }}">
              {{ pt.Name | transform | translate }}
            </option>
          </select>
          <small
            *ngIf="
              landlordFormControl.property_type.touched &&
              landlordFormControl.property_type.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            [disabled]="landlordFormGroup.invalid"
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template> -->
<!------------------------ Landlord end -------------------------------------->

<!------------------------ Rental Documents start ------------------------------------>
<ng-template #rentalDocuments let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.UPDATE_RENTAL_DOCUMENTATION" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }}
    </span>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="rentalDocumentsFormGroup"
      (ngSubmit)="onRentalDocumentsSubmit()"
    >
      <fieldset [disabled]="viewMode">
        <div class="row">
          <div class="col-md-12">
            <label>
              {{ "applications.LEASE_REQUIRED" | translate }}
              <app-required
                [control]="rentalDocumentsFormGroup.controls['file_lease']"
              ></app-required>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-10">
            <label>{{ "applications.PLEASE_NOTE" | translate }}:</label>
            <ul>
              <li>{{ "applications.LEASE_UPLOAD_1" | translate }}</li>
              <li>{{ "applications.LEASE_UPLOAD_2" | translate }}</li>
              <li>{{ "applications.LEASE_UPLOAD_3" | translate }}</li>
              <li>{{ "applications.LEASE_UPLOAD_4" | translate }}</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-10">
            <input
              type="file"
              (change)="
                onSelectFileUpload($event, 'file_name_lease', 'file_lease')
              "
            />
            <small
              *ngIf="!applicationData.file_lease_url"
              class="text-warning d-block"
            >
              {{ "applications.NOT_UPLOADED_YET" | translate }}
            </small>
            <div>
              <a
                *ngIf="applicationData.file_lease_url"
                href="{{ getFileRouteLink(applicationData.file_lease_url) }}"
                target="_blank"
                class="text-success2"
              >
                {{ applicationData.file_lease }}
              </a>
              <app-file-delete
                *ngIf="applicationData.file_lease_url"
                [recordId]="recordId"
                [tableId]="projectSpecificData.applicationsData.TableId"
                [modalConfig]="modelConfig"
                [fields]="['file_lease', 'file_lease_url']"
                [tryFn]="tryRentalDoc"
                [disabled]="viewMode"
              ></app-file-delete>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12"><br /></div>
        </div>
        <div class="row">
          <div class="col-md-12 hr_separator"></div>
        </div>
        <div class="row">
          <div class="col-md-12"><br /></div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>
              {{ "applications.PAST_DUE_RENT" | translate }}
              <app-required
                [control]="
                  rentalDocumentsFormGroup.controls['file_past_due_rent']
                "
              ></app-required
            ></label>
            <input
              type="file"
              (change)="
                onSelectFileUpload(
                  $event,
                  'file_name_past_due_rent',
                  'file_past_due_rent'
                )
              "
            />
            <small
              *ngIf="!applicationData.file_past_due_rent_url"
              class="text-warning d-block"
            >
              {{ "applications.NOT_UPLOADED_YET" | translate }}
            </small>
            <div>
              <a
                *ngIf="applicationData.file_past_due_rent_url"
                href="{{
                  getFileRouteLink(applicationData.file_past_due_rent_url)
                }}"
                class="text-success2"
                target="_blank"
              >
                {{ applicationData.file_past_due_rent }}
              </a>
              <app-file-delete
                *ngIf="applicationData.file_past_due_rent_url"
                [recordId]="recordId"
                [tableId]="projectSpecificData.applicationsData.TableId"
                [modalConfig]="modelConfig"
                [fields]="['file_past_due_rent', 'file_past_due_rent_url']"
                [tryFn]="tryRentalDoc"
                [disabled]="viewMode"
              ></app-file-delete>
            </div>
          </div>
          <div class="col-md-6">
            <label>
              {{ "applications.EVICTION" | translate }}
              <app-required
                [control]="
                  rentalDocumentsFormGroup.controls['file_eviction_notice']
                "
              ></app-required
            ></label>
            <input
              type="file"
              (change)="
                onSelectFileUpload(
                  $event,
                  'file_name_eviction_notice',
                  'file_eviction_notice'
                )
              "
            />
            <small
              *ngIf="!applicationData.file_eviction_notice_url"
              class="text-warning d-block"
            >
              {{ "applications.NOT_UPLOADED_YET" | translate }}
            </small>
            <div>
              <a
                *ngIf="applicationData.file_eviction_notice_url"
                href="{{
                  getFileRouteLink(applicationData.file_eviction_notice_url)
                }}"
                class="text-success2"
                target="_blank"
              >
                {{ applicationData.file_eviction_notice }}
              </a>
              <app-file-delete
                *ngIf="applicationData.file_eviction_notice_url"
                [recordId]="recordId"
                [tableId]="projectSpecificData.applicationsData.TableId"
                [modalConfig]="modelConfig"
                [fields]="['file_eviction_notice', 'file_eviction_notice_url']"
                [tryFn]="tryRentalDoc"
                [disabled]="viewMode"
              ></app-file-delete>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12"><br /></div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>
              {{ "applications.PAST_DUE_UTILITY" | translate }}
              <app-required
                [control]="
                  rentalDocumentsFormGroup.controls['file_past_due_utility']
                "
              ></app-required
            ></label>
            <input
              type="file"
              (change)="
                onSelectFileUpload(
                  $event,
                  'file_name_past_due_utility',
                  'file_past_due_utility'
                )
              "
            />
            <small
              *ngIf="!applicationData.file_past_due_utility_url"
              class="text-warning d-block"
            >
              {{ "applications.NOT_UPLOADED_YET" | translate }}
            </small>
            <div>
              <a
                *ngIf="applicationData.file_past_due_utility_url"
                href="{{
                  getFileRouteLink(applicationData.file_past_due_utility_url)
                }}"
                target="_blank"
                class="text-success2"
              >
                {{ applicationData.file_past_due_utility }}
              </a>
              <app-file-delete
                *ngIf="applicationData.file_past_due_utility_url"
                [recordId]="recordId"
                [tableId]="projectSpecificData.applicationsData.TableId"
                [modalConfig]="modelConfig"
                [fields]="[
                  'file_past_due_utility',
                  'file_past_due_utility_url'
                ]"
                [tryFn]="tryRentalDoc"
                [disabled]="viewMode"
              ></app-file-delete>
            </div>
          </div>
          <div class="col-md-6">
            <label>
              {{ "applications.OTHER" | translate }}
              <app-required
                [control]="rentalDocumentsFormGroup.controls['file_other']"
              ></app-required
            ></label>
            <input
              type="file"
              (change)="
                onSelectFileUpload($event, 'file_name_other', 'file_other')
              "
            />
            <small
              *ngIf="!applicationData.file_other_url"
              class="text-warning d-block"
            >
              {{ "applications.NOT_UPLOADED_YET" | translate }}
            </small>
            <div>
              <a
                *ngIf="applicationData.file_other_url"
                href="{{ getFileRouteLink(applicationData.file_other_url) }}"
                target="_blank"
                class="text-success2"
              >
                {{ applicationData.file_other }}
              </a>
              <app-file-delete
                *ngIf="applicationData.file_other_url"
                [recordId]="recordId"
                [tableId]="projectSpecificData.applicationsData.TableId"
                [modalConfig]="modelConfig"
                [fields]="['file_other', 'file_other_url']"
                [tryFn]="tryRentalDoc"
                [disabled]="viewMode"
              ></app-file-delete>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <!-- <button *ngIf=" !modelConfig.busy && !viewMode" type="submit"
						class="btn background-third-whitetext">{{ 'applications.UPDATE' | translate }}</button>
					<button *ngIf="modelConfig.busy" type="button" class="btn background-third-whitetext">
						<strong><i class="fa fa-cog fa-spin"></i> {{ 'applications.PLEASE_WAIT' | translate }}</strong>
					</button> -->
          <button
            type="button"
            (click)="closeModel(false)"
            class="btn btn-dark"
          >
            {{ "applications.CLOSE" | translate }}
          </button>
          <!-- <small [innerHTML]="'applications.SAVE_BTN_INFO_TEXT' | translate"></small> -->
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Rental Documents end -------------------------------------->

<!------------------------ Received Funds start ------------------------------------>
<ng-template #fundsTemp let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.UPDATE_RECEIVED_FUNDS" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }}
    </span>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="receivedFundsFormGroup"
      (ngSubmit)="onReceivedFundsSubmit()"
    >
      <div class="row">
        <div class="col-md-9">
          <label>
            {{ "applications.RECEIVED_FUNDS_QUESTION" | translate }}
          </label>
        </div>
        <div class="col-md-3">
          <mat-radio-group formControlName="received_funds">
            <mat-radio-button value="True">
              <strong>{{ "applications.YES" | translate }}</strong>
            </mat-radio-button>
            <mat-radio-button class="ml-3" value="False">
              <strong>{{ "applications.NO" | translate }}</strong>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div
        *ngIf="receivedFundsFormGroup.value['received_funds'] === 'True'"
        class="row mt-3"
      >
        <div class="col-md-12">
          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th width="30%">{{ "applications.CATEGORY" | translate }}</th>
                <th width="15%">{{ "applications.YESNO" | translate }}</th>
                <th width="20%">{{ "applications.AMOUNT" | translate }}</th>
                <th width="35%">{{ "applications.DOCUMENT" | translate }}</th>
                <!-- <th width="5%">*</th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label>
                    {{ "applications.RECEIVED_FUNDS_PPP" | translate }}</label
                  >
                </td>
                <td>
                  <mat-radio-group formControlName="received_funds_ppp">
                    <mat-radio-button value="True">
                      <strong>{{ "applications.YES" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button value="False" class="ml-3">
                      <strong>{{ "applications.NO" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
                <td>
                  <div
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_ppp'] ===
                      'True'
                    "
                    class="input-group input-group-sm"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      numbersOnly
                      type="text"
                      formControlName="received_funds_ppp_amount"
                      class="form-control form-control-sm"
                    />
                  </div>
                </td>
                <td>
                  <input
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_ppp'] ===
                      'True'
                    "
                    type="file"
                  />
                  <small
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_ppp'] ===
                        'True' && applicationData.file_funds_ppp_url
                    "
                  >
                    <a
                      href="{{
                        getFileRouteLink(applicationData.file_funds_ppp_url)
                      }}"
                      target="_blank"
                    >
                      {{ applicationData.file_funds_ppp }}
                    </a>
                  </small>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    {{ "applications.RECEIVED_FUNDS_SBA" | translate }}
                  </label>
                </td>
                <td>
                  <mat-radio-group formControlName="received_funds_sba">
                    <mat-radio-button value="True">
                      <strong>{{ "applications.YES" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button value="False" class="ml-3">
                      <strong>{{ "applications.NO" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
                <td>
                  <div
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_sba'] ===
                      'True'
                    "
                    class="input-group input-group-sm"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      numbersOnly
                      type="text"
                      formControlName="received_funds_sba_amount"
                      class="form-control form-control-sm"
                    />
                  </div>
                </td>
                <td>
                  <input
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_sba'] ===
                      'True'
                    "
                    type="file"
                  />
                  <small
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_sba'] ===
                        'True' && applicationData.file_funds_sba_url
                    "
                  >
                    <a
                      href="{{
                        getFileRouteLink(applicationData.file_funds_sba_url)
                      }}"
                      target="_blank"
                    >
                      {{ applicationData.file_funds_sba }}
                    </a>
                  </small>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    {{ "applications.RECEIVED_FUNDS_SBA_GRANT" | translate }}
                  </label>
                </td>
                <td>
                  <mat-radio-group formControlName="received_funds_eidl_grant">
                    <mat-radio-button value="True">
                      <strong>{{ "applications.YES" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button value="False" class="ml-3">
                      <strong>{{ "applications.NO" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
                <td>
                  <div
                    *ngIf="
                      receivedFundsFormGroup.value[
                        'received_funds_eidl_grant'
                      ] === 'True'
                    "
                    class="input-group input-group-sm"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      numbersOnly
                      type="text"
                      formControlName="received_funds_eidl_grant_amount"
                      class="form-control form-control-sm"
                    />
                  </div>
                </td>
                <td>
                  <input
                    *ngIf="
                      receivedFundsFormGroup.value[
                        'received_funds_eidl_grant'
                      ] === 'True'
                    "
                    type="file"
                  />
                  <small
                    *ngIf="
                      receivedFundsFormGroup.value[
                        'received_funds_eidl_grant'
                      ] === 'True' && applicationData.file_funds_eidl_grant_url
                    "
                  >
                    <a
                      href="{{
                        getFileRouteLink(
                          applicationData.file_funds_eidl_grant_url
                        )
                      }}"
                      target="_blank"
                    >
                      {{ applicationData.file_funds_eidl_grant }}
                    </a>
                  </small>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    {{ "applications.RECEIVED_FUNDS_ARDC" | translate }}
                  </label>
                </td>
                <td>
                  <mat-radio-group formControlName="received_funds_edc">
                    <mat-radio-button value="True">
                      <strong>{{ "applications.YES" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button value="False" class="ml-3">
                      <strong>{{ "applications.NO" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
                <td>
                  <div
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_edc'] ===
                      'True'
                    "
                    class="input-group input-group-sm"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      numbersOnly
                      type="text"
                      formControlName="received_funds_edc_amount"
                      class="form-control form-control-sm"
                    />
                  </div>
                </td>
                <td>
                  <input
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_edc'] ===
                      'True'
                    "
                    type="file"
                  />
                  <small
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_edc'] ===
                        'True' && applicationData.file_funds_edc_url
                    "
                  >
                    <a
                      href="{{
                        getFileRouteLink(applicationData.file_funds_edc_url)
                      }}"
                      target="_blank"
                    >
                      {{ applicationData.file_funds_edc }}
                    </a>
                  </small>
                </td>
              </tr>

              <tr>
                <td>
                  <label>
                    {{ "applications.RECEIVED_FUNDS_ANY_GRANT" | translate }}
                  </label>
                </td>
                <td>
                  <mat-radio-group formControlName="received_funds_any">
                    <mat-radio-button value="True">
                      <strong>{{ "applications.YES" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button value="False" class="ml-3">
                      <strong>{{ "applications.NO" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
                <td>
                  <div
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_any'] ===
                      'True'
                    "
                    class="input-group input-group-sm"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      numbersOnly
                      type="text"
                      formControlName="received_funds_any_amount"
                      class="form-control form-control-sm"
                    />
                  </div>
                </td>
                <td>
                  <input
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_any'] ===
                      'True'
                    "
                    type="file"
                  />
                  <small
                    *ngIf="
                      receivedFundsFormGroup.value['received_funds_any'] ===
                        'True' && applicationData.file_funds_any_url
                    "
                  >
                    <a
                      href="{{
                        getFileRouteLink(applicationData.file_funds_any_url)
                      }}"
                      target="_blank"
                    >
                      {{ applicationData.file_funds_any }}
                    </a>
                  </small>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    {{ "applications.RECEIVED_FUNDS_DONATIONS" | translate }}
                  </label>
                </td>
                <td>
                  <mat-radio-group formControlName="received_funds_donations">
                    <mat-radio-button value="True">
                      <strong>{{ "applications.YES" | translate }}</strong>
                    </mat-radio-button>
                    <mat-radio-button value="False" class="ml-3">
                      <strong>{{ "applications.NO" | translate }}</strong>
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
                <td>
                  <div
                    *ngIf="
                      receivedFundsFormGroup.value[
                        'received_funds_donations'
                      ] === 'True'
                    "
                    class="input-group input-group-sm"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input
                      numbersOnly
                      type="text"
                      formControlName="received_funds_donations_amount"
                      class="form-control form-control-sm"
                    />
                  </div>
                </td>
                <td>
                  <input
                    *ngIf="
                      receivedFundsFormGroup.value[
                        'received_funds_donations'
                      ] === 'True'
                    "
                    type="file"
                  />
                  <small
                    *ngIf="
                      receivedFundsFormGroup.value[
                        'received_funds_donations'
                      ] === 'True' && applicationData.file_funds_donations_url
                    "
                  >
                    <a
                      href="{{
                        getFileRouteLink(
                          applicationData.file_funds_donations_url
                        )
                      }}"
                      target="_blank"
                    >
                      {{ applicationData.file_funds_donations }}
                    </a>
                  </small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-8">
          <button
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------Received Funds end -------------------------------------->

<!------------------------ Acknowledgements start ------------------------------------>
<ng-template #ackw let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.UPDATE_ACKNOWLEDGEMENTS" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }}
    </span>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="acknowledgementsFormGroup"
      (ngSubmit)="onAcknowledgementsSubmit()"
    >
      <fieldset [disabled]="viewMode">
        <div class="table-responsive">
          <table class="table table-sm table-bordered">
            <tr>
              <td colspan="3">
                <label>
                  <h4>{{ "RN_CERT_ACK_INTRO" | translate }}:</h4>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <label>
                  <h4>{{ "applications.CERTIFICATIONS" | translate }}:</h4>
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="certify"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "CERTRENTER" + ".CERT1" | translate }} <br />
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['certify'],
                    'text-danger': !acknowledgementsFormGroup.value['certify']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <label>
                  <h4>{{ "applications.ACKNOWLEDGEMENTS" | translate }}:</h4>
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK1" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack'],
                    'text-danger': !acknowledgementsFormGroup.value['ack']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack_2"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK2" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack_2'],
                    'text-danger': !acknowledgementsFormGroup.value['ack_2']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack_3"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK3" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack_3'],
                    'text-danger': !acknowledgementsFormGroup.value['ack_3']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack_4"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK4" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack_4'],
                    'text-danger': !acknowledgementsFormGroup.value['ack_4']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack_5"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK5" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack_5'],
                    'text-danger': !acknowledgementsFormGroup.value['ack_5']
                  }"
                >
                </i>
              </td>
            </tr>
          </table>
        </div>

        <div class="row">
          <div class="col">
            {{ "RN_CERT_ACK_CONFIRM" | translate }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <br />
          </div>
        </div>
        <div class="row" *ngIf="checkCertifyDate()">
          <div class="col">
            {{ "ACK6_V3" | translate }}
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <label
              >{{ "applications.CERTIFIER_NAME" | translate
              }}<app-required
                [control]="acknowledgementsFormGroup.controls['certifier_name']"
              ></app-required
            ></label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="certifier_name"
            />
            <small
              *ngIf="
                acknowledgementsFormControl.certifier_name.touched &&
                acknowledgementsFormControl.certifier_name.errors?.required
              "
              class="text-danger"
            >
              {{ "errors.REQUIRED" | translate }}
            </small>
          </div>
          <!-- <div class="col-md-6">
					<label>{{ 'applications.CERTIFIER_TITLE' | translate }}</label>
					<input type="text" class="form-control form-control-sm" formControlName="certifier_title">
					<small
						*ngIf="acknowledgementsFormControl.certifier_title.touched &&
						acknowledgementsFormControl.certifier_title.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div> -->
        </div>

        <div class="row mt-3">
          <div class="col-md-3">
            <label
              >{{ "applications.DRIVERS_LICENSE_IMAGE" | translate }}
              <app-required [mark]="true"></app-required
            ></label>
          </div>
          <div class="col-md">
            <input type="file" (change)="handleDLUpoad($event)" />
            <br />
            <a
              *ngIf="applicationData.file_drivers_license_url"
              href="{{
                getFileRouteLink(applicationData.file_drivers_license_url)
              }}"
              target="_blank"
              class="text-success2"
            >
              {{ applicationData.file_drivers_license }}
            </a>
            <app-file-delete
              *ngIf="applicationData.file_drivers_license_url"
              [recordId]="recordId"
              [tableId]="projectSpecificData.applicationsData.TableId"
              [modalConfig]="modelConfig"
              [fields]="['file_drivers_license', 'file_drivers_license_name']"
              [tryFn]="tryDriving"
              [disabled]="viewMode"
            ></app-file-delete>
            <small
              *ngIf="!applicationData.file_drivers_license_url"
              class="text-danger"
            >
              {{ "errors.FILE_REQUIRED" | translate }}
            </small>
          </div>
        </div>
      </fieldset>

      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            *ngIf="!modelConfig.busy && !viewMode"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.UPDATE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Acknowledgements end -------------------------------------->

<!------------------------ Bank Details start ------------------------------------>
<!-- <ng-template #bank let-modal>
	<div class="modal-header bg-light">
		<h4 class="modal-title" id="modal-basic-title">
			{{ 'applications.UPDATE_BANK_INFORMATION' | translate }}
		</h4>
	</div>
	<div class="modal-body">
		<form [formGroup]="bankDetailsFormGroup" (ngSubmit)="onBankDetailsSubmit()">
			<div class="row">
				<div class="col-md-6">
					<label>{{ 'applications.BANK_NAME' | translate }}</label>
					<input type="text" class="form-control form-control-sm" formControlName="bank_name">
					<small
						*ngIf="bankDetailsFormControl.bank_name.touched &&
						bankDetailsFormControl.bank_name.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
				<div class="col-md-6">
					<label>
						{{ 'applications.BANK_BRANCH' | translate }}
						<i
							class="fa fa-question-circle"
							data-toggle="tooltip"
							title="Please provide the Bank Branch name and/or address information">
						</i>
					</label>
					<input type="text" class="form-control form-control-sm" formControlName="bank_location">
					<small
						*ngIf="bankDetailsFormControl.bank_location.touched &&
						bankDetailsFormControl.bank_location.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-md-6">
					<label>
						{{ 'applications.NAME_ON_ACCOUNT' | translate }}
						<i
							class="fa fa-question-circle"
							data-toggle="tooltip"
							title="Please provide the name on the bank account into which grant funds will be deposited ">
						</i>
					</label>
					<input type="text" class="form-control form-control-sm" formControlName="bank_account_name">
					<small
						*ngIf="bankDetailsFormControl.bank_account_name.touched &&
						bankDetailsFormControl.bank_account_name.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
				<div class="col-md-6">
					<label>{{ 'applications.ACCOUNT_TYPE' | translate }}</label>
					<select class="form-control form-control-sm" formControlName="bank_account_type">
						<option value="" hidden>- Select -</option>
						<option *ngFor="let optn of bankAccountTypes" value="{{optn.value}}">{{optn.name}}</option>
					</select>
					<small
						*ngIf="bankDetailsFormControl.bank_account_type.touched &&
						bankDetailsFormControl.bank_account_type.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-md-6">
					<label>{{ 'applications.ROUTING_NUMBER' | translate }}</label>
					<input
						type="text"
						mask="000000000"
						(change)="OnChangeRoutingNumber($event.target.value)"
						class="form-control form-control-sm"
						formControlName="bank_routing_number">
					<small
						*ngIf="bankDetailsFormControl.bank_routing_number.touched &&
						bankDetailsFormControl.bank_routing_number.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
					<small
						class="text-success2"
						*ngIf="bankName && !bankDetailsFormControl.bank_routing_number.errors?.required">
						{{ 'applications.BANK_NAME' | translate }} : {{bankName}}
					</small>
				</div>
				<div class="col-md-6">
					<label>{{ 'applications.CONFIRM_ROUTING_NUMBER' | translate }}</label>
					<input
						type="text"
						mask="000000000"
						class="form-control form-control-sm"
						formControlName="confirm_bank_routing_number">
					<small
						*ngIf="bankDetailsFormControl.confirm_bank_routing_number.touched &&
						bankDetailsFormControl.confirm_bank_routing_number.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
					<small
						*ngIf="bankDetailsFormControl.confirm_bank_routing_number.touched &&
						bankDetailsFormControl.confirm_bank_routing_number.errors?.mustMatch"
						class="text-danger">
						{{ 'errors.ROUTING_NUMBERS_DO_NOT_MATCH' | translate }}
					</small>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-md-6">
					<label>{{ 'applications.ACCOUNT_NUMBER' | translate }}</label>
					<input
						type="text"

						class="form-control form-control-sm"
						formControlName="bank_account_number">
					<small
						*ngIf="bankDetailsFormControl.bank_account_number.touched &&
						bankDetailsFormControl.bank_account_number.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
				</div>
				<div class="col-md-6">
					<label>{{ 'applications.CONFIRM_ACCOUNT_NUMBER' | translate }}</label>
					<input
						type="text"
						class="form-control form-control-sm"
						formControlName="confirm_bank_account_number">
					<small
						*ngIf="bankDetailsFormControl.confirm_bank_account_number.touched &&
						bankDetailsFormControl.confirm_bank_account_number.errors?.required"
						class="text-danger">
						{{ 'errors.REQUIRED' | translate }}
					</small>
					<small
						*ngIf="bankDetailsFormControl.confirm_bank_account_number.touched &&
						bankDetailsFormControl.confirm_bank_account_number.errors?.mustMatch"
						class="text-danger">
						{{ 'errors.ACCOUNT_NUMBERS_DO_NOT_MATCH' | translate }}
					</small>
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-md-12">
					<button
						*ngIf=" !modelConfig.busy && !viewMode"
						type="submit"
						class="btn background-third-whitetext">
						{{ 'applications.UPDATE' | translate }}
					</button>
					<button
						*ngIf="modelConfig.busy"
						type="button"
						class="btn background-third-whitetext">
						<strong>
							<i class="fa fa-cog fa-spin"></i> {{ 'applications.PLEASE_WAIT' | translate }}
						</strong>
					</button>
					<button
						type="button"
						(click)="closeModel()"
						class="btn btn-dark">
						{{ 'applications.CANCEL' | translate }}
					</button>
				</div>
			</div>
		</form>
	</div>
</ng-template> -->
<!------------------------ Bank Details end -------------------------------------->


<!------------------------ Expenses Acknowledgements start ------------------------------------>
<ng-template #expack let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.EXPENSES_ACKNOWLEDGEMENTS" | translate }}
      </h4>
    </span>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="expensesAcknowledgementsFormGroup"
      (ngSubmit)="onExpensesAcknowledgementsSubmit(true)"
    >
      <fieldset [disabled]="viewMode">
        <div class="table-responsive">
          <table class="table table-sm table-bordered">
            <tr>
              <td colspan="3">
                <label>
                  <h4>{{ "applications.SUBMIT_APPLICATION_POPUP1" | translate }}</h4>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <ul>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP2" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP3" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP4" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP5" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP6" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP7" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP8" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP9" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP10" | translate }}</h5></li>
                  <li><h5>{{ "applications.SUBMIT_APPLICATION_POPUP11" | translate }}</h5></li>
                </ul>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <label>
                  <h4>{{ "applications.SUBMIT_APPLICATION_POPUP12" | translate }}</h4>
                </label>
              </td>
            </tr>
          </table>
        </div>
      </fieldset>
      <div class="row mt-3">
        <div class="col-md-12 btn-responsive">
          <button
            *ngIf="!modelConfig.busy && !viewMode2"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.SUBMIT" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
          <small
            [innerHTML]="'applications.UPDATE_BTN_INFO_TEXT' | translate"
          ></small>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!------------------------ Expenses Acknowledgements end -------------------------------------->






<!--------------------------------- BUBBLES START --------------------------------->

<ng-template #addressDetailsInfo>
  <h3>{{ "applications.ADDRESS_DETAILS" | translate }}</h3>
  <p>{{ "BUBBLE.ADDRESS_DETAILS" | translate }}</p>
</ng-template>

<!-- <ng-template #LLDetailsInfo>
  <h3>{{ "applications.LANDLORD_DETAILS" | translate }}</h3>
  <p>{{ "BUBBLE.LANDLORD_DETAILS" | translate }}</p>
</ng-template> -->

<ng-template #rentalDetailsInfo>
  <h3>{{ "applications.RENTAL_DOCUMENTATION" | translate }}</h3>
  <p>{{ "BUBBLE.RENTAL_DOCUMENTATION" | translate }}</p>
</ng-template>

<ng-template #hhDetailsInfo>
  <h3>{{ "applications.HOUSEHOLD_DETAILS" | translate }}</h3>
  <label>{{ "BUBBLE.HOUSEHOLD_DETAILS" | translate }}</label>
  <ol>
    <li>{{ "BUBBLE.HH_1" | translate }}</li>
    <li>{{ "BUBBLE.HH_2" | translate }}</li>
    <li>{{ "BUBBLE.HH_3" | translate }}</li>
    <li>{{ "BUBBLE.HH_4" | translate }}</li>
    <li>{{ "BUBBLE.HH_5" | translate }}</li>
  </ol>
</ng-template>

<ng-template #yearBuildInfo>
  <h3>{{ "applications.YEAR_RENTAL_UNIT_BUILT" | translate }}</h3>
  <label>{{ "BUBBLE.YEAR_RENTAL_UNIT_BUILT" | translate }}</label>
  <!-- <ol>
    <li>{{ "BUBBLE.HH_1" | translate }}</li>
    <li>{{ "BUBBLE.HH_2" | translate }}</li>
    <li>{{ "BUBBLE.HH_3" | translate }}</li>
    <li>{{ "BUBBLE.HH_4" | translate }}</li>
    <li>{{ "BUBBLE.HH_5" | translate }}</li>
  </ol> -->
</ng-template>

<ng-template #expensesDetailsInfo>
  <h3>{{ "applications.EXPENSES" | translate }}</h3>
  <ol>
    <li>{{ "BUBBLE.EX_1" | translate }}</li>
    <li>{{ "BUBBLE.EX_2" | translate }}</li>
    <li>{{ "BUBBLE.EX_3" | translate }}</li>
    <li>{{ "BUBBLE.EX_4" | translate }}</li>
    <li>{{ "BUBBLE.EX_5" | translate }}</li>
  </ol>
</ng-template>

<ng-template #ackDetailsInfo>
  <h3>{{ "applications.ACKNOWLEDGEMENTS" | translate }}</h3>
  <p>{{ "BUBBLE.ACKNOWLEDGEMENTS" | translate }}</p>
</ng-template>

<!-- <ng-template #addTicket>
  <button class="float2-btn" style="top: 200px; z-index: 999">
    <i class="fa fa-envelope"></i>
  </button>
</ng-template> -->

<!--------------------------------- BUBBLES END --------------------------------->

<!-- ================================================================================================ -->
<!--------------------------------------------- Popups Ends here---------------------------------------->
<!-- ================================================================================================ -->
