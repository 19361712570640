import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from './dashboard.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: []
})
export class DashboardComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private dashboardService: DashboardService
  ) {
    translate.setDefaultLang('en');
  }

  languageSubject: any;

  ngOnInit() {
    this.languageSubject = this.dashboardService.language
      .subscribe(data => {
        this.translate.use(data)
      })

    // this.spinner.hide();
  }

  ngOnDestroy() {
    this.languageSubject.unsubscribe()
  }


}