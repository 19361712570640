<i *ngIf="!template" class="fa fa-plus-circle"></i>
<ng-container *ngTemplateOutlet="template"></ng-container>
<ng-template #modal let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4 class="modal-title" id="modal-basic-title">
        {{ "applications.CREATE_TICKET" | translate }}
      </h4>
    </span>
  </div>
  <div class="modal-body" *ngIf="render">
    <form [formGroup]="ticketForm" (ngSubmit)="submitTicket()">
      <div class="form-group">
        <div class="row">
          <div class="col-md">
            <label
              >{{ "applications.TICKET_TYPE" | translate
              }}<app-required
                [control]="getControl(ticketForm, 'ticket_type')"
              ></app-required
            ></label>

             <!-- (item?.Value === 'Recertification' && showRecertificationTicket) ||
                    (item?.Value === 'Utilities' && showUtilityTicket) || -->
            <select class="form-control" formControlName="ticket_type">
              <option value="" disabled>
                - {{ "applications.SELECT" | translate }} -
              </option>
              <ng-container *ngFor="let item of ticketTypes">
                <option
                  *ngIf="
                    (item?.Value !== 'Utilities' && item?.Value != 'Recertification' && item?.Value !== 'Direct Payment Form') || 
                    (item?.Value === 'Direct Payment Form' && showDirectPaymentTicket)
                  "
                  [value]="item?.Value"
                >
                  <!--
                  (selectedApp?.uncooperative_landlord === 'TRUE' &&
                      applicantType === 'Applicant' &&
                      item.value === 'Direct Payment Form') -->

                  {{ item?.Name | transform | translate }}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="col-md" *ngIf="appId === -1">
            <label
              >{{ "applications.APPLICATION" | translate
              }}<app-required
                [control]="getControl(ticketForm, 'related_applications')"
              ></app-required
            ></label>
            <select class="form-control" formControlName="related_applications">
              <option value="" disabled>
                - {{ "applications.SELECT" | translate }} -
              </option>
              <option *ngFor="let item of applications" [value]="item.id">
                {{ getDisplayName(item) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div
            class="col-md"
            *ngIf="
              ticketForm.controls.ticket_type.value !== 'Attestation' &&
              ticketForm.value.ticket_type !== 'Utilities' &&
              ticketForm.controls.ticket_type.value !== 'Direct Payment Form' &&
              ticketForm.value.ticket_type !== 'Recertification'
            "
          >
            <label
              >{{ "applications.SUBJECT" | translate
              }}<app-required
                [control]="getControl(ticketForm, 'subject')"
              ></app-required
            ></label>
            <input type="text" class="form-control" formControlName="subject" />
          </div>
          <div
            class="col-md"
            *ngIf="ticketForm.value.ticket_type === 'Utilities'"
          >
            <label
              >{{ "applications.EXPENSE_TYPE" | translate
              }}<app-required [mark]="true"></app-required
            ></label>
            <select class="form-control" formControlName="expense_type">
              <option value="" disabled>
                - {{ "applications.SELECT" | translate }} -
              </option>
              <ng-container *ngFor="let item of expenseTypes">
                <option
                  *ngIf="!item?.Name.includes('Rent')"
                  [value]="item?.Value"
                >
                  {{ item?.Name | transform | translate }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          ticketForm.controls.ticket_type.value !== 'Attestation' &&
          ticketForm.value.ticket_type !== 'Utilities' &&
          ticketForm.controls.ticket_type.value !== 'Direct Payment Form' &&
          ticketForm.value.ticket_type !== 'Recertification'
        "
        class="form-group"
      >
        <label
          >{{ "applications.DETAILS" | translate
          }}<app-required
            [control]="getControl(ticketForm, 'details')"
          ></app-required
        ></label>
        <textarea
          class="form-control"
          rows="5"
          formControlName="details"
        ></textarea>
      </div>
      <div *ngIf="ticketForm.value.ticket_type === 'Utilities'">
        <div class="row" formGroupName="utilities">
          <ng-container
            *ngIf="ticketForm.value.expense_type === 'Utility - Electricity'"
          >
            <div class="col-lg">
              <label
                >{{ "applications.UTILITY_PROVIDER" | translate }}
                <app-required [mark]="true"></app-required
              ></label>
              <div class="input-group input-group-sm">
                <select class="form-control" formControlName="utility_provider">
                  <option value="" disabled>
                    - {{ "applications.SELECT" | translate }} -
                  </option>
                  <option
                    *ngFor="let p of utilityProviders"
                    value="{{ p.Value }}"
                  >
                    {{ p.Name | transform | translate }}
                  </option>
                </select>
                <input
                  *ngIf="
                    ticketForm.value.utilities.utility_provider === 'Other'
                  "
                  type="text"
                  formControlName="electricity_company_other"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="ticketForm.value.expense_type !== 'Utility - Electricity'"
            class="col-md-6"
          >
            <div class="form-group">
              <label
                >{{ "applications.UTILITY_PROVIDER" | translate
                }}<app-required [mark]="true"></app-required
              ></label>
              <input
                type="text"
                class="form-control"
                formControlName="utility_provider"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ "applications.NAME_ON_ACCOUNT" | translate
                }}<app-required [mark]="true"></app-required
              ></label>
              <input
                type="text"
                class="form-control"
                formControlName="name_on_account"
              />
              <div class="d-flex align-items-center">
                <input
                  formControlName="name_on_account_hh_member"
                  type="checkbox"
                  name="form-control"
                />
                <small class="ml-1">{{
                  "applications.IS_ACC_HOLDER_MEMBER" | translate
                }}</small>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ "applications.ACCOUNT_NUMBER" | translate
                }}<app-required [mark]="true"></app-required
              ></label>
              <input
                type="text"
                class="form-control"
                formControlName="account_number"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ "applications.TOTAL_AMOUNT_DUE" | translate
                }}<app-required [mark]="true"></app-required
              ></label>
              <input
                [attr.disabled]="
                  ticketForm.value.expense_type === 'Utility - Electricity' &&
                  ticketForm.controls.utilities.get('utility_provider')
                    .value === 'Entergy'
                    ? true
                    : null
                "
                type="number"
                class="form-control"
                formControlName="amount_past_due"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label
                >{{ "applications.COMMENTS" | translate
                }}<app-required [mark]="true"></app-required
              ></label>
              <textarea
                class="form-control"
                rows="5"
                formControlName="comments"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label
                >{{ "applications.NO_MONTH_APPLY_FOR_PAST_DUE" | translate
                }}<app-required [mark]="true"></app-required
              ></label>
              <input
                min="0"
                max="99"
                type="number"
                class="form-control"
                formControlName="utility_months_in_arrears"
              />
            </div>
          </div>
        </div>
      </div>

      <fieldset
        [disabled]="
          ticketForm.value.ticket_type !== 'Technical Support' &&
          ticketForm.value.ticket_type !== 'Response to Case Manager Request' &&
          ticketForm.value.ticket_type !== 'Utilities'
        "
        *ngIf="
          ticketForm.value.ticket_type === 'Technical Support' ||
          ticketForm.value.ticket_type === 'Response to Case Manager Request' ||
          ticketForm.value.ticket_type === 'Utilities'
        "
      >
        <div class="form-group">
          <label>{{ "applications.DOCUMENTS" | translate }}</label>
          <div>{{ "applications.FILE_LIMIT_SIZE" | translate }}</div>
          <small *ngIf="ticketForm.value.ticket_type !== 'Recertification'">{{
            "applications.EXAMPLE_OF_DOCS_COMM" | translate
          }}</small>
          <small *ngIf="ticketForm.value.ticket_type === 'Recertification'">{{
            "applications.EXAMPLE_OF_DOCS_COMM_RECERTIFICATION" | translate
          }}</small>
          <div class="d-flex">
            <app-required
              *ngIf="ticketForm.value.ticket_type !== 'Recertification'"
              [control]="getControl(ticketForm, 'file_doc_1')"
            ></app-required>
            <input
              type="file"
              class="form-control border-0"
              (change)="loadFile($event, 'file_doc_1', 'file_doc_1_url')"
            />
          </div>
          <input
            type="file"
            class="form-control border-0"
            (change)="loadFile($event, 'file_doc_2', 'file_doc_2_url')"
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="loadFile($event, 'file_doc_3', 'file_doc_3_url')"
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="loadFile($event, 'file_doc_4', 'file_doc_4_url')"
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="loadFile($event, 'file_doc_5', 'file_doc_5_url')"
          />
        </div>
      </fieldset>

      <div *ngIf="ticketForm.controls.ticket_type.value === 'Attestation'">
        <label class="mb-2">{{ "applications.DETAILS" | translate }}</label>
        <div>
          {{ "applications.TENANT_NAME" | translate }}:
          {{ attestationData.tenant || "_________________" }}
          {{ "applications.ATTESTATION_TENANT" | translate }}
        </div>
        <div>
          {{ "applications.UNIT_ADDRESS" | translate }}:
          {{ attestationData.unitAddress || "_________________" }}
          {{ "applications.ATTESTATION_UNIT" | translate }}
        </div>
        <div>
          {{ "applications.LANDLORD_NAME" | translate }}:
          {{ attestationData.landlordName || "_________________" }}
          {{ "applications.ATTESTATION_LANDLORD" | translate }}
        </div>
        <div class="mb-2">
          {{ "applications.APPLICATION_ID" | translate }}: #:
          {{ attestationData.appId || "_________________" }}
        </div>
        <p>{{ "applications.ATTESTATION_TEXT_1" | translate }}:</p>
        <P> {{ "applications.ATTESTATION_TEXT_2" | translate }}: </P>
        <ol>
          <li>
            {{ "applications.ATTESTATION_PT_1" | translate }}
          </li>
          <li>
            {{ "applications.ATTESTATION_PT_2" | translate }}
          </li>
          <li>
            {{ "applications.ATTESTATION_PT_3" | translate }}
          </li>
          <li>
            {{ "applications.ATTESTATION_PT_4" | translate }}
          </li>
          <li>
            {{ "applications.ATTESTATION_PT_5" | translate }}
          </li>
        </ol>
        <p>
          {{ "applications.ATTESTATION_TEXT_3" | translate }}
        </p>
        <!-- <div>
          <label for=""
            ><i>{{ "applications.ENTER_YOUR_NAME" | translate }}</i
            ><input
              [(ngModel)]="nameField"
              [ngModelOptions]="{ standalone: true }"
              class="ml-2 name-field"
          /></label>
        </div> -->
        <p>
          {{ "applications.ATTESTATION_WARNING" | translate }}
        </p>
      </div>

      <div
        *ngIf="ticketForm.controls.ticket_type.value === 'Direct Payment Form'"
      >
        <label class="mb-2">{{ "applications.DETAILS" | translate }}</label>
        <div>
          {{ "applications.TENANT_NAME" | translate }}:
          {{ attestationData.tenant || "_________________" }}
          <!-- {{ "applications.ATTESTATION_TENANT" | translate }} -->
        </div>
        <div>
          {{ "applications.UNIT_ADDRESS" | translate }}:
          {{ attestationData.unitAddress || "_________________" }}
          <!-- {{ "applications.ATTESTATION_UNIT" | translate }} -->
        </div>
        <div>
          {{ "applications.LANDLORD_NAME" | translate }}:
          {{ attestationData.landlordName || "_________________" }}
          <!-- {{ "applications.ATTESTATION_LANDLORD" | translate }} -->
        </div>
        <div class="mb-2">
          {{ "applications.APPLICATION_ID" | translate }}: #:
          {{ attestationData.appId || "_________________" }}
        </div>
        <p>{{ "applications.DIRECT_PF_TEXT_1" | translate }}:</p>
        <P> {{ "applications.DIRECT_PF_TEXT_2" | translate }}: </P>
        <ol>
          <li>
            {{ "applications.DIRECT_PF_PT_1" | translate }}
          </li>
          <li>
            {{ "applications.DIRECT_PF_PT_2" | translate }}
          </li>
          <li>
            {{ "applications.DIRECT_PF_PT_3" | translate }}
          </li>
          <li>
            {{ "applications.DIRECT_PF_PT_4" | translate }}
          </li>
          <li>
            {{ "applications.DIRECT_PF_PT_5" | translate }}
          </li>
        </ol>
        <p>
          {{ "applications.DIRECT_PF_TEXT_3" | translate }}
        </p>
        <!-- <div>
          <label for=""
            ><i>{{ "applications.ENTER_YOUR_NAME" | translate }}</i
            ><input
              [(ngModel)]="nameField"
              [ngModelOptions]="{ standalone: true }"
              class="ml-2 name-field"
          /></label>
        </div> -->
        <p>
          {{ "applications.ATTESTATION_WARNING" | translate }}
        </p>
      </div>

      <div
        class="form-group"
        *ngIf="
          ticketForm.controls.ticket_type.value === 'Attestation' ||
          ticketForm.controls.ticket_type.value === 'Direct Payment Form'
        "
      >
        <label
          >{{ "applications.ENTER_YOUR_NAME" | translate
          }}<app-required
            [control]="getControl(ticketForm, 'subject')"
          ></app-required
        ></label>
        <input type="text" class="form-control" formControlName="subject" />
      </div>

      <div
        class="form-group"
        *ngIf="ticketForm.controls.ticket_type.value === 'Direct Payment Form'"
      >
        <label
          >{{ "applications.DATE" | translate
          }}<app-required
            [control]="getControl(ticketForm, 'date')"
          ></app-required
        ></label>
        <input type="date" class="form-control" formControlName="date" />
      </div>

      <div *ngIf="ticketForm.value.ticket_type === 'Utilities'">
        <ng-container *ngTemplateOutlet="ackw"></ng-container>
      </div>

      <div
        *ngIf="
          ticketForm.controls.ticket_type.value === 'Recertification' &&
          ticketForm.controls.related_applications.value != ''
        "
      >
        <div formGroupName="recertifications">
          <label class="mb-2">{{ "applications.DETAILS" | translate }}</label>
          <div class="mb-2">
            {{ "applications.APPLICATION_ID" | translate }}: #:
            {{ recertificationData.appId || "_________________" }}
          </div>
          <div class="mb-2">
            {{ "applications.TENANT_NAME" | translate }}:
            {{ recertificationData.tenant || "_________________" }}
            {{ "applications.ATTESTATION_TENANT" | translate }}
          </div>
          <div class="mb-2">
            {{ "applications.CONTACT_PHONE" | translate }}:
            {{ recertificationData.contactPhone || "_________________" }}
            {{ "applications.ATTESTATION_CONTACT_PHONE" | translate }}
          </div>
          <div class="mb-2">
            {{ "applications.LANDLORD_NAME" | translate }}:
            {{ recertificationData.landlordName || "_________________" }}
            {{ "applications.ATTESTATION_LANDLORD" | translate }}
          </div>
          <div class="mb-2">
            {{ "applications.RENTAL_ADDRESS" | translate }}:
            {{ recertificationData.rentalAddress || "_________________" }}
            {{ "applications.ATTESTATION_RENTAL_ADDRESS" | translate }}
          </div>
          <div class="mb-2">
            {{ "applications.MONTHLY_RENT_AMOUNT" | translate }}: ${{
              recertificationData.monthlyRentAmount || "_________________"
            }}
            {{ "applications.ATTESTATION_MONTHLY_RENT_AMOUNT" | translate }}
          </div>
          <hr />

          <!-- ADDRESS CHANGED -->
          <p>
            {{ "applications.RECERTIFICATION_TEXT_1a" | translate }}
          </p>
          <p>
            {{ "applications.RECERTIFICATION_TEXT_2" | translate }}
          </p>
          <div class="mb-2">
            <mat-radio-group formControlName="recert_address_changed">
              <mat-radio-button value="True">
                <strong>{{ "applications.YES" | translate }}</strong>
              </mat-radio-button>
              <mat-radio-button value="False" class="ml-2">
                <strong>{{ "applications.NO" | translate }}</strong>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <p>
            <i>{{ "applications.RECERTIFICATION_TEXT_3" | translate }}</i>
          </p>

          <div
            *ngIf="
              ticketForm.controls.recertifications.get('recert_address_changed')
                .value === 'False'
            "
          >
            <div class="mb-2">
              <p>
                <em
                  ><u>{{ "applications.CURRENT_ADDRESS" | translate }}</u></em
                >
              </p>
            </div>
            <div class="row col-md-12">
              <div class="mb-2 col-md-6">
                <label class="mt-2 mb-0">
                  {{ "applications.CURRENT_RENT_ADDRESS_1" | translate }}
                  <app-required
                    [control]="
                      getControl(
                        $any(ticketForm.controls.recertifications),
                        'recert_address_1'
                      )
                    "
                  ></app-required
                ></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="recert_address_1"
                />
              </div>
              <div class="mb-2 col-md-6">
                <label class="mt-2 mb-0">
                  {{ "applications.CURRENT_RENT_ADDRESS_2" | translate }}
                  <app-required
                    [control]="getControl(ticketForm, 'recert_address_2')"
                  ></app-required
                ></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="recert_address_2"
                />
              </div>
            </div>

            <div class="row col-md-12">
              <div class="mb-2 col-md-6">
                <label class="mt-2 mb-0">
                  {{ "applications.CURRENT_RENT_CITY" | translate }}
                  <app-required
                    [control]="
                      getControl(
                        $any(ticketForm.controls.recertifications),
                        'recert_city'
                      )
                    "
                  ></app-required
                ></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="recert_city"
                />
              </div>
              <div class="mb-2 col-md-6">
                <label class="mt-2 mb-0">
                  {{ "applications.CURRENT_RENT_STATE" | translate }}
                  <app-required
                    [control]="
                      getControl(
                        $any(ticketForm.controls.recertifications),
                        'recert_state'
                      )
                    "
                  ></app-required
                ></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="recert_state"
                />
              </div>
            </div>
            <div class="row col-md-12">
              <div class="mb-2 col-md-6">
                <label class="mt-2 mb-0">
                  {{ "applications.CURRENT_RENT_ZIP" | translate }}
                  <app-required
                    [control]="
                      getControl(
                        $any(ticketForm.controls.recertifications),
                        'recert_zip'
                      )
                    "
                  ></app-required
                ></label>
                <input
                  type="text"
                  mask="00000-0000||00000"
                  class="form-control"
                  formControlName="recert_zip"
                />
              </div>
              <div class="mb-2 col-md-6">
                <label class="mt-2 mb-0">
                  {{ "applications.CURRENT_LANDLORD_NAME" | translate }}
                  <app-required
                    [control]="
                      getControl(
                        $any(ticketForm.controls.recertifications),
                        'recert_landlord_name'
                      )
                    "
                  ></app-required
                ></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="recert_landlord_name"
                />
              </div>
            </div>
            <div class="row col-md-12">
              <div class="mb-2 col-md-6">
                <label class="mt-2 mb-0">
                  {{ "applications.CURRENT_NUM_BEDROOMS" | translate }}
                  <app-required
                    [control]="
                      getControl(
                        $any(ticketForm.controls.recertifications),
                        'recert_num_bedrooms'
                      )
                    "
                  ></app-required
                ></label>
                <select
                  class="form-control"
                  formControlName="recert_num_bedrooms"
                >
                <option value="-1" disabled>
                  - {{ "applications.SELECT" | translate }} -
                </option>
                  <option value="1">
                    {{ "DROPDOWNS.1_BEDROOM" | translate }}
                  </option>
                  <option value="2">
                    {{ "DROPDOWNS.2_BEDROOM" | translate }}
                  </option>
                  <option value="3">
                    {{ "DROPDOWNS.3_BEDROOM" | translate }}
                  </option>
                  <option value="4">
                    {{ "DROPDOWNS.4_BEDROOM" | translate }}
                  </option>
                </select>
              </div>
              <div class="mb-2 col-md-6"></div>
            </div>

            <div class="col-md-3">&nbsp;</div>
          </div>

          <hr />

          <!-- MONTHLY RENT CHANGED -->

          <p>
            {{ "applications.RECERTIFICATION_TEXT_1b" | translate }}
          </p>
          <div class="mb-2">
            <mat-radio-group formControlName="recert_monthly_rent_changed">
              <mat-radio-button value="True">
                <strong>{{ "applications.YES" | translate }}</strong>
              </mat-radio-button>
              <mat-radio-button value="False" class="ml-2">
                <strong>{{ "applications.NO" | translate }}</strong>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <p>
            <i>{{ "applications.RECERTIFICATION_TEXT_6" | translate }}</i>
          </p>

          <div
            *ngIf="
              ticketForm.controls.recertifications.get(
                'recert_monthly_rent_changed'
              ).value === 'False'
            "
          >
            <div class="mb-2">
              <label class="mt-2 mb-0">
                {{ "applications.CURRENT_MONTHLY_RENT_AMOUNT" | translate }}
                <app-required
                  [control]="
                    getControl(
                      $any(ticketForm.controls.recertifications),
                      'recert_monthly_rent'
                    )
                  "
                ></app-required
              ></label>
              <input
                type="text"
                prefix="$ "
                mask="separator.2"
                thousandSeparator=","
                class="form-control col-md-4"
                formControlName="recert_monthly_rent"
              />
            </div>
            <div class="col-md-3">&nbsp;</div>
          </div>

          <hr />

          <!-- PHONE CHANGED -->

          <p>
            {{ "applications.RECERTIFICATION_TEXT_1c" | translate }}
          </p>
          <div class="mb-2">
            <mat-radio-group formControlName="recert_phone_changed">
              <mat-radio-button value="True">
                <strong>{{ "applications.YES" | translate }}</strong>
              </mat-radio-button>
              <mat-radio-button value="False" class="ml-2">
                <strong>{{ "applications.NO" | translate }}</strong>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div
            *ngIf="
              ticketForm.controls.recertifications.get('recert_phone_changed')
                .value === 'False'
            "
          >
            <div class="mb-2">
              <label class="mt-2 mb-0">
                {{ "applications.CURRENT_PHONE" | translate }}
                <app-required
                  [control]="
                    getControl(
                      $any(ticketForm.controls.recertifications),
                      'recert_phone'
                    )
                  "
                ></app-required
              ></label>
              <input
                type="text"
                mask="(000) 000-0000"
                class="form-control col-md-4"
                formControlName="recert_phone"
              />
            </div>
            <div class="col-md-3">&nbsp;</div>
          </div>
          <!-- placeholder="{{ 'applications.CURRENT_PHONE' | translate}}" -->

          <hr />

          <!-- <div class="mb-2">
              <label class="mt-2 mb-0"
                >{{ "applications.HOUSEHOLD_MEMBER_COUNT" | translate
                }}<app-required
                  [control]="getControl(ticketForm, 'recert_household_count')"
                ></app-required
              ></label>
              <input
                type="text"
                mask="separator.0"
                thousandSeparator=","
                class="form-control col-md-4"
                formControlName="recert_household_count"
              />
            </div> -->
          <!-- <div class="mb-2">
              <label class="mt-2 mb-0"
                >{{ "applications.CURRENT_ANNUAL_HOUSEHOLD_INCOME" | translate
                }}<app-required
                  [control]="getControl(ticketForm, 'recert_annual_income')"
                ></app-required
              ></label>
              <input
                type="text"
                mask="separator.2"
                thousandSeparator=","
                class="form-control col-md-4"
                formControlName="recert_annual_income"
              />
          </div> -->
        </div>

        <p>{{ "applications.RECERTIFICATION_TEXT_4" | translate }}</p>
        <ol>
          <li>
            {{ "applications.RECERT_ATTESTATION_PT_1" | translate }}
          </li>
          <li>
            {{ "applications.RECERT_ATTESTATION_PT_2a" | translate }}
            <span style="font-weight: bold">{{
              "applications.RECERT_ATTESTATION_PT_2b" | translate
            }}</span>
          </li>
          <li>
            {{ "applications.RECERT_ATTESTATION_PT_3" | translate }}
          </li>
          <li>
            {{ "applications.RECERT_ATTESTATION_PT_4" | translate }}
          </li>
          <li>
            {{ "applications.RECERT_ATTESTATION_PT_5" | translate }}
          </li>
          <li>
            {{ "applications.RECERT_ATTESTATION_PT_6" | translate }}
          </li>
        </ol>
        <p>
          {{ "applications.RECERT_ATTESTATION_TEXT_3" | translate }}
        </p>
        <p>
          {{ "applications.RECERTIFICATION_TEXT_5" | translate }}
        </p>
        <p>
          {{ "applications.ATTESTATION_WARNING" | translate }}
        </p>
      </div>
      <div
        class="form-group"
        *ngIf="
          ticketForm.controls.ticket_type.value === 'Recertification' &&
          ticketForm.controls.related_applications.value != ''
        "
      >
        <p style="color: red">
          {{ "applications.RECERT_ATTESTATION_TEXT_4a" | translate }}
          <span style="font-weight: bold">{{
            "applications.RECERT_ATTESTATION_TEXT_4b" | translate
          }}</span>
          {{ "applications.RECERT_ATTESTATION_TEXT_4c" | translate }}
        </p>
        <label
          >{{ "applications.ENTER_YOUR_NAME" | translate
          }}<app-required
            [control]="getControl(ticketForm, 'subject')"
          ></app-required
        ></label>
        <input type="text" class="form-control" formControlName="subject" />
      </div>

      <fieldset
        [disabled]="ticketForm.value.ticket_type !== 'Recertification'"
        *ngIf="
          ticketForm.value.ticket_type === 'Recertification' &&
          ticketForm.controls.related_applications.value != ''
        "
      >
        <div class="form-group">
          <hr />
          <label>{{ "applications.DOCUMENTS" | translate }}</label>
          <div>{{ "applications.FILE_LIMIT_SIZE" | translate }}</div>
          <small *ngIf="ticketForm.value.ticket_type !== 'Recertification'">{{
            "applications.EXAMPLE_OF_DOCS_COMM" | translate
          }}</small>
          <small *ngIf="ticketForm.value.ticket_type === 'Recertification'">{{
            "applications.EXAMPLE_OF_DOCS_COMM_RECERTIFICATION" | translate
          }}</small>
          <div class="d-flex">
            <app-required
              *ngIf="ticketForm.value.ticket_type === 'Recertification'"
              [control]="getControl(ticketForm, 'file_doc_1')"
            ></app-required>
            <input
              type="file"
              class="form-control border-0"
              (change)="loadFile($event, 'file_doc_1', 'file_doc_1_url')"
            />
          </div>
          <input
            type="file"
            class="form-control border-0"
            (change)="loadFile($event, 'file_doc_2', 'file_doc_2_url')"
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="loadFile($event, 'file_doc_3', 'file_doc_3_url')"
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="loadFile($event, 'file_doc_4', 'file_doc_4_url')"
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="loadFile($event, 'file_doc_5', 'file_doc_5_url')"
          />
        </div>
      </fieldset>

      <div class="btn-responsive">
        <button
          *ngIf="!modalConfig.busy"
          type="submit"
          class="btn background-third-whitetext"
          [disabled]="
            (ticketForm.value.ticket_type === 'Utilities' &&
              !isAllAcksChecked()) ||
            ticketForm.invalid
          "
        >
          {{ "applications.SUBMIT" | translate }}
        </button>
        <button
          *ngIf="modalConfig.busy"
          type="button"
          class="btn background-third-whitetext"
        >
          <strong
            ><i class="fa fa-cog fa-spin"></i>
            {{ "applications.PLEASE_WAIT" | translate }}</strong
          >
        </button>
        <button type="button" (click)="close()" class="btn btn-dark">
          {{ "applications.CANCEL" | translate }}
        </button>
        <small
          [innerHTML]="'applications.SUBMIT_BTN_INFO_TEXT' | translate"
        ></small>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #ackw let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4>
        {{ "applications.UPDATE_ACKNOWLEDGEMENTS" | translate }}
      </h4>
      {{ "applications.FILE_LIMIT_SIZE" | translate }}
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="acknowledgementsFormGroup">
      <fieldset>
        <div class="table-responsive">
          <table class="table table-sm table-bordered">
            <tr>
              <td colspan="3">
                <label>
                  <h4>{{ "RN_CERT_ACK_INTRO" | translate }}:</h4>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <label>
                  <h4>{{ "applications.CERTIFICATIONS" | translate }}:</h4>
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="certify"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "CERTRENTER" + ".CERT1" | translate }} <br />
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['certify'],
                    'text-danger': !acknowledgementsFormGroup.value['certify']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <label>
                  <h4>{{ "applications.ACKNOWLEDGEMENTS" | translate }}:</h4>
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK1" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack'],
                    'text-danger': !acknowledgementsFormGroup.value['ack']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack_2"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK2" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack_2'],
                    'text-danger': !acknowledgementsFormGroup.value['ack_2']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack_3"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK3" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack_3'],
                    'text-danger': !acknowledgementsFormGroup.value['ack_3']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack_4"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK4" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack_4'],
                    'text-danger': !acknowledgementsFormGroup.value['ack_4']
                  }"
                >
                </i>
              </td>
            </tr>
            <tr>
              <td>
                <mat-checkbox formControlName="ack_5"></mat-checkbox>
              </td>
              <td>
                <label>
                  {{ "ACKRENTER" + ".ACK5" | translate }}
                </label>
              </td>
              <td>
                <i
                  [ngClass]="{
                    'fa fa-circle': true,
                    'text-success2': acknowledgementsFormGroup.value['ack_5'],
                    'text-danger': !acknowledgementsFormGroup.value['ack_5']
                  }"
                >
                </i>
              </td>
            </tr>
          </table>
        </div>

        <div class="row">
          <div class="col">
            {{ "RN_CERT_ACK_CONFIRM" | translate }}
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</ng-template>
