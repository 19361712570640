import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { RouteDataService } from 'src/app/services/route-data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { IgnatiusService } from 'src/app/services/ignatius.service';
import { ProjectSpecificService } from 'src/app/services/project-specific.service';
import { forkJoin, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardResolver implements Resolve<Object[]>{
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Object[] | import("rxjs").Observable<Object[]> | Promise<Object[]> {
    this.projectSpecificData = this.projectSpecificService.getProjectSpecificData();
    const subjectTableName = this.routeDataService.getData(route, 'subjectTableName');
    let observables = new Array<Observable<Object[]>>();

    switch (subjectTableName) {

      case "application-add": {
        observables = this.getApplicationAddObservables();
        break;
      }
      case "application-list":
        observables = this.getApplicationListObservables();
        break;
      case "application-edit-landlord":
        observables = this.getApplicationEditObservables();
        break;
      case "application-edit-applicant":
        observables = this.getApplicationEditObservables();
        break;
      case 'determine-eligibility':
        observables = this.getEligibilityObservables(route.paramMap.get("appId"));
        break;
    }

    this.spinner.show();
    return forkJoin(observables)
  }

  constructor(
    private spinner: NgxSpinnerService,
    private ignatiusService: IgnatiusService,
    private routeDataService: RouteDataService,
    private projectSpecificService: ProjectSpecificService,
  ) {
    this.projectSpecificData = this.projectSpecificService.getProjectSpecificData();
   }

  projectSpecificData: any;


  getApplicationListObservables() {
    const observables: Array<Observable<Object[]>> = [
      //--------------- [0] ---------------//
      this.ignatiusService.getQueryReportObservable(
        this.projectSpecificData.appData,
        { "ApplicationTableId": this.projectSpecificData.applicationsData.TableId }
      )
    ]
    return observables;
  }

  getApplicationAddObservables() {
    const observables: Array<Observable<Object[]>> = [
      //--------------- [0] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.BusinessEntityTypeMCID.toString()
      ),
      //--------------- [1] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.ParishMCID.toString()
      ),
      //--------------- [2] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.PropertyTypeMCID.toString()
      )
    ]

    return observables;
  }

  getApplicationEditObservables() {
    const observables: Array<Observable<Object[]>> = [

      //--------------- [0] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.BusinessEntityTypeMCID.toString()
      ),
      //--------------- [1] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.BusinessEntityTypeMCID.toString()
      ),
      //--------------- [2] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.BusinessEntityTypeMCID.toString()
      ),
      //--------------- [3] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.expensesData.ExpenseTypeMCID.toString()
      ),
      //--------------- [4] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.HouseholdNumberMCID.toString()
      ),
      //--------------- [5] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.EmploymentStatusMCID.toString()
      ),
      //--------------- [6] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.RaceMCID.toString()
      ),
      //--------------- [7] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.BusinessEntityTypeMCID.toString()
      ),
      //--------------- [8] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.ParishMCID.toString()
      ),
      //--------------- [9] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.RaceMCID.toString()
      ),
      //--------------- [10] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.GenderMCID.toString()
      ),
      //--------------- [11] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.IncomeGrossAmountTypeMCID.toString()
      ),
      //--------------- [12] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.expensesData.InArrearsMCID.toString()
      ),
      //--------------- [13] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.applicationsData.PropertyTypeMCID.toString()
      ),
      //--------------- [14] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.RelationToApplicant.toString()
      ),
      //--------------- [15] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.propertiesData.PropertyTypeMCID.toString()
      ),
      //--------------- [16] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.commThreadsData.TicketTypeMCID.toString()
      ),
      //--------------- [17] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.expensesData.ElectricityCompaniesFieldId.toString()
      ),
      //--------------- [18] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.EthnicityMCID.toString()
      ),
      //--------------- [19] ---------------//
      this.ignatiusService.getDropdownValues(
        this.projectSpecificData.householdData.VeteranMCID.toString()
      ),
    ]

    return observables;
  }

  getEligibilityObservables(appId) {
    const observables: Array<Observable<Object[]>> = [
      //--------------- [0] ---------------//
      this.ignatiusService.getTargetTableObservable(
        this.projectSpecificData.appData,
        appId,
        this.projectSpecificData.applicationsData.TableId as number,
        this.projectSpecificData.applicationsData.RecordIdFieldId as number)
    ]
    return observables;
  }
}
