import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/modules/dashboards/services';
import { IgnatiusService } from 'src/app/services/ignatius.service';
import { ProjectSpecificService } from 'src/app/services/project-specific.service';
import { Constants } from 'src/app/shared/constants';
import { naics } from './naics';
import { ConfirmDialogComponent } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'application-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationAddComponent implements OnInit {

  @HostListener('window:beforeunload', ['$event'])
  public onPageUnload($event: BeforeUnloadEvent) {
    if (this.hasUnsavedChanges()) {
      $event.returnValue = true;
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : naics.filter(item => item.code.toLowerCase().indexOf(term.toLowerCase()) > -1
          || item.title.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

  formatter = result => !result['code'] ? '' : result['code'] + ', ' + result['title'];

  tinMask = '00-0000000';
  //tin_2Mask = '00-0000000';
  dunsMask = '000000000';
  isSubmitted = false;

  options = {
    componentRestrictions: {
      country: ["US"]
    }
  };

  businessEntities: Array<any>;
  parishes: Array<any>;
  preferred_parishes_1: Array<any>;
  preferred_parishes_2: Array<any>;
  preferred_parishes_3: Array<any>;
  propertyTypes: Array<any>;
  applicantType = "";
  isUserInvalid = false;

  constructor(
    private projectSpecificService: ProjectSpecificService,
    private ignatiusService: IgnatiusService,
    private common: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  projectSpecificData:any;
  appForm: FormGroup;

  ngOnInit() {
    this.projectSpecificData = this.projectSpecificService.getProjectSpecificData();
    const componentData = this.route.snapshot.data['componentData'];
    this.businessEntities = componentData[0];
    this.parishes = componentData[1]
    this.preferred_parishes_1 = componentData[1]
    this.preferred_parishes_2 = componentData[1]
    this.preferred_parishes_3 = componentData[1]
    this.propertyTypes = componentData[2]
    
    const permissionTypeData = this.projectSpecificService.getPermissionType();
    if (JSON.stringify(permissionTypeData).toString().includes("Landlord"))
      this.applicantType = "Landlord";
    else
    this.applicantType = "Applicant";

    this.setupApplicationForm();
    this.spinner.hide();
  }

  setupApplicationForm(): void {
    const tinMatchValidator = (frm: FormGroup) => {
      if (frm.get('tin_enc').value !== frm.get('ctin').value) {
        frm.get('ctin').setErrors({ 'noMatch': true });
        return { invalid: true };
      }
    }

    const femaMatchValidator = (frm: FormGroup) => {
      if (frm.get('fema_id').value !== frm.get('cfema_id').value) {
        frm.get('cfema_id').setErrors({ 'noMatch': true });
        return { invalid: true };
      }
    }

    // const tin2MatchValidator = (frm: FormGroup) => {
    //   if (frm.get('tin_2').value !== frm.get('ctin_2').value) {
    //     frm.get('ctin_2').setErrors({ 'noMatch': true });
    //     return { invalid: true };
    //   }
    // }

    if (this.applicantType == "Applicant") {
      this.appForm = new FormGroup({
        fema_id: new FormControl('', [Validators.required]),
        cfema_id: new FormControl('', [Validators.required]),
        status: new FormControl('Pre-Eligibility'),
        applicant_type: new FormControl('Applicant'),
      }, [femaMatchValidator]);
    }
    else if (this.applicantType == "Applicant1") {
      //this.tinMask = '000-00-0000';
      this.appForm = new FormGroup({
        contact_first_name: new FormControl('', Validators.required),
        contact_mi: new FormControl(''),
        contact_last_name: new FormControl('', Validators.required),
        contact_phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
        contact_email: new FormControl('', [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)]),
        // landlord_email: new FormControl('', [Validators.pattern(Constants.EMAIL_PATTERN)]),
        //tin: new FormControl('', [Validators.required, Validators.pattern(/^\d{9}$/)]),
        //tin_type: new FormControl('SSN'),
        //ctin: new FormControl('', [Validators.required]),
        // business_name: new FormControl('', Validators.required),
        physical_street: new FormControl('', Validators.required),
        physical_street_2: new FormControl(''),
        physical_city: new FormControl('', Validators.required),
        physical_state: new FormControl('', Validators.required),
        physical_zip_code: new FormControl('', Validators.required),
        physical_longitude: new FormControl(''),
        physical_latitude: new FormControl(''),
        parish: new FormControl('', Validators.required),
        preferred_parish_1: new FormControl(''),
        preferred_parish_2: new FormControl(''),
        preferred_parish_3: new FormControl(''),
        status: new FormControl('FEMA ID Not Confirmed'),
        landlord_status: new FormControl('Request Not Sent'),
        applicant_type: new FormControl('Applicant'),
        number_of_bedrooms: new FormControl('-1'),
        unit_size: new FormControl('Not Selected')
      });//, [tinMatchValidator]);
    }
    else {
      this.appForm = new FormGroup({
        business_name: new FormControl('', Validators.required),
        business_entity_type: new FormControl('', Validators.required),
        tin_type: new FormControl('EIN'),
        //tin_type2: new FormControl('EIN'),
        tin_enc: new FormControl('', [Validators.required, Validators.pattern(/^\d{9}$/)]),
        //tin_2: new FormControl(''),
        ctin: new FormControl('', [Validators.required]),
        //ctin_2: new FormControl(''),
        duns_nbr: new FormControl('', [Validators.pattern(/^\d{9}$/)]),
        //property_name: new FormControl('', Validators.required),
        //property_description: new FormControl('', Validators.required),
        //property_type: new FormControl('', Validators.required),
        //property_type: new FormControl(''),
        physical_street: new FormControl('', Validators.required),
        physical_street_2: new FormControl(''),
        physical_city: new FormControl('', Validators.required),
        physical_state: new FormControl('', Validators.required),
        physical_zip_code: new FormControl('', Validators.required),
        physical_latitude: new FormControl(''),
        physical_longitude: new FormControl(''),
        // mailing_street: new FormControl('', Validators.required),
        // mailing_street_2: new FormControl(''),
        // mailing_city: new FormControl('', Validators.required),
        // mailing_state: new FormControl('', Validators.required),
        // mailing_zip_code: new FormControl('', Validators.required),
        // mailing_latitude: new FormControl(''),
        // mailing_longitude: new FormControl(''),
        contact_first_name: new FormControl('', Validators.required),
        contact_mi: new FormControl(''),
        contact_last_name: new FormControl('', Validators.required),
        contact_phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
        contact_email: new FormControl('', [Validators.required, Validators.pattern(Constants.EMAIL_PATTERN)]),
        contact_titles: new FormControl('', Validators.required),
        business_phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
        status: new FormControl('Pre-Eligibility', Validators.required),
        applicant_type: new FormControl('Landlord', Validators.required),
        landlord_email: new FormControl(''),
        parish: new FormControl(''),
        preferred_parish_1: new FormControl(''),
        preferred_parish_2: new FormControl(''),
        preferred_parish_3: new FormControl(''),
      }, [tinMatchValidator]);//, tin2MatchValidator]);
    }
  } 
 
  onChangeTintype(value: string): void {
    this.tinMask = value === 'SSN' ? '000-00-0000' : '00-0000000';
  }

  async onAppFormSubmit() {
    if (!this.appForm.valid) {
      this.common.validateAllFormFields(this.appForm)
      return;
    }

    const dialogData: { title: string, message: string } = {
      title: "applications.SAVE_CONTINUE",
      message: `applications.SAVE_CONTINUE_MESSAGE`
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) return;

    this.checkFEMAID();
  }

  private checkFEMAID = async() => {
    const userData = this.projectSpecificService.getUserData();
    const applicantTable = this.projectSpecificData.appData.Tables.find((table:any)=> table.Name === 'Applicants')
    const applicantsData = await this.ignatiusService.getQueryReportObservable(
      this.projectSpecificData.appData,
      {
        ApplicationTableId: applicantTable.Id,
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: applicantTable.Fields.find(field=> field.Name == "FEMA ID").Id,
                },
                OperationType: "is equal",
                Value: this.appForm.value.fema_id,
              },
            ],
          },
        ],
      }
    ).toPromise();
    const applicantData:any = applicantsData.find((applicant:any) =>applicant.fema_id === this.appForm.value.fema_id && applicant.matched !== 'True');
    if (applicantData && Object.keys(applicantData).length) {
      const recordPayload = this.common.createPutData(applicantTable.Id,{ matched:'True' },Number(applicantData?.id))
      await this.ignatiusService.putData(recordPayload).toPromise();
      this.appForm.controls.status.setValue('Pre-Eligibility');
      this.createApplication();
    } else {
     this.toastr.error(this.translate.instant("errors.INVALID_FEMA_ID"), this.translate.instant("applications.ERROR"));
     this.isUserInvalid = true;
     this.router.navigateByUrl('/application/list',{ state :{ data: true}});
    }
  }

  async createApplication() {
    try {
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const formData = Object.assign({}, this.appForm.value);
      const userData = JSON.parse(localStorage.getItem('userData'));
      formData.email_user = userData.userName;
      // if (this.applicantType == 'Applicant') {
      //   if (['Caddo', 'Calcasieu', 'East Baton Rouge', 'Jefferson', 'Lafayette', 'Orleans', 'Saint Tammany'].indexOf(formData.parish) > -1) {
      //     formData.status = 'Referred';
      //   }
      // }
      if (this.applicantType == "Landlord") {
        formData.landlord_email = formData.contact_email;
        delete formData.ctin;
      } 
      const recordFAD = this.common.createPostData(tableId, formData);
      if (await this.isTinUnique(this.applicantType)) {
        this.spinner.show(); // start loader
        const resultData = await this.ignatiusService.postData(recordFAD).toPromise();
        this.createApplicationCompleted(true, {
          ...resultData,
          recordData: {
            status: formData.status
          },
        });
      } else {
        if (this.applicantType == "Landlord")
          this.toastr.error(this.translate.instant("MESSAGES.TIN_ALREADY_EXIST"), this.translate.instant("applications.ERROR"));
        else
        this.toastr.error(this.translate.instant("MESSAGES.APPLICATION_CREATE_ERROR"), this.translate.instant("applications.ERROR"));
        this.spinner.hide();
      }
    } catch (error) {
      this.createApplicationCompleted(false);
    }
  }

  private createApplicationCompleted(statue: boolean, application?: any) {
    if (statue) {
      this.toastr.success(this.translate.instant("MESSAGES.APPLICATION_CREATE_SUCCESS"), this.translate.instant("applications.SUCCESS"));
      this.isSubmitted = true;
      this.navigateByStatus(application.recordData.status, application.recordId)
      // this.router.navigate(['application/list']);
    } else {
      this.toastr.error(this.translate.instant("MESSAGES.TIN_ALREADY_EXIST"), this.translate.instant("applications.ERROR"));
    }
    this.spinner.hide();
  }

  private navigateByStatus = (status: string, recordId: string) => {
    switch(status) {
      case 'Pre-Eligibility':
        this.router.navigate(['application/determine-eligibility', recordId]);
        break;
      default: case 'Referred': this.router.navigate(['application/list']);
    }
  }

  async isTinUnique(applicant_type) {
    if (applicant_type === 'Applicant')
      return true;
    else {
      const tinNo = this.appForm.get('tin_enc').value;
      const conditions = [
        {
          ConditionField: {
            Id: this.projectSpecificData.applicationsData.TinId
          },
          OperationType: 'is equal',
          Value: tinNo
        }
      ];

      const hasExistingApp = await this.ignatiusService.getQueryReportObservable(
        this.projectSpecificData.appData,
        {
          ApplicationTableId: this.projectSpecificData.applicationsData.TableId,
          ConditionGroups: [
            {
              Type: 'all',
              Conditions: conditions
            }
          ]
        }).toPromise();

      return hasExistingApp.length ? false : true;
    }
  }

  hasUnsavedChanges() {
    return this.appForm.dirty && !this.isSubmitted && !this.isUserInvalid;
  }

  addressChangePhysical(address: any, field: string) {
    this.appForm.get(field).setValue(address.formatted_address);
    var streetNumber = "";
    this.latLngToAddress(address);
    if (address.address_components[0]) {
      for (var i = 0; i < address.address_components.length; i++) {
        var addressType = address.address_components[i].types[0];
        switch (addressType) {
          case "street_number":
            streetNumber = address.address_components[i].long_name;
            break;
          case "route":
            this.appForm.get('physical_street').setValue(streetNumber + " " + address.address_components[i].long_name);
            break;
          case "locality":
            this.appForm.get('physical_city').setValue(address.address_components[i].long_name);
            break;
          case "administrative_area_level_1":
            this.appForm.get('physical_state').setValue(address.address_components[i].long_name);
            break;
          case "postal_code":
            this.appForm.get('physical_zip_code').setValue(address.address_components[i].long_name);
            break;
          case "longitude":
            this.appForm.get('physical_longitude').setValue(address.address_components[i].long_name);
            break;
          case "latitude":
            this.appForm.get('physical_latitude').setValue(address.address_components[i].long_name);
            break;
        }
      }
    }
  }
  addressChangeMailing(address: any, field: string) {
    this.appForm.get(field).setValue(address.formatted_address);
    var streetNumber = "";
    if (address.address_components[0]) {
      for (var i = 0; i < address.address_components.length; i++) {
        var addressType = address.address_components[i].types[0];
        switch (addressType) {
          case "street_number":
            streetNumber = address.address_components[i].long_name;
            break;
          case "route":
            this.appForm.get('mailing_street').setValue(streetNumber + " " + address.address_components[i].long_name);
            break;
          case "locality":
            this.appForm.get('mailing_city').setValue(address.address_components[i].long_name);
            break;
          case "administrative_area_level_1":
            this.appForm.get('mailing_state').setValue(address.address_components[i].long_name);
            break;
          case "postal_code":
            this.appForm.get('mailing_zip').setValue(address.address_components[i].long_name);
            break;
            case "longitude":
              this.appForm.get('mailing_longitude').setValue(address.address_components[i].long_name);
            case "latitude":
              this.appForm.get('mailing_latitude').setValue(address.address_components[i].long_name);
              break;
        }
      }
    }
  }

    /**
   * Add lat, long to address component
   */
  latLngToAddress = (address) => {
    address.address_components.push(...[{
      long_name: address.geometry.location.lat(),
      short_name: address.geometry.location.lat(),
      types: [
        "latitude"
      ]
    }, {
      long_name: address.geometry.location.lng(),
      short_name: address.geometry.location.lng(),
      types: [
        "longitude"
      ]
    }]);
  }


  onChangeBusinessEntityType(value: string) {

    if (this.applicantType === 'Applicant') {
      this.appForm.get('tin_type').setValue('SSN');
      this.tinMask = '000-00-0000';
      //this.appForm.get('tin_2').clearValidators();
      //this.appForm.get('tin_2').updateValueAndValidity();
      this.appForm.patchValue({
        //tin_2: '',
        //ctin_2: '',
        //tin_type2: '',
        duns_nbr: '',
      })
    }
    else if (value === 'Sole Proprietor') {
      this.appForm.get('tin_type').setValue('SSN');
      //this.appForm.get('tin_type2').setValue('EIN');
      this.tinMask = '000-00-0000';

      //this.appForm.get('tin_2').setValidators([Validators.pattern(/^\d{9}$/)]);
      // this.appForm.get('ctin_2').setValidators(Validators.required);
      //this.appForm.get('tin_2').updateValueAndValidity();
      // this.appForm.get('ctin_2').updateValueAndValidity();
    } else {
      this.appForm.get('tin_type').setValue('EIN');
      this.tinMask = '00-0000000';
      
      //this.appForm.get('tin_2').clearValidators();
      //this.appForm.get('tin_2').updateValueAndValidity();
      // this.appForm.get('ctin_2').clearValidators()
      // this.appForm.get('ctin_2').updateValueAndValidity();

      // this.appForm.patchValue({
      //   tin_2: '',
      //   ctin_2: '',
      //   tin_type2: ''
      // })
    }
  }

  /**
   * Function to view input on fields of password type
   */
   viewProtected = (input: HTMLInputElement) => {
    if (input.className.includes("pw")) input.className = input.className.replace(" pw", "")
    else input.className = `${input.className} pw`;
  }
}