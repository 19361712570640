import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-hh-verify",
  templateUrl: "./hh-verify.component.html",
  styleUrls: ["./hh-verify.component.css"],
})
export class HhVerifyComponent implements OnInit {
  private AMOUNT_TYPE_ANNUAL = "Annual";
  private AMOUNT_TYPE_MONTHLY = "Annual";

  annualIncome: number = 0;
  monthly1Income: number = 0;
  monthly2Income: number = 0;
  displayAnnual = true;
  displayMonthly = false;

  constructor(
    private dialogRef: MatDialogRef<HhVerifyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.calculate();
  }

  /**
   * calculate income
   */
  calculate = () => {
    const amount = this.getAmounts();
    if (this.data.income_gross_amount_type === this.AMOUNT_TYPE_ANNUAL) {
      this.annualIncome = Number(this.data.income_gross_amount) + 12 * amount;
      this.displayAnnual = true;
      this.displayMonthly = false;
    }
    else {
      this.monthly1Income = Number(this.data.income_gross_amount) + amount;
      this.monthly2Income = Number(this.data.income_gross_amount_2) + amount;
      this.displayAnnual = false;
      this.displayMonthly = true;
    }
  };

  /**
   * @returns sum of values of provided fields
   */
  getAmounts = () => {
    let sum = 0;
    sum += Number(this.data.income_in_lieu_of_earnings_amount) || 0;
    sum += Number(this.data.income_payments_annuities_amount) || 0;
    sum += Number(this.data.income_government_amount) || 0;
    sum += Number(this.data.income_alimony_amount) || 0;
    sum += Number(this.data.income_other_amount) || 0;
    return sum;
  };

  /**
   * Close
   */
  close = (isSaveClicked: boolean = false) => {
    this.dialogRef.close(isSaveClicked);
  };
}
