import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[noSpecialChars]",
})
export class NoSpecialCharsDirective {
  constructor() {}

  @HostListener("keypress", ["$event"]) keypress(ev: KeyboardEvent) {
    if (!/[a-zA-Z0-9-, ]/.test(ev.key)) return false;
  }
}
