//node modules
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";

import { CommonModule, CurrencyPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MaterialModule } from "src/app/shared/material.module";
import { CommonService } from "src/app/modules/dashboards/services";

//local
import { DashboardRoutes } from "./dashboard.routing";

import { UnauthorisedComponent } from "src/app/modules/dashboards/components";

import { DashboardComponent } from "./dashboard.component";

import { AdminGuard } from "src/app/modules/dashboards/guards";
import { DashboardResolver } from "src/app/modules/dashboards/dashboard.resolver";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { BoolPipe } from "src/app/shared/pipes/bool.pipe";
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { HasUnsavedChangesGuard } from "./guards/unsaved-changes.guard";
import { NumberDirective } from "src/app/modules/dashboards/directives/numbers-only";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { HelpDeskComponent } from "./components/applications/communication/help-desk/help-desk.component";
import { CaseManagerComponent } from "./components/applications/communication/case-manager/case-manager.component";
import { UserEditAddComponent } from "./components/user/edit-add/edit-add.component";
import { UserListComponent } from "./components/user/list/list.component";
import { ApplicationAddComponent } from "./components/applications/add/add.component";
import { ApplicationEditLandlordComponent } from "./components/applications/edit_landlord/edit_landlord.component";
import { ApplicationEditApplicantComponent } from "./components/applications/edit_applicant/edit_applicant.component";
import { DetermineEligibilityComponent } from "./components/applications/eligibility/determine-eligibility.component";
import { ApplicationListComponent } from "./components/applications/list/list.component";
export {
  UserListComponent,
  UserEditAddComponent,
  ApplicationListComponent,
  ApplicationAddComponent,
  ApplicationEditLandlordComponent,
  ApplicationEditApplicantComponent,
  DetermineEligibilityComponent,
};
@NgModule({
  imports: [
    RouterModule.forChild(DashboardRoutes),
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    NgbModule,
    NgbModalModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MaterialModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    TranslateModule,
    NgCircleProgressModule.forRoot({}),
    NgxMaskModule.forRoot(),
    GooglePlaceModule,
  ],
  declarations: [
    BoolPipe,
    NumberDirective,
    UnauthorisedComponent,
    DashboardComponent,
    UserListComponent,
    UserEditAddComponent,
    ApplicationListComponent,
    ApplicationAddComponent,
    ApplicationEditLandlordComponent,
    ApplicationEditApplicantComponent,
    DetermineEligibilityComponent,
    HelpDeskComponent,
    CaseManagerComponent,
    ConfirmDialogComponent,
  ],
  providers: [
    DashboardResolver,
    CurrencyPipe,
    CommonService,
    AdminGuard,
    HasUnsavedChangesGuard,
  ],
})
export class DashboardModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
