import { Injectable } from "@angular/core";
import {
  HttpParams,
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { User } from "../models/user";
import { BehaviorSubject, throwError, Observable, forkJoin, of } from "rxjs";
import { catchError, tap, mergeMap, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { PasswordRecoveryData } from "../models/password-recovery-data";
import { environment } from "src/environments/environment";
import { AppData } from "../models/app-data";
import { ProjectSpecificService } from "./project-specific.service";
import { RoleData } from "../models/role-data";
import { Title } from "@angular/platform-browser";
import moment from "moment";
import { IgnatiusService } from "./ignatius.service";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  readonly IDLE_TIME = 60; // in sec
  readonly IDLE_TIMEOUT = 600; // in sec

  constructor(
    private ignatiusService: IgnatiusService,
    private http: HttpClient,
    private router: Router,
    private link: ProjectSpecificService,
    private title: Title,
    private idle: Idle,
    private ngbModal: NgbModal,
  ) {
    this.language = new BehaviorSubject("en");
  }

  user = new BehaviorSubject<User>(null);

  language: BehaviorSubject<string>;
  titleInterval: any;
  refreshTokenPromise: any = null;

  changeLanguage(language: string) {
    this.language.next(language);
  }

  signIn(username: string, password: string, recaptchaV3token: string): Observable<User> {
    let rolesParams = new HttpParams();
    rolesParams = rolesParams.append("Email", username);
    rolesParams = rolesParams.append("PasswordHash", password);
    rolesParams = rolesParams.append(
      "ApplicationId",
      environment.applicationId
    );
    rolesParams = rolesParams.append(
     "GoogleRecaptchaTokenV3",
     recaptchaV3token
          );
    const loginUrl = environment.apiUrl + "/api/user/login";

    const rolesObservable = this.http
      .post<RoleData>(loginUrl, rolesParams)
      .pipe(
        tap((roleData) => {
          localStorage.setItem(
            "permissionData",
            JSON.stringify(roleData.Rights).toString()
          );

          localStorage.setItem(
            "roleData",
            JSON.stringify(roleData.Roles).toString()
          );
          // localStorage.setItem(
          //   'userroleData', this.getApplicationData(roleData.Rights));
        })
      );

    const tokenUrl = environment.apiUrl + "/token";
    let tokenParams = new HttpParams();
    tokenParams = tokenParams.append("grant_type", "password");
    tokenParams = tokenParams.append("client_id", environment.client_id);
    tokenParams = tokenParams.append("username", username);
    tokenParams = tokenParams.append("password", password);

    const tokenOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };

    const tokenObservable = this.http
      .post<User>(tokenUrl, tokenParams, tokenOptions)
      .pipe(
        tap((user) => {
          this.idleWatch();
          this.setUserSubject(user);
          localStorage.setItem("session", user.access_token.toString());
          let user_data = JSON.stringify(user).toString();
          localStorage.setItem("userData", user_data);
        })
      );

    return forkJoin(rolesObservable, tokenObservable).pipe(
      catchError(this.handleError),
      mergeMap(() => {
        return this.http
          .get<AppData>(
            environment.apiUrl +
              `/api/applicationdata?applicationId=${environment.applicationId}`
          )
          .pipe(
            map((appData: AppData) => {
              try {
                this.link.createProjectSpecificData(appData);
                return this.user.value;
              } catch (e) {
                const error = e as Error;
                console.log(error.message);
                this.handleError();
                return null;
              }
            })
          );
      })
    );
  }
  private getApplicationData(rights): any {
    const isBusinessApplicant = rights
      .map((ar) => ar.ApplicationRolesId)
      .includes(700);
    const isRentalApplicant = rights
      .map((ar) => ar.ApplicationRolesId)
      .includes(703);

    if (isBusinessApplicant) {
      return JSON.stringify({
        id: 700,
        name: "Business Applicant",
      });
    } else if (isRentalApplicant) {
      return JSON.stringify({
        id: 700,
        name: "Rental Applicant",
      });
    } else {
      return JSON.stringify({
        id: null,
        name: "Admin",
      });
    }
  }

  autoLogin() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      return;
    }
    this.setUserSubject(userData);
    this.idleWatch();
  }

  changePassword(oldPassword: String, newPassword: String) {
    let query = {
      Email: this.user.value.userName,
      PasswordConfirm: newPassword,
      OldPassword: oldPassword,
    };
    const url = environment.apiUrl + "/api/user/updateprofile";
    return this.http.post<Object[]>(url, query).pipe(
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  forgotPassword(username: string,recaptchaToken:string) {
    let forgotPassword = new PasswordRecoveryData(
      username,
      environment.domain,
      null,
      null,
      recaptchaToken
    );

    return this.http
      .post<string>(
        environment.apiUrl + "/api/user/forgotpassword",
        forgotPassword
      )
      .pipe(
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  recoverPassword(passwordRecoveryData: PasswordRecoveryData) {
    return this.http
      .post<string>(
        environment.apiUrl + "/api/user/recoverpassword",
        passwordRecoveryData
      )
      .pipe(
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  logout() {
    if (this.user.value)
      this.ignatiusService
        .revokeToken(this.user.value.refreshToken)
        .toPromise();
    localStorage.clear();
    this.user.next(null);
    this.clearTimeouts();
    this.title.setTitle("LA Rapid Rehousing");
    this.router.navigate(["/login"]);
  }

  register(user: any,recaptchaCode:any) {
    const appID = environment.applicationId;
    const body = {
      Email: user.email,
      Password: user.password,
      ApplicationRoleIds: [user.roleId],
      ApplicationIds: [appID],
      GoogleRecaptchaTokenV3:recaptchaCode
    };
    const regOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<string>(
        environment.apiUrl + "/api/user/custom-register",
        body,
        regOptions
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  private handleError() {
    return throwError("Error Logging In");
  }

  checkAdmin(): boolean {
    const appData = JSON.parse(localStorage.getItem("roleData"));
    if (!appData) return false;
    const pattern = /admin/g;
    return Boolean(pattern.test(appData.Name));
  }

  /**
   * Refresh token functions
   */

  /**
   * Set timeout function to update title with time left for session
   */
  showExpiryTimeInTitle = () => {
    this.clearTimeouts();
    let state = 0;
    this.titleInterval = setInterval(() => {
      if (state === 0) {
        state = 1;
        this.title.setTitle("LA Rapid Rehousing");
      } else {
        state = 0;
        const text = moment(this.user.value.expires).fromNow();
        this.title.setTitle(
          `Session expire${text.includes("ago") ? "d" : "s"} ${text}`
        );
      }
    }, 10000);
  };

  /**
   * Clear timeouts
   */
  clearTimeouts = () => {
    if (this.titleInterval) clearInterval(this.titleInterval);
  };

  /**
   * User object to model class
   * @param user User object from token API / localstorage
   */
  setUserSubject = (user: any) => {
    const loadedUser = new User(
      user.access_token,
      // new Date(new Date(user['.expires']).getTime() - 86200000),
      new Date(user[".expires"]),
      user.userName,
      user.fullName,
      user.refresh_token
    );
    this.user.next(loadedUser);
    // this.showExpiryTimeInTitle();
  };

  /**
   * Refresh token
   * @returns observable
   */
  refreshToken = async (): Promise<User> => {
    if (!this.user.value || this.user.value.isTokenValid)
      return Promise.resolve(this.user.value);

    const tokenUrl = environment.apiUrl + "/token";
    let tokenParams = new HttpParams();
    tokenParams = tokenParams.append("grant_type", "refresh_token");
    tokenParams = tokenParams.append(
      "refresh_token",
      this.user.value.refreshToken
    );
    tokenParams = tokenParams.append("client_id", environment.client_id);

    const tokenOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };

    if (!this.refreshTokenPromise)
      this.refreshTokenPromise = this.http
        .post<User>(tokenUrl, tokenParams, tokenOptions)
        .pipe(
          tap((user) => {
            this.setUserSubject(user);
            localStorage.setItem("session", user.access_token.toString());
            let user_data = JSON.stringify(user).toString();
            localStorage.setItem("userData", user_data);
            this.refreshTokenPromise = null;
          })
        )
        .toPromise();
    return await this.refreshTokenPromise;
  };

  idleWatch = () => {
    this.idle.setIdle(this.IDLE_TIME);
    this.idle.setTimeout(this.IDLE_TIMEOUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.title.setTitle(
        `${new Date(countdown * 1000)
          .toISOString()
          .substr(14, 5)} minutes idle time remaining before being logged out`
      );
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.title.setTitle("LA Rapid Rehousing");
    });
    this.idle.onTimeout.subscribe(() => {
      this.logout();
      this.idle.stop();
      this.ngbModal.dismissAll();
    });
    this.idle.watch();
  };
}
