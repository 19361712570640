<div class="mt-4">
  <table class="table">
    <thead>
      <tr>
        <td>Subject</td>
        <td>Messages</td>
        <td>Date Created</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of applicationData">
        <td>{{ item.subject }}</td>
        <td>{{ item.details }}</td>
        <td>
          {{ item.datecreated | date }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
