import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { InfoPopComponent } from "./components/info-pop/info-pop.component";
import { TranslateModule } from "@ngx-translate/core";
import { FileDeleteComponent } from "./components/file-delete/file-delete.component";
import { CreateTicketComponent } from "./components/communication/create-ticket/create-ticket.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TransformPipe } from "./pipes/transform.pipe";
import { HhVerifyComponent } from "./components/hh-verify/hh-verify.component";
import { NoSpecialCharsDirective } from "./directives/no-special-chars.directive";
import { RequiredFieldComponent } from "./components/required-field/required-field.component";
import { FileViewComponent } from "./components/file-view/file-view.component";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
  declarations: [
    InfoPopComponent,
    FileDeleteComponent,
    CreateTicketComponent,
    TransformPipe,
    HhVerifyComponent,
    NoSpecialCharsDirective,
    RequiredFieldComponent,
    FileViewComponent,
  ],
  imports: [
    MatCheckboxModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    MatCheckboxModule,
    MatStepperModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule,
    InfoPopComponent,
    FileDeleteComponent,
    CreateTicketComponent,
    TransformPipe,
    NoSpecialCharsDirective,
    RequiredFieldComponent,
  ],
})
export class MaterialModule {}
