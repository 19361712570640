import { Routes } from "@angular/router";

import { ApplicationListComponent } from "./list/list.component";
import { ApplicationAddComponent } from "./add/add.component";
import { ApplicationEditLandlordComponent } from "./edit_landlord/edit_landlord.component";
import { ApplicationEditApplicantComponent } from "./edit_applicant/edit_applicant.component";
import { DashboardResolver } from "src/app/modules/dashboards/dashboard.resolver";
import { DetermineEligibilityComponent } from "./eligibility/determine-eligibility.component";
import { HasUnsavedChangesGuard } from "../../guards/unsaved-changes.guard";
import { HelpDeskComponent } from "./communication/help-desk/help-desk.component";
import { CaseManagerComponent } from "./communication/case-manager/case-manager.component";
import { FileViewComponent } from "src/app/shared/components/file-view/file-view.component";

export const ApplicationRoutes: Routes = [
  {
    path: "",
    component: ApplicationListComponent,
    data: {
      title: "Application",
      subjectTableName: "application-list",
      urls: [{ title: "" }],
    },
    resolve: {
      componentData: DashboardResolver,
    },
  },
  {
    path: "application/list",
    component: ApplicationListComponent,
    data: {
      title: "Application",
      subjectTableName: "application-list",
      urls: [{ title: "" }],
    },
    // resolve: {
    //   componentData: DashboardResolver
    // }
  },
  {
    path: "application/edit_landlord/:id",
    component: ApplicationEditLandlordComponent,
    data: {
      title: "Edit Landlord Application",
      subjectTableName: "application-edit-landlord",
      urls: [{ title: "" }],
    },
    resolve: {
      componentData: DashboardResolver,
    },
  },
  {
    path: "application/edit_applicant/:id",
    component: ApplicationEditApplicantComponent,
    data: {
      title: "Edit Rental Application",
      subjectTableName: "application-edit-applicant",
      urls: [{ title: "" }],
    },
    resolve: {
      componentData: DashboardResolver,
    },
  },
  {
    path: "application/add",
    component: ApplicationAddComponent,
    canDeactivate: [HasUnsavedChangesGuard],
    data: {
      title: "Add Application",
      subjectTableName: "application-add",
      urls: [{ title: "" }],
    },
    resolve: {
      componentData: DashboardResolver,
    },
  },
  {
    path: "application/determine-eligibility/:appId",
    component: DetermineEligibilityComponent,
    canDeactivate: [HasUnsavedChangesGuard],
    data: {
      title: "Determine Eligibility",
      subjectTableName: "determine-eligibility",
      urls: [{ title: "Determine Eligibility" }],
    },
    resolve: {
      componentData: DashboardResolver,
    },
  },
  {
    path: "application/communication/help-desk",
    component: HelpDeskComponent,
    data: {
      title: "Help Desk - Communication",
    },
  },
  {
    path: "application/communication/case-manager",
    component: CaseManagerComponent,
    data: {
      title: "Case Manager - Communication",
    },
  },
  {
    path: "application/:tableId/file-view/:recordId/:fieldId",
    component: FileViewComponent,
    data: {
      title: "Case Manager - Communication",
    },
  },
];

export const ApplicationComponents: any = [

];
