import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
import { CommonService } from "../../../services";
import { Constants } from "src/app/shared/constants";
import { ApplicationForms } from "../forms";
import { FormControl, FormGroup } from "@angular/forms";
import { PopupModel } from "../../../models/popup";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { validateHorizontalPosition } from "@angular/cdk/overlay";
import { TranslateService } from "@ngx-translate/core";
import { stringify } from "querystring";

@Component({
  selector: "application-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
  providers: [ApplicationForms],
})
export class ApplicationListComponent implements OnInit {
  receivedData: any;
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private ignatiusService: IgnatiusService,
    private projectSpecificService: ProjectSpecificService,
    private toastr: ToastrService,
    private ngbModal: NgbModal,
    private appForms: ApplicationForms,
    private common: CommonService,
    private translate: TranslateService,
    private router: Router
  ) {}

  applicationData: Array<any> = [];
  propertiesData: Array<any> = [];
  allPropertiesData: Array<any> = [];
  projectSpecificData: any =
    this.projectSpecificService.getProjectSpecificData();
  applicantType = "";
  rentalData: Array<any> = [];
  rentalPendingData: Array<any> = [];
  paymentFilesData: Array<any> = [];
  assistanceClosedReasonsData: Array<any> = [];
  llemail = "";
  modelConfig: PopupModel;
  approveApplicantFormGroup: FormGroup;
  inviteApplicantFormGroup: FormGroup;
  ticketForm: FormGroup;
  // communityUser: boolean = false;
  get approveApplicantFormControl() {
    return this.approveApplicantFormGroup.controls;
  }
  get inviteApplicantFormControl() {
    return this.inviteApplicantFormGroup.controls;
  }
  get ticketFormContol() {
    return this.ticketForm.controls;
  }
  @ViewChild("statusPopup", { static: true }) statusPopup: TemplateRef<any>;
  @ViewChild("statusPopup2", { static: true }) statusPopup2: TemplateRef<any>;

  async ngOnInit() {
    this.projectSpecificData = this.projectSpecificService.getProjectSpecificData();
    this.receivedData = localStorage.getItem('invalid-user');
    if (window.history.state && window.history.state.data) {
      localStorage.setItem('invalid-user',window.history.state.data);
      this.receivedData = window.history.state.data;
    }
    await this.getApplicationData();
    this.assistanceClosedReasonsData = this.applicationData[0].assistance_closed_reason.split(";");
  }

  async getApplicationData() {
    try {
      this.spinner.show();
      this.applicationData = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.applicationsData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData.CreatedBy,
                  },
                  OperationType: "is equal",
                  Value: this.projectSpecificService.getUserData().userName,
                },
              ],
            },
          ],
        })
        .toPromise();

      // "ReportId": this.projectSpecificData.applicationsData.LandlordApplicationListReportId
      this.spinner.hide();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
    const permissionTypeData = this.projectSpecificService.getPermissionType();
    const userData = this.projectSpecificService.getUserData();

    await this.getPaymentFilesData();

    if (JSON.stringify(permissionTypeData).toString().includes("Landlord")) {
      this.applicantType = "Landlord";
      this.llemail = userData.userName;
      await this.getRentalData();
      await this.getPendingRentalData();
      await this.getPropertiesData();
    } else this.applicantType = "Applicant";

    if (
      this.applicationData &&
      !userData.userName.includes("@lastaterent.com") &&
      this.applicantType === "Applicant"
    )
      this.applicationData.splice(1);

    // if (this.applicationData &&
    //   userData.userName.includes("@lastaterent.com") &&
    //   this.applicantType === "Applicant"
    // )
    //   this.communityUser = true;

    this.openPopupByStatus();
    this.openPopup2ByStatus();
  }

  async getPropertiesData() {
    try {
      this.spinner.show();
      this.propertiesData = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.propertiesData.TableId,
        })
        .toPromise();
      this.allPropertiesData = this.propertiesData;
      this.spinner.hide();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.PROPERTY_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
  }

  companyChange(landlord_id) {
    this.propertiesData = this.allPropertiesData.filter((p) => {
      return p["related_applications"] === landlord_id;
    });
  }
  /**
   * Upload selected file instantly
   * @param field_name is name of field in which file name is stored
   * @param field_file is name of field in which file is stored
   */
  onSelectFileUpload = async (
    event: any,
    field_name: string,
    field_file: string
  ) => {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    const tableId = this.projectSpecificData.applicationsData.TableId;
    const recordId = Number(this.approveApplicantFormGroup.value["id"]);
    // alert(recordId);

    this.approveApplicantFormGroup.patchValue({
      [field_file]: filestr,
      [field_name]: strFilename,
    });
    // const updateData = this.common.createRentalDocumentsData({
    //   [field_name]: filename,
    //   [field_file]: filestr
    // }, tableId, recordId)
    // this.uploadFile(updateData, event);
  };

  private async getRentalData(sync: boolean = false) {
    try {
      this.rentalData = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.applicationsData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData.LandlordEmail,
                  },
                  OperationType: "is equal",
                  Value: this.llemail,
                },
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData.ApplicantType,
                  },
                  OperationType: "is equal",
                  Value: "Applicant",
                },
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData
                      .LandlordStatus,
                  },
                  OperationType: "is equal",
                  Value: "Request Accepted",
                },
              ],
            },
          ],
        })
        .toPromise();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.RENTAL_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
  }

  private async getPendingRentalData(sync: boolean = false) {
    try {
      this.rentalPendingData = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.applicationsData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData.LandlordEmail,
                  },
                  OperationType: "is equal",
                  Value: this.llemail,
                },
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData.ApplicantType,
                  },
                  OperationType: "is equal",
                  Value: "Applicant",
                },
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData
                      .LandlordStatus,
                  },
                  OperationType: "is equal",
                  Value: "Request Sent",
                },
              ],
            },
          ],
        })
        .toPromise();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.RENTAL_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
  }

  private async getPaymentFilesData(sync: boolean = false) {
    try {
      this.paymentFilesData = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.paymentFilesData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: this.projectSpecificData.paymentFilesData.RelatedApplicationsFieldId,
                  },
                  OperationType: "is equal",
                  Value: this.applicationData[0].id,
                },
              ],
            },
          ],
        })
        .toPromise();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.PAYMENT_FILES_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
  }

  async submitApplication(recordId: number) {
    const dialogData: { title: string; message: string } = {
      title: "applications.SAVE_CONTINUE",
      message: `applications.SAVE_CONTINUE_MESSAGE_APP`,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData,
    });

    const confirmation = await dialogRef.afterClosed().toPromise();

    if (!confirmation) return;

    try {
      const reqData = { status: "Submitted" };
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = recordId;
      const recordFAD = this.common.createPutData(tableId, reqData, condition);

      this.spinner.show();
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.getApplicationData();
      this.spinner.hide();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.ERROR_SYNC_PERCENTAGE"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
  }

  async submitEligibilityAnswers(app: any) {
    const dialogData: { title: string; message: string } = {
      title: "applications.SAVE_CONTINUE",
      message: `applications.SAVE_CONTINUE_MESSAGE_SUBMIT`,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData,
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) return;

    // let status =  app.ineligible_comment ? 'Ineligible' : 'Open';
    if (app.applicant_type == "Applicant") {
      let questions: any = await this.translate.instant(
        "eligibilityApplicant.questions"
      );
      status = questions
        .map((q) => app[q.field] == "True")
        .some((x) => x == true)
        ? "Open"
        : "Ineligible";
    }
    if (app.applicant_type == "Landlord") {
      let questions: any = await this.translate.instant(
        "eligibilityLandlord.questions"
      );
      questions = questions.slice(0, questions.length - 1);
      status = questions
        .map((q) => app[q.field] == "True")
        .some((x) => x == false)
        ? "Ineligible"
        : "Open";
    }
    const tableId = this.projectSpecificData.applicationsData.TableId;
    const condition = Number(app.id);
    const obj = { status };
    const recordFAD = this.common.createPutData(tableId, obj, condition);

    try {
      this.spinner.show();
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.getApplicationData();
      // this.navigateByStatus(status, app.id);
      this.spinner.hide();
    } catch {
      this.toastr.error(
        this.translate.instant("MESSAGES.ELIGIBILITY_ANSWERS_SAVE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
  }

  private navigateByStatus = (status: string, recordId: string) => {
    switch (status) {
      case "Open":
        this.router.navigate([
          "application",
          this.applicantType === "Applicant" ? "edit_applicant" : "edit_landlord",
          recordId,
        ]);
        break;
      default:
      case "Ineligible":
        this.router.navigate(["application/list"]);
    }
  };

  // openInviteApplicant(content: any) {
  //   const userData = JSON.parse(localStorage.getItem('userData'));
  //   const email = userData ? userData.userName : null;
  //   this.inviteApplicantFormGroup = this.appForms.setupInviteApplicantForm(email);
  //   this.modelConfig = new PopupModel();
  //   this.ngbModal.open(content, this.modelConfig.settings)
  // }

  openInviteApplicant(content: any, appId: string) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const email = userData ? userData.userName : null;
    this.inviteApplicantFormGroup = this.appForms.setupInviteApplicantForm(
      email,
      appId
    );
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "lg";
    this.ngbModal.open(content, this.modelConfig.settings);
  }

  onInviteApplicantSubmit() {
    if (!this.inviteApplicantFormGroup.valid) {
      this.common.validateAllFormFields(this.inviteApplicantFormGroup);
      return;
    }
    // if (!this.inviteApplicantFormControl.verification.value) {
    //   this.toastr.error(Constants.MESSAGES.VERIFICATION_NOT_CHECKED, 'Error');
    //   return;
    // }
    this.insertInviteApplicant(this.inviteApplicantFormGroup.value);
  }

  private async insertInviteApplicant(reqData) {
    try {
      this.modelConfig.busy = true;
      const tableId = this.projectSpecificData.applicantInvitationsData.TableId;
      const recordFAD = this.common.createPostData(tableId, reqData);
      await this.ignatiusService.postData(recordFAD).toPromise();
      await this.getApplicationData();

      this.updateInviteApplicant(true);
    } catch (error) {
      this.updateInviteApplicant(false);
    }
    this.modelConfig.busy = false;
  }

  private updateInviteApplicant(statue: boolean) {
    if (statue) {
      this.toastr.success(
        this.translate.instant("MESSAGES.CREATE_TICKET_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.ngbModal.dismissAll();
    } else {
      this.toastr.error(
        this.translate.instant("MESSAGES.INVITATION_EMAIL_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
    this.modelConfig.busy = false;
  }

  openApproveApplicant(content: any, rentId) {
    this.approveApplicantFormGroup = this.appForms.setupApproveApplicantForm(
      rentId,
      this.rentalPendingData
    );
    this.modelConfig = new PopupModel();
    this.ngbModal.open(content, this.modelConfig.settings);
  }

  onApproveApplicantSubmit() {
    if (!this.approveApplicantFormGroup.valid) {
      this.common.validateAllFormFields(this.approveApplicantFormGroup);
      return;
    }
    if (!this.approveApplicantFormControl.verification.value) {
      this.toastr.error(
        this.translate.instant("MESSAGES.VERIFICATION_NOT_CHECKED"),
        this.translate.instant("applications.ERROR")
      );
      return;
    }
    if (!this.validateApproveApplicant()) {
      this.toastr.error(
        this.translate.instant("MESSAGES.VERIFICATION_MISMATCH"),
        this.translate.instant("applications.ERROR")
      );
      return;
    }

    this.approveApplicantFormControl.landlord_status.setValue("Request Accepted");

    this.updateApplicantApplication(this.approveApplicantFormGroup.value);
  }

  validateApproveApplicant(): Boolean {
    // verify the amounts match and verify the confirmation code matches
    // if (!(this.approveApplicantFormControl.monthly_rent.value ===  this.approveApplicantFormControl.ll_monthly_rent.value) ||
    //     !(this.approveApplicantFormControl.past_due_amount.value === this.approveApplicantFormControl.ll_past_due_amount.value) ||
    //     !(this.approveApplicantFormControl.confirmation_number.value === this.approveApplicantFormControl.ll_confirmation_number.value)) {
    //       return false;
    // }

    // verify the confirmation code matches only
    if (
      !(
        this.approveApplicantFormControl.confirmation_number.value ===
        this.approveApplicantFormControl.ll_confirmation_number.value
      )
    ) {
      return false;
    }
    return true;
  }

  private async updateApplicantApplication(reqData: any) {
    try {
      const tableId = this.projectSpecificData.applicationsData.TableId;

      // update the applicant application, not the landlord application
      const condition = Number(reqData.id);
      const recordFAD = this.common.createPutDataApproval(
        tableId,
        reqData,
        condition
      );
      this.modelConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.getApplicationData();
      this.updateApplicationCompleted(true);
    } catch (error) {
      this.updateApplicationCompleted(false);
    }
  }
  private updateApplicationCompleted(statue: boolean) {
    if (statue) {
      this.toastr.success(
        this.translate.instant("MESSAGES.APPLICATION_UPDATE_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.ngbModal.dismissAll();
    } else {
      this.toastr.error(
        this.translate.instant("MESSAGES.APPLICATION_UPDATE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
    this.modelConfig.busy = false;
  }

  async closeModel() {
    const dialogData: { title: string; message: string } = {
      title: "applications.CONFIRM_DISCARD",
      message: `applications.CONFIRM_DISCARD_MESSAGE`,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData,
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) return;

    this.ngbModal.dismissAll();
  }

  createTicket(content: any, appId: string) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const email = userData ? userData.userName : null;
    this.ticketForm = this.appForms.setupTicketForm(email, appId);
    this.modelConfig = new PopupModel();
    this.ngbModal.open(content, this.modelConfig.settings);
  }

  async saveTicket() {
    if (!this.ticketForm.valid) {
      this.common.validateAllFormFields(this.ticketForm);
      return;
    }

    try {
      this.modelConfig.busy = true;
      const tableId = this.projectSpecificData.commThreadsData.TableId;
      const recordFAD = this.common.createPostData(
        tableId,
        this.ticketForm.value
      );
      await this.ignatiusService.postData(recordFAD).toPromise();

      this.toastr.success(
        this.translate.instant("MESSAGES.CREATE_TICKET_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.ngbModal.dismissAll();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.CREATE_TICKET_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
    this.modelConfig.busy = false;
  }

  isViewMode = (applicationStatus: string) =>
    ![
      //"Referred",
      //"Ineligible",
      //"Pre-Eligibility",
      //"Eligibility Questions Answered",
      "Open",
      "Submitted"
      //"Registered"
    ].find((mode) => mode === applicationStatus);

  isEligible = (application: any) => {
    if (
      application.eligibility_1 === "True" ||
      application.eligibility_2 === "True"
    )
      return true;
    return false;
  };

  /**
   * Can landlord approve the applicant
   */
  canApprove = () =>
    this.applicationData.filter(
      (record) => record.applicant_type === "Landlord"
    ).length;

  /**
   * Open a popup when a application is in list of statuses
   */
  openPopupByStatus = () => {
    let isPopupVisible = false;
    /// NES - 12/15/2022 - Do not show popups
    // if (this.applicantType === "Applicant") {
    //   if (
    //     this.applicationData.length &&
    //     this.applicationData
    //       .map(
    //         (application) =>
    //           [
    //             "Ineligible",
    //             "Referred",
    //             "Pending Eligibility",
    //             "Eligibility Questions Answered",
    //             "Open",
    //             "Pre-Eligibility"
    //           ].indexOf(application.status) === -1
    //       )
    //       .some((status) => status === true) &&
    //     !isPopupVisible
    //   ) {
    //     this.dialog.open(this.statusPopup);
    //     isPopupVisible = true;
    //   }
    // }
  };

  /**
   * Open a popup when a application is in list of statuses
   */
   openPopup2ByStatus = () => {
    let isPopup2Visible = false;
    /// NES - 12/15/2022 - Do not show popups
    // if (this.applicantType === "Applicant") {
    //   if (
    //     this.applicationData.length &&
    //     this.applicationData
    //     .map(
    //        (application) =>
    //          [
    //            "0"
    //          ].indexOf(application.total_recerts) !== -1
    //     ) 
    //     .some((status) => status === true) &&
    //     this.applicationData
    //       .map(
    //         (application) =>
    //           [
    //             "Ineligible",
    //             "Referred",
    //             "Pending Eligibility",
    //             "Eligibility Questions Answered",
    //             "Open",
    //             "Submitted",
    //             "Application Under Review",
    //             "Application Approved for Payment",
    //             "Application Deemed Ineligible",
    //             "Pre-Eligibility"
    //           ].indexOf(application.status) === -1
    //       )
    //       .some((status) => status === true) &&
    //     !isPopup2Visible
    //   ) {
    //     this.dialog.open(this.statusPopup2);
    //     isPopup2Visible = true;
    //   }
    // }
  };

  /* create file view url
   */
  getFileRouteLink(url: string): string {
    if (!url) return "";
    const urlSplit = url.split("tables");
    if (urlSplit.length < 1) return "";
    return "application" + urlSplit[1];
  }

  getControl(form: FormGroup, control: any) {
    return form.controls[control] as FormControl;
  }
}
