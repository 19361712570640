import {
  Component,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { forkJoin, lastValueFrom } from "rxjs";
import { FieldListItem, FormActionData } from "src/app/models/form-action-data";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { ApplicationForms } from "src/app/modules/dashboards/components/applications/forms";
import { PopupModel } from "src/app/modules/dashboards/models/popup";
import { CommonService } from "src/app/modules/dashboards/services";
import { AuthService } from "src/app/services/auth.service";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { Constants } from "src/app/shared/constants";
import en from "src/assets/i18n/en.json";
import { timeStamp } from "console";
import { userInfo } from "os";

@Component({
  selector: "app-create-ticket",
  templateUrl: "./create-ticket.component.html",
  styleUrls: ["./create-ticket.component.css"],
  providers: [ApplicationForms],
})
export class CreateTicketComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal: any;
  @Input("successFn") successFn: Function = function () {};
  @Input("template") template: TemplateRef<any>;
  @Input("appId") appId: number = -1;
  @Input("application") application: any;

  projectSpecificData: any =
    this.projectSpecificService.getProjectSpecificData();
  ticketForm: FormGroup;
  modalConfig: PopupModel;
  ticketTypes: Array<any> = [];
  allTicketTypes: Array<any> = [];
  applications: Array<any> = [];
  utilityProviders: Array<any> = [];
  applicantType: string;
  attestationData = {
    tenant: "",
    unitAddress: "",
    landlordName: "",
    appId: "",
  };
  recertificationData = {
    appId: "",
    tenant: "",
    contactPhone: "",
    rentalAddress: "",
    landlordName: "",
    monthlyRentAmount: "",
    recert_monthly_rent: "",
    recert_address_1: "",
    recert_address_2: "",
    recert_city: "",
    recert_state: "",
    recert_zip: "",
    recert_phone: "",
    recert_landlord_name: "",
    recert_address_changed: "",
    recert_monthly_rent_changed: "",
    recert_phone_changed: "",
    recert_num_bedrooms: "",
  };
  nameField: string = "";
  lastTicketType: string = "";
  resetFieldsForTickets: Array<string> = ["Utilities", "Attestation"];
  expenseTypes: Array<any> = [];
  acknowledgementsFormGroup: FormGroup;
  showUtilityTicket: boolean = false;
  showRecertificationTicket: boolean = false;
  showDirectPaymentTicket: boolean = false;
  render: boolean = true;
  selectedApp: any;
  invalidLeaseDates: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private ignatiusService: IgnatiusService,
    private toastr: ToastrService,
    private ngbModal: NgbModal,
    private common: CommonService,
    private projectSpecificService: ProjectSpecificService,
    private translate: TranslateService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private appForms: ApplicationForms
  ) {}

  ngOnInit() {
    this.projectSpecificData =
    this.projectSpecificService.getProjectSpecificData();
  }

  /**
   * Fetch data from backend
   */
  fetch = () => {
    // fetch dropdowns
    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.commThreadsData?.TicketTypeMCID?.toString()
      )
      .subscribe((data) => {
        this.ticketTypes = data.filter(
          (a: any) => a.Value != "Direct Payment Form"
        );
        this.allTicketTypes = [...data];
        // this.setUtilitiesTypeToTop();
        // this.setRecertificationTypeToTop();
      });

    if (this.appId === -1)
      // fetch applications if app id doesnt exists
      this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.applicationsData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData.ApplicantType,
                  },
                  OperationType: "is equal",
                  Value: this.applicantType,
                },
                {
                  ConditionField: {
                    Id: this.projectSpecificData.applicationsData.CreatedBy,
                  },
                  OperationType: "is equal",
                  Value: this.authService.user.getValue().userName,
                },
              ],
            },
          ],
        })
        .subscribe((data) => {
          this.applications = data;
          // this.isUtilityTicketAvailable();
          // this.isRecertificationTicketAvailable();
          // this.isDirectPaymentTicketAvailable();
          // this.setUtilitiesTypeToTop();
          // this.setRecertificationTypeToTop();
          // this.checkRecertTicketLocation();
        });
    else {
      this.applications.push(this.application);
      // this.isUtilityTicketAvailable();
      // this.isRecertificationTicketAvailable();
      // this.setUtilitiesTypeToTop();
      // this.setRecertificationTypeToTop();
      // this.checkRecertTicketLocation();
    }
  };

  checkRecertTicketLocation() {
    // if (this.ticketTypes
    //   .map((ticket) => ticket.value)
    //   .indexOf("Recertification") > 1) {
    //     this.ticketTypes.splice(this.ticketTypes
    //       .map((ticket) => ticket.value)
    //       .indexOf("Recertification"), 1);
    //   }
  }
  /**
   * Check if direct payment form ticket is available
   */
  isDirectPaymentTicketAvailable = () =>
    (this.showDirectPaymentTicket =
      this.applicantType === "Applicant" &&
      this.applications
        .map(
          (application) =>
            ["TRUE"].indexOf(application.uncooperative_landlord) !== -1
        )
        .some((status) => status === true));

  /**
   * Set ticket type utilities to top
   */
  setDirectPaymentTypeToTop = () => {
    const directPaymentObject = this.ticketTypes.find(
      (ticket) => ticket.value === "Direct Payment Form"
    );
    const location = this.ticketTypes
      .map((ticket) => ticket.value)
      .indexOf("Direct Payment Form");
    // remove from current location
    this.ticketTypes.splice(location, 1);
    // insert at location 0
    this.ticketTypes.splice(0, 0, directPaymentObject);
  };

  /**
   * Check if utility ticket is available
   */
  isUtilityTicketAvailable = () =>
    (this.showUtilityTicket =
      this.applicantType === "Applicant" &&
      this.applications
        .map(
          (application) =>
            [
              "Ineligible",
              "Referred",
              "Pending Eligibility",
              "Eligibility Questions Answered",
              "Open",
              "Registered",
              "Pre-Eligibility"
            ].indexOf(application.status) === -1
        )
        .some((status) => status === true));

  /**
   * Set ticket type utilities to top
   */
  setUtilitiesTypeToTop = () => {
    const utilityObject = this.ticketTypes.find(
      (ticket) => ticket.value === "Utilities"
    );
    const location = this.ticketTypes
      .map((ticket) => ticket.value)
      .indexOf("Utilities");
    // remove from current location
    this.ticketTypes.splice(location, 1);
    //this.ticketTypes.splice(0, 0, utilityObject);
    // insert at location 0
  };

  /**
   * Check if recertification ticket is available
   */
   isRecertificationTicketAvailable () {
    if (
      this.authService.user.getValue().userName.toLowerCase() === "community1@lastaterent.com" || 
      this.authService.user.getValue().userName.toLowerCase() === "community2@lastaterent.com" || 
      this.authService.user.getValue().userName.toLowerCase() === "community3@lastaterent.com" || 
      this.authService.user.getValue().userName.toLowerCase() === "community4@lastaterent.com" || 
      this.authService.user.getValue().userName.toLowerCase() === "community5@lastaterent.com" ) 
      {
        this.showRecertificationTicket = true;
      }
    else {
      this.showRecertificationTicket = false;
      /// NES - 12/15/2022 - Do not show recertification
      // this.showRecertificationTicket = (
      //   this.applicantType === "Applicant" &&
      //   this.applications
      //     .map(
      //       (application) =>
      //         [
      //           "0"
      //         ].indexOf(application.total_recerts) !== -1
      //       )
      //       .some((status) => status === true) &&
      //   this.applications
      //     .map(
      //       (application) =>
      //         [
      //           "Ineligible",
      //           "Referred",
      //           "Pending Eligibility",
      //           "Eligibility Questions Answered",
      //           "Open",
      //           "Submitted",
      //           "Application Under Review",
      //           "Application Approved for Payment",
      //           "Application Deemed Ineligible",
      //           "Pre-Eligibility"
      //         ].indexOf(application.status) === -1
      //     )
      //     .some((status) => status === true)
      //     );
    }
    return this.showRecertificationTicket
  }

  /**
   * Set ticket type recertification to top
   */
  setRecertificationTypeToTop = () => {
    const recertificationObject = this.ticketTypes.find(
      (ticket) => ticket?.Value === "Recertification"
    );
    const location = this.ticketTypes
      .map((ticket) => ticket?.Value)
      .indexOf("Recertification");
    // remove from current location
    
    
    /// NES - 12/15/2022 - Do not add recertification back
    if (
      this.applicantType === "Applicant" &&
        (
          this.authService.user.getValue().userName.toLowerCase() === "community1@lastaterent.com" || 
          this.authService.user.getValue().userName.toLowerCase() === "community2@lastaterent.com" || 
          this.authService.user.getValue().userName.toLowerCase() === "community3@lastaterent.com" || 
          this.authService.user.getValue().userName.toLowerCase() === "community4@lastaterent.com" || 
          this.authService.user.getValue().userName.toLowerCase() === "community5@lastaterent.com" ) 
    ) {
      this.ticketTypes.splice(location, 1);
      this.ticketTypes.splice(0, 0, recertificationObject);
      // insert at location 0
    }
    // insert at location 0
    // if (this.applicantType === "Applicant" && this.showRecertificationTicket)
    //   this.ticketTypes.splice(0, 0, recertificationObject);
  };

  fetchExpenseType = () =>
    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.expensesData.ExpenseTypeMCID.toString()
      )
      .subscribe((data) => {
        this.expenseTypes = data;
      });

  fetchProviders = () =>
    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.commThreadsData.ElectricityCompaniesFieldId.toString()
      )
      .subscribe((data) => {
        this.utilityProviders = data;
      });

  /**
   * Init form
   */
  initForm = () => {
    this.ticketForm = this.formBuilder.group({
      subject: [null, Validators.required],
      details: [null, Validators.required],
      file_doc_1: [null],
      file_doc_1_url: [null],
      file_doc_2: [null],
      file_doc_2_url: [null],
      file_doc_3: [null],
      file_doc_3_url: [null],
      file_doc_4: [null],
      file_doc_4_url: [null],
      file_doc_5: [null],
      file_doc_5_url: [null],
      ticket_type: ["", Validators.required],

      related_applications: ["", Validators.required],
      status: "Open",
      type: "Ticket",
      applicant_send_to: this.authService.user.getValue().userName,
      utilities: this.formBuilder.group({
        utility_provider: "",
        name_on_account: null,
        account_number: null,
        amount_past_due: null,
        comments: null,
        electricity_company_other: null,
        name_on_account_hh_member: null,
      }),
      appId: "",
      recertifications: this.formBuilder.group({
        recert_monthly_rent: "",
        recert_address_1: "",
        recert_address_2: "",
        recert_city: "",
        recert_state: "",
        recert_zip: "",
        recert_phone: "",
        recert_landlord_name: "",
        recert_address_changed: "",
        recert_monthly_rent_changed: "",
        recert_phone_changed: "",
        recert_num_bedrooms: "",
      }),
      expense_type: [
        "",
        // this.applicantType === "Applicant" ? Validators.required : [],
      ],
      utility_months_in_arrears: [0, [Validators.min(0), Validators.max(99)]],
      date: [null],
    });

    if (this.appId !== -1)
      this.ticketForm.patchValue(
        {
          related_applications: this.appId,
        },
        {
          emitEvent: false,
        }
      );
  };

  /**
   * Load file
   */
  loadFile = async (event: any, nameField: string, urlField: string) => {
    let file = event.target.files[0];
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error("Maximum 20MB file size exceeded", "Error");
      this.ticketForm.patchValue({
        [nameField]: null,
        [urlField]: null,
      });
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    this.ticketForm.patchValue(
      {
        [nameField]: strFilename,
        [urlField]: filestr,
      },
      {
        emitEvent: false,
      }
    );
  };

  /**
   * Submit helpdesk
   */
  submitTicket = async () => {
    if (this.ticketForm.invalid) return;
    if (this.ticketForm.controls.ticket_type.value === "Utilities") {
      this.ticketForm.patchValue({
        details: `Utility Provider = ${this.ticketForm.controls["utilities"].value.utility_provider}
          Name on Account = ${this.ticketForm.controls["utilities"].value.name_on_account}
          Account Number = ${this.ticketForm.controls["utilities"].value.account_number}
          Past Due Amount = ${this.ticketForm.controls["utilities"].value.amount_past_due}
          Comments = ${this.ticketForm.controls["utilities"].value.comments}
          Expense Type = ${this.ticketForm.value.details}\n${this.ticketForm.value.expense_type}`,
      });
    }
    // if (this.applicantType === "Applicant") {
    //   this.ticketForm.patchValue({
    //     details: `${this.ticketForm.value.details}\n${this.ticketForm.value.expense_type}`,
    //   });
    // }
    try {
      this.modalConfig.busy = true;
      const tableId = this.projectSpecificData.commThreadsData.TableId;

      const recordFAD = new FormActionData(
        0,
        tableId,
        null,
        new Array<FieldListItem>()
      );

      for (const key in this.ticketForm.value) {
        if (
          key.includes("file_doc_") &&
          !key.includes("_url") &&
          this.ticketForm.value[key]
        ) {
          recordFAD.fieldsList.push(
            new FieldListItem(
              key,
              this.ticketForm.value[key],
              this.ticketForm.value[`${key}_url`]
            )
          );
        } else if (
          !key.includes("file_doc_") &&
          this.ticketForm.value[key] &&
          key !== "utilities" &&
          key !== "recertifications" &&
          key !== "expense_type" &&
          key != "date"
        ) {
          recordFAD.fieldsList.push(
            new FieldListItem(key, this.ticketForm.value[key], "")
          );
        }
      }

      if (
        this.ticketForm.controls.utilities.get("utility_provider").value ===
        "Other"
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "electricity_company_other",
            this.ticketForm.controls.utilities.get(
              "electricity_company_other"
            ).value,
            ""
          )
        );
      }

      if (this.ticketForm.value.expense_type === "Utility - Electricity") {
        recordFAD.fieldsList.push(
          new FieldListItem(
            "name_on_account_hh_member",
            this.ticketForm.controls.utilities.get(
              "name_on_account_hh_member"
            ).value,
            ""
          )
        );
      }

      if (this.ticketForm.controls.ticket_type.value === "Attestation") {
        recordFAD.fieldsList.push(
          new FieldListItem("details", this.getDetailsText(), "")
        );
      }

      // RECERTIFICATION START
      if (this.ticketForm.controls.ticket_type.value === "Recertification") {
        recordFAD.fieldsList.push(
          new FieldListItem("details", this.getRecertificationDetailsText(), "")
        );

        this.createExpenseRecord({
          related_applications: this.ticketForm.value.related_applications,
          expense_type: "Rent",
          in_arrears: "Rental Advance Only",
          recert_monthly_rent:
            this.ticketForm.controls["recertifications"].value.recert_monthly_rent,
          recert_address_1:
            this.ticketForm.controls["recertifications"].value.recert_address_1,
          recert_address_2:
            this.ticketForm.controls["recertifications"].value.recert_address_2,
          recert_city:
            this.ticketForm.controls["recertifications"].value.recert_city,
          recert_state:
            this.ticketForm.controls["recertifications"].value.recert_state,
          recert_zip:
            this.ticketForm.controls["recertifications"].value.recert_zip,
          recert_phone:
            this.ticketForm.controls["recertifications"].value.recert_phone,
          recert_landlord_name:
            this.ticketForm.controls["recertifications"].value
              .recert_landlord_name,
          recert_address_changed:
            this.ticketForm.controls["recertifications"].value
              .recert_address_changed,
          recert_monthly_rent_changed:
            this.ticketForm.controls["recertifications"].value
              .recert_monthly_rent_changed,
          recert_phone_changed:
            this.ticketForm.controls["recertifications"].value
              .recert_phone_changed,
          recert_num_bedrooms:
            this.ticketForm.controls["recertifications"].value
              .recert_num_bedrooms,

          recert_expense: "True",
        });

        let payload: FormActionData = {
          reportId: 0,
          applicationTableId: this.projectSpecificData.expensesData.TableId,
          where: { rid: this.ticketForm.value.related_applications },
          fieldsList: [
            { name: "recert_expense", value: "True", base64EncodedFile: "" },
            {
              name: "recert_address_1",
              value:
                this.ticketForm.controls[
                  "recertifications"
                ].value.recert_address_1.toString(),
              base64EncodedFile: "",
            },
            {
              name: "recert_address_2",
              value:
                this.ticketForm.controls["recertifications"].value
                  .recert_address_2,
              base64EncodedFile: "",
            },
            {
              name: "recert_city",
              value:
                this.ticketForm.controls["recertifications"].value.recert_city,
              base64EncodedFile: "",
            },
            {
              name: "recert_state",
              value:
                this.ticketForm.controls["recertifications"].value.recert_state,
              base64EncodedFile: "",
            },
            {
              name: "recert_zip",
              value:
                this.ticketForm.controls["recertifications"].value.recert_zip,
              base64EncodedFile: "",
            },
            {
              name: "recert_phone",
              value:
                this.ticketForm.controls["recertifications"].value.recert_phone,
              base64EncodedFile: "",
            },
            {
              name: "recert_landlord_name",
              value:
                this.ticketForm.controls["recertifications"].value
                  .recert_landlord_name,
              base64EncodedFile: "",
            },
            {
              name: "recert_address_changed",
              value:
                this.ticketForm.controls["recertifications"].value
                  .recert_address_changed,
              base64EncodedFile: "",
            },
            {
              name: "recert_monthly_rent_changed",
              value:
                this.ticketForm.controls["recertifications"].value
                  .recert_monthly_rent_changed,
              base64EncodedFile: "",
            },
            {
              name: "recert_phone_changed",
              value:
                this.ticketForm.controls["recertifications"].value
                  .recert_phone_changed,
              base64EncodedFile: "",
            },
            {
              name: "recert_monthly_rent",
              value:
                this.ticketForm.controls["recertifications"].value
                  .recert_monthly_rent,
              base64EncodedFile: "",
            },
            {
              name: "recert_num_bedrooms",
              value:
                this.ticketForm.controls["recertifications"].value
                  .recert_num_bedrooms,
              base64EncodedFile: "",
            },
          ],
        };
        ////wait this.ignatiusService.putData(payload).toPromise();
      }
      // RECERTIFICATION END

      if (
        this.ticketForm.controls.ticket_type.value === "Direct Payment Form"
      ) {
        recordFAD.fieldsList.push(
          new FieldListItem("details", this.getDirectPFDetailsText(), "")
        );
      }

      if (
        this.ticketForm.controls.ticket_type.value === "Utilities" &&
        this.applicantType === "Applicant"
      ) {
        // add cert and acks
        for (const key in this.acknowledgementsFormGroup.value) {
          recordFAD.fieldsList.push(
            new FieldListItem(
              key,
              this.acknowledgementsFormGroup.value[key],
              ""
            )
          );
        }
        this.submitAck();

        this.createExpenseRecord({
          related_applications: this.ticketForm.value.related_applications,
          expense_type: this.ticketForm.value.expense_type,
          provider:
            this.ticketForm.controls.utilities.get("utility_provider").value,
          // full_name: this.ticketForm.controls.utilities.get("utility_provider").value,
          account_number:
            this.ticketForm.controls.utilities.get("account_number").value,
          name_on_account:
            this.ticketForm.controls.utilities.get("name_on_account").value,
          name_on_account_hh_member: this.ticketForm.controls.utilities.get(
            "name_on_account_hh_member"
          ).value,
          amount_past_due:
            this.ticketForm.controls.utilities.get("amount_past_due").value,
          document_file: this.ticketForm.value.file_doc_1_url,
          document_name: this.ticketForm.value.file_doc_1,
          utility_months_in_arrears:
            this.ticketForm.value.utility_months_in_arrears,
        });
      }

      await this.ignatiusService.postData(recordFAD).toPromise();
      this.toastr.success(
        this.translate.instant("MESSAGES.CREATE_TICKET_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );

      await this.successFn();
      this.initForm();
      this.ngbModal.dismissAll();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.CREATE_TICKET_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
    this.modalConfig.busy = false;
  };

  submitAck = () => {
    const appData = this.common.createApplicantAcknowledgementsDataTicket(
      this.acknowledgementsFormGroup.value,
      this.projectSpecificData.applicationsData.TableId,
      this.ticketForm.value.related_applications,
    );
    this.ignatiusService.putData(appData).toPromise();
  };

  /**
   * Close modal
   */
  close = () => {
    this.ngbModal.dismissAll();
  };

  /**
   * @returns applicant type
   */
  getApplicantType = () => {
    const permissionTypeData = this.projectSpecificService.getPermissionType();
    if (JSON.stringify(permissionTypeData).toString().includes("Landlord"))
      return "Landlord";
    return "Applicant";
  };

  /**
   * @returns display name on the basis of applicant type
   */
  getDisplayName = (application: any) => {
    if (this.applicantType === "Applicant")
      return `${application.contact_first_name} ${application.contact_last_name}`;
    return `${application.business_name}`;
  };

  /**
   * Subscribers
   */
  subscribers = () => {
    const arrControls = [
      "recert_address_1",
      "recert_city",
      "recert_state",
      "recert_zip",
      "recert_landlord_name",
      "recert_num_bedrooms",
    ];
    this.ticketForm.controls.expense_type.valueChanges.subscribe(() => {
      this.ticketForm.controls.utilities.get("utility_provider").setValue("");
    });
    this.ticketForm.controls.recertifications
      .get("recert_address_changed")
      .valueChanges.subscribe((val: any) => {
        if (val === "True") {
          this.ticketForm.get("file_doc_1").clearValidators();
          this.ticketForm.get("file_doc_1").setValue("");
          this.ticketForm.get("file_doc_1").updateValueAndValidity();
          arrControls.forEach((e: any) => {
            (this.ticketForm.get("recertifications") as FormGroup)
              .get(e)
              .clearValidators();
            (
              (this.ticketForm.get("recertifications") as FormGroup).get(
                e
              ) as FormControl
            ).setValue("");
            (this.ticketForm.get("recertifications") as FormGroup)
              .get(e)
              .updateValueAndValidity();
          });
        } else {
          this.ticketForm
            .get("file_doc_1")
            .addValidators([Validators.required]);
          this.ticketForm.get("file_doc_1").updateValueAndValidity();
          arrControls.forEach((e: any) => {
            (this.ticketForm.get("recertifications") as FormGroup)
              .get(e)
              .addValidators([Validators.required]);
            (this.ticketForm.get("recertifications") as FormGroup)
              .get(e)
              .updateValueAndValidity();
          });
        }
      });
    this.ticketForm.controls.recertifications
      .get("recert_monthly_rent_changed")
      .valueChanges.subscribe((val: any) => {
        if (val === "True") {
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_monthly_rent")
            .clearValidators();
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_monthly_rent")
            .setValue("");
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_monthly_rent")
            .updateValueAndValidity();
        } else {
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_monthly_rent")
            .setValidators([Validators.required]);
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_monthly_rent")
            .updateValueAndValidity();
        }
      });
    this.ticketForm.controls.recertifications
      .get("recert_phone_changed")
      .valueChanges.subscribe((val: any) => {
        if (val === "True") {
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_phone")
            .clearValidators();
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_phone")
            .setValue("");
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_phone")
            .updateValueAndValidity();
        } else {
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_phone")
            .setValidators([Validators.required]);
          (this.ticketForm.controls.recertifications as FormGroup)
            .get("recert_phone")
            .updateValueAndValidity();
        }
      });

    this.ticketForm.controls.ticket_type.valueChanges.subscribe((data) => {
      this.setUnsetUtilityValidators(false);
      this.ticketForm.controls.details.setValidators([Validators.required]);
      this.ticketForm.controls.details.updateValueAndValidity();
      this.ticketForm.controls.utility_months_in_arrears.setValue(0);
      if (data === "Attestation" || data === "Direct Payment Form") {
        if (data === "Direct Payment Form") {
          this.ticketForm.controls.date.setValidators([Validators.required]);
        } else {
          this.ticketForm.controls.date.clearValidators();
          this.ticketForm.controls.date.setValue("");
        }
        this.ticketForm.controls.date.updateValueAndValidity();
        const application = this.applications.find(
          (app) => +app.id === +this.ticketForm.value.related_applications
        );
        this.ticketForm.controls.details.clearValidators();
        this.ticketForm.controls.details.updateValueAndValidity();
        this.ticketForm.controls.expense_type.clearValidators();
        this.ticketForm.controls.expense_type.updateValueAndValidity();
        this.ticketForm.controls.file_doc_1.clearValidators();
        this.ticketForm.controls.file_doc_1.updateValueAndValidity();
        if (!application) return;
        this.setAttestationData(application);
        this.resetFields();
      } else if (data === "Utilities") {
        this.acknowledgementsFormGroup =
          this.appForms.setupApplicantAcknowledgementsFormTicket(
            this.applications.find(
              (app) => this.ticketForm.value.related_applications == app.id
            ),
          );
        this.setUnsetUtilityValidators(true);
        this.ticketForm.controls.expense_type.setValidators([
          Validators.required,
        ]);
        this.ticketForm.controls.expense_type.updateValueAndValidity();
        this.ticketForm.controls.details.clearValidators();
        this.ticketForm.controls.details.updateValueAndValidity();
        this.ticketForm.controls.file_doc_1.setValidators([
          Validators.required,
        ]);
        this.ticketForm.controls.file_doc_1.updateValueAndValidity();
        this.ticketForm.patchValue(
          {
            subject: "Utility Expense Request",
          },
          { emitEvent: false }
        );
      } else if (data === "Recertification") {
        if (this.applications.length === 1) {
          this.ticketForm
            .get("related_applications")
            .setValue(this.applications[0].id);
        }
        const application = this.applications.find(
          (app) => +app.id === +this.ticketForm.value.related_applications
        );
        this.ticketForm.controls.details.clearValidators();
        this.ticketForm.controls.details.updateValueAndValidity();
        this.ticketForm.controls.expense_type.clearValidators();
        this.ticketForm.controls.expense_type.updateValueAndValidity();
        // this.ticketForm.controls.file_doc_1.setValidators([
        //   Validators.required,
        // ]);
        //this.ticketForm.controls.file_doc_1.updateValueAndValidity();
        if (!application) return;
        this.setRecertificationData(application);
        this.resetFields();
      } else {
        this.ticketForm.controls.details.setValidators([Validators.required]);
        this.ticketForm.controls.details.updateValueAndValidity();
        this.nameField = "";
        this.ticketForm.controls.expense_type.clearValidators();
        this.ticketForm.controls.expense_type.updateValueAndValidity();
        this.ticketForm.controls.file_doc_1.clearValidators();
        this.ticketForm.controls.file_doc_1.updateValueAndValidity();
        this.resetFields();
      }
      this.lastTicketType = data;
    });
    this.ticketForm.controls.related_applications.valueChanges.subscribe(
      (data) => {
        const application = this.applications.find((app) => +app.id === +data);
        if (!application) return;
        this.selectedApp = application;
        this.acknowledgementsFormGroup =
          this.appForms.setupApplicantAcknowledgementsFormTicket(
            application,
          );
        this.setAttestationData(application);
        if (this.ticketForm.controls.ticket_type.value === "Recertification")
          this.setRecertificationData(application);
        this.ticketTypes = this.allTicketTypes;
        this.setRecertificationTypeToTop();
        this.checkRecertTicketLocation();
      }
    );
    this.ticketForm.controls.utilities
      .get("utility_provider")
      .valueChanges.subscribe((provider) => {
        if (!provider) return;
        if (provider === "Other")
          this.ticketForm.controls.utilities
            .get("electricity_company_other")
            .setValidators([Validators.required]);
        else
          this.ticketForm.controls.utilities
            .get("electricity_company_other")
            .clearValidators();
        this.ticketForm.controls.utilities
          .get("electricity_company_other")
          .updateValueAndValidity();
        if (this.ticketForm.value.expense_type !== "Utility - Electricity")
          return;
        if (provider === "Entergy") {
          this.ticketForm.controls.utilities
            .get("amount_past_due")
            .clearValidators();
          this.ticketForm.controls.utilities
            .get("amount_past_due")
            .updateValueAndValidity();
          this.ticketForm.controls.file_doc_1.clearValidators();
          this.ticketForm.controls.file_doc_1.updateValueAndValidity();
        } else {
          this.ticketForm.controls.utilities
            .get("amount_past_due")
            .setValidators([Validators.required]);
          this.ticketForm.controls.utilities
            .get("amount_past_due")
            .updateValueAndValidity();
          this.ticketForm.controls.file_doc_1.setValidators([
            Validators.required,
          ]);
          this.ticketForm.controls.file_doc_1.updateValueAndValidity();
        }
      });
  };

  /**
   * Rest form fiedls if required
   */
  resetFields = () => {
    if (this.resetFieldsForTickets.indexOf(this.lastTicketType) > -1) {
      if (this.lastTicketType === "Utilities") {
        this.ticketForm.controls.utilities.reset();
        // this.ticketForm.controls.subject.reset();
      }
    }
  };

  /**
   * Set/Unset utility validators
   */
  setUnsetUtilityValidators = (required: boolean) => {
    const utilitiesFields = (<FormGroup>this.ticketForm.controls.utilities)
      .controls;
    for (let field in utilitiesFields) {
      if (required)
        (<FormGroup>this.ticketForm.controls.utilities).controls[
          field
        ].setValidators([Validators.required]);
      else
        (<FormGroup>this.ticketForm.controls.utilities).controls[
          field
        ].clearValidators();
      (<FormGroup>this.ticketForm.controls.utilities).controls[
        field
      ].updateValueAndValidity();
    }
    this.ticketForm.controls.utilities
      .get("name_on_account_hh_member")
      .clearValidators();
    this.ticketForm.controls.utilities
      .get("name_on_account_hh_member")
      .updateValueAndValidity();
  };

  setAttestationData = (data: any) => {
    this.attestationData = {
      tenant: `${data.contact_first_name} ${data.contact_mi} ${data.contact_last_name}`,
      unitAddress: `${data.physical_street} ${data.physical_street_2} ${data.physical_city}, ${data.physical_state} ${data.physical_zip_code}`,
      landlordName: `${data.landlord_first_name} ${data.landlord_last_name}`,
      appId: data.id,
    };
  };

  setRecertificationData = (data: any) => {
    this.recertificationData = {
      appId: data.id,
      tenant: `${data.contact_first_name} ${data.contact_mi} ${data.contact_last_name}`,
      contactPhone: `${data.contact_phone}`,
      rentalAddress: `${data.physical_street} ${data.physical_street_2} ${data.physical_city}, ${data.physical_state} ${data.physical_zip_code}`,
      landlordName: `${data.landlord_first_name} ${data.landlord_last_name}`,
      monthlyRentAmount: `${data.total_monthly_rent}`,
      recert_monthly_rent: "",
      recert_address_1: "",
      recert_address_2: "",
      recert_city: "",
      recert_state: "",
      recert_zip: "",
      recert_phone: "",
      recert_landlord_name: "",
      recert_address_changed: "",
      recert_monthly_rent_changed: "",
      recert_phone_changed: "",
      recert_num_bedrooms: "",
    };
  };

  /**
   * Get details text
   */
  getDetailsText = () => {
    if (this.ticketForm.controls.ticket_type.value !== "Attestation") return "";
    return `${en.applications.TENANT_NAME}: ${
      this.attestationData.tenant || "_____________________"
    } ${en.applications.ATTESTATION_TENANT}
    ${en.applications.UNIT_ADDRESS}: ${
      this.attestationData.unitAddress || "_____________________"
    } ${en.applications.ATTESTATION_UNIT}
    ${en.applications.LANDLORD_NAME}: ${
      this.attestationData.landlordName || "_____________________"
    } ${en.applications.ATTESTATION_LANDLORD}
    ${en.applications.APPLICATION_ID} #: ${
      this.attestationData.appId || "_____________________"
    }

    ${en.applications.ATTESTATION_TEXT_1}

    ${en.applications.ATTESTATION_TEXT_2}

    1. ${en.applications.ATTESTATION_PT_1}

    2. ${en.applications.ATTESTATION_PT_2}

    3. ${en.applications.ATTESTATION_PT_3}

    4. ${en.applications.ATTESTATION_PT_4}

    5. ${en.applications.ATTESTATION_PT_5}

    ${en.applications.ATTESTATION_TEXT_3}

    ${en.applications.ATTESTATION_WARNING}

    ${en.applications.ENTER_YOUR_NAME} ${":"} ${
      this.ticketForm.controls.subject.value
    }`;
  };

  getDirectPFDetailsText = () => {
    if (this.ticketForm.controls.ticket_type.value !== "Direct Payment Form")
      return "";
    return `${en.applications.TENANT_NAME}: ${
      this.attestationData.tenant || "_____________________"
    } ${en.applications.ATTESTATION_TENANT}
    ${en.applications.UNIT_ADDRESS}: ${
      this.attestationData.unitAddress || "_____________________"
    } ${en.applications.ATTESTATION_UNIT}
    ${en.applications.LANDLORD_NAME}: ${
      this.attestationData.landlordName || "_____________________"
    } ${en.applications.ATTESTATION_LANDLORD}
    ${en.applications.APPLICATION_ID} #: ${
      this.attestationData.appId || "_____________________"
    }

    ${en.applications.DIRECT_PF_TEXT_1}

    ${en.applications.DIRECT_PF_TEXT_2}

    1. ${en.applications.DIRECT_PF_PT_1}

    2. ${en.applications.DIRECT_PF_PT_2}

    3. ${en.applications.DIRECT_PF_PT_3}

    4. ${en.applications.DIRECT_PF_PT_4}

    5. ${en.applications.DIRECT_PF_PT_5}

    ${en.applications.DIRECT_PF_TEXT_3}

    ${en.applications.ATTESTATION_WARNING}

    ${en.applications.ENTER_YOUR_NAME} ${":"} ${
      this.ticketForm.controls.subject.value
    }

    ${en.applications.DATE} ${":"} ${this.ticketForm.controls.date.value}`;
  };

  /**
   * Get recertification details text
   */

  // ${en.applications.PREVIOUS_MONTH_OF_ASSISTANCE_RECEIVED} : ${
  //   this.recertificationData.num_assistance_months || "_____________________"
  // }

  getRecertificationDetailsText = () => {
    if (this.ticketForm.controls.ticket_type.value !== "Recertification")
      return "";

    return `${en.applications.APPLICATION_ID} #: ${
      this.recertificationData.appId || "_____________________"
    }
    ${en.applications.TENANT_NAME}: ${
      this.recertificationData.tenant || "_____________________"
    } ${en.applications.ATTESTATION_TENANT}
    ${en.applications.CONTACT_PHONE}: ${
      this.recertificationData.contactPhone || "_____________________"
    } ${en.applications.ATTESTATION_CONTACT_PHONE}
    ${en.applications.LANDLORD_NAME}: ${
      this.recertificationData.landlordName || "_____________________"
    } ${en.applications.ATTESTATION_LANDLORD}
    ${en.applications.RENTAL_ADDRESS}: ${
      this.recertificationData.rentalAddress || "_____________________"
    } ${en.applications.ATTESTATION_RENTAL_ADDRESS}
    ${en.applications.MONTHLY_RENT_AMOUNT}: ${
      this.recertificationData.monthlyRentAmount || "_____________________"
    } ${en.applications.ATTESTATION_MONTHLY_RENT_AMOUNT}

    ${en.applications.RECERTIFICATION_TEXT_1a}
    ${en.applications.RECERTIFICATION_TEXT_2} : ${
      (this.ticketForm.controls["recertifications"].value
        .recert_address_changed === "True"
        ? "Yes"
        : "No") || ""
    }
    ${en.applications.RECERTIFICATION_TEXT_3}


    ${en.applications.CURRENT_RENT_ADDRESS_1} :
      ${
        this.ticketForm.controls["recertifications"].value.recert_address_1 ||
        "_____________________"
      }
    ${en.applications.CURRENT_RENT_ADDRESS_2} : ${
      this.ticketForm.controls["recertifications"].value.recert_address_2 ||
      "_____________________"
    }
    ${en.applications.CURRENT_RENT_CITY} : ${
      this.ticketForm.controls["recertifications"].value.recert_city ||
      "_____________________"
    }
    ${en.applications.CURRENT_RENT_STATE} : ${
      this.ticketForm.controls["recertifications"].value.recert_state ||
      "_____________________"
    }
    ${en.applications.CURRENT_RENT_ZIP} : ${
      this.ticketForm.controls["recertifications"].value.recert_zip ||
      "_____________________"
    }
    ${en.applications.CURRENT_LANDLORD_NAME} : ${
      this.ticketForm.controls["recertifications"].value.recert_landlord_name ||
      "_____________________"
    }
    ${en.applications.CURRENT_NUM_BEDROOMS} : ${
      this.ticketForm.controls["recertifications"].value.recert_num_bedrooms ||
      "_____________________"
    }
    ${en.applications.RECERTIFICATION_TEXT_1b} : ${
      (this.ticketForm.controls["recertifications"].value
        .recert_monthly_rent_changed === "True"
        ? "Yes"
        : "No") || ""
    }
    ${en.applications.CURRENT_MONTHLY_RENT_AMOUNT} : ${
      this.ticketForm.controls["recertifications"].value.recert_monthly_rent ||
      "_____________________"
    }

    ${en.applications.RECERTIFICATION_TEXT_1c} : ${
      (this.ticketForm.controls["recertifications"].value
        .recert_phone_changed === "True"
        ? "Yes"
        : "No") || ""
    }
    ${en.applications.CURRENT_PHONE} : ${
      this.ticketForm.controls["recertifications"].value.recert_phone ||
      "_____________________"
    }


    ${en.applications.RECERTIFICATION_TEXT_4}
    1. ${en.applications.RECERT_ATTESTATION_PT_1}

    2. ${en.applications.RECERT_ATTESTATION_PT_2a} ${en.applications.RECERT_ATTESTATION_PT_2b}

    3. ${en.applications.RECERT_ATTESTATION_PT_3}

    4. ${en.applications.RECERT_ATTESTATION_PT_4}

    5. ${en.applications.RECERT_ATTESTATION_PT_5}

    6. ${en.applications.RECERT_ATTESTATION_PT_6}


    ${en.applications.RECERT_ATTESTATION_TEXT_3}

    ${en.applications.RECERTIFICATION_TEXT_5}

    ${en.applications.ATTESTATION_WARNING}

    ${en.applications.RECERT_ATTESTATION_TEXT_4a} ${en.applications.RECERT_ATTESTATION_TEXT_4b} ${en.applications.RECERT_ATTESTATION_TEXT_4c}

    ${en.applications.ENTER_YOUR_NAME} ${":"} ${
      this.ticketForm.controls.subject.value
    }
    `;
  };

  // ${en.applications.HOUSEHOLD_MEMBER_COUNT} : ${
  //   this.ticketForm.controls["recertifications"].value.recert_household_count || "_____________________"
  // }

  // ${en.applications.CURRENT_ANNUAL_HOUSEHOLD_INCOME} : ${
  //   this.ticketForm.controls["recertifications"].value.recert_annual_income || "_____________________"
  // }

  /**
   * Click listener
   */
  @HostListener("click", ["$event"]) async click(event) {
    this.spinner.show();
    event.preventDefault();
    this.applicantType = this.getApplicantType();
    if (this.ticketForm) this.ticketForm.reset();
    this.ticketForm = null;
    this.render = false;
    setTimeout(() => {
      this.render = true;
    }, 0);
    this.initForm();
    await this.fetch();
    this.fetchExpenseType();
    this.fetchProviders();
    this.subscribers();
    this.nameField = "";
    this.modalConfig = new PopupModel();
    this.modalConfig.settings.windowClass = "create-ticket";
    this.ngbModal.open(this.modal, this.modalConfig.settings);
    this.spinner.hide();
  }

  /**
   * Create expense record if ticket type is utility
   */
  createExpenseRecord = async (data: any) => {
    if (
      (!this.isUtilityTicketAvailable() && !this.isRecertificationTicketAvailable()) ||
      (this.ticketForm.value.ticket_type !== "Utilities" && this.ticketForm.value.ticket_type !== "Recertification")
    )
      return;
    try {
      const obs = this.common.getExpensesAddObservables(
        data,
        this.projectSpecificData.expensesData.TableId,
        ""
      )[0];
      await forkJoin(obs).toPromise();
    } catch (err) {}
  };

  /**
   * @returns true if all acks/certs are checked
   */
  isAllAcksChecked = () => {
    if (!this.acknowledgementsFormGroup) return;
    for (let field in this.acknowledgementsFormGroup.value) {
      if (!this.acknowledgementsFormGroup.value[field]) return false;
    }
    return true;
  };

  getControl(form: FormGroup, control: any) {
    return form.controls[control] as FormControl;
  }

  isLeaseDatesValid = () => {
    if (this.ticketForm.controls.ticket_type.value !== "Recertification") {
      this.invalidLeaseDates = false;
      return false;
    }
    if (
      moment(this.ticketForm.controls.lease_start_date.value) >
        moment(this.ticketForm.controls.lease_end_date.value) ||
      this.ticketForm.controls.lease_start_date.value == null ||
      this.ticketForm.controls.lease_end_date.value == null ||
      this.ticketForm.controls.lease_start_date.value == "" ||
      this.ticketForm.controls.lease_end_date.value == ""
    ) {
      this.invalidLeaseDates = true;
      return true;
    } else {
      this.invalidLeaseDates = false;
      return false;
    }
  };
}
