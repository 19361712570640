import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable, forkJoin, lastValueFrom } from "rxjs";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
import { Constants } from "src/app/shared/constants";
import { PopupModel } from "../../../models/popup";
import { CommonService } from "../../../services";
import { ApplicationForms } from "../forms";
import moment from "moment";
import { HhVerifyComponent } from "src/app/shared/components/hh-verify/hh-verify.component";
import { TranslateService } from "@ngx-translate/core";
import en from "src/assets/i18n/en.json";
import { application } from "express";
import { HttpErrorResponse } from "@angular/common/http";
import { FieldListItem } from "src/app/models/form-action-data";
import { expenseMinFields, pastDueFields, rentalObligationFields, states } from "./edit_applicant.constants";
import { isDefined } from "@ng-bootstrap/ng-bootstrap/util/util";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";

@Component({
  selector: "application-edit-applicant",
  templateUrl: "./edit_applicant.component.html",
  styleUrls: ["./edit_applicant.component.css"],
  providers: [ApplicationForms],
})
export class ApplicationEditApplicantComponent implements OnInit {
  documents: Object[];
  constructor(
    private ignatiusService: IgnatiusService,
    private projectSpecificService: ProjectSpecificService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private ngbModal: NgbModal,
    private appForms: ApplicationForms,
    private common: CommonService,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private cdr:ChangeDetectorRef
  ) {}

  //bankAccountTypes: Array<any>;
  expenseTypes: Array<any>;
  pastDueMonths: Array<any>;
  months: Array<any> = [];
  householdNumbers: Array<any>;
  employmentStatuses: Array<any>;
  parishes: Array<any>;
  preferred_parishes_1: Array<any>;
  preferred_parishes_2: Array<any>;
  preferred_parishes_3: Array<any>;
  races: Array<any>;
  ethnicities: Array<any>;
  veterans: Array<any>;
  genders: Array<any>;
  relationToApplicant: Array<any>;
  incomeTypes: Array<any>;
  inArrearsOptions: Array<any>;
  propertyTypes: Array<any>;
  projectSpecificData:any;
  applicationData: any = {};
  appExpenseData: any = {};
  appExpenseRecertificationData: any = {};
  expensesData: Array<any> = [];
  householdData: Array<any> = [];
  householdAdultData: Array<any> = [];
  utilityProvider: Array<any> = [];
  //pastDueAmountsData: Array<any> = [];
  inviteData: any = {};
  recordId: string;
  modelConfig: PopupModel;
  completionStatus: any = {};
  expenseDisplayData: any = {};
  householdDisplayData: any = {};
  applicantType = "";
  confirmationCodeLength = 6;
  viewMode: boolean = false;
  viewMode2: boolean = false;
  viewMode3: boolean = false;
  //bankName: string = null;
  isPastDueValid: boolean = false;
  pastDueFields = pastDueFields;
 
  tinMask = "000-00-0000";
  dueAmount0: boolean = false;
  requiredFields = {
    lastDateWorked: false,
    wagesIncome: false,
    ssn_enc: false,
    otherIncome: false,
    income_lieu: false,
    income_pymts: false,
    income_alimony: false,
    income_gig: false,
    income_government: false,
  };
  householdValidationRules = Constants.HOUSEHOLD_VALIDATION_RULES;
  rentalObligationFields = rentalObligationFields;

  expenseMinFields:any = expenseMinFields
  // communityUser: boolean = false;
  mapping_expenses = Constants.EXPENSES_MAPPING;
  mapping_household = Constants.HOUSEHOLD_MAPPING;
  appStatus = "";

  options = {
    componentRestrictions: {
      country: ["US"],
    },
  };
  currentHouseholdIndex: number = 0;
  yearRange:any = [];
  stateOptions:any;
  isExpenseDataSubmitted:boolean = false
  proof_of_income = [
    'proof_of_income_file_1',
    'proof_of_income_file_2',
    'proof_of_income_file_3',
    'proof_of_income_file_4',
    'proof_of_income_file_5',
    'proof_of_income_file_6',
  ];
  proof_of_rental = [
    'proof_of_rental_payment_file_1',
    'proof_of_rental_payment_file_2',
    'proof_of_rental_payment_file_3',
    'proof_of_rental_payment_file_4',

  ];
  @ViewChild("householdTemp", { static: true }) householdTemp: ElementRef;

  @ViewChild("expack", { static: true }) expack: ElementRef;

  appFormGroup: FormGroup;
  contactFormGroup: FormGroup;
  addressesFormGroup: FormGroup;
  landlordFormGroup: FormGroup;
  expensesFormGroup: FormGroup;
  expensesAcknowledgementsFormGroup: FormGroup;
  expensesAddFormGroup: FormGroup;
  expensesDeleteFormGroup: FormGroup;
  householdFormGroup: FormGroup;
  householdAddFormGroup: FormGroup;
  householdDeleteFormGroup: FormGroup;
  rentalDocumentsFormGroup: FormGroup;
  acknowledgementsFormGroup: FormGroup;
  recertificationExpenseForm: FormGroup;
  //pastDueAmountsFormGroup: FormGroup;
  //pastDueAmountsAddFormGroup: FormGroup;

  get appFormControl() {
    return this.appFormGroup.controls;
  }
  get contactFormControl() {
    return this.contactFormGroup.controls;
  }
  get addressesFormControl() {
    return this.addressesFormGroup.controls;
  }
  get landlordFormControl() {
    return this.landlordFormGroup.controls;
  }
  get expensesFormControl() {
    return this.expensesFormGroup.controls;
  }
  get expensesAddFormControl() {
    return this.expensesAddFormGroup.controls;
  }
  get expensesDeleteFormControl() {
    return this.expensesDeleteFormGroup.controls;
  }
  get householdFormControl() {
    return this.householdFormGroup.controls;
  }
  get householdAddFormControl() {
    return this.householdAddFormGroup.controls;
  }
  get householdDeleteFormControl() {
    return this.householdDeleteFormGroup.controls;
  }
  get rentalDocumentsFormControl() {
    return this.rentalDocumentsFormGroup.controls;
  }
  get acknowledgementsFormControl() {
    return this.acknowledgementsFormGroup.controls;
  }
  get recertificationExpenseFormControl() {
    return this.recertificationExpenseForm?.controls;
  }
  //get pastDueAmountsFormControl() { return this.pastDueAmountsFormGroup.controls }
  //get pastDueAmountsAddFormControl() { return this.pastDueAmountsAddFormGroup.controls }

  ngOnInit() {
    this.projectSpecificData = this.projectSpecificService.getProjectSpecificData();
    this.recordId = this.route.snapshot.paramMap.get("id");
    const componentData = this.route.snapshot.data["componentData"];
    this.expenseTypes = componentData[3];
    this.householdNumbers = componentData[4];
    this.employmentStatuses = componentData[5];
    //this.pastDueMonths = componentData[7];
    this.parishes = componentData[8];
    this.preferred_parishes_1 = componentData[8];
    this.preferred_parishes_2 = componentData[8];
    this.preferred_parishes_3 = componentData[8];
    this.races = componentData[9];
    this.genders = componentData[10];
    this.incomeTypes = componentData[11];
    this.inArrearsOptions = componentData[12];
    this.propertyTypes = componentData[13];
    this.relationToApplicant = componentData[14];
    this.utilityProvider = componentData[17];
    this.ethnicities = componentData[18];
    this.veterans = componentData[19];
    this.yearRange = this.generateYearRange();
    this.stateOptions = states.map((state:any)=>{
      return {
        label:state.label,
        value:state.abbreviation
      }
    })

    forkJoin([
      this.getApplicationData(),
      this.getExpensesData(),
      this.getHouseholdData(),
      this.getHouseholdAdultData(),
    ]).subscribe(() => {
      this.syncStatus(this.completionStatus);
      
      this.openApplication('');
      this.appFormGroup.controls.accessibility.valueChanges.subscribe(value => {
        if(value == 'True'){
          this.appFormGroup.controls.accessibility_explain.setValidators([Validators.required]);
        } else {
          this.appFormGroup.controls.accessibility_explain.removeValidators([Validators.required]);
        }
        this.appFormGroup.controls.accessibility_explain.updateValueAndValidity();
      })
      this.spinner.hide();
      
      this.appStatus = this.applicationData.status;

      if(this.isRecertification()){
        this.recertificationExpenseForm = this.appForms.setupExpensesIncomeForm(this.appExpenseRecertificationData,this.recordId);
      }

      if(this.isCommitmentLetterIssued() || this.isUnitRequestFormSubmitted()){
        this.getDocuments();
        this.rentalObligationFields.forEach(field => {
          this.appFormGroup?.get(field)?.addValidators(Validators.required);
          if(Object.keys(this.expenseMinFields).includes(field)){
            this.appFormGroup?.get(field)?.addValidators(Validators.min(this.expenseMinFields[field]));
            if(field !== 'unit_size'){
              this.appFormGroup?.get(field)?.addValidators(Validators.pattern(Constants.AMOUNT_PATTERN));
            }
          }
        })
        this.expensesAcknowledgementsFormGroup = new FormGroup({});
      }
    });
    this.appFormGroup = this.appForms.setupAppForm(
      this.applicationData
    );
  }

  /**
   * Sync status of individual sections
   * @param status object of completion status
   */
  private syncStatus = async (status: any) => {
    let reqData = {
      //is_valid_r_acks: !!status.AcknowledgementsStatus,
      //is_valid_r_address: !!status.AddressesStatus,
      //is_valid_r_contact: !!status.ContactStatus,
      //is_valid_r_docs: !!status.RentalDocumentsStatus,
      //is_valid_r_expenses: !!status.ExpensesStatus,
      is_valid_r_household: !!status.HouseholdStatus,
      //is_valid_r_landlord: !!status.LandlordStatus,
      household_error_message: this.isHHTopErrorVisible()
        ? en.applications.HH_MEMBERS_DATA_ERROR
        : this.isApplicantAMember()
        ? en.applications.HH_RELATION_APPLICANT_ERROR
        : "",
    };
    try {
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = Number(this.recordId);
      const recordFAD = this.common.createPutData(tableId, reqData, condition);
      await this.ignatiusService.putData(recordFAD).toPromise();
    } catch (error) {}
  };

  private async getApplicationData(sync: boolean = false) {
    // let strUserName = "";
    try {
      const applicationData = await this.ignatiusService
        .getTargetTableObservable(
          this.projectSpecificData.appData,
          this.recordId,
          this.projectSpecificData.applicationsData.TableId as number,
          this.projectSpecificData.applicationsData.RecordIdFieldId as number
        )
        .toPromise();
      this.applicationData =
        applicationData && applicationData.length > 0 ? applicationData[0] : {};
      if (!Object.keys(this.applicationData).length)
        return this.router.navigate(["../"]);
      const userData = this.projectSpecificService.getUserData();
      const roleData = this.projectSpecificService.getRoleData();

      // strUserName = userData.userName.toString().toLowerCase();

      if (roleData.Id === "super_admin" || roleData.Id === "admin") {
        // pass
      } else if (
        this.applicationData.createdby.toString().toUpperCase() !=
        userData.userName.toString().toUpperCase()
      )
        return this.router.navigate(["../"]);

      this.completionStatus = this.common.getCompletionStatusApplicant(
        this.applicationData,
        this.expensesData,
        this.householdData
      );
      // if (sync) {
      //   this.syncCompletionPercentageWithDB(this.completionStatus.total);
      // }
      //this.askUserToSubmit();

      // NES - Allow community user to still edit
      // if (this.applicationData &&
      //   strUserName.toLowerCase().includes("@lastaterent.com") 
      // )
      //   this.communityUser = true;

      this.setViewMode(this.applicationData.status);
      this.setViewMode2(this.applicationData.status);
      this.setViewMode3(this.applicationData.status);
      const permissionTypeData =
        this.projectSpecificService.getPermissionType();
      if (JSON.stringify(permissionTypeData).toString().includes("Landlord")) {
        this.applicantType = "Landlord";
      } else this.applicantType = "Applicant";
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }

    // NES - Allow community user to still edit
    // if (this.applicationData &&
    //   strUserName.toLowerCase().includes("@lastaterent.com") &&
    //   this.applicantType === "Applicant"
    // )
    //   this.communityUser = true;
  }
  private async getExpensesData(sync: boolean = false) {
    try {
      this.expensesData = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.expensesData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: this.projectSpecificData.expensesData
                      .RelatedApplicationsFieldId,
                  },
                  OperationType: "is equal",
                  Value: this.recordId,
                },
              ],
            },
          ],
        })
        .toPromise();

      this.expenseDisplayData = this.common.getExpenseDisplayData(
        this.expensesData
      );
      this.completionStatus = this.common.getCompletionStatusApplicant(
        this.applicationData,
        this.expensesData,
        this.householdData
      );

      // if (sync) {
      //   this.syncCompletionPercentageWithDB(this.completionStatus.total);
      // }
      // this.askUserToSubmit();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.EXPENSES_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
  }
  private async getHouseholdData(sync: boolean = false) {
    try {
      this.householdData = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.householdData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: this.projectSpecificData.householdData
                      .RelatedApplicationsFieldId,
                  },
                  OperationType: "is equal",
                  Value: this.recordId,
                },
              ],
            },
          ],
        })
        .toPromise();

      this.householdDisplayData = this.common.getHouseholdDisplayData(
        this.householdData
      );
      this.completionStatus = this.common.getCompletionStatusApplicant(
        this.applicationData,
        this.expensesData,
        this.householdData
      );

      // if (sync) {
      //   this.syncCompletionPercentageWithDB(this.completionStatus.total);
      // }
      // this.askUserToSubmit();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.HOUSEHOLD_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
  }
  private async getHouseholdAdultData(sync: boolean = false) {
    try {
      var adultDate = this.convertDate(moment().subtract(18, 'years').format('MM/DD/yyyy'));
      this.householdAdultData = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.householdData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: this.projectSpecificData.householdData
                      .RelatedApplicationsFieldId,
                  },
                  OperationType: "is equal",
                  Value: this.recordId,
                },
                {
                  ConditionField: {
                    Id: this.projectSpecificData.householdData.DOBId,
                  },
                  OperationType: "on_or_before_date",
                  Value: adultDate,
                },
              ],
            },
          ],
        })
        .toPromise();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.HOUSEHOLD_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
  }

  /**
   * Format dates
   */
  parseDates = () => {
    this.householdData.forEach((record) => {
      record.date_of_birth = moment(record.date_of_birth).format("YYYY-MM-DD");
      record.last_date_worked = moment(record.last_date_worked).format(
        "YYYY-MM-DD"
      );
    });
    this.expensesData.forEach((record) => {
      record.lease_start_date = this.convertDate(record.lease_start_date);
      record.lease_end_date = this.convertDate(record.lease_end_date);
    });
  };

  convertDate = (value: string) =>
    value ? moment(value).format("YYYY-MM-DD") : value;

  // HOUSEHOLD START
  openHousehold(content, i, hh) {
    this.currentHouseholdIndex = i;
    this.parseDates();
    this.householdFormGroup = this.appForms.setupHouseholdForm(
      this.householdData[i].household_number.split(" ")[1],
      this.householdNumbers,
      this.householdData,
      this.recordId,
      hh
    );
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);
    this.setFormState(this.householdFormGroup);
    this.isSSNHouseholdDisabled()
    this.householdFormGroup.controls["household"]
      .get("0")
      .get("income_gross_amount_type")
      .valueChanges.subscribe((data) => {
        if (data === "Annual")
          this.householdFormGroup.controls["household"]
            .get("0")
            .get("income_gross_amount_2")
            .setValue("0");
      });
    this.setRequiredFieldsListener(
      <FormGroup>this.householdFormGroup.controls["household"].get("0")
    );
  }
  async onHouseholdSubmit() {
    const isSaveClicked = await this.dialog
      .open(HhVerifyComponent, {
        width: "800px",
        data: this.householdFormGroup.value.household[0],
      })
      .afterClosed()
      .toPromise();

    if (!isSaveClicked) return;

    if (!this.householdFormGroup.valid) {
      this.common.validateAllFormFields(this.householdFormGroup);
      return;
    }

    const values: any = { ...this.householdFormGroup.value };
    const errMessage = this.getHHErrorRowMessage(values.household[0]);
    if (errMessage){
      values.household[0].status = false;
      values.household[0].error_message = errMessage;
    }
    else values.household[0].error_message = "";

    const tableId = this.projectSpecificData.householdData.TableId;
    const hhObservables = this.common.getHouseholdObservables(values, tableId);

    this.addUpdateHousehold(hhObservables[0]);
  }
  openHouseholdAdd(content) {
    var household_number = 0;
    for (var i = 0; i < 10; i++) {
      var found = false;
      for (var j = 0; j < this.householdData.length; j++) {
        if (
          this.householdData[j]["household_number"].replace("Household ", "") ==
          i
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        household_number = i;
        i = 10;
      }
    }
    this.householdAddFormGroup = this.appForms.setupHouseholdAddForm(
      household_number,
      this.householdNumbers,
      this.recordId
    );
    this.householdAddFormGroup.patchValue({
      income_gross_amount_type: this.incomeTypes[0].value,
    });
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);
    this.householdAddFormGroup.controls[
      "income_gross_amount_type"
    ].valueChanges.subscribe((data) => {
      if (data === "Annual")
        this.householdAddFormGroup.controls["income_gross_amount_2"].setValue(
          "0"
        );
    });
    this.setRequiredFieldsListener(this.householdAddFormGroup);
  }
  async onHouseholdAddSubmit() {
    const isSaveClicked = await this.dialog
      .open(HhVerifyComponent, {
        width: "800px",
        data: this.householdAddFormGroup.value,
      })
      .afterClosed()
      .toPromise();

    if (!isSaveClicked) return;

    if (!this.householdAddFormGroup.valid) {
      this.common.validateAllFormFields(this.householdAddFormGroup);
      return;
    }
    const values: any = { ...this.householdAddFormGroup.value };
    const errMessage = this.getHHErrorRowMessage(values);
    if (errMessage){
      values.status = false
      values.error_message = errMessage;
    }
    else values.error_message = "";

    const tableId = this.projectSpecificData.householdData.TableId;
    const hhObservables = this.common.getHouseholdAddObservables(
      values,
      tableId
    );
    this.addUpdateHousehold(hhObservables[0]);
  }
  openHouseholdDelete(content, i, hh) {
    this.householdDeleteFormGroup = this.appForms.setupHouseholdDeleteForm(
      this.householdData[i].household_number.split(" ")[1],
      this.householdNumbers,
      this.householdData,
      this.recordId,
      hh
    );
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);
  }
  onHouseholdDeleteSubmit() {
    const tableId = this.projectSpecificData.householdData.TableId;

    const hhObservables = this.common.getHouseholdDeleteObservables(
      this.householdDeleteFormGroup.controls.household.value[0],
      tableId
    );
    this.deleteHousehold(hhObservables);
  }
  setUnitSize(rooms: string) {
    var field = "unit_size";
    switch (rooms) {
      case "0":
        this.appFormGroup.get(field).setValue("Efficiency");
        break;
      case "1":
        this.appFormGroup.get(field).setValue("1 Bedroom");
        break;
      case "2":
        this.appFormGroup.get(field).setValue("2 Bedroom");
        break;
      case "3":
        this.appFormGroup.get(field).setValue("3 Bedroom");
        break;
      case "4":
        this.appFormGroup.get(field).setValue("4 Bedroom");
        break;
      default:
        this.appFormGroup.get(field).setValue("Not Selected");
        break;
    }
  }
  incomeTypeChange(value: string) {
    //var field = "income_gross_amount_type";
    //this.householdAddFormGroup.get(field).setValue(value);
    //console.log(this.householdAddFormGroup["income_gross_amount_type"])
  }
  async handleHouseholdUpload(event: any, index: number, type, addOrEdit) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );

      var strFilename = filename;
      if (filename.split(".").length > 2) {
        for (var i = 0; i < filename.split(".").length - 2; i++) {
          for (var j = 0; j < filename.length; j++)
            if (filename[j] === ".") {
              strFilename = strFilename.replace(".", "_");
              j = filename.length;
            }
        }
      }

      switch (type) {
        case "Income Gross":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_gross_file: "",
              income_gross_file_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_gross_file: "",
                income_gross_file_name: "",
              });
          return;
        case "Income Gross 2":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_gross_file_2: "",
              income_gross_file_2_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_gross_file_2: "",
                income_gross_file_2_name: "",
              });
          return;
        case "Income Gross 3":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_gross_file_3: "",
              income_gross_file_3_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_gross_file_3: "",
                income_gross_file_3_name: "",
              });
          return;
        case "Income Gross 4":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_gross_file_4: "",
              income_gross_file_4_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_gross_file_4: "",
                income_gross_file_4_name: "",
              });
          return;
        case "Income Gross 5":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_gross_file_5: "",
              income_gross_file_5_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_gross_file_5: "",
                income_gross_file_5_name: "",
              });
          return;
        case "Income in Lieu of Earnings":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_in_lieu_of_earnings_file: "",
              income_in_lieu_of_earnings_file_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_in_lieu_of_earnings_file: "",
                income_in_lieu_of_earnings_file_name: "",
              });
          return;
        case "Income Payments Annuities":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_payments_annuities_file: "",
              income_payments_annuities_file_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_payments_annuities_file: "",
                income_payments_annuities_file_name: "",
              });
          return;
        case "Income Government":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_government_file: "",
              income_government_file_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_government_file: "",
                income_government_file_name: "",
              });
          return;
        case "Income Alimony":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_alimony_file: "",
              income_alimony_file_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_alimony_file: "",
                income_alimony_file_name: "",
              });
          return;
        case "Income Other":
          if (addOrEdit == "Add")
            this.householdAddFormGroup.patchValue({
              income_other_file: "",
              income_other_file_name: "",
            });
          else
            (<FormArray>this.householdFormGroup.get("household"))
              .at(index)
              .patchValue({
                income_other_file: "",
                income_other_file_name: "",
              });
          return;
      }
    }
    switch (type) {
      case "Income Gross":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_gross_file: filestr,
            income_gross_file_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_gross_file: filestr,
              income_gross_file_name: strFilename,
            });
        break;
      case "Income Gross 2":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_gross_file_2: filestr,
            income_gross_file_2_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_gross_file_2: filestr,
              income_gross_file_2_name: strFilename,
            });
        break;
      case "Income Gross 3":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_gross_file_3: filestr,
            income_gross_file_3_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_gross_file_3: filestr,
              income_gross_file_3_name: strFilename,
            });
        break;
      case "Income Gross 4":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_gross_file_4: filestr,
            income_gross_file_4_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_gross_file_4: filestr,
              income_gross_file_4_name: strFilename,
            });
        break;
      case "Income Gross 5":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_gross_file_5: filestr,
            income_gross_file_5_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_gross_file_5: filestr,
              income_gross_file_5_name: strFilename,
            });
        break;
      case "Income in Lieu of Earnings":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_in_lieu_of_earnings_file: filestr,
            income_in_lieu_of_earnings_file_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_in_lieu_of_earnings_file: filestr,
              income_in_lieu_of_earnings_file_name: strFilename,
            });
        break;
      case "Income Payments Annuities":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_payments_annuities_file: filestr,
            income_payments_annuities_file_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_payments_annuities_file: filestr,
              income_payments_annuities_file_name: strFilename,
            });
        break;
      case "Income Government":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_government_file: filestr,
            income_government_file_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_government_file: filestr,
              income_government_file_name: strFilename,
            });
        break;
      case "Income Alimony":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_alimony_file: filestr,
            income_alimony_file_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_alimony_file: filestr,
              income_alimony_file_name: strFilename,
            });
        break;
      case "Income Other":
        if (addOrEdit == "Add")
          this.householdAddFormGroup.patchValue({
            income_other_file: filestr,
            income_other_file_name: strFilename,
          });
        else
          (<FormArray>this.householdFormGroup.get("household"))
            .at(index)
            .patchValue({
              income_other_file: filestr,
              income_other_file_name: strFilename,
            });
        break;
    }
  }
  calculate_income(
    income_gross_amount_type,
    income_gross_amount: number,
    income_in_lieu_of_earnings_amount: number,
    income_payments_annuities_amount: number,
    income_government_amount: number,
    income_alimony_amount: number,
    income_other_amount: number
  ) {
    if (income_gross_amount_type === "Monthly") {
      return (
        Number(income_gross_amount) +
        Number(income_in_lieu_of_earnings_amount) +
        Number(income_payments_annuities_amount) +
        Number(income_government_amount) +
        Number(income_alimony_amount) +
        Number(income_other_amount)
      );
    } else if (income_gross_amount_type === "Annual") {
      return (
        Number(income_gross_amount) +
        Number(income_in_lieu_of_earnings_amount) * 12 +
        Number(income_payments_annuities_amount) * 12 +
        Number(income_government_amount) * 12 +
        Number(income_alimony_amount) * 12 +
        Number(income_other_amount) * 12
      );
    } else if (income_gross_amount_type === "Annual Month") {
      return (
        Number(income_gross_amount) / 12 +
        Number(income_in_lieu_of_earnings_amount) +
        Number(income_payments_annuities_amount) +
        Number(income_government_amount) +
        Number(income_alimony_amount) +
        Number(income_other_amount)
      );
    }
  }
  // HOUSEHOLD END

  // EXPENSES START
  openExpenses(content, id) {
    this.dueAmount0 = false;
    this.parseDates();
    this.expensesFormGroup = this.appForms.setupExpensesForm(
      id,
      this.expenseTypes,
      this.expensesData.filter((record) => record.id == id),
      this.recordId
    );
    //this.pastDueAmountsAddFormGroup = this.appForms.setupPastDueAmountsAddForm(this.months, this.recordId);
    //this.expensesFormGroup.addControl("past_due_amounts", this.pastDueAmountsAddFormGroup)

    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);
    this.setFormState(this.expensesFormGroup);

    this.expensesFormGroup.valueChanges.subscribe((data) => {
      this.dueAmount0 = false;
      let count = 0,
        total = 0;
      let changeObject = {};
      for (let i in data.expenses[0]) {
        if (i.includes("past_due_amount") && +data.expenses[0][i]) {
          count++;
          total += +data.expenses[0][i];
          // if (+data.expenses[0][i] > data.expenses[0].amount_monthly) {
          //   changeObject[i] = 0;
          //   total -= +data.expenses[0][i];
          //   count -= 1;
          // }
        }
      }
      this.expensesFormGroup.controls["expenses"].patchValue(
        [
          {
            months_in_arrears: count,
            total_arrears: total,
          },
        ],
        {
          emitEvent: false,
        }
      );

      if (data.expenses[0].in_arrears === "Rental Advance Only") {
        this.isPastDueValid = false;
        this.expensesFormGroup.controls["expenses"].patchValue(
          [
            {
              months_in_arrears: "0",
              total_arrears: "0",
              past_due_amount_april_2020: "0",
              past_due_amount_may_2020: "0",
              past_due_amount_june_2020: "0",
              past_due_amount_july_2020: "0",
              past_due_amount_august_2020: "0",
              past_due_amount_september_2020: "0",
              past_due_amount_october_2020: "0",
              past_due_amount_november_2020: "0",
              past_due_amount_december_2020: "0",
              past_due_amount_january_2021: "0",
              past_due_amount_february_2021: "0",
              past_due_amount_march_2021: "0",
              past_due_amount_april_2021: "0",
              past_due_amount_may_2021: "0",
              past_due_amount_june_2021: "0",
              past_due_amount_july_2021: "0",
              past_due_amount_august_2021: "0",
              past_due_amount_september_2021: "0",
              past_due_amount_october_2021: "0",
              past_due_amount_november_2021: "0",
              past_due_amount_december_2021: "0",
              past_due_amount_january_2022: "0",
              past_due_amount_february_2022: "0",
              past_due_amount_march_2022: "0",
              past_due_amount_april_2022: "0",
              past_due_amount_may_2022: "0",
              past_due_amount_june_2022: "0",
              past_due_amount_july_2022: "0",
              past_due_amount_august_2022: "0",
              past_due_amount_september_2022: "0",
              past_due_amount_october_2022: "0",
              past_due_amount_november_2022: "0",
              past_due_amount_december_2022: "0",
              past_due_amount_january_2023: "0",
              past_due_amount_february_2023: "0",
              past_due_amount_march_2023: "0",
              past_due_amount_april_2023: "0",
            },
          ],
          {
            emitEvent: false,
          }
        );
      }
    });
    const formGroup = <FormGroup>(
      this.expensesFormGroup.controls["expenses"].get("0")
    );
    this.setExpValidators(formGroup, formGroup.value.expense_type, false);
    // this.commonExpenseSubscription(<FormGroup>this.expensesFormGroup.controls['expenses'].get('0'));
  }
  onExpensesSubmit() {
    this.isDueAmount0(this.expensesFormGroup.controls["expenses"].get("0"));
    if (this.dueAmount0) return;
    if (!this.expensesFormGroup.valid) {
      this.common.validateAllFormFields(this.expensesFormGroup);
      return;
    }

    const tableId = this.projectSpecificData.expensesData.TableId;
    const expObservables = this.common.getExpensesObservables(
      this.expensesFormGroup.getRawValue(),
      tableId,
      this.recordId
    );
    this.addUpdateExpenses(expObservables);
  }
  openExpensesAdd(content) {
    this.dueAmount0 = false;
    this.expensesAddFormGroup = this.appForms.setupExpensesAddForm(
      this.recordId
    );
    //this.pastDueAmountsAddFormGroup = this.appForms.setupPastDueAmountsAddForm(this.months, this.recordId);
    //this.expensesAddFormGroup.addControl("past_due_amounts", this.pastDueAmountsAddFormGroup)

    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);

    this.expensesAddFormGroup.valueChanges.subscribe((data) => {
      this.dueAmount0 = false;
      let count = 0,
        total = 0;
      let changeObject = {};
      for (let i in data) {
        if (i.includes("past_due_amount") && +data[i]) {
          count++;
          total += +data[i];
          // if (+data[i] > data.amount_monthly) {
          //   changeObject[i] = 0;
          //   total -= +data[i];
          //   count -= 1;
          // }
        }
      }
      this.expensesAddFormGroup.patchValue(
        {
          months_in_arrears: count,
          total_arrears: total,
        },
        {
          emitEvent: false,
        }
      );

      if (data.in_arrears === "Rental Advance Only") {
        this.isPastDueValid = false;
        this.expensesAddFormGroup.patchValue(
          {
            months_in_arrears: "0",
            total_arrears: "0",
            past_due_amount_april_2020: "0",
            past_due_amount_may_2020: "0",
            past_due_amount_june_2020: "0",
            past_due_amount_july_2020: "0",
            past_due_amount_august_2020: "0",
            past_due_amount_september_2020: "0",
            past_due_amount_october_2020: "0",
            past_due_amount_november_2020: "0",
            past_due_amount_december_2020: "0",
            past_due_amount_january_2021: "0",
            past_due_amount_february_2021: "0",
            past_due_amount_march_2021: "0",
            past_due_amount_april_2021: "0",
            past_due_amount_may_2021: "0",
            past_due_amount_june_2021: "0",
            past_due_amount_july_2021: "0",
            past_due_amount_august_2021: "0",
            past_due_amount_september_2021: "0",
            past_due_amount_october_2021: "0",
            past_due_amount_november_2021: "0",
            past_due_amount_december_2021: "0",
            past_due_amount_january_2022: "0",
            past_due_amount_february_2022: "0",
            past_due_amount_march_2022: "0",
            past_due_amount_april_2022: "0",
            past_due_amount_may_2022: "0",
            past_due_amount_june_2022: "0",
            past_due_amount_july_2022: "0",
            past_due_amount_august_2022: "0",
            past_due_amount_september_2022: "0",
            past_due_amount_october_2022: "0",
            past_due_amount_november_2022: "0",
            past_due_amount_december_2022: "0",
            past_due_amount_january_2023: "0",
            past_due_amount_february_2023: "0",
            past_due_amount_march_2023: "0",
            past_due_amount_april_2023: "0",
          },
          {
            emitEvent: false,
          }
        );
      }
    });

    /**
     * Expense type listener
     */
    this.commonExpenseSubscription(this.expensesAddFormGroup);
  }

  commonExpenseSubscription = (formGroup: FormGroup) => {
    /**
     * Expense type listener
     */
    formGroup.controls.expense_type.valueChanges.subscribe((expense_type) => {
      this.setExpValidators(
        formGroup,
        expense_type,
        formGroup.value.expense_type === "Utility - Electricity" &&
          formGroup.controls.provider.value !== "Entergy"
          ? true
          : false,
        formGroup.value.expense_type === "Utility - Electricity" &&
          formGroup.controls.provider.value !== "Entergy"
          ? true
          : false
      );
    });

    formGroup.controls.provider.valueChanges.subscribe((provider) => {
      if (
        provider === "Other" &&
        formGroup.value.expense_type === "Utility - Electricity"
      )
        formGroup.controls.electricity_company_other.setValidators([
          Validators.required,
        ]);
      else formGroup.controls.electricity_company_other.clearValidators();
      formGroup.controls.electricity_company_other.updateValueAndValidity();

      if (provider === "Entergy") {
        formGroup.controls.document_file.clearValidators();
        formGroup.controls.document_name.clearValidators();
        formGroup.controls.amount_past_due.clearValidators();
        formGroup.controls.document_file.updateValueAndValidity();
        formGroup.controls.document_name.updateValueAndValidity();
        formGroup.controls.amount_past_due.updateValueAndValidity();
      } else {
        formGroup.controls.document_file.setValidators([Validators.required]);
        formGroup.controls.document_name.setValidators([Validators.required]);
        formGroup.controls.amount_past_due.setValidators([Validators.required]);
        formGroup.controls.document_file.updateValueAndValidity();
        formGroup.controls.document_name.updateValueAndValidity();
        formGroup.controls.amount_past_due.updateValueAndValidity();
      }
    });
  };

  /**
   * Setup expense validators by expense_type
   */
  setExpValidators = (
    formGroup: FormGroup,
    expense_type: string,
    docRequired: boolean = true,
    amountRequired: boolean = true
  ) => {
    let utilityFields = [
      "account_number",
      "name_on_account",
      "provider",
      "amount_past_due",
      "document_file",
      "document_name",
    ];
    if (!docRequired) {
      utilityFields.pop();
      utilityFields.pop();
    }
    if (!amountRequired) {
      utilityFields.splice(utilityFields.indexOf("amount_past_due"), 1);
    }
    if (expense_type === "Rent") {
      utilityFields.forEach((field) => {
        formGroup.controls[field].clearValidators();
        formGroup.controls[field].updateValueAndValidity();
      });
      formGroup.controls.amount_monthly.setValidators([
        Validators.min(1),
        Validators.required,
        Validators.pattern(Constants.AMOUNT_PATTERN),
      ]);
      formGroup.controls.in_arrears.setValidators([Validators.required]);
      formGroup.controls.document_file.clearValidators();
      formGroup.controls.document_name.clearValidators();
      formGroup.controls.amount_past_due.clearValidators();
      formGroup.controls.document_file.updateValueAndValidity();
      formGroup.controls.document_name.updateValueAndValidity();
      formGroup.controls.amount_past_due.updateValueAndValidity();
    } else {
      utilityFields.forEach((field) => {
        formGroup.controls[field].setValidators([Validators.required]);
        formGroup.controls[field].updateValueAndValidity();
      });
      formGroup.controls.amount_monthly.clearValidators();
      formGroup.controls.in_arrears.clearValidators();
      formGroup.setErrors(null);
    }
    formGroup.controls.amount_monthly.updateValueAndValidity();
    formGroup.controls.in_arrears.updateValueAndValidity();
  };

  /**
   * Select expense file
   */
  selectExpenseFile = async (event, formGroup: FormGroup) => {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      formGroup.patchValue({
        document_file: "",
        document_name: "",
      });
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    formGroup.patchValue({
      document_file: filestr,
      document_name: strFilename,
    });
  };

  selectIncomeExpenseFile = async (event,controlName:any) => {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }
    const tableId = this.projectSpecificData.expensesData.TableId;
    this.spinner.show();
    const uploadData = this.common.createExpenseFileUploadData(
      tableId,
      this.appExpenseRecertificationData ? this.appExpenseRecertificationData.id : '',
      controlName,
      strFilename,
      filestr,
      this.recordId
    )
    this.uploadExpenceFile(uploadData,event);
  };

  private async uploadExpenceFile(recordFAD: any, event: any) {
    try {
      // this.modelConfig.busy = true;
      if(this.appExpenseRecertificationData){
        await this.ignatiusService.putData(recordFAD).toPromise();
      } else {
        await this.ignatiusService.postData(recordFAD).toPromise();
      }
      await this.getExpensesData(true);
      this.appExpenseRecertificationData = this.expensesData.find(data => data.related_applications == this.recordId && data.expense_type === 'Recertification');
      this.recertificationExpenseForm = this.appForms.setupExpensesIncomeForm(this.appExpenseRecertificationData,this.recordId);
      this.updateFileCompleted(true);
      event.target.value = null;
    } catch (error) {
      this.updateFileCompleted(false);
    } finally {
      this.spinner.hide();
    }
  }

  proofOfExpenseDeleted = async () => {
    await this.getExpensesData(true);
    this.appExpenseRecertificationData = this.expensesData.find(data => data.related_applications == this.recordId && data.expense_type === 'Recertification');
    this.recertificationExpenseForm = this.appForms.setupExpensesIncomeForm(this.appExpenseRecertificationData,this.recordId);
  }

  onExpensesAddSubmit() {
    this.isDueAmount0(this.expensesAddFormGroup);
    if (this.dueAmount0) return;
    if (!this.expensesAddFormGroup.valid) {
      this.common.validateAllFormFields(this.expensesAddFormGroup);
      return;
    }
    const tableId = this.projectSpecificData.expensesData.TableId;
    const expObservables = this.common.getExpensesAddObservables(
      this.expensesAddFormGroup.getRawValue(),
      tableId,
      this.recordId
    );
    this.addUpdateExpenses(expObservables[0]);
  }
  openExpensesDelete(content, id) {
    this.expensesDeleteFormGroup = this.appForms.setupExpensesDeleteForm(
      id,
      this.expensesData,
      this.expensesData,
      this.recordId
    );
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);
  }
  onExpensesDeleteSubmit() {
    const tableId = this.projectSpecificData.expensesData.TableId;

    const expObservables = this.common.getExpensesDeleteObservables(
      this.expensesDeleteFormGroup.controls.expense.value[0],
      tableId
    );
    this.deleteExpense(expObservables);
  }
  async handleExpenseUpload(event: any, index: number, addOrEdit) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );

      var strFilename = filename;
      if (filename.split(".").length > 2) {
        for (var i = 0; i < filename.split(".").length - 2; i++) {
          for (var j = 0; j < filename.length; j++)
            if (filename[j] === ".") {
              strFilename = strFilename.replace(".", "_");
              j = filename.length;
            }
        }
      }

      if (addOrEdit == "Add")
        this.expensesAddFormGroup.patchValue({
          document_file: "",
          document_name: "",
        });
      else
        (<FormArray>this.expensesFormGroup.get("expenses"))
          .at(index)
          .patchValue({
            document_file: "",
            document_name: "",
          });
      return;
    }

    if (addOrEdit == "Add")
      this.expensesAddFormGroup.patchValue({
        document_file: filestr,
        document_name: strFilename,
      });
    else
      (<FormArray>this.expensesFormGroup.get("expenses")).at(index).patchValue({
        document_file: filestr,
        document_name: strFilename,
      });
  }
  // EXPENSES END


  // APPLICATION START
  openApplication(content: any) {
    this.appExpenseData = this.expensesData.find(data => data.related_applications == this.recordId);
    this.appExpenseRecertificationData = this.expensesData.find(data => data.related_applications == this.recordId && data.expense_type === 'Recertification');
    if(this.appExpenseData){
      this.isExpenseDataSubmitted = true;
    }
    this.appFormGroup = this.appForms.setupAppForm(
      this.applicationData,this.appExpenseData,this.appExpenseRecertificationData
    );
    this.acknowledgementsFormGroup =
      this.appForms.setupApplicantAcknowledgementsForm(
        this.applicationData,
      );
    //this.modelConfig = new PopupModel();
    //this.ngbModal.open(content, this.modelConfig.settings);
    this.setFormState(this.appFormGroup);
  }
  onApplicationSubmit(event) {
    if( event.submitter.name == "Save") {
      if (!this.appFormGroup.valid) {
        this.common.validateAllFormFields(this.appFormGroup);
        return;
      }
      this.updateApplication(this.appFormGroup.value);
      //this.onAcknowledgementsSubmit();
      if(this.isCommitmentLetterIssued()){
        this.onExpensesAcknowledgementsSubmit();
      }

      if(this.isRecertification()){
        this.onExpensesIncomeFormSubmitted();
      }
    }
    else if( event.submitter.name == "Submit" ) {
      if (!this.appFormGroup.valid) {
        this.common.validateAllFormFields(this.appFormGroup);
        return;
      }
      if(this.isCommitmentLetterIssued()){
        this.modelConfig = new PopupModel();
        this.modelConfig.settings.windowClass = "xlModal";
        this.ngbModal.open(this.expack, this.modelConfig.settings);
      } else { 
        if(this.isRecertification()){
          this.onExpensesIncomeFormSubmitted(true);
        } else{
          this.submitApplication(+this.recordId);
        }
      }
    }
  }

  // CONTACT INFO START
  openContact(content: any) {
    this.contactFormGroup = this.appForms.setupContactForm(
      this.applicationData
    );
    this.modelConfig = new PopupModel();
    this.ngbModal.open(content, this.modelConfig.settings);
    this.setFormState(this.contactFormGroup);
  }
  onContactSubmit() {
    if (!this.contactFormGroup.valid) {
      this.common.validateAllFormFields(this.contactFormGroup);
      return;
    }
    this.updateApplication(this.contactFormGroup.value);
  }
  // CONTACT INFO END

  // ADDRESS INFO START
  openAddresses(content: any) {
    this.addressesFormGroup = this.appForms.setupAddressesForm(
      this.applicationData
    );
    this.modelConfig = new PopupModel();
    this.ngbModal.open(content, this.modelConfig.settings);
    this.setFormState(this.addressesFormGroup);
  }
  onAddressesSubmit() {
    if (!this.addressesFormGroup.valid) {
      this.common.validateAllFormFields(this.addressesFormGroup);
      return;
    }
    this.updateApplication(this.addressesFormGroup.value);
  }
  addressChange(address: any, field: string) {
    this.addressesFormControl[field].setValue(address.formatted_address);
  }
  addressChangePhysical(address: any, field: string) {
    this.addressesFormGroup?.get(field).setValue(address.formatted_address);
    var streetNumber = "";
    if (address.address_components[0]) {
      for (var i = 0; i < address.address_components.length; i++) {
        var addressType = address.address_components[i].types[0];
        switch (addressType) {
          case "street_number":
            streetNumber = address.address_components[i].long_name;
            break;
          case "route":
            this.addressesFormGroup?.get("physical_street")
              .setValue(
                streetNumber + " " + address.address_components[i].long_name
              );
            break;
          case "locality":
            this.addressesFormGroup?.get("physical_city")
              .setValue(address.address_components[i].long_name);
            break;
          case "administrative_area_level_1":
            this.addressesFormGroup?.get("physical_state")
              .setValue(address.address_components[i].long_name);
            break;
          case "postal_code":
            this.addressesFormGroup?.get("physical_zip_code")
              .setValue(address.address_components[i].long_name);
            break;
          case "longitude":
            this.addressesFormGroup?.get("physical_longitude")
              .setValue(address.address_components[i].long_name);
            break;
          case "latitude":
            this.addressesFormGroup?.get("physical_latitude")
              .setValue(address.address_components[i].long_name);
            break;
        }
      }
    }
  }
  addressChangeMailing(address: any, field: string) {
    this.addressesFormGroup?.get(field).setValue(address.formatted_address);
    var streetNumber = "";
    if (address.address_components[0]) {
      for (var i = 0; i < address.address_components.length; i++) {
        var addressType = address.address_components[i].types[0];
        switch (addressType) {
          case "street_number":
            streetNumber = address.address_components[i].long_name;
            break;
          case "route":
            this.addressesFormGroup
              .get("mailing_street")
              .setValue(
                streetNumber + " " + address.address_components[i].long_name
              );
            break;
          case "locality":
            this.addressesFormGroup
              .get("mailing_city")
              .setValue(address.address_components[i].long_name);
            break;
          case "administrative_area_level_1":
            this.addressesFormGroup
              .get("mailing_state")
              .setValue(address.address_components[i].long_name);
            break;
          case "postal_code":
            this.addressesFormGroup
              .get("mailing_zip_code")
              .setValue(address.address_components[i].long_name);
            break;
          case "longitude":
            this.addressesFormGroup
              .get("mailing_longitude")
              .setValue(address.address_components[i].long_name);
          case "latitude":
            this.addressesFormGroup
              .get("mailing_latitude")
              .setValue(address.address_components[i].long_name);
            break;
            break;
        }
      }
    }
  }
  // ADDRESS INFO END

  // LANDLORD START
  // openLandlord(content: any) {
  //   this.landlordFormGroup = this.appForms.setupLandlordForm(
  //     this.applicationData
  //   );
  //   this.modelConfig = new PopupModel();
  //   this.ngbModal.open(content, this.modelConfig.settings);
  //   this.setFormState(this.landlordFormGroup);
  // }
  // onLandlordSubmit() {
  //   if (!this.landlordFormGroup.valid) {
  //     this.common.validateAllFormFields(this.landlordFormGroup);
  //     return;
  //   }
  //   this.updateApplication(this.landlordFormGroup.value);
  // }
  // LANDLORD END

  // RENTAL DOCUMENTS START
  openRentalDocuments(content: any) {
    this.rentalDocumentsFormGroup = this.appForms.setupRentalDocumentsForm(
      this.applicationData
    );
    this.modelConfig = new PopupModel();
    this.ngbModal.open(content, this.modelConfig.settings);
    this.setFormState(this.rentalDocumentsFormGroup);
  }
  onRentalDocumentsSubmit() {
    if (!this.rentalDocumentsFormGroup.valid) {
      this.common.validateAllFormFields(this.rentalDocumentsFormGroup);
      return;
    }
    const tableId = this.projectSpecificData.applicationsData.TableId;
    const recordId = Number(this.recordId);
    const updateData = this.common.createRentalDocumentsData(
      this.rentalDocumentsFormGroup.value,
      tableId,
      recordId
    );
    this.updateApplicationAndDocs(updateData);
  }

  // RENTAL DOCUMENTS START
  async handleLeaseUpload(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.rentalDocumentsFormGroup.patchValue({
        file_lease: "",
        file_name_lease: "",
      });
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    this.rentalDocumentsFormGroup.patchValue({
      file_lease: filestr,
      file_name_lease: strFilename,
    });
  }
  // async handlePastDueRentUpload(event: any) {
  //   const file = event.target.files[0];
  //   if (!file) return;
  //   const { filesize, filestr, strFilename } = await this.common.getFileData(file);

  //   if (filesize > Constants.TWENTY_MB_IN_BYTE) {
  //     this.toastr.error('Maximum 20MB file size exceeded', 'Error');
  //     this.rentalDocumentsFormGroup.patchValue({
  //       file_past_due_rent: "",
  //       file_name_past_due_rent: ""
  //     })
  //     return;
  //   }

  //   this.rentalDocumentsFormGroup.patchValue({
  //     file_past_due_rent: filestr,
  //     file_name_past_due_rent: strFilename
  //   })
  // }
  async handleEvictionUpload(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.rentalDocumentsFormGroup.patchValue({
        file_eviction_notice: "",
        file_name_eviction_notice: "",
      });
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    this.rentalDocumentsFormGroup.patchValue({
      file_eviction_notice: filestr,
      file_name_eviction_notice: strFilename,
    });
  }
  async handlePastDueUtilityUpload(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.rentalDocumentsFormGroup.patchValue({
        file_past_due_utility: "",
        file_name_past_due_utility: "",
      });
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    this.rentalDocumentsFormGroup.patchValue({
      file_past_due_utility: filestr,
      file_name_past_due_utility: strFilename,
    });
  }
  async handleOtherUpload(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.rentalDocumentsFormGroup.patchValue({
        file_other: "",
        file_name_other: "",
      });
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    this.rentalDocumentsFormGroup.patchValue({
      file_other: filestr,
      file_name_other: strFilename,
    });
  }
  // RENTAL DOCUMENTS END

  // ACKNOWLEDGEMENTS START
  openAcknowledgements(content) {
    this.acknowledgementsFormGroup =
      this.appForms.setupApplicantAcknowledgementsForm(
        this.applicationData,
      );
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "xlModal";
    this.ngbModal.open(content, this.modelConfig.settings);
  }
  onAcknowledgementsSubmit = async() => {
    if (!this.acknowledgementsFormGroup.valid) {
      this.common.validateAllFormFields(this.acknowledgementsFormGroup);
      return;
    }
    const tableId = this.projectSpecificData.applicationsData.TableId;
    const recordId = Number(this.recordId);
    const updateData = this.common.createApplicantAcknowledgementsData(
      this.acknowledgementsFormGroup.value,
      tableId,
      recordId,
    );
    await this.updateApplicationAndDocs(updateData);
  }
  async handleDLUpoad(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.acknowledgementsFormGroup.patchValue({
        file_drivers_license: "",
        file_drivers_license_name: "",
      });
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    this.acknowledgementsFormGroup.patchValue({
      file_drivers_license: filestr,
      file_drivers_license_name: strFilename,
    });
    this.spinner.show();
    await this.onAcknowledgementsSubmit();
    event.target.value = null;
    this.spinner.hide();
  }
  // ACKNOWLEDGEMENTS END

  // BANK INFO START
  // openBankDetails(content) {
  //   this.bankDetailsFormGroup = this.appForms.setupBankDetailsForm(this.applicationData);
  //   this.modelConfig = new PopupModel();
  //   this.ngbModal.open(content, this.modelConfig.settings)
  // }
  // onBankDetailsSubmit() {
  //   if (!this.bankDetailsFormGroup.valid) {
  //     this.common.validateAllFormFields(this.bankDetailsFormGroup);
  //     return;
  //   }
  //   this.updateApplication(this.bankDetailsFormGroup.value);
  // }
  // async OnChangeRoutingNumber(value: number) {
  //   try {
  //     const routingData = await fetch(`https://www.routingnumbers.info/api/data.json?rn=${value}`);
  //     const data = await routingData.json();
  //     this.bankName = (data && data.customer_name) || '';
  //   } catch (error) {
  //     console.log('Error in fetching routing data', error);
  //   }
  // }
  // BANK START

  // APPLICATION START
  private async updateApplication(reqDada: any) {
    try {
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = Number(this.recordId);
      const recordFAD = this.common.createPutData(tableId, reqDada, condition);
      //this.modelConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.getApplicationData(true);
      this.updateApplicationCompleted(true);
    } catch (error) {
      this.updateApplicationCompleted(false);
    }
  }
  private async updateApplicationAndDocs(recordFAD: any) {
    try {
      if(this.modelConfig) this.modelConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.getApplicationData(true);
      this.updateApplicationCompleted(true);
    } catch (error) {
      this.updateApplicationCompleted(false);
    }
  }
  private updateInviteCompleted(statue: boolean) {
    if (statue) {
      this.toastr.success(
        this.translate.instant("MESSAGES.INVITATION_EMAIL_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
    } else {
      this.toastr.error(
        this.translate.instant("MESSAGES.INVITATION_EMAIL_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
  }
  private updateApplicationCompleted(statue: boolean) {
    if (statue) {
      this.toastr.success(
        this.translate.instant("MESSAGES.APPLICATION_UPDATE_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.ngbModal.dismissAll();
    } else {
      this.toastr.error(
        this.translate.instant("MESSAGES.APPLICATION_UPDATE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
    //this.modelConfig.busy = false;
  }
  async addUpdateExpenses(obs: any) {
    try {
      this.modelConfig.busy = true;
      await forkJoin(obs).toPromise();
      await this.getExpensesData(true);
      this.toastr.success(
        this.translate.instant("MESSAGES.EXPENSES_UPDATE_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.ngbModal.dismissAll();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.EXPENSES_UPDATE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    } finally {
      this.modelConfig.busy = false;
    }
  }
  async addUpdateHousehold(obs: any) {
    try {
      this.modelConfig.busy = true;
      await forkJoin(obs).toPromise();
      await this.getHouseholdData(true);
      this.toastr.success(
        this.translate.instant("MESSAGES.HOUSEHOLD_UPDATE_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.ngbModal.dismissAll();
    } catch (error) {
      if (error.error.Message === "Duplicate entry error for field SSN_ENC") {
        this.toastr.error(
          this.translate.instant("MESSAGES.SSN_ALREADY_EXIST"),
          this.translate.instant("applications.ERROR")
        );
        return;
      }
      this.toastr.error(
        this.translate.instant("MESSAGES.HOUSEHOLD_UPDATE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    } finally {
      this.modelConfig.busy = false;
    }
  }
  async deleteHousehold(obs: any) {
    try {
      this.modelConfig.busy = true;
      await forkJoin(obs).toPromise();
      await this.getHouseholdData(true);
      this.toastr.success(
        this.translate.instant("MESSAGES.HOUSEHOLD_DELETE_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.ngbModal.dismissAll();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.HOUSEHOLD_DELETE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    } finally {
      this.modelConfig.busy = false;
    }
  }
  async deleteExpense(obs: any) {
    try {
      this.modelConfig.busy = true;
      await forkJoin(obs).toPromise();
      await this.getExpensesData(true);
      this.toastr.success(
        this.translate.instant("MESSAGES.EXPENSE_DELETE_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.ngbModal.dismissAll();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.EXPENSE_DELETE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    } finally {
      this.modelConfig.busy = false;
    }
  }
  async syncCompletionPercentageWithDB(cp) {
    try {
      const reqData = { percent_complete: cp };
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = Number(this.recordId);
      const recordFAD = this.common.createPutData(tableId, reqData, condition);
      await this.ignatiusService.putData(recordFAD).toPromise();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.ERROR_SYNC_PERCENTAGE"),
        this.translate.instant("applications.ERROR")
      );
    }
  }
  async closeModel(confirm: boolean = true) {
    if (this.viewMode || !confirm) {
      this.ngbModal.dismissAll();
      return;
    }
    const dialogData: { title: string; message: string } = {
      title: "applications.CONFIRM_DISCARD",
      message: `applications.CONFIRM_DISCARD_MESSAGE`,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData,
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) return;

    this.ngbModal.dismissAll();
  }
  // APPLICATION END

  public async inviteLandlord(landlord_email) {
    try {
      // CHECK FOR UNIQUENESS

      this.ignatiusService
        .putData(
          this.common.createPutData(
            this.projectSpecificData.applicationsData.TableId,
            {
              confirmation_number: await this.generateCode(
                this.confirmationCodeLength
              ),
              landlord_status: "Request Sent",
              email_landlord: landlord_email,
            },
            Number(this.recordId)
          )
        )
        .toPromise();

      this.getApplicationData(true);
      this.updateInviteCompleted(true);
    } catch (error) {
      this.updateInviteCompleted(false);
    }
  }

  async generateCode(length) {
    const chars = "1234567890";
    var localCode = "";
    var randNum = 0;
    var char = "";
    var randCode = "";
    for (let i = 0; i < length; i++) {
      randNum = Math.round(Math.random() * (9 - 0));
      char = chars[randNum];
      randCode += char.toString();
    }
    return randCode;
  }

  async askUserToSubmit() {
    if (
      (!(this.isApplicationValid())) &&
      this.applicationData.status !== "Open"
    )
      return;
    const dialogData: { title: string; message: string; hasSubmit: boolean } = {
      title: "applications.SUBMIT_APPLICATION",
      message: `applications.SUBMIT_APPLICATION_MESSAGE`,
      hasSubmit: true,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData,
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) {
      return;
    }
    this.ngbModal.dismissAll();
    this.submitApplication(+this.recordId);
  }

  async submitApplication(recordId: number) {
    if (!this.isApplicationValid()) { return; }

    const dialogData: { title: string; message: string } = {
      title: "applications.SAVE_CONTINUE",
      message: `applications.SAVE_CONTINUE_MESSAGE_APP`,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData,
    });

    const confirmation = await dialogRef.afterClosed().toPromise();

    if (!confirmation) return;

    
    try {
      this.updateApplication(this.appFormGroup.value);
      const reqData = { status: this.isCommitmentLetterIssued() ? "Unit Request Form Submitted" : this.isRecertification() ? "Recertification Submitted":"Submitted"  }; //this.applicationData.status == 'Commitment Letter Issued' ? "Unit Request Form Submitted" : "Submitted" };
      const tableId = this.projectSpecificData.applicationsData.TableId;
      const condition = recordId;
      const recordFAD = this.common.createPutData(tableId, reqData, condition);
      this.spinner.show();
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.getApplicationData();
      this.spinner.hide();
      this.router.navigate(["../"]);
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.ERROR_SYNC_PERCENTAGE"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
  }

  setViewMode = (applicationStatus: string) => {
    this.viewMode = true;
    //   // NES - Remove the ability for the applicant to edit applications
      this.viewMode = ![
        "Referred",
        "Ineligible",
        "Pre-Eligibility",
        "Eligibility Questions Answered",
        "Open",
        "Registered",
      ].find((mode) => mode === applicationStatus);
  };
  setViewMode2 = (applicationStatus: string) => {
    this.viewMode2 = true;
    this.viewMode2 = ![
      "Commitment Letter Issued",
    ].find((mode) => mode === applicationStatus);
    this.cdr.detectChanges();
  };
  setViewMode3 = (applicationStatus: string) => {
    this.viewMode3 = true;
    this.viewMode3 = ![
      "Recertification Pending",
    ].find((mode) => mode === applicationStatus);
    this.cdr.detectChanges();
  };

  setFormState = (form: FormGroup) => {
    if (this.viewMode && this.viewMode2 && this.viewMode3) form.disable();
    else form.enable();
  };

  getFieldState = (controlName: string, expense: any) => {
    if (+expense.get(controlName).value > +expense.get("amount_monthly").value)
      return "red";
    return "";
  };

  isPastDue = (expense: any) => {
    const d = this.pastDueFields
      .map(
        (item) =>
          +expense.get(item).value > +expense.get("amount_monthly").value
      )
      .filter((i) => i === true);
    this.isPastDueValid = !!d.length;
    return !!d.length;
  };

  isDueAmount0 = (expense: any) => {
    if (expense.value["in_arrears"] !== "Past-Due Rental and Rental Advance") {
      this.dueAmount0 = false;
      return;
    }
    const d = +expense.value.total_arrears > 0;
    this.dueAmount0 = !d;
    return !d;
  };

  /**
   * onSelectFileUploadHousehold($event, 'income_gross_file_name', 'income_gross_file')
   * Upload selected file instantly household
   * @param field_name is name of field in which file name is stored
   * @param field_file is name of field in which file is stored
   * @param index of the household array
   */
  onSelectFileUploadHousehold = async (
    event: any,
    field_name: string,
    field_file: string,
    add: boolean = false
  ) => {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    this.spinner.show();
    const tableId = this.projectSpecificData.householdData.TableId;
    let hhObservables = null;
    if (add) {
      // const hhObservables = this.common.getHouseholdAddObservables(this.householdAddFormGroup.value, tableId)
      hhObservables = this.common.getHouseholdAddObservables(
        {
          ...this.householdAddFormGroup.value,
          [field_name]: strFilename,
          [field_file]: filestr,
        },
        tableId
      );
    } else {
      hhObservables = this.common.getHouseholdObservables(
        {
          household: this.householdFormGroup.value.household.map((record) => {
            return {
              ...record,
              id: record.id,
              [field_name]: strFilename,
              [field_file]: filestr,
              used: record.used,
              related_applications: record.related_applications,
            };
          }),
        },
        tableId
      );
    }
    this.householdFileCompleted(hhObservables[0], event, add);
  };

  /**
   * Upload selected file instantly
   * @param field_name is name of field in which file name is stored
   * @param field_file is name of field in which file is stored
   */
  onSelectFileUpload = async (
    event: any,
    field_name: string,
    field_file: string
  ) => {
    const file = event.target.files[0];
    if (!file) return;
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error(
        this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      return;
    }

    var strFilename = filename;
    if (filename.split(".").length > 2) {
      for (var i = 0; i < filename.split(".").length - 2; i++) {
        for (var j = 0; j < filename.length; j++)
          if (filename[j] === ".") {
            strFilename = strFilename.replace(".", "_");
            j = filename.length;
          }
      }
    }

    this.spinner.show();
    const tableId = this.projectSpecificData.applicationsData.TableId;
    const recordId = Number(this.recordId);
    const updateData = this.common.createRentalDocumentsData(
      {
        [field_name]: strFilename,
        [field_file]: filestr,
      },
      tableId,
      recordId
    );
    this.uploadFile(updateData, event);
  };

  private async uploadFile(recordFAD: any, event: any) {
    try {
      this.modelConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.getApplicationData(true);
      this.updateFileCompleted(true);
      event.target.value = null;
    } catch (error) {
      this.updateFileCompleted(false);
    } finally {
      this.spinner.hide();
    }
  }

  private updateFileCompleted(statue: boolean) {
    if (statue)
      this.toastr.success(
        this.translate.instant("MESSAGES.FILE_UPLOAD_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
    else
      this.toastr.error(
        this.translate.instant("MESSAGES.FILE_UPLOAD_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    if(this.modelConfig) this.modelConfig.busy = false;
  }

  async householdFileCompleted(obs: any, event: any, add: boolean) {
    try {
      this.modelConfig.busy = true;
      await forkJoin(obs).toPromise();
      await this.getHouseholdData(true);
      if (add) {
        this.ngbModal.dismissAll();
        this.openHousehold(
          this.householdTemp,
          +this.householdData.length - 1,
          this.householdData[+this.householdData.length - 1]
        );
      } else {
        this.parseDates();
        this.householdFormGroup = this.appForms.setupHouseholdForm(
          this.householdData[this.currentHouseholdIndex].household_number.split(
            " "
          )[1],
          this.householdNumbers,
          this.householdData,
          this.recordId,
          this.householdData[this.currentHouseholdIndex]
        );
      }
      this.updateFileCompleted(true);
      event.target.value = null;
    } catch (error) {
      this.updateFileCompleted(false);
    } finally {
      this.modelConfig.busy = false;
      this.spinner.hide();
    }
  }

  /**
   * Function to view input on fields of password type
   */
  viewProtected = (input: HTMLInputElement,hh:any = '') => {
    if (input.className.includes("pw"))
      input.className = input.className.replace(" pw", "");
    else input.className = `${input.className} pw`;

    if(hh && hh.controls['ssn_enc'].disabled && !input.className.includes("pw")){
      input.value = `***-**-${hh.controls['ssn_enc'].value.slice(-4)}`;
    }
  };

  /**
   * Rental document try function
   */
  tryRentalDoc = async () => {
    await this.getApplicationData(true);
  };

  /**
   * driving doc try function
   */
  tryDriving = async () => {
    await this.getApplicationData(true);
  };

  /**
   * Household try function
   */
  tryHH = async () => {
    await this.getHouseholdData(true);
    this.householdFormGroup = this.appForms.setupHouseholdForm(
      this.householdData[this.currentHouseholdIndex].household_number.split(
        " "
      )[1],
      this.householdNumbers,
      this.householdData,
      this.recordId,
      this.householdData[this.currentHouseholdIndex]
    );
  };

  /**
   * @returns error message for passed HH object
   */
  getHHErrorRowMessage = (hh: any) => {
    const errorMessages = this.householdValidationRules
      .filter(rule => rule.condition(hh))
      .map(rule => this.translate.instant(`applications.${rule.messageKey}`));;

    if(errorMessages.length){
      return errorMessages.join(';')
    }
    return null;
  };

  isHHTopErrorVisible = () => {
    if (this.isApplicantAMember()) { return true; }
    for (let hh of this.householdData) {
      if (this.getHHErrorRowMessage(hh)) return true;
    }
    return false;
  };

  isHHNumberError = () => {
    if (this.appFormGroup.value.family_seeking_housing_number.toString() !== this.householdData.length.toString()) {
      return true;
    }
    return false;
  };
  isHHNumberZeroError = () => {
    if (this.appFormGroup.value.family_seeking_housing_number.toString() === "0") {
      return true;
    }
    return false;
  };

  /**
   * Find if applicant is itself a member
   */
  isApplicantAMember = () =>
    !this.householdData.find(
      (member) => member.relation_to_applicant === "Applicant"
    );

  /**
   *
   * Resets required fields for reuse
   */
  resetRequiredFields = () => {
    for (let field in this.requiredFields) this.requiredFields[field] = false;
  };

  /**
   * Setup listerners for setting required fields
   */
  setRequiredFieldsListener = (formGroup: FormGroup) => {
    this.resetRequiredFields();
    this.setRequiredFields(formGroup);
    formGroup.controls["date_of_birth"].valueChanges.subscribe((data) => {
      if (data && moment().diff(data, "years") >= 18)
        this.requiredFields.ssn_enc = true;
      else this.requiredFields.ssn_enc = false;
    });

    formGroup.controls["employment_status"].valueChanges.subscribe((data) => {
      if (data === "Unemployed") this.requiredFields.lastDateWorked = true;
      else this.requiredFields.lastDateWorked = false;

      if (["Employed", "Other"].find((el) => el === data))
        this.requiredFields.wagesIncome = true;
      else this.requiredFields.wagesIncome = false;
    });

    formGroup.valueChanges.subscribe((data) => {
      if (+data.income_in_lieu_of_earnings_amount)
        this.requiredFields.income_lieu = true;
      else this.requiredFields.income_lieu = false;

      if (+data.income_payments_annuities_amount)
        this.requiredFields.income_pymts = true;
      else this.requiredFields.income_pymts = false;

      if (+data.income_government_amount)
        this.requiredFields.income_government = true;
      else this.requiredFields.income_government = false;

      if (+data.income_alimony_amount)
        this.requiredFields.income_alimony = true;
      else this.requiredFields.income_alimony = false;
    });
  };

  setRequiredFields = (formGroup: FormGroup) => {
    const dob = formGroup.controls["date_of_birth"].value;
    const emp_status = formGroup.controls["employment_status"].value;
    const data = formGroup.value;
    if (dob && moment().diff(dob, "years") >= 18)
      this.requiredFields.ssn_enc = true;
    else this.requiredFields.ssn_enc = false;

    if (emp_status === "Unemployed") this.requiredFields.lastDateWorked = true;
    else this.requiredFields.lastDateWorked = false;

    if (["Employed", "Other"].find((el) => el === emp_status))
      this.requiredFields.wagesIncome = true;
    else this.requiredFields.wagesIncome = false;

    if (+data.income_in_lieu_of_earnings_amount)
      this.requiredFields.income_lieu = true;
    else this.requiredFields.income_lieu = false;

    if (+data.income_payments_annuities_amount)
      this.requiredFields.income_pymts = true;
    else this.requiredFields.income_pymts = false;

    if (+data.income_government_amount)
      this.requiredFields.income_government = true;
    else this.requiredFields.income_government = false;

    if (+data.income_alimony_amount) this.requiredFields.income_alimony = true;
    else this.requiredFields.income_alimony = false;
  };

  /**
   * Is certified
   */
  isCertified = () => {
    return (
      this.applicationData["certify"] === "True" &&
      this.applicationData["ack"] === "True" &&
      this.applicationData["ack_2"] === "True" &&
      this.applicationData["ack_3"] === "True" &&
      this.applicationData["ack_4"] === "True"
    );
  };

  hasExpenseRent = () =>
    this.expensesData.find((exp) => exp.expense_type === "Rent");

  checkCertifyDate = () => {
    if (
      this.acknowledgementsFormGroup.value.certified_dt === "" ||
      this.acknowledgementsFormGroup.value.certified_dt === null ||
      this.acknowledgementsFormGroup.value.certified_dt === undefined
    )
      return false;
    else {
      //alert(moment(this.acknowledgementsFormGroup.value.certified_dt).format("MM-DD-YYYY"));
      //alert(moment("12/6/2021").format("MM-DD-YYYY"));
      return (
        moment(this.acknowledgementsFormGroup.value.certified_dt).format(
          "MM-DD-YYYY"
        ) > moment("12/6/2021").format("MM-DD-YYYY")
      );
    }
  };

  /* create file view url
   */
  getFileRouteLink(url: string): string {
    if (!url) return "";
    const urlSplit = url.split("tables");
    if (urlSplit.length < 1) return "";
    return "application" + urlSplit[1];
  }

  isCommitmentLetterIssued(){
    return this.appStatus === 'Commitment Letter Issued'
  }
  isUnitRequestFormSubmitted(){
    return this.appStatus === 'Unit Request Form Submitted'
  }
  isRecertification(){
    return this.appStatus === 'Recertification Pending'
  }
  isRecertificationSubmitted(){
    return this.appStatus === 'Recertification Submitted'
  }
  isRecertificationApproved(){
    return this.appStatus === 'Recertification Approved'
  }
  isAppFormInvalid(){
    return this.appFormGroup.invalid || this.appFormGroup?.value?.family_seeking_housing_number != this.householdData?.length;
  }

  isApplicationValid(){
    if(this.isRecertification()){
      if((this.recertificationExpenseFormControl['proof_of_income_file_1_url'].value &&
      this.recertificationExpenseFormControl['proof_of_rental_payment_file_1_url'].value &&
      this.appFormControl.recert_applicant_signature.value && 
      this.appFormControl.recert_applicant_signature_date.value)
     ) {
      return true
     }
      return false;
    }
    if(this.isCommitmentLetterIssued() || this.isUnitRequestFormSubmitted()){
      return !this.appFormGroup.invalid && (this.documents && this.documents.length);
    }
    if (this.viewMode) {
      if (!this.isAppFormInvalid()) { 
        return true;
      }
      else {
        return false;
      }
    }
    else if (
      !this.isAppFormInvalid() && 
      !this.isHHTopErrorVisible() &&
      this.isRelocationValid() &&
      this.appFormGroup.value.contact_first_name != "" &&
      this.appFormGroup.value.contact_last_name != "" &&
      this.appFormGroup.value.contact_email != "" &&
      this.appFormGroup.value.contact_phone != "" &&
      this.appFormGroup.value.applicant_signature && 
      this.appFormGroup.value.applicant_signature_date != "" && 
      (this.acknowledgementsFormGroup.value.file_drivers_license || this.acknowledgementsFormGroup.value.file_drivers_license_name)
    ){
      return true;
    }
    return false;
  }

  isSSNHouseholdDisabled() {
    const ssnControl = this.householdFormGroup.controls["household"].get("0").get("ssn_enc");
    if (ssnControl.value) {
      ssnControl.setValue(`00000${ssnControl.value.slice(-4)}`);
      ssnControl.disable();
    }
  }

  fileDeleted(form:FormGroup,fields:Array<any>=[]){
    fields?.forEach(field=>{
      form.patchValue({
        [field]:''
      })
    })
  }

  isRelocationValid(){
    if(this.appFormGroup.controls.willing_to_relocate.value == 'True' && 
        (this.appFormGroup.controls.preferred_parish_1.value ||
        this.appFormGroup.controls.preferred_parish_2.value ||
        this.appFormGroup.controls.preferred_parish_3.value)
    ) {
      return true
    } else if (this.appFormGroup.controls.willing_to_relocate.value == 'False'){
      return true
    }
    return false
  }

  handleFileUpload = async (event: any, docType: string) => {
    this.spinner.show();
    const obs: Array<Observable<any>> = [];
    for (const file of event.srcElement.files) {
      const { filesize, filestr, filename } =
        await this.common.getFileData(file);
      if (filesize > Constants.TWENTY_MB_IN_BYTE) {
        this.spinner.hide();
        this.toastr.error(
          this.translate.instant("MESSAGES.20MB_FILE_SIZE_ERROR"),
          this.translate.instant("applications.ERROR")
        );
        return;
      }
      const payload = await this.getUploadFileDocsPayload(
        this.recordId,
        filestr,
        filename,
        docType
      );
      obs.push(await this.saveFile(payload));
    }
    await forkJoin(obs)
      .toPromise()
      .catch((err: HttpErrorResponse) => {
        console.log(err);
      });
    this.getDocuments();
    this.spinner.hide();
  };

  getUploadFileDocsPayload = (
    applicationId: string,
    file: string,
    file_name: string,
    docType: string
  ): Array<any> => {
    let payload: Array<any> = [];

    payload.push({
      name: "related_applications",
      value: +this.recordId,
      base64EncodedFile: "",
    });
    payload.push({
      name: "file",
      value: this.getUpdatedFileName(file_name),
      base64EncodedFile: file,
    });
    return payload;
  };

  saveFile = async (payload: any, isChildMember?: boolean) => {
    const documentationTableId = this.projectSpecificData.documentsData.TableId;
    return this.ignatiusService.postData({
      applicationTableId: documentationTableId,
      fieldsList: payload,
    });
  };

  getUpdatedFileName(fileName: string): string {
    const fNameArray = fileName.split(".");
    const len = fNameArray.length;
    if (len <= 2) return fileName;
    else {
      return fNameArray.slice(0, -1).join("") + "." + fNameArray[len - 1];
    }
  }

  async getDocuments() {
    this.documents = await this.ignatiusService
      .getTargetTableObservable(
        this.projectSpecificData.appData,
        this.recordId,
        this.projectSpecificData.documentsData.TableId,
        this.projectSpecificData.documentsData
          .RelatedApplicationsFieldId
      )
      .toPromise();
  }

  removeFile = async (file: any) => {
    this.spinner.show();
    let payload = await this.getRemoveFilePayload(file.recordId || file!.id);
    await lastValueFrom(this.ignatiusService.deleteData(payload));
    this.toastr.success(
      this.translate.instant("MESSAGES.FILE_DELETE_SUCCESS"),
      this.translate.instant("applications.SUCCESS")
    );
    await this.getDocuments();
    this.spinner.hide();
  };

  getRemoveFilePayload = async (recordId: any) => {
    const documentationTableId = this.projectSpecificData.documentsData.TableId;
    let payload = {
      reportId: null,
      applicationTableId: documentationTableId,
      where: {
        rid: recordId,
      },
      fieldsList: new Array<FieldListItem>(),
    };

    let fields = [
      "file",
      "document_name",
      "documentation_type",
      "related_applications",
      "related_household",
    ];
    fields.forEach((field) => {
      payload.fieldsList.push(new FieldListItem(field, "", ""));
    });
    return payload;
  };

  async onExpensesAcknowledgementsSubmit(isSubmiting:boolean = false){
    try {
      const reqData = { };
      this.rentalObligationFields.forEach(field =>{
        reqData[field]= this.appFormGroup.get(field)?.value
      })
      reqData['landlord_phone']=this.appFormGroup.get('landlord_phone')?.value;
      reqData['new_address_2']=this.appFormGroup.get('new_address_2')?.value;
      reqData['mailing_address_2']=this.appFormGroup.get('mailing_address_2')?.value;
      reqData['expense_type']='Initial Award';
      reqData['related_applications']= +this.recordId;
      const tableId = this.projectSpecificData.expensesData.TableId;
      if((this.appExpenseData && Object.keys(this.appExpenseData).length) || this.isExpenseDataSubmitted){
        const condition = +this.appExpenseData?.id;
        const recordFAD = this.common.createPutData(tableId, reqData, condition);
        this.spinner.show();
        await this.ignatiusService.putData(recordFAD).toPromise();
        this.spinner.hide();
      } else {
        const recordFAD = this.common.createPostData(tableId, reqData);
        await this.ignatiusService.postData(recordFAD).toPromise().then((value:any) => {
          this.appExpenseData = value.recordData
          this.isExpenseDataSubmitted = true;
        });;
      }
      if(isSubmiting){
        this.submitApplication(+this.recordId);
      }
      await this.getExpensesData();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.ERROR_SYNC_PERCENTAGE"),
        this.translate.instant("applications.ERROR")
      );
    }
  }
  async onExpensesIncomeFormSubmitted(isSubmiting:boolean = false){
    try {
      const reqData = { };
      reqData['recert_applicant_signature']=this.appFormGroup.get('recert_applicant_signature')?.value;
      reqData['recert_applicant_signature_date']=this.appFormGroup.get('recert_applicant_signature_date')?.value;
      reqData['related_applications']= +this.recordId;
      reqData['expense_type']= 'Recertification';
      const tableId = this.projectSpecificData.expensesData.TableId;
      if((this.appExpenseRecertificationData && Object.keys(this.appExpenseRecertificationData).length)){
        const condition = +this.appExpenseRecertificationData?.id;
        const recordFAD = this.common.createPutData(tableId, reqData, condition);
        this.spinner.show();
        await this.ignatiusService.putData(recordFAD).toPromise();
        this.spinner.hide();
      } else {
        const recordFAD = this.common.createPostData(tableId, reqData);
        await this.ignatiusService.postData(recordFAD).toPromise().then((value:any) => {
          this.appExpenseRecertificationData = value.recordData
          // this.isExpenseDataSubmitted = true;
        });;
      }
      if(isSubmiting){
        this.submitApplication(+this.recordId);
      }
      await this.getExpensesData();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.ERROR_SYNC_PERCENTAGE"),
        this.translate.instant("applications.ERROR")
      );
    }
  }

  mailConfirmationChanged(event:any){
    if(event.value === 'Mail to New Residence Address'){
      this.appFormGroup.patchValue({
        mailing_address_1:this.appFormGroup.value.new_address_1,
        mailing_address_2:this.appFormGroup.value.new_address_2,
        mailing_city:this.appFormGroup.value.new_city,
        mailing_state:this.appFormGroup.value.new_state,
        mailing_zip:this.appFormGroup.value.new_zip,
      })
    }
    this.cdr.detectChanges();
  }

  isMailingToNewAddress(){
    return this.appFormGroup.get('mail_to').value == 'Mail to New Residence Address';
  }

  generateYearRange(): number[] {
    const currentYear = new Date().getFullYear();
    const startYear = 1970;
    return Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
  }

  isHouseHoldAddFormValid() {
    if ((this.householdAddFormGroup.value.date_of_birth && Math.abs(new Date(this.householdAddFormGroup.value.date_of_birth).getFullYear() - new Date().getFullYear()) >= 18) ||
      (this.householdAddFormGroup.value.employment_status == 'Unemployed' || this.householdAddFormGroup.value.employment_status == 'Under 18')) {
      this.requiredFields.wagesIncome = true;
      return true
    }
    this.requiredFields.wagesIncome = false;
    return false
  }

  isHouseHoldEditFormValid() {
    if (
      (this.householdFormGroup.value?.household?.[0]?.date_of_birth &&
        Math.abs(new Date(this.householdFormGroup.value?.household?.[0]?.date_of_birth).getFullYear() - new Date().getFullYear()) >= 18)
      || (this.householdFormGroup.value?.household?.[0]?.employment_status == 'Unemployed' || this.householdFormGroup.value?.household?.[0]?.employment_status == 'Under 18')) {
      this.requiredFields.wagesIncome = true;
      return true
    }
    this.requiredFields.wagesIncome = false;
    return false
  }

  onInput(event: Event,controlName:string): void {
    const input = (event.target as HTMLInputElement).value;
    let cleanedInput = input.replace(/[^0-9.]/g, '');
    const [integerPart, decimalPart] = cleanedInput.split('.');
    let formattedInput = integerPart.slice(0, 4);
    if (decimalPart !== undefined) {
      formattedInput += '.' + decimalPart.slice(0, 2);
    }
    if (formattedInput.length === 7) {
      event.preventDefault();
    }

    this.appFormGroup.controls[controlName].setValue(formattedInput);
  }
}
