<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<div class="row col-md-12">
  <div class="col-md-3">
    <ul class="float-right image-container">
      <li class="d-md-block mt-2">
        <a routerLink="/">
          <img
            src="/assets/images/LHC_Logo_70.png"
            class="d-inline-block align-top mt-1 logo-image"
            alt=""
          />
        </a>
      </li>
    </ul>
  </div>
  <div class="float-right">
    <a class="topbartoggler d-block d-md-none waves-effect waves-light toggle-button"  *ngIf="isVisible"
        (click)="isCollapsed = !isCollapsed"
        aria-controls="navbarSupportedContent">
        <i class="ti-more"></i>
    </a>
  </div>

  <div class="col-md-6 heading-container" *ngIf="!isCollapsed">
    <ul class="float-center mr-auto title-container">
      <li class="d-md-block">
        <!-- <a (click)="toggleSidebar.emit()"
                    class="nav-link sidebartoggler waves-effect waves-light">
                    <i class="icon-arrow-left-circle font-18"></i>
                </a> -->
        <div class="text-center text-white header-text">
          <br />
          <h3 class="center-text">
            {{ "applications.TITLE_1" | translate }}<br />{{
              "applications.TITLE_2" | translate
            }}
          </h3>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-md-3 user-container" *ngIf="!isCollapsed">
    <!-- ============================================================== -->
    <!-- Right side toggle and nav items -->
    <!-- ============================================================== -->
    <ul class="navbar-nav float-right profile-container">
      <app-communication style="margin-right: 12px"></app-communication>

      <li class="nav-item d-lg-block">
        <select
          class="form-control form-control-sm"
          style="
            margin-top: 15px;
            max-width: 120px;
            padding-left: 12px;
            background: #ffc832;
            color: black;
            font-weight: 500;
            border-radius: 3px;
            border: none;
          "
          (change)="changeLanguage($event.target.value)"
        >
          <option value="en">English</option>
          <option value="es">Español</option>
          <!-- <option value="ma">Marshallese</option> -->
        </select>
      </li>
      <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle waves-effect waves-dark">
                    <i class="mdi mdi-email text-white" style="font-size: 28px;"></i>
                    <div class="notify" style="top: -42px;right: -7px;"> <span class="heartbit"></span> <span
                            class="point"></span> </div>
                </a>
            </li> -->
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <li class="nav-item dropdown" ngbDropdown>
        <a
          ngbDropdownToggle
          class="nav-link waves-effect waves-dark pro-pic dropdown custom-applicant-name"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span style="font-size: 1.5em">{{ applicant }}</span>
        </a>
      </li>
      <li *ngIf="showApplicants" class="nav-item dropdown" ngbDropdown>
        <a
          ngbDropdownToggle
          class="nav-link text-white waves-effect waves-dark pro-pic dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i style="font-size: 2em" class="mdi mdi-account-switch"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <select
            [(ngModel)]="selApplicant"
            class="form-control custom-select"
            style="
              width: 200px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            (ngModelChange)="onChange($event)"
          >
            <option
              *ngFor="let app of apps"
              value="app"
              [selected]="app.applicant_name === applicant"
            >
              {{ app.applicant_name }}
            </option>
          </select>
        </div>
      </li>
      <li class="nav-item dropdown" ngbDropdown>
        <a
          ngbDropdownToggle
          class="nav-link text-white waves-effect waves-dark pro-pic dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i style="font-size: 2em" class="mdi mdi-account-circle"></i>
          <!-- <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31"> -->
        </a>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <div
            class="d-flex no-block align-items-center p-15 bg-white text-muted m-b-10"
          >
            <div class="m-l-10">
              <p class="m-b-0">{{ username }}</p>
            </div>
          </div>
          <!-- <div class="dropdown-divider"></div>
                    <a (click)="openModal(modalcontent)"
                        class="dropdown-item">
                        <i class="ti-lock m-r-5 m-l-5"></i> Change Password</a> -->
          <div class="dropdown-divider"></div>
          <a
            (click)="logout()"
            class="dropdown-item"
            href="javascript: void(0);"
          >
            <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a
          >
        </div>
      </li>
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
    </ul>
  </div>
</div>
<ng-template #modalcontent let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title">Change Password</h4>
  </div>
  <div class="modal-body" (keydown)="keyDownSubmitPassword($event)">
    <div *ngIf="modalMessage" class="alert alert-danger">
      {{ modalMessage }}
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group row">
          <div class="col-12">
            <input
              [(ngModel)]="currentPassword"
              class="form-control form-control-md"
              type="password"
              required=""
              placeholder="Current Password"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <input
              [(ngModel)]="newPassword"
              class="form-control form-control-md"
              type="password"
              required=""
              placeholder="Password"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <input
              [(ngModel)]="confirmPassword"
              class="form-control form-control-md"
              type="password"
              required=""
              placeholder="Confirm Password"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      [disabled]="!enableSavePassword()"
      type="submit"
      class="btn btn-success1 float-left"
      (click)="submitPassword()"
    >
      <i class="fa fa-check"></i>
      Save
    </button>
    <button type="button" class="btn btn-dark" (click)="modal.close()">
      Cancel
    </button>
  </div>
</ng-template>

